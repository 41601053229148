import OrganisationalChartContainer from "./components/charts/OrganisationalChart/OrganisationalChartContainer";
import ProjectSupportChartContainer from "./components/charts/ProjectSupportChart/ProjectSupportChartContainer";
import ContactsContainer from "./components/Contacts/ContactsContainer";
import CustomerDetailsContainer from "./components/Customers/CustomerDetails/CustomerDetailsContainer";
import CustomersContainer from "./components/Customers/CustomersContainer";
import RecordEngagement from "./components/Engagement/RecordEngagement/RecordEngagement";
import SetEngagement from "./components/Engagement/SetEngagement/SetEngagement";
import Summary from "./components/Engagement/Summary/Summary";
import ViewEngagement from "./components/Engagement/ViewEngagement/ViewEngagement";
import ForgotPassword from "./components/Login/ForgotPassword/ForgotPassword";
import LoginContainer from "./components/Login/LoginContainer";
import MatrixContainer from "./components/Matrix/MatrixContainer";
import OrganizationsListContainer from "./components/OrganizationsList/OrganizationsListContainer";
import ProjectDetailsFormContainer from "./components/Projects/ProjectDetailsForm/ProjectDetailsFormContainer";
import Settings from "./components/Settings/Settings";
import SynchronizationPage from "./components/Settings/SynchronizationPage";
import ProjectsContainer from "./components/Projects/ProjectsContainer";
import StakeholderAttributesPageContainer from "./components/StakeholderAttributesPage/StakeholderAttributesPageContainer";
import StakeholderMapContainer from "./components/StakeholderMap/StakeholderMapContainer";
import UsersContainer from "./components/Users/UsersContainer";
import VersionPage from "./components/VersionPage";
import Templates from "./components/Templates/Templates";
import AdminsContainer from "./components/Admins/AdminsContainer";
import MultipleTenantsLogin from "./components/Login/MultipleTenantsLogin/MultipleTenantsLogin";
import LoginRolePick from "./components/Login/LoginRolePick/LoginRolePick";

const routes = [
  {id: 1, path: '/login/create-password', component: ForgotPassword, exact: true, auth: false},
  {id: 2, path: '/login', component: LoginContainer, exact: true, auth: false},
  {id: 3, path: '/ForgotPassword', component: ForgotPassword, exact: true, auth: false},
  {id: 4, path: '/StakeholderMap', component: StakeholderMapContainer, exact: true, auth: true},
  {id: 5, path: '/StakeholderMap/StakeholderForm', component: StakeholderAttributesPageContainer, exact: true, auth: true, desktopOnly: true},
  {id: 6, path: '/Projects', component: ProjectsContainer, exact: true, auth: true, desktopOnly: true},
  {id: 7, path: '/Projects/add-project', component: ProjectDetailsFormContainer, exact: true, auth: true, desktopOnly: true},
  {id: 8, path: '/Projects/edit/:projId', component: ProjectDetailsFormContainer, exact: true, auth: true, desktopOnly: true},
  {id: 29, path: '/Projects/edit/:projId/StakeholderForm/:id', component: StakeholderAttributesPageContainer, exact: true, auth: true, desktopOnly: true},
  {id: 9, path: '/Contacts', component:ContactsContainer, exact: true, auth: true, desktopOnly: true},
  {id: 10, path: '/Contacts/StakeholderForm', component: StakeholderAttributesPageContainer, exact: true, auth: true, desktopOnly: true},
  {id: 11, path: '/Contacts/edit/:id', component: StakeholderAttributesPageContainer, exact: true, auth: true, desktopOnly: true},
  {id: 12, path: '/SupportChart', component: ProjectSupportChartContainer, exact: true, auth: true},
  {id: 13, path: '/Users', component: UsersContainer, exact: true, auth: true, desktopOnly: true},
  {id: 14, path: '/Organisations', component: OrganizationsListContainer, exact: true, auth: true, desktopOnly: true},
  {id: 15, path: '/Tenants', component: CustomersContainer, exact: true, auth: true, desktopOnly: true},
  {id: 16, path: '/Tenants/add-tenant', component: CustomerDetailsContainer, exact: true, auth: true, desktopOnly: true},
  {id: 17, path: '/Tenants/edit/:id', component: CustomerDetailsContainer, exact: true, auth: true, desktopOnly: true},
  {id: 18, path: '/Settings', component: Settings, exact: true, auth: true, desktopOnly: true},
  {id: 19, path: '/SetEngagement', component: SetEngagement, exact: true, auth: true, desktopOnly: true},
  {id: 20, path: '/RecordEngagement/:step', component: RecordEngagement, exact: true, auth: true, desktopOnly: true},
  {id: 21, path: '/ViewEngagement', component: ViewEngagement, exact: true, auth: true, desktopOnly: true},
  {id: 22, path: '/UpcomingEngagements', component: Summary, exact: true, auth: true, desktopOnly: true},
  {id: 23, path: '/office-365/authorization/code', component: SynchronizationPage, exact: true, auth: true, desktopOnly: true},
  {id: 24, path: '/google/authorization/code', component: SynchronizationPage, exact: true, auth: true, desktopOnly: true},
  {id: 25, path: '/version', component: VersionPage, exact: true, auth: true, desktopOnly: true},
  {id: 26, path: '/InfluenceMatrix', component: MatrixContainer, exact: true, auth: true},
  {id: 27, path: '/Charts/organisational', component: OrganisationalChartContainer, exact: true, auth: true},
  {id: 28, path: '/Templates', component: Templates, exact: true, auth: true},
  {id: 29, path: '/Admins', component: AdminsContainer, exact: true, auth: true, desktopOnly: true},
  {id: 30, path: '/login/choose-tenant', component: MultipleTenantsLogin, exact: true, auth: false, desktopOnly: true},
  {id: 30, path: '/login/choose-role', component: LoginRolePick, exact: true, auth: false, desktopOnly: true},
]

export default routes
