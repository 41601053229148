import { Popconfirm, Tooltip } from 'antd'
import classes from './../OrganizationsList.module.css'
import {tableWidth} from '../../common/sizes'
import {ReactComponent as EditIcon} from './../../../img/icons/editIcon.svg'
import {ReactComponent as DeleteIcon} from './../../../img/icons/deleteIcon.svg'
import {ReactComponent as ExclamationCircle} from './../../../img/icons/exclamationCircle.svg'

const partOfTableWidthForBigColumn = 0.15
const partOfTableWidthForSmallColumn = 0.11
const partOfTableWidthForName = 0.21
const partOfTableWidthForColor = 0.09

const bigColumnWidth = tableWidth * partOfTableWidthForBigColumn
const nameColumnWidth = tableWidth * partOfTableWidthForName
const columnWidth = tableWidth * partOfTableWidthForSmallColumn
const colorColumnWidth = tableWidth * partOfTableWidthForColor

const getColumns = (deleteOrganization, openOrganizationModal) => [
  { field: 'name', 
    headerName: 'Name',
    width: nameColumnWidth
  },
  { field: 'actions', 
    headerName: 'Actions', 
    sortable: false, 
    width: columnWidth,
    renderCell: (params) => {
      return (
        <div style={{display:'flex', alignItems:'center'}} >
          <EditIcon 
            className={classes.tableIcon}
            onClick={() => openOrganizationModal({isOpen: true, organizationData: params.row})}
            style={{marginRight: '15px'}}
          />
          {params.row.isMain ? (
            <Tooltip
              placement="top"
              title={
                <div style={{textAlign:'center', color:'black'}}>
                  You can't delete the main organisation
                </div>
              }
              color={'white'}
            >
              <DeleteIcon className={`${classes.tableIcon} ${classes.mainOrgTableIcon}`}/>
            </Tooltip>
          ) : (
            <Popconfirm
              title="Are you sure to delete this organisation?"
              onConfirm={() => deleteOrganization(params.row.id)}
              okText='Yes'
              cancelText='No'
              icon={<ExclamationCircle />}
              cancelButtonProps={{type: 'primary', style:{background: '#1890FF'}}}
              okButtonProps={{type: 'default'}}
            >
              <DeleteIcon className={classes.tableIcon}/>
            </Popconfirm>
          )}
        </div>
      )
    }
  },
  { field: 'color', 
    headerName: 'Color', 
    width: colorColumnWidth, 
    renderCell: (params) => {
      const colorBlockStyle = {
        backgroundColor: params.row.color || 'gray',
        borderRadius: '20px',
        height: '70%',
        width: '100%',
      }
      return (
        <div style={colorBlockStyle}></div>
      )
    }
  },
  { field: 'nickname', 
    headerName: 'Short Name', 
    width: bigColumnWidth
  },
  { field: 'location', 
    headerName: 'Location', 
    width: bigColumnWidth
  },
  { field: 'address', 
    headerName: 'Address', 
    width: bigColumnWidth
  },
  { field: 'status', 
    headerName: 'Status', 
    width: columnWidth, 
    renderCell: (params) => {
      const color = params.row.isActive ? 'rgba(194, 232, 18, 0.6)' : 'rgba(246, 88, 88, 0.6)'
      return (
        <div className={classes.tableIsActiveColumn} style={{backgroundColor: color}}>
          {params.row.isActive ? 'Active' : 'Inactive'}
        </div>
      )
    }
  },
]

export default getColumns
