import  React, { useEffect, useState } from 'react'
import { DataGridPro, GridToolbarColumnsButton, GridToolbarContainer, useGridApiRef} from '@mui/x-data-grid-pro'
import classes from './../OrganizationsList.module.css'
import getColumns from './getColumns'
import { useDispatch, useSelector } from 'react-redux'
import { setTableColumns } from '../../../store/tablesReducer'

const OrganizationsTable = (props) => {
  const [page, setPage] = useState(0)

  const deleteOrganization = props.deleteOrganization
  const openOrganizationModal = props.setOrganizationModalData
  const tableColumns = getColumns(deleteOrganization, openOrganizationModal)
  const tableAppearance = useSelector(state => state.tablesReducer?.organisationsTable)
  const apiRef = useGridApiRef()
  const dispatch = useDispatch()

  useEffect(() => {
    setPage(0)
  }, [props?.tableRows])

  useEffect(() => {
    apiRef.current.subscribeEvent('columnHeaderDragEnd', () => saveTableColumns())
    // eslint-disable-next-line
  }, [apiRef])

  const saveTableColumns = () => {
    const stateColumns = apiRef.current.getAllColumns()
    const colsData = Object.keys(stateColumns).map(key => stateColumns[key])
    dispatch(setTableColumns('organisationsTable', colsData))
  }
  return (
    <div className={classes.tableWrapper}>
      <DataGridPro
        rows={props.tableRows ? props.tableRows : []} 
        apiRef={apiRef}
        onColumnWidthChange={(e) => saveTableColumns()}
        columns={tableAppearance.length ? tableAppearance : tableColumns}  
        pageSize={10} 
        rowsPerPageOptions={[10]}
        style={{borderRadius:'15px', border:'none'}}
        components={{
          Toolbar: CustomToolbar,
        }}
        rowCount={props.tableRows?.length || 0}
        pagination
        setPage={page}
        onPageChange={(e) => setPage(e.pageCount - 1)}
      />
    </div>
  )
}

const CustomToolbar = () => {
  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
    </GridToolbarContainer>
  )
}

export default OrganizationsTable
