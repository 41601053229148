import React, {useEffect, useState} from 'react'
import { useSelector, useDispatch } from 'react-redux'
import NewWorkerModal from './NewWorkerModal'
import {setIsWorkerModalOpen} from '../../../../../store/canvasReducer'
import {addStakeholderToProject} from '../../../../../store/stakeholderMapReducer'
import {setFormData} from '../../../../../store/stakeholderFormReducer'
import { getStakeholders } from '../../../../../store/stakeholdersReducer'

const NewWorkerModalContainer = () => {
  const workers= useSelector(state => state.stakeholderMapReducer.workers)
  const isWorkerModalOpen= useSelector(state => state.canvasReducer.workers.isWorkerModalOpen)
  const selectedProject= useSelector(state => state.projectsReducer.selectedProject)
  const organizations= useSelector(state => state.organizationsReducer.organizations)
  const stakeholders= useSelector(state => state.stakeholdersReducer.stakeholders)
  const fullFormInitialValues= useSelector(state => state.stakeholderFormReducer.initialValues)
  const userId= useSelector(state => state.authReducer.userId)

  const dispatch = useDispatch()
  const [selectedWorker, setSelectedWorker] = useState(null)
  const [activeTab, setActiveTab] = useState('1')
  const [isAdded, setIsAdded] = useState(false)
  const [error, setError] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [orgOptions, setOrgOptions] = useState([])

  useEffect(() => {
    dispatch(getStakeholders())
  }, [dispatch])

  useEffect(() => {
    setOrgOptions(getOrganizationOptions())
    // eslint-disable-next-line
  }, [organizations])

  const getStakeholderOptions = () => {
    return !!stakeholders.length
    ? stakeholders
      .filter(stk => !workers.some(worker => worker.stakeholderId === stk.stakeholderId))
      .map(stk => ({id: stk.stakeholderId, name: stk.fullName, value: stk.stakeholderId}))
      .sort((a,b) => {
        if (a.name < b.name) return -1;
        if (a.name > b.name) return 1;
        return 0;
      })
    : []
  }

  const getOrganizationOptions = () => {
    const placeholderOption = {id: 0, value: 'none', name: 'Organisation', disabled: true}
    const options = organizations
      ?.map(org => ({id: org.orgId, name: org.name, value: org.orgId}))
      ?.sort((a,b) => {
        if (a.title < b.title) return -1;
        if (a.title > b.title) return 1;
        return 0;
      })
    return !!organizations.length ? [placeholderOption, ...options] : []
  }

  const closeModal = () => {
    setSelectedWorker(null)
    dispatch(setIsWorkerModalOpen(false))
    setActiveTab('1')
    setIsAdded(false)
  }
  const clearTab = (tabKey) => {
    setActiveTab(tabKey)
    if(tabKey === '2') {
      setSelectedWorker(null)
    }
  }

  const setDataForFullForm = (data) => {
    dispatch(setFormData(
      {
        ...fullFormInitialValues,
        firstNameKnownAs: data.firstNameKnownAs,
        firstName: data.firstName,
        lastName: data.lastName,
        orgId: data.organizationId,
        projectRelations: [{
          influenceLvl: data.influence,
          interestLvl: data.interest,
          isDisrupter: null,
          projId: selectedProject,
          roleDescription: "",
          roleTitle: data.projectRole,
          supportLvl: data.support,
          workloadPct: null,
          isProjectTeamMember: true,
        }]
      },
      [1],
      [1]
    ))
  }

  const addNewWorker = (formData) => {
    setIsLoading(true)
    if (selectedWorker && workers.some(worker => worker.id === selectedWorker)) {
      return alert('this worker is already in this project!')
    } else if (!selectedWorker) {
    const isNewCompany = !!formData.newOrganizationName
      return dispatch(addStakeholderToProject(selectedProject, null, formData, isNewCompany, userId))
       .then((isSaved) => {!!isSaved && setIsAdded(true) && setIsLoading(false)})
       .catch(error => setError(error?.response?.data ? error.response.data?.message : 'Something went wrong'))
    } else {
      const stkData = stakeholders?.find(stk => stk.stakeholderId === selectedWorker)
      const newProjectData = {
        influenceLvl: formData.influence,
        interestLvl: formData.interest,
        isDisrupter: null,
        projId: selectedProject,
        roleDescription: "",
        roleTitle: formData.projectRole,
        supportLvl: formData.support,
        workloadPct: null,
        isProjectTeamMember: true
      }
      const updatedStakeholder = {...stkData, projectRelations: stkData.projects ? [...stkData.projects, newProjectData] : [newProjectData]}
      dispatch(addStakeholderToProject(selectedProject, selectedWorker, updatedStakeholder, false, userId))
        .then((isSaved) => isSaved && setIsAdded(true) && setIsLoading(false))
        .catch(error => setError(error?.response?.data ? error.response.data?.message : 'Something went wrong'))
    }
  }

  return (
    <NewWorkerModal 
      workers={workers}
      activeTab={activeTab}
      addNewWorker={addNewWorker}
      clearTab={clearTab}
      closeModal={closeModal}
      isWorkerModalOpen={isWorkerModalOpen}
      organizationOptions={orgOptions}
      selectedWorker={selectedWorker}
      setSelectedWorker={setSelectedWorker}
      stakeholderOptions={getStakeholderOptions()}
      isAdded={isAdded}
      error={error}
      setDataForFullForm={setDataForFullForm}
      setError={setError}
      isLoading={isLoading}
    />
  )
}

export default NewWorkerModalContainer
