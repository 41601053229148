import axios from '../helpers/axiosHelper'
import {
  getIsSynchronizedWithOffice,
  setIsSynchronizedWithOffice,
  getIsSynchronizedWithGoogle,
  setIsSynchronizedWithGoogle
} from '../helpers/localStorage'
import {handleError} from './errorsReducer'

const initialState = {
  isSynchronizedWithOffice: getIsSynchronizedWithOffice(),
  isSynchronizedWithGoogle: getIsSynchronizedWithGoogle(),
  googleSyncLink: null,
}

const synchronizationReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_IS_SYNCHRONIZED_WITH_OFFICE':
      return {...state, isSynchronizedWithOffice: action.isSynchronized} 
    case 'SET_IS_SYNCHRONIZED_WITH_GOOGLE':
      return {...state, isSynchronizedWithGoogle: action.isSynchronized}
    case 'SET_GOOGLE_SYNC_LINK':
      return {...state, googleSyncLink: action.link}
    default: return state
  } 
}

export const setIsSynchronizedWithOfficeAC = (isSynchronized) => ({type: 'SET_IS_SYNCHRONIZED_WITH_OFFICE', isSynchronized})
export const setIsSynchronizedWithGoogleAC = (isSynchronized) => ({type: 'SET_IS_SYNCHRONIZED_WITH_GOOGLE', isSynchronized})
export const setGoogleSyncLinkAC = (link) => ({type: 'SET_GOOGLE_SYNC_LINK', link})

export const synchronizeWithOfficeTC = (code) => (dispatch) => {
  return axios.get(`/office-365/authorization?code=${code}`)
    .then(response => {
      if (response.status === 200 ) {
        if (!!response.data.is_successful) {
          dispatch(setIsSynchronizedWithOfficeAC(true))
          setIsSynchronizedWithOffice(true)
          return true
        } else {
          return false
        }
      } else {
        dispatch(handleError(response.data))
      }
    })
  .catch(error => {
    dispatch(handleError(error))
    return error
  })
}

export const deleteDataAndSynchronizeWithOfficeTC = () => (dispatch) => {
  return axios.post(`/office-365/authorization`)
    .then(response => {
      if (response.status === 200 ) {
        dispatch(setIsSynchronizedWithOfficeAC(true))
        setIsSynchronizedWithOffice(true)
        return true
      } else {
        dispatch(handleError(response.data))
      }
    })
  .catch(error => {
    dispatch(handleError(error))
    return error
  })
}

export const stopSyncWithOfficeTC = () => (dispatch) => {
  return axios.get(`/office-365/logout`)
    .then(response => {
      if (response.status === 200 ) {
        dispatch(setIsSynchronizedWithOfficeAC(false))
        setIsSynchronizedWithOffice(false)
        return true
      } else {
        dispatch(handleError(response.data))
      }
    })
  .catch(error => {
    dispatch(handleError(error))
  })
}

export const syncContactsFromOfficeTC = () => (dispatch) => {
  return axios.post(`/office-365/contacts/synchronization`)
    .then(response => {
      if (response.status === 200 ) {
        return true
      } else {
        dispatch(handleError(response.data))
      }
    })
  .catch(error => {
    dispatch(handleError(error))
  })
}

export const getGoogleSyncLinkTC = () => (dispatch) => {
  return axios.get(`/google/authorization/url`)
    .then(response => {
      if (response.status === 200 ) {
        dispatch(setGoogleSyncLinkAC(response.data.message))
      } else {
        dispatch(handleError(response.data))
      }
    })
  .catch(error => {
    dispatch(handleError(error))
  })
}

export const synchronizeWithGoogleTC = (code) => (dispatch) => {
  return axios.get(`/google/authorization?code=${code}`)
    .then(response => {
      if (response.status === 200 ) {
        if (!!response.data.is_successful) {
          dispatch(setIsSynchronizedWithGoogleAC(true))
          setIsSynchronizedWithGoogle(true)
          return true
        } else {
          return false
        }
      } else {
        dispatch(handleError(response.data))
      }
    })
  .catch(error => {
    dispatch(handleError(error))
    return error
  })
}

export const stopSyncWithGoogleTC = () => (dispatch) => {
  return axios.get(`/google/logout`)
    .then(response => {
      if (response.status === 200 ) {
        dispatch(setIsSynchronizedWithGoogleAC(false))
        setIsSynchronizedWithGoogle(false)
        return true
      } else {
        dispatch(handleError(response.data))
      }
    })
  .catch(error => {
    dispatch(handleError(error))
  })
}

export const syncContactsFromGoogleTC = () => (dispatch) => {
  return axios.post(`/google/contacts/synchronization`)
    .then(response => {
      if (response.status === 200 ) {
        return true
      } else {
        dispatch(handleError(response.data))
      }
    })
  .catch(error => {
    dispatch(handleError(error))
  })
}

export const deleteDataAndSynchronizeWithGoogleTC = () => (dispatch) => {
  return axios.post(`/google/authorization`)
    .then(response => {
      if (response.status === 200 ) {
        dispatch(setIsSynchronizedWithGoogleAC(true))
        setIsSynchronizedWithGoogle(true)
        return true
      } else {
        dispatch(handleError(response.data))
      }
    })
  .catch(error => {
    dispatch(handleError(error))
    return error
  })
}

export default synchronizationReducer
