import  React, { useRef } from 'react'
import classes from './Projects.module.css'
import { PrimaryButton } from '../common/buttons/buttons'
import {pageDefaultHeight} from './../common/sizes'
import ProjectsTable from './ProjectsTable/ProjectsTable'
import Search from './../common/Search/Search'
import Select from './../common/Select/Select'
import UserInfoArea from '../common/UserInfoArea/UserInfoArea'
import { Link } from 'react-router-dom'
import {CSVLink} from 'react-csv'
import moment from 'moment'
import Spinner from './../common/Spinner/Spinner'

const Projects = (props) => {
  const csvLink = useRef()

  if (props.isLoading) {
    return <div style={{height: '100%'}}><Spinner /></div>
  }

  return (
    <>
      <header className={classes.header}>
        <Search onChange={event => props.search(event.target.value)} />
        <UserInfoArea onExport={() => csvLink.current.link.click()} isCsvExport/>
      </header>
      <div className={classes.wrapper} style={{height: pageDefaultHeight}}>
        <div className={classes.titleArea} >
          <h1 className={classes.pageTitle}>
            PROJECTS LIST
          </h1>
          <div>
            <Select 
              options={props.projectPeriodOptions}
              onSelect={(value) => props.selectProjectPeriod(value)}
              style={{width: '200px', marginRight: '15px'}}
              value={props.selectedProjectPeriod}
              className='smallSelect'
            />
            <Link to='/Projects/add-project'>
              <PrimaryButton text='ADD PROJECT' />
            </Link>
          </div>
        </div>
        <ProjectsTable deleteProject={props.deleteProject} tableRows={props.tableRows}/>
      </div>
      <CSVLink 
        data={props.tableRows 
            ? props.tableRows
              .map(row => (
                { ...row,
                  activeFrom: moment(row.activeFrom).format('DD.MM.YYYY'),
                  activeTill: moment(row.activeTill).format('DD.MM.YYYY')
                }
              ))
              .sort((a, b) => {
                if(a.name < b.name) { return -1 }
                if(a.name > b.name) { return 1 }
                return 0
              })
            : []
        }
        headers={props.exportHeaders}
        style={{display:'none'}}
        ref={csvLink}
        filename={`Projects_${props.selectedProjectPeriod}.csv`}
      >
        Download
      </CSVLink>
    </>
  )
}

export default Projects
