import React from 'react'

const WorkerDefaultIcon = (props) => {
  const iconColor = props.color || '#C2E812'
  const className = props.className || null
  return (
    <svg 
      className={className}       
      fill='none' 
      x={props.x || null} 
      y={props.y || null} 
      height='39' 
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 39 39' 
      width='39' 
    >
      <path d='M18.5217 3C14.1675 3 10.6377 6.47939 10.6377 10.7714C10.6377 15.0635 14.1675 18.5429 18.5217 18.5429C22.876 18.5429 26.4058 15.0635 26.4058 10.7714C26.4058 6.47939 22.876 3 18.5217 3Z' fill={iconColor}/>
      <path d='M23.1744 22.0003C20.0921 21.5154 16.9514 21.5154 13.8691 22.0003L13.5387 22.0523C9.19568 22.7355 6 26.4281 6 30.7633C6 33.1031 7.92434 35 10.2981 35H26.7453C29.1191 35 31.0435 33.1031 31.0435 30.7633C31.0435 26.4281 27.8478 22.7355 23.5048 22.0523L23.1744 22.0003Z' fill={iconColor}/>
    </svg>
  )
}

export default WorkerDefaultIcon
