import { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { notification } from 'antd'
import classes from './ForgotPassword.module.css'
import {ReactComponent as CompanyLogo} from './../../../img/companyLogo.svg'
import {ReactComponent as EmailIsSent} from './../../../img/emailIsSent.svg'
import {ReactComponent as Refresh} from './../../../img/icons/refresh.svg'
import { ControlledInput } from '../../common/Input/Input'
import { PrimaryButton } from '../../common/buttons/buttons'
import { createNewPassword, sendResetPasswordEmail } from '../../../store/authReducer'
import { useMediaQuery } from 'react-responsive'

const ForgotPassword = () => {
  const dispatch = useDispatch()
  const location = useLocation()
  const [email, setEmail] = useState('')
  const [isEmailSent, setIsEmailSent] = useState(false)
  const [isSending, setIsSending] = useState(false)

  const isCreatingPassword = location.pathname.includes('/login/create-password')

  const openNotificationWithIcon = type => {
    notification[type]({
      message: 'Email is sent!',
    });
  };

  const sendEmail = (isSentAgain) => {
    setIsSending(true)
    dispatch(sendResetPasswordEmail(email))
      .then((resp) => {
        !!resp && setIsEmailSent(true)
        !!isSentAgain && openNotificationWithIcon('success')
        setIsSending(false)
      })
  }
  const isSmallScreen = useMediaQuery({ query: '(min-width: 300px) and (max-width: 427px)' })

  return (
    <>
      <div className={classes.wrapper}>
        {!!isEmailSent ? (
          <div className={classes.contentWrapper} style={{padding: '0px', height: isSmallScreen ? 'auto' : '90%'}}>
            <CompanyLogo className={classes.companyLogo} style={{width: '30%', margin: '0px'}}/>
            <div className={classes.title} style={{margin: '0px'}}> 
              Check your email!
            </div>
            <div>
              We sent you an email to reset your password.
            </div>
            <EmailIsSent style={{height:'50%', width: isSmallScreen ? '100%' : ''}}/>
            <div style={{marginTop: '10px'}}>
              Didn’t get an email?
            </div>
            <div style={{display: 'flex', alignItems: 'center', marginTop: '7px'}}>
              Check your spam folder or
              <div className={classes.sendAgain} onClick={() => sendEmail(true)}>
                <Refresh style={{margin: '0px 7px'}}/> Send again
              </div>
            </div>
          </div>
        ) : (
          <div className={classes.contentWrapper}>
            <CompanyLogo className={classes.companyLogo}/>
            {isCreatingPassword 
              ? <SaveNewPasswordForm />
              : <SendEmailForm
                  setEmail={setEmail}
                  sendEmail={sendEmail}
                  email={email}
                  isSending={isSending}
                />
            }
          </div>
        )}
      </div>
    </>
  )
}

const SendEmailForm = (props) => {
  return (
    <>
      <div className={classes.title}> 
        FORGOT YOUR PASSWORD?
      </div>
      <div className={classes.description}>
        Enter the email you used when you joined and we will send you link to reset your password.
      </div>
      <ControlledInput
        label='Email'
        placeholder='Enter your email'
        value={props.email}
        onChange={(event) => props.setEmail(event.target.value)}
      />
      <PrimaryButton
        text='SEND'
        style={{width: '132px', marginBottom: '15px'}}
        onClick={() => props.sendEmail(false)}
        loading={props.isSending}
      />
      <Link to='/login' style={{color:'black', cursor: 'pointer', textDecoration: 'underline'}}>
        Back to Sign in
      </Link>
    </>
  )
}

const SaveNewPasswordForm = () => {
  const location = useLocation()
  const dispatch = useDispatch()
  const [password, setPassword] = useState('')
  const [repeatPassword, setRepeatPassword] = useState('')
  const [validationErr, setValidationErr] = useState('')
  const [isChanged, setIsChanged] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const changePassword = () => {
    if (password !== repeatPassword) {
      setValidationErr('Passwords do not match!')
    } else {
      setIsLoading(true)
      //  search example ?token=9bd2f33c-083b-4c64-9360-069ea01bb72d
      const token = location.search.split('token=')[1]
      dispatch(createNewPassword(password, token))
        .then((resp) => {
          !!resp && setIsChanged(true)
          setIsLoading(false)
        })
    }
  }

  useEffect(() => {
    if (!!validationErr && password === repeatPassword) {
      setValidationErr('')
    }
  }, [password, repeatPassword, validationErr])

  return (
    <>
      {!!isChanged ? (
        <>
          <div className={classes.title}> 
            YOUR PASSWORD HAS BEEN CHANGED
          </div>
          <Link to='/login'>
            <PrimaryButton text='TO SIGN IN'/>
          </Link>
        </>
      ) : (
        <>
          <div className={classes.title}> 
            CREATE NEW PASSWORD
          </div>
          <ControlledInput
            label='New Password'
            placeholder='Enter new password'
            value={password}
            onChange={(event) => setPassword(event.target.value)}
            InputProps={{ type:'password' }}
          />
          <ControlledInput
            label='Repeat new Password'
            placeholder='Repeat new password'
            value={repeatPassword}
            onChange={(event) => setRepeatPassword(event.target.value)}
            InputProps={{ type:'password' }}
          />
          {!!validationErr &&
            <div className={classes.errorMessage}>
              {validationErr}
            </div>
          }
          <PrimaryButton
            text='SET PASSWORD'
            style={{width: 'fit-content', marginBottom: '15px', outline: 'none'}}
            onClick={changePassword}
            loading={isLoading}
          />
        </>
      )}
    </>
  )
}

export default ForgotPassword
