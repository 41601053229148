import React from 'react'
import {Formik, Form} from 'formik'
import * as Yup from 'yup'
import classes from './Login.module.css'
import {PrimaryButton} from '../common/buttons/buttons'
import {Input} from '../common/Input/Input'
import {SelectForFormik} from "../common/Select/Select";
import {useDispatch, useSelector} from "react-redux";
import {logIn} from "../../store/authReducer";
import {setLoginRolePicked} from "../../helpers/localStorage";

const LoginForm = (props) => {
    const currentLoginData = useSelector(state => state.authReducer.currentLoginData)
    const loginResponseData = useSelector(state => state.authReducer.loginResponseData)
    const rolesForLogin = useSelector(state => state.authReducer.rolesForLogin)

    const dispatch = useDispatch()
    const initialValues = {
        password: props.isMultipleLogin ? currentLoginData?.password : '',
        email: props.isMultipleLogin ? currentLoginData?.email : '',
    }
    const tenantsForLogin = useSelector(state => state.authReducer.tenantsForLogin)

    const optionsForTenantsSelect = tenantsForLogin.map((t) => {
        return (
            {id: t.custId, value: t.custId, title: t.name}
        )
    })

    const rolePriority = {
        'system admin': 1,
        'project admin': 2,
        'user': 3
    };

    const optionsForRoleSelect = rolesForLogin
        .map((t) => ({id: t.id, value: t.code, title: t.code}))
        .sort((a, b) => (rolePriority[a.value] || 99) - (rolePriority[b.value] || 99));

    const validationSchema = !props.isMultipleLogin && !props.isRolePicker ? Yup.object({
            email: Yup.string()
                .required('Email is required'),
            password: Yup.string()
                .required('Password is required'),
        }) :
        props.isRolePicker
            ?
            Yup.object({
                role: Yup.string()
                    .required('Role is required')
            })
            :
            Yup.object({
                cust_id: Yup.number()
                    .required('Tenant is required')
            })

    const onSubmit = (loginData) => {
        if (!props.isRolePicker || props.isRolePicker === false) {
            props.signIn(loginData)
        } else {
            dispatch(logIn({...loginResponseData, email: currentLoginData?.email}, loginData.role))
        }

    }

    return (
        <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
            {({setFieldValue, values, errors}) => {
                const onEmailChange = (e) => {
                    setFieldValue('cust_id', e.target.value)
                }

                const onRoleSelect = (e) => {
                    setFieldValue('role', e.target.value)
                    setLoginRolePicked(e.target.value)
                }

                return (
                    <Form className={classes.formWrapper} style={{
                        width: '100%'
                    }}>

                        {
                            props.isMultipleLogin
                                ?
                                <>
                                    <SelectForFormik
                                        label='Tenant'
                                        name='cust_id'
                                        options={optionsForTenantsSelect}
                                        className={props.values?.empType === 'none' ? 'placeholderIsShown InputAutoWidth' : 'InputAutoWidth'}
                                        InputProps={{
                                            onChange: onEmailChange
                                        }}
                                    />
                                </>
                                :
                                props.isRolePicker
                                    ?
                                    <>
                                        <SelectForFormik
                                            label='Role'
                                            name='role'
                                            options={optionsForRoleSelect}
                                            className={props.values?.empType === 'none' ? 'placeholderIsShown InputAutoWidth' : 'InputAutoWidth'}
                                            InputProps={{
                                                onChange: onRoleSelect
                                            }}
                                        />
                                    </>
                                    :

                                    <>
                                        <Input
                                            label='Email'
                                            name='email'
                                            placeholder='Enter your email'
                                        />

                                        <Input
                                            label='Password'
                                            name='password'
                                            placeholder='Enter your password'
                                            InputProps={{type: 'password'}}
                                            isLogin={true}
                                        />
                                    </>

                        }
                        <div
                            className={classes.loginError}
                            style={{display: props.loginError ? 'block' : 'none'}}
                        >
                            {props.loginError?.message ? (
                                props.loginError?.message
                            ) : (
                                'An unexpected error has occurred'
                            )}
                        </div>
                        <PrimaryButton htmlType='submit'
                                       loading={props.isLoading}
                                       text={props.isMultipleLogin || props.isRolePicker ? 'CONFIRM' : 'SIGN IN'}
                                       style={{height: '36px', width: '132px'}}/>
                    </Form>
                )
            }}

        </Formik>
    )
}

export default LoginForm
