import React, {useCallback, useEffect, useState} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Customers from './Customers'
import {
  deleteCustomer,
  getCustomers,
  setCustomer,
  setCustomerUsers
} from './../../store/customersReducer'
import moment from 'moment'
import {getTenantsListFilter, getTenantsSearchString} from "../../helpers/localStorage";
import {changeAddTenantFormValues} from "../../store/formValuesReducer";

const CustomersContainer = () => { 
  const dispatch = useDispatch() 
  const [searchResults, setSearchResults] = useState(null)
  const [selectedIsActive, setSelectedIsActive] = useState(getTenantsListFilter())
  const customers = useSelector(store => store.customersReducer.customers)
  const searchedValue = getTenantsSearchString()

  useEffect(() => {
    dispatch(getCustomers(selectedIsActive))
    dispatch(setCustomer({}))
    dispatch(setCustomerUsers([]))
    dispatch(changeAddTenantFormValues(null, "logo"))
    dispatch(changeAddTenantFormValues('', "name"))
    dispatch(changeAddTenantFormValues('', "activeFrom"))
    dispatch(changeAddTenantFormValues('', "activeTill"))
  }, [selectedIsActive, dispatch])


  useEffect(() => {
    const hasInactiveItem = selectedIsActive === 'ACTIVE' &&
        customers
            .filter(cust => moment().isAfter(moment(cust.activeTill)))
            .some(item => item.name?.toLowerCase().includes(searchedValue?.toLowerCase()))

    const hasActiveItem = selectedIsActive === 'INACTIVE' &&
        customers
            .filter(cust => moment().isBefore(moment(cust.activeTill)))
            .some(item => item.name?.toLowerCase().includes(searchedValue?.toLowerCase()))
    if (hasInactiveItem || hasActiveItem) dispatch(getCustomers(selectedIsActive))
  }, [customers, dispatch, selectedIsActive, searchedValue])
  
  const onSearch = useCallback((searchQuery) => {
    if (!searchQuery?.length) {
      setSearchResults(null)
    } else {
      const results = customers.filter(item => item.name?.toLowerCase().includes(searchQuery?.toLowerCase()))
      setSearchResults(results)
    }
  }, [customers])

  useEffect(() => {
    onSearch(searchedValue)
  }, [searchedValue, customers, onSearch])

  const removeCustomer = (custId) => {
    dispatch(deleteCustomer(custId))
        .then(() => {
          dispatch(getCustomers(selectedIsActive))
          dispatch(setCustomer({}))
          dispatch(setCustomerUsers([]))
        })
  }

  const periodOptions = [
    {id: 1, value: 'ALL', name: 'All'},
    {id: 2, value: 'ACTIVE', name: 'Active'},
    {id: 3, value: 'INACTIVE', name: 'Inactive'},
  ]

  return (
    <Customers 
      customers={searchResults ? searchResults : customers}
      deleteCustomer={removeCustomer}
      selectedIsActive={selectedIsActive}
      setSelectedIsActive={setSelectedIsActive}
      periodOptions={periodOptions}
      searchValue={searchedValue}
      onSearch={onSearch}
    />
  )
}

export default CustomersContainer
