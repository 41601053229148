import React, {useState} from 'react'
import {connect, useSelector} from 'react-redux'
import Login from "../Login";
import {logIn} from "../../../store/authReducer";
import {Navigate} from "react-router";

const LoginRolePick = (props) => {
    const [loginError, setLoginError] = useState(null)
    const [isAccessModalOpen, setIsAccessModalOpen] = useState(false)
    const currentUserRoles = useSelector(store => store.authReducer.userRoles)
    const isSystemAdmin = currentUserRoles?.some(role => role.code.toLowerCase() === 'system admin')
    const [isLoading, setIsLoading] = useState(false)

    if (props.isSignedIn && !isSystemAdmin) {
        return <Navigate to='/StakeholderMap' />
    }
    if (props.isSignedIn && isSystemAdmin){
        return <Navigate to='/Tenants' />
    }


    return (
        <Login
            loginError={loginError}
            isAccessModalOpen={isAccessModalOpen}
            setIsAccessModalOpen={setIsAccessModalOpen}
            isMultipleTenant={false}
            isRolePicker={true}
            isLoading={isLoading}
        />
    )
}

const mapSateToProps = (state) => ({
    isSignedIn: state.authReducer.isSignedIn,
})

export default connect(mapSateToProps,{logIn})(LoginRolePick)