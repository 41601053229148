import React from 'react'
import {Form, Formik} from 'formik'
import { Modal} from 'antd'
import classes from './ConnectionInfoModal.module.css'
import { PrimaryButton, SecondaryButton } from '../../../../common/buttons/buttons'
import Slider from '../../../../common/Slider/Slider'
import {Input} from '../../../../common/Input/Input'
import Switch from '../../../../common/Switch/Switch'

const ConnectionInfoModal = (props) => {
  return (
    <Modal 
      closable={false}
      onCancel={props.closeModal}
      style={{padding: '10px'}}
      visible={props.isConnectionInfoModalOpen} 
      footer={null}
    >
      {props.isConnectionDeleted ? (
        <div className={classes.connectionDeletedMessage}>
          Connection has been deleted!
          <PrimaryButton text='OK' onClick={props.closeModal} style={{marginTop: '15px'}}/>
        </div>
      ) : (
        <ModalForm 
          closeModal={props.closeModal}
          connectionData={props.selectedElement.data}
          saveChanges={props.saveChanges}
          deleteConnection={props.deleteConnection}
          isConnectionDeleted={props.isConnectionDeleted}
          isDeleting={props.isDeleting}
        />
      )}
    </Modal>
  )
}

const ModalForm = (props) => {
  const initialValues = {
    purpose: props.connectionData.purpose,
    strength: props.connectionData.strength
  }

  const onSubmit = (formData) => {
    props.saveChanges(formData)
  }

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit}>
      {({setFieldValue, values}) => (
        <Form className={classes.form}>
          <div className={classes.inputLabel}>
            Relationship Purpose
          </div>
          <div style={{height: '180px'}}>
            <Input 
              name='purpose'
              placeholder='Purpose'
              multiline={true}
              rows={6}
            />
          </div>
          <Switch
            leftLabel={<span style={{fontWeight: 600}}>Relationship Strength</span>}
            checked={values.strength !== null}
            onChange={() => values.strength !== null ? setFieldValue('strength', null) : setFieldValue('strength', 1)}
          />
          {values.strength !== null &&
            <>
              <div className={classes.inputLabel}>
                Relationship Strength
              </div>
              <div style={{width: '70%'}}>
                <Slider 
                  defaultValue={values.strength}
                  onChange={(_, value) => setFieldValue('strength', value)}
                  value={values.strength} 
                  min={1}
                  max={10}
                />
              </div>
            </>
          }
          <div className={classes.buttonsArea}>
            <SecondaryButton
              text='DELETE'
              onClick={() => props.deleteConnection()}
              loading={props.isDeleting}
              style={{height: 'auto', display: 'flex'}}
            />
            <div>
              <SecondaryButton
                onClick={() => props.closeModal()}
                style={{width:'100px', height: 'auto'}}
                text='CANCEL' 
              />
              <PrimaryButton 
                className={classes.primaryButton}    
                htmlType='submit' 
                style={{width:'100px', height: '34px', marginLeft: '10px'}}
                text='SAVE' 
              />
            </div>
          </div>
        </Form>
      )}
    </Formik>
  )
}

export default ConnectionInfoModal
