import { Dialog, DialogContent } from '@material-ui/core'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, Link } from 'react-router-dom'
import { deleteDataAndSynchronizeWithGoogleTC, deleteDataAndSynchronizeWithOfficeTC, synchronizeWithGoogleTC, synchronizeWithOfficeTC } from '../../store/synchronizationReducer'
import { PrimaryButton, SecondaryButton } from '../common/buttons/buttons'
import Spinner from '../common/Spinner/Spinner'
import UserInfoArea from '../common/UserInfoArea/UserInfoArea'
import classes from './Settings.module.css'
import {Navigate} from "react-router";

const SynchronizationPage = () => {
  const [isLoading, setIsLoading] = useState(true)
  const [error, setError] = useState('')
  const [isSyncLoading, setIsSyncLoading] = useState(false)
  const location = useLocation()
  const dispatch = useDispatch()
  const isAuth = useSelector(store => store.authReducer.isSignedIn)

  useEffect(() => {
    // search example ?code=M.R3_BAY.bb28b31b-6c81-17e9-9588-b303c849cf9a
    const code = location.search.split('code=')[1]
    if(location.pathname.includes('google')) {
      dispatch(synchronizeWithGoogleTC(code))
      .then((resp) => {
        if (typeof resp !== 'boolean') {
          setError(resp?.response?.data?.message || 'Something went wrong :(')
        } else if (!resp) {
          setError('showWarning')
        }
        setIsLoading(false)
      })
    } else {
      dispatch(synchronizeWithOfficeTC(code))
      .then((resp) => {
        if (typeof resp !== 'boolean') {
          setError(resp?.response?.data?.message || 'Something went wrong :(')
        } else if (!resp) {
          setError('showWarning')
        }
        setIsLoading(false)
      })
    }
  }, [location, dispatch])

  const deleteDataAndSynchronizeAgain = () => {
    setIsSyncLoading(true)
    dispatch(
      location.pathname.includes('google')
        ? deleteDataAndSynchronizeWithGoogleTC()
        : deleteDataAndSynchronizeWithOfficeTC()
    )
      .then((resp) => {
        if (typeof resp !== 'boolean') {
          setError(resp?.response?.data?.message || 'Something went wrong :(')
        } else {
          !!resp && setError('')
        }
        setIsSyncLoading(false)
      })
  }

  const getMessage = () => {
    let message
    let okBtn = true
    if (!error) {
      message = <div className={classes.synchronizationMessage}>Synchronization setting is done</div>
    } else if (error === 'showWarning' ) {
      message = (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            fontSize: '18px',
            alignItems: 'center',
            justifyContent:'center',
            textAlign:'center'
          }}
        >
          {location.pathname.includes('google')
            ? 'Data from Contacts\\Stakeholders group will be deleted. Confirm'
            : 'Data from Outlook\\Stakeholders folder will be deleted. Confirm'
          }
          <div style={{marginTop: '10px', display:'flex'}}>
            <SecondaryButton text='NO' style={{marginRight: '10px'}} onClick={() => setError('needToDeleteData')}/>
            <PrimaryButton text='YES' onClick={deleteDataAndSynchronizeAgain} loading={isSyncLoading}/>
          </div>
        </div>
      )
      okBtn = false
    } else if (error === 'needToDeleteData' ) {
      message = (
        <div style={{textAlign:'center'}}  className={classes.synchronizationMessage}>
          Synchronization is not possible. You need to confirm deleting data from {location.pathname.includes('google') ? 'Contacts\\Stakeholders group' :'Outlook\\Stakeholders folder'}
        </div>
      )
    } else {
      message = <div className={classes.synchronizationMessage}>{error}</div>
    }
    return {message, okBtn}
  }

  const messageData = getMessage()

  if (!isAuth) return <Navigate to='/login'/>

  return (
    <div>
      {isLoading ? (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100vh'}}>
          <Spinner /> 
        </div>
      ) : (
        <div style={{height: '100vh'}}>
          <header
            className={classes.header}
            style={{
              display:'flex',
              justifyContent:'flex-end',
              alignSelf: 'flex-end',
            }}
          >
            <UserInfoArea />
          </header>
          <Dialog open={true}>
            <DialogContent className={classes.synchronizationModal}>
              <div>
                {messageData.message}
              </div>
              {!!messageData.okBtn &&
                <Link to='/Settings' style={{marginBottom: '20px'}}>
                  <PrimaryButton text='OK' />
                </Link>
              }
            </DialogContent>
          </Dialog>
        </div>
      )}
    </div>
  )
}

export default SynchronizationPage
