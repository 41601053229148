import React from 'react'
import {
  getBezierPath,
  // getSmoothStepPath,
  getMarkerEnd,
  getEdgeCenter
} from 'react-flow-renderer'
import {Tooltip} from 'antd'
import infoIcon from './../../../img/icons/infoIcon.svg'

export default function CustomEdge({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  style,
  arrowHeadType,
  markerEndId,
  data,
}) {
   sourceX = sourceX - 0
   sourceY = sourceY - 100
   targetX = targetX+ 0
   targetY = targetY + 100

  const edgePath = getBezierPath({ sourceX, sourceY, sourcePosition, targetX, targetY, targetPosition })
  // const edgePath = getSmoothStepPath({  sourceX, sourceY, sourcePosition, targetX, targetY, targetPosition })

  const markerEnd = getMarkerEnd(arrowHeadType, markerEndId)

  const foreignObjectWidth = 30
  const [edgeCenterX, edgeCenterY] = getEdgeCenter({
    sourceX,
    sourceY,
    targetX,
    targetY,
  })
  return (
    <>
      <path id={id} style={style} className='react-flow__edge-path' d={edgePath} markerEnd={markerEnd}/>
      <foreignObject
        width={foreignObjectWidth}
        height={foreignObjectWidth}
        x={edgeCenterX - foreignObjectWidth / 2}
        y={edgeCenterY - foreignObjectWidth / 2}
        className="edgebutton-foreignobject"
        requiredExtensions="http://www.w3.org/1999/xhtml"
      >
        <body>
          <Tooltip
            placement='top'
            title={
              <>
                <div>Relationship Purpose: {data.purpose}</div>
                <div>Relationship Strength: {data.strength ? data.strength : 'Unknown'}</div>
              </>
            }
          >
            <img src={infoIcon} style={{width: `${foreignObjectWidth}px`}} alt=''/>
          </Tooltip>
        </body>
      </foreignObject>
    </>
  )
}
