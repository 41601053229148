import { DataGridPro, GridToolbarColumnsButton, GridToolbarContainer } from '@mui/x-data-grid-pro'
import { useSelector } from 'react-redux'
import classes from './RecordEngagement.module.css'
import EditableNumber from '../../common/EditableNumber/EditableNumber'
import { PrimaryButton } from '../../common/buttons/buttons'
import {tableWidth} from '../../common/sizes'
import { useEffect, useState } from 'react'
import SuccessModal from '../../common/SuccessModal/SuccessModal'
import {useNavigate} from "react-router";

const Step1 = (props) => {
  const [sortModel, setSortModel] = useState([
    {
      field: 'influenceLvl',
      sort: 'asc',
    },
  ])

  const [columns, setColumns] = useState([])
  const [tableData, setTableData] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [isSent, setIsSent] = useState(false)
  const navigate = useNavigate()
  const projectStakeholders = useSelector(state => state.projectsReducer.projectStakeholders)
  const organizations = useSelector(state => state.organizationsReducer.organizations)

  useEffect(() => {
    const dataForTable = projectStakeholders?.filter(stk => !stk.isProjectTeamMember) || []
    setColumns(getColumns(props.step1Changes, props.setStep1Changes, dataForTable, organizations))
    setTableData(dataForTable)
    // eslint-disable-next-line
  }, [projectStakeholders, props.step1Changes])

  const onSave = () => {
    setIsLoading(true)
    props.onSaveStep1()
      .then(() => {
        setIsSent(true)
        setIsLoading(false)
        navigate('/RecordEngagement/2')
      })
  }

  return (
    <div className={classes.tableWrapper}>
      <DataGridPro
        getRowId={e => e.stakeholderId || null}
        rows={tableData} 
        columns={columns}
        rowCount={tableData?.length || 0}
        components={{Toolbar: () => <CustomToolbar />}}
        disableSelectionOnClick
        style={{borderRadius:'15px', border:'none'}}
        sortModel={tableData?.length ? sortModel : []}
        onSortModelChange={(model) => {
          if(JSON.stringify(model) !== JSON.stringify(sortModel)) setSortModel(model)
        }}
      />
      <div  className={classes.btnArea}>
          <PrimaryButton
            text='NEXT'
            style={{width:'100px', marginTop: '15px'}}
            onClick={onSave}
            loading={isLoading}
          />
      </div>
      {isSent && <SuccessModal onOk={() => setIsSent(false)} isOpen={isSent}/>}
    </div>
  )
}

const getColumns = (step1Changes, setStep1Changes, tableData, organizations) => {
  const columnSizeBig = 0.14
  const columnSizeSmall = 0.12
  const bigColumnWidth = tableWidth * columnSizeBig
  const defaultColumnWidth = tableWidth * columnSizeSmall

  const updatedProjectNumberValue = (stkId, valueKey, value) => {
    const isAlreadyChanged = step1Changes.some(stk => stk.stakeholderId === stkId)
    if (isAlreadyChanged) {
      const updatedChanges = step1Changes.map(stk => stk.stakeholderId === stkId ? {...stk, [valueKey]: value} : stk)
      setStep1Changes(updatedChanges)
    } else {
      const stkData = tableData.find(stk => stk.stakeholderId === stkId)
      setStep1Changes([...step1Changes, {...stkData, [valueKey]: value}])
    }
  }
  return [
    { field: 'fullName',
      headerName: 'Full Name',
      width: bigColumnWidth
    },
    { field: 'influenceLvl',     
      headerName: 'Influence',  
      width: defaultColumnWidth,
      renderCell: (params) => {
        return (
          <EditableNumber
            value={params.row.influenceLvl}
            itemId={params.row.stakeholderId}
            minValue={1}
            maxValue={10}
            updateData={updatedProjectNumberValue}
            valueKey='influenceLvl'
          />
        )
      }
    },
    { field: 'interestLvl',
      headerName: 'Interest',  
      width: defaultColumnWidth,
      renderCell: (params) => {
        return (
          <EditableNumber
            value={params.row.interestLvl}
            itemId={params.row.stakeholderId}
            minValue={1}
            maxValue={10}
            updateData={updatedProjectNumberValue}
            valueKey='interestLvl'
          />
        )
      }
    },
    { field: 'supportLvl', 
      headerName: 'Support', 
      width: defaultColumnWidth,
      renderCell: (params) => {
        return (
          <EditableNumber
            value={params.row.supportLvl}
            itemId={params.row.stakeholderId}
            minValue={-5}
            maxValue={5}
            updateData={updatedProjectNumberValue}
            valueKey='supportLvl'
          />
        )
      }
    },
    { field: 'orgId', 
      headerName: 'Organisation', 
      width: bigColumnWidth,
      renderCell: (params) => {
        const orgData = organizations?.find(org => org.orgId === params.row.orgId)
        return (
          <div>{orgData?.nickname}</div>
        )
      },
      sortComparator: (v1, v2, org1, org2) => {
        const org1Name = organizations?.find(org => org.orgId === org1.value)?.name
        const org2Name = organizations?.find(org => org.orgId === org2.value)?.name
        return org1Name?.localeCompare(org2Name)
      },
    },
    { field: 'roleTitle',
      headerName: 'Role Title',
      width: defaultColumnWidth
    },
    { field: 'jobTitle',
      headerName: 'Job Title',
      width: defaultColumnWidth
    },
    { field: 'department',
      headerName: 'Department',
      width: defaultColumnWidth
    },
  ]
}

const CustomToolbar = () => {
  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
    </GridToolbarContainer>
  )
}

export default Step1
