import moment from 'moment'
import axios from './../helpers/axiosHelper'
import { handleError } from "./errorsReducer"

const initialState = {
  projectEngagements: [],
  stakeholderEngagements: [],
  engagementsHistory: []
}

const engagementReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_PROJECT_ENGAGEMENTS':
      return {...state, projectEngagements: action.engagements}
    case 'SET_STAKEHOLDER_ENGAGEMENTS':
      return {...state, stakeholderEngagements: action.engagements}
    case 'SET_ENGAGEMENTS_HISTORY':
      return {...state, engagementsHistory: action.engagements}
    default: return state
  } 
}

export const setProjectEngagements = (engagements) => ({type: 'SET_PROJECT_ENGAGEMENTS', engagements})
export const setStakeholderEngagements = (engagements) => ({type: 'SET_STAKEHOLDER_ENGAGEMENTS', engagements})
export const setEngagementsHistory = (engagements) => ({type: 'SET_ENGAGEMENTS_HISTORY', engagements})

export const createEngagementTC = (projId, stkId, userId, fullEngagementObj) => (dispatch) => {
  const engagementDataTemplate = {
    comments: '',
    engagement_date: null,
    frequency: 'Monthly',
    is_completed: false,
    project_id: projId,
    start_date: moment(),
    stk_id: stkId,
    user_id: userId
  }
  const engagementData = !!fullEngagementObj ? fullEngagementObj : engagementDataTemplate
  return axios.post(`/engagements`, engagementData)
    .then(response => {
      if (response.status === 201) {
        return true
      } else {
        dispatch(handleError(response.data))
        return false
      } 
    })
    .catch(error => dispatch(handleError(error)))
}

export const editEngagementTC = (engagementId, engagementData) => (dispatch) => {
  return axios.put(`/engagements/${engagementId}`, engagementData)
    .then(response => {
      if (response.status === 200) {
        return true
      } else {
        dispatch(handleError(response.data))
        return false
      } 
    })
    .catch(error => dispatch(handleError(error)))
}

export const getProjectEngagementsTC = (projId, includeNotCreated = false, isForUpdating = false) => (dispatch) => {
  return axios.get(`/engagements/projects/${projId}?includeNotCreated=${includeNotCreated}&isForUpdating=${isForUpdating}`)
    .then(response => {
      if (response.status === 200) {
        dispatch(setProjectEngagements(response.data.stakeholderEngagements))
        return response.data.stakeholderEngagements
      } else {
        dispatch(handleError(response.data))
        return false
      } 
    })
    .catch(error => dispatch(handleError(error)))
}

export const createEngagementsTC = (engagementList) => (dispatch) => {
  return axios.post(`/engagements/list`, {engagement_list: engagementList})
    .then(response => {
      if (response.status === 201) {
        dispatch(setProjectEngagements(response.data.stakeholderEngagements))
        return true
      } else {
        dispatch(handleError(response.data))
        return false
      } 
    })
    .catch(error => dispatch(handleError(error)))
}

export const getStakeholderEngagementsTC = (stkId) => (dispatch) => {
  return axios.get(`/engagements/stakeholders/${stkId}`)
    .then(response => {
      if (response.status === 200) {
        dispatch(setStakeholderEngagements(response.data.stakeholderEngagements))
      } else {
        dispatch(handleError(response.data))
        return false
      } 
    })
    .catch(error => dispatch(handleError(error)))
}

export const getEngagementsHistoryTC = (isCompleted, startPeriod, endPeriod, selectedProject) => (dispatch) => {
  const requestData = {
    is_completed: isCompleted,
    start_period: startPeriod,
    end_period: endPeriod,
    project_id: selectedProject,
  }
  return axios.post(`/engagements/history`, requestData)
    .then(response => {
      if (response.status === 200) {
        dispatch(setEngagementsHistory(response.data.stakeholderEngagements))
        return response.data.stakeholderEngagements
      } else {
        dispatch(handleError(response.data))
        return false
      } 
    })
    .catch(error => dispatch(handleError(error)))
}

export const deleteEngagementTC = (engagementId) => (dispatch, getState) => {
  return axios.delete(`/engagements/${engagementId}`)
    .then(response => {
      if (response.status === 200) {
        const updatedData = getState().engagementReducer.stakeholderEngagements
          .filter((eng) => eng.id !== engagementId)
        dispatch(setStakeholderEngagements(updatedData))
        return response.data
      } else {
        dispatch(handleError(response.data))
        return false
      } 
    })
    .catch(error => dispatch(handleError(error)))
}

export default engagementReducer
