const initialValuesTemplate = {
    orgId: 'none',
    organizationConfirmed: true,
    organizationShortName: '',
    empType: 'none',
    grade: '',
    isSensitiveContact: false,
    isActive: true,
    firstName: '',
    lastName: '',
    firstNameKnownAs: '',
    avatar: null,
    linkedInProfile: '',
    notes: '',
    department: '',
    jobTitle: '',
    officeLocation: '',
    officeAddress: '',
    emails: [
        {
            comment: '',
            email: '',
            emailType: null,
            isDefault: true,
        }
    ],
    phones: [
        {
            comment: '',
            isDefault: true,
            phone: '',
            phoneType: 'business',
        }
    ],
    projectRelations: [],
    tags: '',
}

const initialState = {
    initialValues: initialValuesTemplate,
    emailArea: [1],
    phoneArea: [1],
    orgId: null
}

const stakeholderFormReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'UPDATE_PHONE_AREA':
            return {...state, phoneArea: action.phoneArea}
        case 'UPDATE_EMAIL_AREA':
            return {...state, emailArea: action.emailArea}
        case 'SET_ORG_ID':
            return {...state, orgId: action.orgId}
        case 'SET_FORM_DATA':
            return {
                ...state,
                initialValues: action.initialValues,
                emailArea: action.emailArea,
                phoneArea: action.phoneArea
            }
        case 'CLEAR_FORM':
            return {...state, initialValues: initialValuesTemplate, emailArea: [1], phoneArea: [1]}
        default:
            return state
    }
}

export const updatePhoneArea = (phoneArea) => ({type: 'UPDATE_PHONE_AREA', phoneArea})
export const updateEmailArea = (emailArea) => ({type: 'UPDATE_EMAIL_AREA', emailArea})
export const setOrgId = (orgId) => ({type: 'SET_ORG_ID', orgId})
export const setFormData = (initialValues, emailArea, phoneArea) => ({
    type: 'SET_FORM_DATA',
    initialValues,
    emailArea,
    phoneArea
})
export const clearForm = () => ({type: 'CLEAR_FORM'})


export default stakeholderFormReducer
