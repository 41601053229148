import React from 'react'
import { Divider, Select as SelectAnt } from 'antd'
import {Field} from 'formik'
import MenuItem from '@material-ui/core/MenuItem'
import {withStyles} from '@material-ui/core/styles'
import {TextField} from 'formik-material-ui'
import {default as MaterialSelect} from '@material-ui/core/TextField'
import { PrimaryButton } from '../buttons/buttons'
import {ReactComponent as WarningIcon} from './../../../img/icons/warningIcon.svg'

const Select = (props) => {
  const { Option } = SelectAnt
  return (
    <SelectAnt 
      allowClear={props.allowClear}
      className={props.className}
      defaultValue={props.defaultValue} 
      filterOption={props.filterOption}
      onChange={props.onSelect}
      placeholder={props.placeholder}
      style={props.style}
      size='large'
      showSearch={props.showSearch}
      value={props.value}
      disabled={props.disabled}
      dropdownRender={props.dropdownRender}
      onDropdownVisibleChange={props.onDropdownVisibleChange}
    >
      {props.options.map(option => {
        return (
          props.groupedOptions ? (
            option.projects?.length ? (
              <SelectAnt.OptGroup key={option.group} label={option.group}>
                {option?.projects?.map(project => (
                  <SelectAnt.Option key={project.id} value={project.value} disabled={project.disabled}>
                    {project.name}
                  </SelectAnt.Option>
                ))}
              </SelectAnt.OptGroup>
            ) : (
              <></>
            )
          ) : (
            <Option key={option.id} value={option.value} disabled={option.disabled}>
              {option.name}
            </Option>
          ) 
        )
      })}
    </SelectAnt>
  )
}

export const StyledSelectForFormik = withStyles({
  root: {
    width: '324px',
    marginRight: '10px',
    '&.InputAutoWidth': {
      width: '98%'
    },
    '& .InputAutoWidth .MuiInputBase-input.MuiOutlinedInput-input': {
      width: '98%',
    },
    '& .MuiInputBase-input.MuiOutlinedInput-input': {
      width: '280px',
    },
    '& .placeholderIsShown.MuiInputBase-formControl': {
      color: '#9e9ea2',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      border: '2px solid black',
    },
    '& .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline': {
      backgroundColor: 'rgba(54, 56, 83, 0.2)',
      border: '2px solid black',
    },
    '& label': {
      color: 'black',
      fontWeight: 600,
    },
    '& label.Mui-focused': {
      color: '#0E9AFC',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: 'black',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'black',
      },
      '&:hover fieldset': {
        borderColor: 'black',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#0E9AFC',
      },
    },
    '& .MuiInputBase-root.MuiOutlinedInput-root.LongInput.MuiInputBase-formControl': {
      width: '700px'
    },
    '& .LongInput .MuiSelect-root.MuiSelect-select.MuiSelect-selectMenu.MuiSelect-outlined.MuiInputBase-input.MuiOutlinedInput-input': {
      width: '700px'
    },
  },
})(TextField)

export const SelectForFormik = (props) => {
  return (
    <Field
      component={StyledSelectForFormik}
      disabled={props.disabled || false}        
      label={props.label}
      name={props.name}
      variant='outlined'
      placeholder={props.placeholder}
      select={true}
      InputLabelProps={ { shrink: true } }
      className={props.className}
      InputProps={{
        className:props.className,
        ...props.InputProps,
      }}
    >
      {props.options.map(option => {
        return (
          <MenuItem disabled={option.disabled} key={option.id} value={option.value}>
            {option.title}
          </MenuItem>
        )
      })}
    </Field>
  )
}

const StyledSelectInput = withStyles({
  root: {
    width: '324px',
    marginRight: '10px',
    '&.InputAutoWidth': {
      width: '98%'
    },
    '& .InputAutoWidth .MuiInputBase-input.MuiOutlinedInput-input': {
      width: '98%',
    },
    '& .MuiInputBase-input.MuiOutlinedInput-input': {
      width: '280px',
    },
    '& .placeholderIsShown.MuiInputBase-formControl': {
      color: '#9e9ea2',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      border: '2px solid black',
    },
    '& .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline': {
      backgroundColor: 'rgba(54, 56, 83, 0.2)',
      border: '2px solid black',
    },
    '& label': {
      color: 'black',
      fontWeight: 600,
    },
    '& label.Mui-focused': {
      color: '#0E9AFC',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: 'black',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'black',
      },
      '&:hover fieldset': {
        borderColor: 'black',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#0E9AFC',
      },
    },
    '& .MuiInputBase-root.MuiOutlinedInput-root.LongInput.MuiInputBase-formControl': {
      width: '700px'
    },
    '& .LongInput .MuiSelect-root.MuiSelect-select.MuiSelect-selectMenu.MuiSelect-outlined.MuiInputBase-input.MuiOutlinedInput-input': {
      width: '700px'
    },
  },
})(MaterialSelect)

export const SelectInput = (props) => {
  return (
    <StyledSelectInput
      error={props.error}
      helperText={props.helperText}
      value={props.value}
      onChange={props.onChange}
      disabled={props.disabled || false}        
      label={props.label}
      variant='outlined'
      placeholder={props.placeholder}
      select
      InputLabelProps={ { shrink: true } }
      className={props.className}
      InputProps={{
        className:props.className,
        ...props.InputProps,
      }}
    >
      {props.options.map(option => {
        return (
          <MenuItem disabled={option.disabled} key={option.id} value={option.value}>
            {option.title}
          </MenuItem>
        )
      })}
    </StyledSelectInput>
  )
}

export const AddOrganisationSelect = (props) => {
  console.log(props.value)
  return (
    <>
      <Select 
        options={props.options}
        style={{width: '100%', marginRight: '15px', ...props.style}}
        className={`
          forFormikForms
          ${props.className}
          ${props.error ? 'hasError' : ''}
          ${props.hasWarning ? 'hasWarning' : ''}
        `}
        placeholder={<span style={{color: 'gray'}}>{props.placeholder}</span>}
        onSelect={props.onChange}
        value={props.value}
        disabled={props.disabled}
        onDropdownVisibleChange={props.onDropdownVisibleChange}
        dropdownRender={(menu) => (
          <>
            {menu}
            <Divider style={{margin:'10px 0px'}}/>
            <div style={{display: 'flex', justifyContent:'center'}}>
              <PrimaryButton text='+Add Organisation' onClick={props.onBtnClick}/>
            </div>
          </>
        )}
      />
      {!!props.error &&
        <div style={{color:'#f44336', fontSize:'12px', marginLeft:'14px', marginTop:'3px'}}>
          {props.error}
        </div>
      }
      {props.hasWarning &&
        <div
          style={{
            color:'#FAAD14',
            fontSize:'12px',
            fontWeight: 600,
            marginTop:'3px',
            display:'flex',
            alignItems:'center'
          }}>
          <WarningIcon style={{marginRight:'5px'}}/>
          The organisation needs to be checked
        </div>
      }
    </>
  )
}

export default Select
