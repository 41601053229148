

const initialState = {
   addTenantFormValues: {
       name: '',
       logo: null,
       activeFrom: '',
       activeTill: ''
   }
}

const formValuesReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'CHANGE_ADD_TENANT_FORM_FIELD': {
            const {fieldName, fieldValue} = action;
            return {
                ...state,
                addTenantFormValues: {
                    ...state.addTenantFormValues,
                    [fieldName]: fieldValue,
                },
            }
        }
        default:
            return state
    }
}

export const changeAddTenantFormValues = (fieldValue, fieldName) => ({type: 'CHANGE_ADD_TENANT_FORM_FIELD', fieldValue, fieldName})



export default formValuesReducer
