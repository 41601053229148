import React, { useState } from 'react'
import { Modal} from 'antd'
import { PrimaryButton, SecondaryButton } from '../../../../common/buttons/buttons'
import classes from './DeleteConfirmationModal.module.css'
import { useSelector } from 'react-redux'

const DeleteConfirmationModal = (props) => {
  const [isLoading, setIsLoading] = useState(false)

  const stakeholders = useSelector(state => state.stakeholderMapReducer.workers)
const projects = useSelector(state => state.projectsReducer.projectList)

  const stakeholderForRemoving = stakeholders.find(stk => stk.stakeholderId === Number(props.selectedElement?.id))
  const currentProjectData = projects.find(proj => proj.projId === Number(props.selectedProject))

  const removeStakeholderCard = () => {
    setIsLoading(true)
    props.removeElement()
  }

  return (
    <Modal 
      closable={false}
      footer={null}
      style={{padding: '10px'}}
      visible={props.isDeleteConfirmOpen} 
    >
      <div>
        <div className={classes.text}>
          Are you sure to remove {stakeholderForRemoving?.fullName} from the project {currentProjectData?.name}?
        </div>
        <div className={classes.btnArea}>
          <SecondaryButton 
            text='NO'
            onClick={() => props.setIsDeleteConfirmOpen(false)}
            style={{marginRight: '15px'}}/>
          <PrimaryButton 
            text='YES'
            onClick={removeStakeholderCard}
            loading={isLoading}
          />
        </div>
      </div>
    </Modal>
  )
}

export default DeleteConfirmationModal
