import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import StakeholderMap from './StakeholderMap'
import {turnOffConnectionStrength, turnOnConnectionStrength, setIsStrengthViewOn, setWorkersData, getOrganisationsFromProject, setConnectionsData, getAnnotationsTC} from '../../store/stakeholderMapReducer'
import {getProjectsByPeriod, getProjectStakeholdersTC, selectProjectTC} from './../../store/projectsReducer'
import { getOrganizations } from '../../store/organizationsReducer'
import { getStakeholders } from '../../store/stakeholdersReducer'
import { getGroupedProjectOptions } from '../../helpers/groupedDataHelper'
import axios from './../../helpers/axiosHelper'

const StakeholderMapContainer = () => {
  const isStrengthViewOn = useSelector(state => state.stakeholderMapReducer.isStrengthViewOn)
  const projectList = useSelector(state => state.projectsReducer.projectList)
  const organizationList = useSelector(state => state.organizationsReducer.organizations)
  const selectedProject = useSelector(state => state.projectsReducer.selectedProject)
  const selectedProjectData = useSelector(state => state.projectsReducer.selectedProjectData)
  const userId = useSelector(state => state.authReducer.userId)
  const projectStakeholders = useSelector(state => state.projectsReducer.projectStakeholders)

  const dispatch = useDispatch()
  const [selectedView, setSelectedView] = useState('userView')
  const [isLoaded, setIsLoaded] = useState(false)
  const [isEmpty, setIsEmpty] = useState(false)

  const viewTypes = [
    {name: 'My view', value:'userView', id: 1},
    {name: 'Project view', value:'projectView', id: 2},
    // ex circle view
    // {name: 'Organisation View', value:'organisationView', id: 3}
  ]

  const switchStrengthView = () => {
    if (!isStrengthViewOn) {
      dispatch(turnOnConnectionStrength())
      dispatch(setIsStrengthViewOn(true))
    } else {
      dispatch(turnOffConnectionStrength())
      dispatch(setIsStrengthViewOn(false))
    }
  }

  useEffect(() => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    if(!!userId) {
      setIsLoaded(false)
      dispatch(getStakeholders())
      Promise.allSettled([
        dispatch(getOrganizations(true)),
        dispatch(getProjectsByPeriod(null, false, userId, source)),
        !!selectedProject && dispatch(getAnnotationsTC(selectedProject)),
        !!selectedProject && dispatch(getProjectStakeholdersTC(selectedProject)),
      ]).then((res) => {
        const stakeholdersResp = res[3]?.value
        const projectsResp = res[1]?.value
        if (Array.isArray(stakeholdersResp) && !!projectsResp?.length) {
          setIsEmpty(false)
          setIsLoaded(true)
        } else if (!stakeholdersResp && !projectsResp?.length){
          setIsEmpty(true)
          setIsLoaded(true)
        } else {
          return 
        }
      })
    }
    return () => {source.cancel('cancel')}
  }, [userId, dispatch, selectedProject])

  useEffect(() => {
    dispatch(setWorkersData(
      projectStakeholders || [],
      selectedProjectData?.[selectedView]?.positions || []
    ))
    dispatch(getOrganisationsFromProject(
      projectStakeholders || [],
      organizationList,
      selectedProjectData?.[selectedView]?.positions || [],
    ))
  }, [organizationList, selectedProjectData, selectedView, dispatch, projectStakeholders])

  useEffect(() => {
    if (selectedProject) dispatch(setConnectionsData(selectedProject, isStrengthViewOn))
  }, [selectedProject, dispatch, isStrengthViewOn])

  const selectProject = (value) => {
    dispatch(selectProjectTC(value, projectList))
  }

  return (
    <StakeholderMap
      selectedProject={selectedProject}
      selectProject={selectProject}
      selectProjectOptions={getGroupedProjectOptions(projectList)}
      viewTypes={viewTypes}
      selectedView={selectedView}
      setSelectedView={setSelectedView}
      isStrengthViewOn={isStrengthViewOn}
      switchStrengthView={switchStrengthView}
      isLoaded={isLoaded}
      isEmpty={isEmpty}
    />
  )
}

export default StakeholderMapContainer

