import { Tooltip, Divider } from "antd"
import { getCurrentUserRoles } from "../../../helpers/localStorage"

const TableContactsColumn = (props) => {
  const currentUserRoles = getCurrentUserRoles()
  const isUser = currentUserRoles?.some(role => role.code.toLowerCase() === 'user')

  if (!props.data?.length || (props.isSensitiveContact && isUser)) {
    return <></>
  }
  return (
    <Tooltip
      placement="top" 
      color="white"
      title={
        props.data.map((item, index) => (
          <div style={{color:'black'}}  key={index}>
            <div>
              <span style={{fontWeight:600}}>{props.infoName.charAt(0).toUpperCase() + props.infoName.slice(1)}:</span> {item[props.infoName]}
            </div>
            <div>
            <span style={{fontWeight:600}}>Comment:</span> {item.comment}
            </div>
            {item.isDefault &&
              <i>
                default
              </i>
            }
            {index < props.data.length - 1 &&
              <Divider style={{backgroundColor:'black', margin:'5px 0px'}} />
            }
          </div>
        ))
      }
    >
      <span style={{...props.style}}>
        {props.data.length === 1 ? `1 ${props.infoName}` : `${props.data.length} ${props.infoName}s`}
      </span>
    </Tooltip>
  )
}

export default TableContactsColumn
