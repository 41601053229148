import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import {updateEmailArea} from './../../../../../../store/stakeholderFormReducer'
import EmailArea from './EmailArea'

const EmailAreaContainer = (props) => {
  const [defaultEmail, setDefaultEmail] = useState(0)

  useEffect(() => {
    const defaultEmailIndex = props.values.emails?.findIndex(email => email.isDefault === true)
    setDefaultEmail(defaultEmailIndex)
  }, [props.values])

  const addNewField = () => {
    const index = props.emailArea?.length + 1
    props.setFieldValue(`emails[${props.emailArea?.length}].email`, '')
    props.updateEmailArea([...props.emailArea, index])
  }

  const removeField = () => {
    const lastElementIndex = props.emailArea?.length - 1
    let updatedEmails = props.values.emails.filter((_, index) => index !== props.emailArea?.length - 1)
    if (props.values.emails[lastElementIndex].isDefault === true) {
      updatedEmails[lastElementIndex-1].isDefault = true
      setDefaultEmail(lastElementIndex-1)
    }
    props.setFieldValue(`emails`, updatedEmails)

    const updatedInputs = props.emailArea.filter(inputNumber => inputNumber !== props.emailArea[lastElementIndex])
    props.updateEmailArea(updatedInputs)
  }

  const changeDefaultEmail = (emailIndex) => {
    setDefaultEmail(emailIndex)
    props.setFieldValue('emails', props.values.emails.map((emailData, index) => {
      if (index === emailIndex) {
        return {...emailData, isDefault: true}
      } else {
        return {...emailData, isDefault: null}
      }
    }))
  }

  return (
    <EmailArea 
      addNewField={addNewField}
      changeDefaultEmail={changeDefaultEmail}
      defaultEmail={defaultEmail}
      emailArea={props.emailArea}
      removeField={removeField}
      setDefaultEmail={setDefaultEmail}
    />
  )
}

const mapStateToProps = (state => ({
  emailArea: state.stakeholderFormReducer.emailArea,
  initialValues: state.stakeholderFormReducer.initialValues
}))

export default connect(mapStateToProps, {updateEmailArea})(EmailAreaContainer)
