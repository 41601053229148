import React, { useEffect, useState } from 'react'

const EditableNumber = ({itemId, value, minValue, maxValue, updateData, valueKey}) => {
  const [currentValue, setCurrentValue] = useState(value)

  useEffect(() => {
    setCurrentValue(value)
  }, [value])

  return (
    <div style={{display: 'flex', alignItems:'center', width: '100%'}}>
      <div
        style={{
          marginRight: '10px',
          cursor: 'pointer',
          // visibility: currentValue === null ? 'hidden' : 'visible',
          display: currentValue === null ? 'none' : 'block',
          fontSize: '30px',
          paddingBottom: '5px'
        }}
        onClick={() => {
          setCurrentValue(currentValue - 1 < minValue ? null : currentValue - 1)
          updateData(itemId, valueKey, currentValue - 1 < minValue ? null : currentValue - 1)
        }}
      >
        -
      </div>
        {typeof currentValue === 'number' ? currentValue : 'Unknown'}
      <div
        style={{
          marginLeft: '10px',
          cursor: 'pointer',
          visibility: currentValue === maxValue ? 'hidden' : 'visible',
          fontSize: '24px',
          paddingBottom: '5px'
        }}
        onClick={() => {
          setCurrentValue(typeof currentValue !== 'number' ? minValue : currentValue + 1)
          updateData(itemId, valueKey, currentValue ? currentValue + 1 : minValue)
        }}
      >
        +
      </div>
    </div>
  )
}

export default EditableNumber
