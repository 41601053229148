import axios from './../helpers/axiosHelper'
import { handleError } from './errorsReducer'

const initialState = {
  organizations: [],
}

const organizationsReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_ORGANIZATIONS':
      return {...state, organizations: action.organizations} 
    case 'ADD_ORGANIZATION':
      return {...state, organizations: [...state.organizations, action.organization]} 
    case 'REMOVE_ORGANIZATION':
      return {...state, organizations: state.organizations.filter(org => org.orgId !== action.id)} 
    case 'EDIT_ORGANIZATION':
      return {...state, organizations: state.organizations.map(org => {
        if(org.orgId === action.organization.orgId) {
          return action.organization
        } else {
          return org
        }
      })} 
    default: return state
  } 
}

export const setOrganizations = (organizations) => ({type: 'SET_ORGANIZATIONS', organizations})
export const addOrganization = (organization) => ({type: 'ADD_ORGANIZATION', organization})
export const editOrganization = (organization) => ({type: 'EDIT_ORGANIZATION', organization})
export const removeOrganization = (id) => ({type: 'REMOVE_ORGANIZATION', id})

export const getOrganizations = (isActive = null) => (dispatch) => {
    return axios.get(isActive === null ? '/organizations' : `/organizations?isActive=${isActive}`)
      .then(response => {
        response.status === 200 
        ? dispatch(setOrganizations(response.data.organizations))
        : dispatch(handleError(response.data))
      })
    .catch(error => dispatch(handleError(error)))
}

export const createOrganization = (newOrganizationData) => (dispatch) => {
  return axios.post('/organizations', newOrganizationData)
    .then(response => {
      response.status === 201
      ? dispatch(addOrganization(response.data.organization))
      : dispatch(handleError(response.data))
    })
  .catch(error => {throw error})
}

export const updateOrganization = (updatedOrganizationData) => (dispatch) => {
  return axios.put(`/organizations/${updatedOrganizationData.orgId}`, updatedOrganizationData)
    .then(response => {
      response.status === 200 
      ? dispatch(editOrganization(response.data.organization))
      : dispatch(handleError(response.data))
    })
  .catch(error => {throw error})
}

export const deleteOrganization = (id) => (dispatch) => {
  return axios.delete(`/organizations/${id}`)
    .then(response => {
      response.status === 200 
      ? dispatch(removeOrganization(id))
      : dispatch(handleError(response.data))
    })
    .catch(error => dispatch(handleError(error)))
}

export default organizationsReducer
