import React from 'react'
import { Modal } from 'antd'
import { Tabs } from 'antd'
import NewWorkerModalForm from './NewWorkerModalForm'
import SelectWorker from './SelectWorker'
import { PrimaryButton } from '../../../../common/buttons/buttons'
import classes from './NewWorkerModal.module.css'

const { TabPane } = Tabs

const NewWorkerModal = (props) => {
  return (
    <Modal 
      closable={false}
      footer={null}
      onCancel={props.closeModal}
      visible={props.isWorkerModalOpen}
      maskClosable={false}
    >
      {props.isAdded ? (
          <div className={classes.successMessageModal}>
            <div className={classes.successMessage}>
              All changes have been saved
            </div>
            <div className={classes.successMessageButtons}>
              <PrimaryButton 
                text='OK' 
                onClick={props.closeModal}
                style={{width: '130px', height: '40px', marginLeft: '25px'}}
              />
            </div>
          </div>
        ) : (
          <Tabs 
            centered={true}
            defaultActiveKey={props.activeTab} 
            activeKey={props.activeTab} 
            onChange={(tabKey) => props.clearTab(tabKey)} 
            style={{width: '100%'}} 
          >
            <TabPane tab='Select a contact' key='1'>
              <SelectWorker 
                addNewWorker={props.addNewWorker}
                closeModal={props.closeModal}
                setSelectedWorker={props.setSelectedWorker}
                selectedWorker={props.selectedWorker}
                stakeholderOptions={props.stakeholderOptions}
                workers={props.workers}
                isLoading={props.isLoading}
              />
            </TabPane>
            <TabPane tab='Add a new contact' key='2'>
              <NewWorkerModalForm 
                addNewWorker={props.addNewWorker}
                closeModal={props.closeModal}
                isWorkerModalOpen={props.isWorkerModalOpen}
                organizationOptions={props.organizationOptions}
                setDataForFullForm={props.setDataForFullForm}
                setError={props.setError}
                error={props.error}
                isLoading={props.isLoading}
              />
            </TabPane>
          </Tabs>
        )}
      {props.error && 
        <div className={classes.errorMessage}>
          {props.error}
        </div>
      }
    </Modal>
  )
}

export default NewWorkerModal
