import React, { useRef } from 'react'
import { NavLink } from 'react-router-dom'
import {CSVLink} from 'react-csv'
import classes from './Contacts.module.css'
import Search from './../common/Search/Search'
import UserInfoArea from '../common/UserInfoArea/UserInfoArea'
import {PrimaryButton, SecondaryButton} from './../common/buttons/buttons'
import ContactsTable from './ContactsTable'
import {pageDefaultHeight} from './../common/sizes'
import {getColumns} from './ContactsTable'
import {ReactComponent as SynchronizationIcon} from './../../img/icons/synchronization.svg'
import SuccessModal from '../common/SuccessModal/SuccessModal'
import Spinner from '../common/Spinner/Spinner'

const Contacts = (props) => {
  const csvLink = useRef()

  if (props.isLoadingSyncContacts) {
    return <Spinner style={{minHeight: pageDefaultHeight}}/>
  }

  return (
    <>
      <header className={classes.header}>
        <UserInfoArea onExport={() => csvLink.current.link.click()} isCsvExport/>
      </header>
      <div className={classes.wrapper} style={{height:`${pageDefaultHeight}px`}}>
        <Search onChange={(e) => props.onSearch(e.target.value)} />
        <div className={classes.titleArea}>
          <h1 className={classes.pageTitle}>CONTACTS LIST</h1>
          <div style={{display:'flex'}}>
            {props.isSyncWithOffice &&
              <SecondaryButton
                text='SYNC WITH OFFICE 365'
                icon={SynchronizationIcon}
                style={{marginRight: '10px'}}
                onClick={props.onSyncOfficeContacts}
              />
            }
            {props.isSyncWithGoogle &&
              <SecondaryButton
                text='SYNC WITH GOOGLE'
                icon={SynchronizationIcon}
                style={{marginRight: '10px'}}
                onClick={props.onSyncGoogleContacts}
              />
            }
            <NavLink to='/Contacts/StakeholderForm'>
              <PrimaryButton text='ADD CONTACT'/>
            </NavLink>
          </div>
        </div>
        <ContactsTable 
          contacts={props.contacts}
          deleteContact={props.deleteContact}
          organizations={props.organizations}
          setTableColumns={props.setTableColumns}
        />
      </div>
      {props.isContactsSync &&
        <SuccessModal
          onOk={() => props.setIsContactsSync(false)}
          isOpen={props.isContactsSync}
          text='Synchronization is done'
        />
      }
      <CSVLink
        data={props.contacts?.length
          ? props.contacts
            .map(row => (
              { ...row,
                emails: row.emails.map((email, index) => index > 0  ? ` ${email.email}` : email.email),
                phones: row.phones.map((phone, index) => index > 0  ? ` ${phone.phone}` : phone.phone),
                projects: row.projects?.map((proj, index) => index > 0  ? ` ${proj.name}` : proj.name),
                isActive: row.isActive ? 'Active' : 'Inactive',
                orgId: props.organizations.find(org => org.orgId === row.orgId)?.name
              }
            ))
            .sort((a, b) => {
              if(a.fullName < b.fullName) { return -1 }
              if(a.fullName > b.fullName) { return 1 }
              return 0
            })
          : []
        }
        headers={
          getColumns(null, props.organizations)
            .filter(col => col.field !== 'actions')
            .map(col => ({label: col.headerName, key: col.field}))
        }
        style={{display:'none'}}
        ref={csvLink}
        filename={`Contacts.csv`}
      >
        Download
      </CSVLink>
    </>
  )
}

export default Contacts
