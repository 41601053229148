import React from 'react'
import {withStyles} from '@material-ui/core/styles'
import {default as MaterialTooltip} from '@material-ui/core/Tooltip'

const StyledTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}))(MaterialTooltip)

const Tooltip = (props) => {
  return (
    <StyledTooltip
      open={props.visible} 
      // disableHoverListener
      title={props.title}
    >
      {props.children}
    </StyledTooltip>
  )
}

export default Tooltip
