import React, {useState, useEffect} from 'react'
import {useParams} from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'
import ProjectDetailsForm from './ProjectDetailsForm'
import { createProject, editProject, editStakeholderInProject, getProjectsByPeriod, getProjectStakeholdersTC } from '../../../store/projectsReducer'
import { handleError } from '../../../store/errorsReducer'
import { getOrganizations } from '../../../store/organizationsReducer'
import Spinner from './../../common/Spinner/Spinner'
import { getUserList } from '../../../store/usersReducer'
import { getStakeholders } from '../../../store/stakeholdersReducer'

const ProjectDetailsFormContainer = (props) => {
  const dispatch = useDispatch()
  const [isDataSent, setIsDataSent] = useState(false)
  const [error, setError] = useState(null)
  const [dataForEditing, setDataForEditing] = useState(null)
  const [updatedContacts, setUpdatedContacts] = useState([])
  const [formData, setFormData] = useState({})
  const [isLoading, setIsLoading] = useState(true)
  const [tableMessage, setTableMessage] = useState('')
  const [isStakeholdersLoading, setIsStakeholdersLoading] = useState(true)

  const projects = useSelector(state => state.projectsReducer.projectList)
  const organizations = useSelector(state => state.organizationsReducer.organizations)
  const userId = useSelector(store => store.authReducer.userId)
  const projectStakeholders = useSelector(state => state.projectsReducer.projectStakeholders)

  const {projId} = useParams()

  useEffect(() => {
    if(!!userId) {
      dispatch(getUserList(true))
      dispatch(getOrganizations(true))
      dispatch(getStakeholders())
        .then(() => setIsStakeholdersLoading(false))
      !props.modalView && dispatch(getProjectsByPeriod(null, false, userId))
      if (!!projId) {
        setTableMessage('Loading...')
        dispatch(getProjectStakeholdersTC(projId))
          .then(resp => resp?.length ? '' : setTableMessage('No contacts for project'))
      }
      setIsLoading(false)
    }
  }, [dispatch, userId, projId, props.modalView])

  // set initial values if editing 
  useEffect(() => {
    if ((projId && !!projects.length)|| (!projId && !projects.length)) {
      setIsLoading(false)
    }
    if (projId) {
      const projectForEditing = projects.find(proj => proj.projId === Number(projId))
      setDataForEditing(projectForEditing)
    }
  }, [projects, projId])

  const saveProject = (newProjectData) => {
    dispatch(createProject(newProjectData))
      .then(() => setIsDataSent(true))
      .catch(error => {
        if (error.response?.data?.status === 422) {
          setError({message: 'This project name already exists', duplicatedName: newProjectData.name})
        } else {
          setError({message: 'Something went wrong'})
        }
      })
  }

  const updateProject = (updatedProjectData) => {
    dispatch(editProject({...updatedProjectData}))
      .then(() => {
        !!updatedContacts.length &&
        updatedContacts.forEach(contact => dispatch(editStakeholderInProject(Number(projId), contact.stakeholderId, contact)))
      })
      .then(() => setIsDataSent(true))
      .catch(error => {
        if (error.response?.data?.status === 422) {
          setError({message: 'This project name already exists', duplicatedName: updatedProjectData.name})
        } else {
          setError({message: 'Something went wrong'})
          handleError(error)
        }
      })
  }

  const changeContactData = (stakeholderId, valueKey, value) => {
    const contactData = projectStakeholders.find(stk => stakeholderId === stk.stakeholderId)    
    if (updatedContacts.some(stk => stk.stakeholderId === stakeholderId)) {
      const updatedContact = {...updatedContacts.find(stk => stk.stakeholderId === stakeholderId), [valueKey]: value}
      const isNothingChanged = updatedContact.influenceLvl === contactData.influenceLvl &&
        updatedContact.interestLvl === contactData.interestLvl &&
        updatedContact.supportLvl === contactData.supportLvl
      const updatedData = isNothingChanged 
      ? updatedContacts.filter(stk => stk.stakeholderId !== stakeholderId)
      : updatedContacts.map(stk => {
          return stk.stakeholderId === stakeholderId ? {...stk, [valueKey]: value} : stk
        })
      setUpdatedContacts(updatedData)
    } else {
      setUpdatedContacts([...updatedContacts, {...contactData, [valueKey]: value}])
    }
  }

  const onSubmit = () => {
    const activeFrom = moment.utc(formData.activeFrom)
    const activeTill = formData.activeTill === null ? moment.utc('2099-12-31T00:00:00') : moment.utc(formData.activeTill)
    if(dataForEditing) {
      const updatedProject = {...dataForEditing, ...formData, activeFrom, activeTill}
      updateProject(updatedProject)
    } else {
      const newProjectData = {...formData, activeFrom, activeTill, code: null, tags: ''}
      saveProject(newProjectData)
    }
  }

  if (isLoading) {
    return <div style={{height: '100%'}}><Spinner /></div>
  }

  return (
    <ProjectDetailsForm
      error={error}
      dataForEditing={dataForEditing}
      setError={setError}
      isDataSent={isDataSent}
      setIsDataSent={setIsDataSent}
      organizations={organizations}
      changeContactData={changeContactData}
      onSubmit={onSubmit}
      setFormData={setFormData}
      tableMessage={tableMessage}
      formData={formData}
      modalView={props.modalView}
      cancelModalView={props.cancelModalView}
      isStakeholdersLoading={isStakeholdersLoading}
    />
  )
}

export default ProjectDetailsFormContainer
