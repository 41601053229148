import axios from './../helpers/axiosHelper'
import {handleError} from './errorsReducer'

const initialState = {
    userList: [],
    allowedUserRoles: [],
    currentPage: 0,
    totalCount: 0,
    adminsList: [],
    userRolesSettings: [],
    usersSearchValue: '',
    usersForEmail: [],
    isAddingUser: false,
    adminsSearchValue: '',
    currentLoginUser: ''
}

const usersReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_USER_LIST':
            return {...state, userList: action.users}
        case 'SET_IS_USER_ADDING':
            return {...state, isAddingUser: action.isAddingUser}
        case 'SET_CURRENT_LOGIN_USER':
            return {...state, currentLoginUser: action.currentLoginUser}
        case 'SET_USER_LIST_FOR_EMAIL_SELECTION':
            return {...state, usersForEmail: action.usersForEmail}
        case 'SET_USER_DATA_SETTINGS_ROLES':
            return {...state, userRolesSettings: action.userRolesSettings}
        case 'SET_ADMINS_LIST':
            return {...state, adminsList: action.admins}
        case 'SET_USER_TABLE_PAGE':
            return {...state, currentPage: action.page}
        case 'SET_USER_TOTAL_COUNT':
            return {...state, totalCount: action.totalCount}
        case 'SET_USERS_SEARCH_VALUE':
            return {...state, usersSearchValue: action.usersSearchValue}
        case 'SET_ADMIN_SEARCH_VALUE':
            return {...state, adminsSearchValue: action.adminsSearchValue}
        case 'REMOVE_USER':
            return {...state, userList: state.userList.filter(user => user.userId !== action.userId)}
        case 'EDIT_USER':
            return {
                ...state, userList: state.userList.map(user => {
                    if (user.userId === action.userId) {
                        return {...user, ...action.userData}
                    } else {
                        return user
                    }
                })
            }
        case 'DEACTIVATE_USER':
            return {
                ...state, userList: state.userList.map(user => {
                    if (user.userId === action.userId) {
                        return {...user, isActive: false}
                    } else {
                        return user
                    }
                })
            }
        case 'ADD_USER':
            return {...state, userList: [...state.userList, action.userData]}
        case 'SET_ALLOWED_USER_ROLES':
            return {...state, allowedUserRoles: action.roles}
        default:
            return state
    }
}

export const setUserList = (users) => ({type: 'SET_USER_LIST', users})
export const setIsUserAdding = (isAddingUser) => ({type: 'SET_IS_USER_ADDING', isAddingUser})
export const setUserListForEmailSelection = (usersForEmail) => ({
    type: 'SET_USER_LIST_FOR_EMAIL_SELECTION',
    usersForEmail
})
export const setAdminsList = (admins) => ({type: 'SET_ADMINS_LIST', admins})
export const setCurrentUsersPage = (page) => ({type: 'SET_USER_TABLE_PAGE', page})
export const setUsersTotalCount = (totalCount) => ({type: 'SET_USER_TOTAL_COUNT', totalCount})
export const removeUser = (userId) => ({type: 'REMOVE_USER', userId})
export const editUser = (userData, userId) => ({type: 'EDIT_USER', userData, userId})
export const deactivateUserById = (userId) => ({type: 'DEACTIVATE_USER', userId})
export const addUser = (userData) => ({type: 'ADD_USER', userData})
export const setAllowedUserRoles = (roles) => ({type: 'SET_ALLOWED_USER_ROLES', roles})
export const setUserSearchValue = (usersSearchValue) => ({type: 'SET_USERS_SEARCH_VALUE', usersSearchValue})
export const setAdminSearchValue = (adminsSearchValue) => ({type: 'SET_ADMIN_SEARCH_VALUE', adminsSearchValue})
export const setCurrentLoginUser = (currentLoginUser) => ({type: 'SET_CURRENT_LOGIN_USER', currentLoginUser})

export const getUserList = (isActive = false) => (dispatch) => {
    return axios.get(`/users${isActive ? '?is_active=true' : ''}`)
        .then(response => {
            response.status === 200
                ? dispatch(setUserList(response.data.users))
                : dispatch(handleError(response.data))
        })
        .catch(error => dispatch(handleError(error)))
}

export const getUserListWithPagination = (pagination_request, isSearchPagination, isTenant) => (dispatch) => {
    const formData = new FormData();
    if(!isTenant) {
        if (!pagination_request?.filter_request?.search_value) {
            formData.append('pagination_request', new Blob([JSON.stringify({
                page: pagination_request.pagination_request.page === 0 ? 1 : pagination_request.pagination_request.page,
                size: pagination_request.pagination_request.size,
                sort: pagination_request.pagination_request.sort
            })], {type: 'application/json'}));
        }
        // Set filter_request
        if (pagination_request?.filter_request?.search_value) {
            formData.append('filter_request', new Blob([JSON.stringify({
                fields: [{
                    field_name: "search_value",
                    search_value: pagination_request?.filter_request?.search_value
                }]
            })], {type: 'application/json'}));
        }
        if (pagination_request?.filter_request?.search_value && isSearchPagination) {
            formData.append('pagination_request', new Blob([JSON.stringify({
                page: pagination_request.pagination_request.page === 0 ? 1 : pagination_request.pagination_request.page,
                size: pagination_request.pagination_request.size,
                sort: pagination_request.pagination_request.sort
            })], {type: 'application/json'}));
            formData.append('filter_request', new Blob([JSON.stringify({
                fields: [{
                    field_name: "search_value",
                    search_value: pagination_request?.filter_request?.search_value
                }]
            })], {type: 'application/json'}));
        }
    }
    else{
        formData.append('filter_request', new Blob([JSON.stringify({
            fields: [{
                field_name: "email",
                "operator": "CONTAINS_IGNORE_CASE",
                search_value: pagination_request?.fields[0].search_value
            }]
        })], {type: 'application/json'}));
    }


    // formData.append('filter_request', new Blob([JSON.stringify({ filter_request: filterRequestData })], { type: 'application/json' }));

    return axios.post(`/users/filter`, formData)
        .then(response => {
            if (response.status === 200) {
                const users = response.data.users.map((u,key) => ({
                    userId: key,
                    firstName: u.first_name,
                    lastName: u.last_name,
                    email: u.email,
                    phoneNumber: u.phone_number,
                    isActive: u.is_active,
                    roles: u.roles,
                    tenants: u.tenants
                }));
                dispatch(setUserList(users));
                dispatch(setUsersTotalCount(response.data.total_count));
            } else {
                dispatch(handleError(response.data));
            }
        })
        .catch(error => dispatch(handleError(error)));
};


export const getUserListWithPaginationForEmailSelection = (pagination_request, isSearchPagination, isTenant) => (dispatch) => {
    const formData = new FormData();
    if (!isTenant) {
        if (!pagination_request?.filter_request?.search_value) {
            formData.append('pagination_request', new Blob([JSON.stringify({
                page: pagination_request.pagination_request.page === 0 ? 1 : pagination_request.pagination_request.page,
                size: pagination_request.pagination_request.size,
                sort: pagination_request.pagination_request.sort
            })], {type: 'application/json'}));
        }
        // Set filter_request
        if (pagination_request?.filter_request?.search_value) {
            formData.append('filter_request', new Blob([JSON.stringify({
                fields: [{
                    field_name: "search_value",
                    search_value: pagination_request?.filter_request?.search_value
                }]
            })], {type: 'application/json'}));
        }
        if (pagination_request?.filter_request?.search_value && isSearchPagination) {
            formData.append('pagination_request', new Blob([JSON.stringify({
                page: pagination_request.pagination_request.page === 0 ? 1 : pagination_request.pagination_request.page,
                size: pagination_request.pagination_request.size,
                sort: pagination_request.pagination_request.sort
            })], {type: 'application/json'}));
            formData.append('filter_request', new Blob([JSON.stringify({
                fields: [{
                    field_name: "search_value",
                    search_value: pagination_request?.filter_request?.search_value
                }]
            })], {type: 'application/json'}));
        }
    } else {
        formData.append('filter_request', new Blob([JSON.stringify({
            fields: [{
                field_name: "email",
                "operator": "CONTAINS_IGNORE_CASE",
                search_value: pagination_request?.fields[0].search_value
            }]
        })], {type: 'application/json'}));
    }


    // formData.append('filter_request', new Blob([JSON.stringify({ filter_request: filterRequestData })], { type: 'application/json' }));

    return axios.post(`/users/filter`, formData)
        .then(response => {
            if (response.status === 200) {
                const users = response.data.users.map((u, key) => ({
                    userId: key,
                    firstName: u.first_name,
                    lastName: u.last_name,
                    email: u.email,
                    phoneNumber: u.phone_number,
                    isActive: u.is_active,
                    roles: u.roles,
                    tenants: u.tenants
                }));
                // const filteredUsers = users.filter(item => {
                //     const tenants = item.tenants
                //     if (tenants.length === 1) {
                //         const roles = tenants[0].roles
                //         return !(roles.length === 1 && roles[0].code === 'system admin')
                //     }
                //     if (tenants.length > 1) {
                //         return !tenants.every(tenant =>
                //             tenant.roles.length === 0 || (tenant.roles.length === 1 && tenant.roles[0].code === 'system admin')
                //         )
                //     }
                //
                //     return false;
                // })
                dispatch(setUserListForEmailSelection(users))

            } else {
                dispatch(handleError(response.data));
            }
        })
        .catch(error => dispatch(handleError(error)));
};

export const getSystemAdminsList = (pagination_request, isSearch) => (dispatch) => {
    const formData = new FormData();

    if (!isSearch) {
        formData.append('filter_request', new Blob([JSON.stringify({
            fields: [{
                field_name: "role",
                search_value: "system_admin"
            }]
        })], {type: 'application/json'}));
    } else {
        formData.append('filter_request', new Blob([JSON.stringify({
            fields: [{
                field_name: "role",
                search_value: "system_admin"
            }, {
                field_name: "search_value",
                search_value: pagination_request?.filter_request?.search_value
            }]
        })], {type: 'application/json'}));
    }

    return axios.post(`/users/filter`, formData)
        .then(response => {
            if (response.status === 200) {
                const admins = response.data.users.map((u, index) => ({
                    userId: u.tenants[0].user_id,
                    firstName: u.first_name,
                    lastName: u.last_name,
                    email: u.email,
                    phoneNumber: u.phone_number,
                    isActive: u.tenants[0].is_active,
                    roles: u.roles
                }));
                dispatch(setAdminsList(admins));
                dispatch(setUsersTotalCount(response.data.total_count));
            } else {
                dispatch(handleError(response.data));
            }
        })
        .catch(error => dispatch(handleError(error)));
};

export const deleteUser = (userId) => (dispatch) => {
    return axios.delete(`/users/${userId}`)
        .then(response => {
            response.status === 200
                ? dispatch(removeUser(userId))
                : dispatch(handleError(response.data))
        })
        .catch(error => dispatch(handleError(error)))
}

export const deactivateUser = (userId, delete_stakeholder = false) => (dispatch) => {
    return axios.delete(`/users/${userId}/deactivate?delete_stakeholder=${delete_stakeholder}`)
        .then(response => {
            response.status === 200
                ? dispatch(deactivateUserById(userId))
                : dispatch(handleError(response.data))
        })
        .catch(error => dispatch(handleError(error)))
}

export const editUserData = (userData, userId) => (dispatch) => {
    const formData = new FormData()
    formData.append('user_settings', new Blob([JSON.stringify({delete_photo: false}, null, 2)], {type: 'application/json'}))
    formData.append('user', new Blob([JSON.stringify({...userData}, null, 2)], {type: 'application/json'}))
    return axios.put(`/users/${userId}`, formData)
        .then(response => {
            if (response.status === 200) {
                dispatch(editUser(userData, userId))
                return true
            } else {
                dispatch(handleError(response.data))
            }
        })
        .catch(error => dispatch(handleError(error)))
}

export const editUserDataFields = (userData, userId) => (dispatch) => {
    const formData = new FormData()
    formData.append('user_settings', new Blob([JSON.stringify({delete_photo: false}, null, 2)], {type: 'application/json'}))
    formData.append('user', new Blob([JSON.stringify({...userData}, null, 2)], {type: 'application/json'}))
    return axios.put(`/users/${userId}/common`, formData)
        .then(response => {
            if (response.status === 200) {
                dispatch(editUser(userData, userId))
                return true
            } else {
                dispatch(handleError(response.data))
            }
        })
        .catch(error => dispatch(handleError(error)))
}


export const createUser = (userData) => (dispatch) => {
    const formData = new FormData()
    formData.append('user_settings', new Blob([JSON.stringify({delete_photo: false}, null, 2)], {type: 'application/json'}))
    formData.append('user', new Blob([JSON.stringify({...userData}, null, 2)], {type: 'application/json'}))
    return axios.post(`/users`, formData)
        .then(response => {
            if (response.status === 201) {
                dispatch(addUser(response.data.user))
                return true
            } else {
                dispatch(handleError(response.data))
            }
        })
        .catch(error => dispatch(handleError(error)))
}

export const getAllowedUserRoles = () => (dispatch) => {
    return axios.get('/roles/users')
        .then(response => {
            response.status === 200
                ? dispatch(setAllowedUserRoles(response.data.roles))
                : dispatch(handleError(response.data))
        })
        .catch(error => dispatch(handleError(error)))
}


export const updateUserRoles = (roles) => (dispatch) => {
    return axios.put(`/system-admin/users/roles`, {settings: roles})
        .then(response => {
        })
        .catch(error => dispatch(handleError(error)))
}

export default usersReducer
