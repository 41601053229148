import React, {useEffect, useState} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Users from './Users'
import {deactivateUser, getUserList, getUserListWithPagination} from '../../store/usersReducer'
import {getCurrentUserRoles, getLoginRolePicked, getUsersSearchString} from '../../helpers/localStorage'
import {Navigate} from "react-router";

const UsersContainer = () => {
  const dispatch = useDispatch()
  const users = useSelector(state => state.usersReducer.userList)
  const isSignedIn = useSelector(state => state.authReducer.isSignedIn)
  const [tableRows, setTableRows] = useState()
  const [modalData, setModalData] = useState({isOpen: false})
  const [selectedUserType, setSelectedUserType] = useState('All')
  const searchedValue =  getUsersSearchString()
  const page = useSelector(state => state.usersReducer.currentPage)
  const loginRolePickerValue = getLoginRolePicked()
  const currentUserRoles = getCurrentUserRoles()
  const isAdmin = currentUserRoles == null ? false : loginRolePickerValue ? loginRolePickerValue === "system admin" : currentUserRoles?.some(role => role.code.toLowerCase() === "system admin" || role.code.toLowerCase() === "customer admin")
  useEffect(() => {
    if(isAdmin) {
      if(!searchedValue){
        dispatch(getUserListWithPagination({
          pagination_request: {
            page: page,
            size: 10,
            sort: []
          }
        }))
      }
      else{
        dispatch(getUserListWithPagination({
          pagination_request: {
            page: page + 1,
            size: 10,
            sort: []
          },
          filter_request: {
            search_value: searchedValue
          }
        }))
      }
    }
    else{
      if(!searchedValue){
        dispatch(getUserList())
      }
    }
  }, [dispatch, isAdmin, searchedValue])

  useEffect(() => {
    if(isAdmin) {
      setTableRows(users)
    }
    else{
      if(!searchedValue){
        setTableRows(users)
      }
    }
  }, [users, searchedValue, isAdmin])

  const search = (value) => {
    if(isAdmin) {
      dispatch(getUserListWithPagination({
        pagination_request: {
          page: page + 1,
          size: 10,
          sort: []
        },
        filter_request: {
          search_value: value
        }
      }))
    }
    else{
      const searchResults = users.filter(user => `${user.firstName} ${user.lastName}`.toLowerCase().includes(value.toLowerCase()))
      if (!value) {
        setTableRows(users)
      }
      setTableRows(searchResults)
    }

  }

  const selectUserType = (val) => {
    if (val === 'All') {
      setTableRows(users)
    } else {
      setTableRows(users.filter(user => val === 'Active' ? user.isActive : !user.isActive))
    }
    setSelectedUserType(val)
  }

  const removeUser = (userId, deleteStakeholder) => {
    dispatch(deactivateUser(userId, deleteStakeholder))
  }




  const userTypeOptions = [
    {id: 1, name: 'All', value: 'All'},
    {id: 2, name: 'Active', value: 'Active'},
    {id: 3, name: 'Inactive', value: 'Inactive'},
  ]

  if(!isAdmin && isSignedIn) {
    return <Navigate to='/StakeholderMap' />
  }

  return (
      <Users
          removeUser={removeUser}
          modalData={modalData}
          search={search}
          setModalData={setModalData}
          tableRows={tableRows}
          setTableRows={setTableRows}
          selectedUserType={selectedUserType}
          selectUserType={selectUserType}
          userTypeOptions={userTypeOptions}
          searchedValue={searchedValue}
          isUsersTable={true}
      />
  )
}

export default UsersContainer
