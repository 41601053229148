import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals'
import store from './store/store'
import { Provider } from 'react-redux'
import {BrowserRouter} from 'react-router-dom'
import { MuiThemeProvider } from '@material-ui/core';
import { createTheme } from '@material-ui/core/styles'
import { LicenseInfo } from '@mui/x-data-grid-pro';

LicenseInfo.setLicenseKey(
  'e7abf093bce7c4616489a21b592f9d52T1JERVI6MzEyNTcsRVhQSVJZPTE2NjY0MTUxNjUwMDAsS0VZVkVSU0lPTj0x',
);

const THEME = createTheme({
  typography: {
   "fontFamily": `'Montserrat', sans-serif`,
  }
});

ReactDOM.render(
  <React.StrictMode>
    <MuiThemeProvider theme={THEME}>
      <Provider store={store}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </Provider>
    </MuiThemeProvider>
  </React.StrictMode>,
  document.getElementById('root')
);


if ('serviceWorker' in navigator) {
    navigator.serviceWorker.ready
        .then(registration => {
            if ('caches' in window) {
                console.log('Clearing caches and updating application...');
                return caches.keys().then(cacheNames => {
                    return Promise.all(cacheNames.map(cacheName => {
                        return caches.delete(cacheName).then(() => {
                            console.log(`Cache ${cacheName} deleted.`);
                        });
                    }));
                }).then(() => {
                    console.log('All caches have been cleared.');
                    return registration.clients.matchAll({type: 'window'});
                }).then(clients => {
                    clients.forEach(client => client.navigate(client.url));
                });
            }
        })
        .catch(error => {
            console.error('Service worker registration failed:', error);
        });
} else {
    console.log('Service Worker and Cache API are not supported in this browser.');
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
