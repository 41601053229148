import { Popconfirm } from 'antd'
import classes from './../Projects.module.css'
import {tableWidth} from '../../../../../common/sizes'
import Tooltip from '../../../../../common/Tooltip/Tooltip'
import {ReactComponent as EditIcon} from './../../../../../../img/icons/editIcon.svg'
import {ReactComponent as DeleteIcon} from './../../../../../../img/icons/deleteIcon.svg'
import EditableNumber from '../../../../../common/EditableNumber/EditableNumber'
import {ReactComponent as ExclamationCircle} from './../../../../../../img/icons/exclamationCircle.svg'

const partOfTableWidthSmallColumn = 0.1
const partOfTableWidthBigColumn = 0.15
const smallColumnMaxWidth = 110
const defaultColumnWidth = (tableWidth * partOfTableWidthSmallColumn) > smallColumnMaxWidth ? smallColumnMaxWidth : tableWidth * partOfTableWidthSmallColumn
const biggerColumnWidth = tableWidth * partOfTableWidthBigColumn

const getColumns = (removeProject, setModalData, setHasProjectChanges, updatedProjectNumberValue) => {
  const deleteProject = (projId) => {
    removeProject(projId)
    setHasProjectChanges(true)
  }
  return [
    {
      field: 'projectName', 
      headerName: 'Project Name',  
      width: biggerColumnWidth,
      renderCell: (params) => <div style={{overflow: 'auto'}}>{params.row.projectName}</div>
    },
    { 
      field: 'actions', 
      headerName: 'Actions', 
      sortable: false, 
      width: defaultColumnWidth,
      renderCell: (params) => {
        return (
          <div style={{display:'flex', alignItems:'center'}} >
            <EditIcon 
              className={classes.tableIcon}
              onClick={() => setModalData({isOpen: true, editingData: params.row})}
              style={{marginRight: '15px'}}
            />
            <Popconfirm
              title='Are you sure to remove the person from this project?'
              onConfirm={() => deleteProject(params.row.projId)}
              okText='Yes'
              cancelText='No'
              icon={<ExclamationCircle />}
              cancelButtonProps={{type: 'primary', style:{background: '#1890FF'}}}
              okButtonProps={{type: 'default'}}
            >
              <DeleteIcon className={classes.tableIcon}/>
            </Popconfirm>
          </div>
        )
      }
    },
    { field: 'team', 
      headerName: 'Workstream', 
      width: biggerColumnWidth
    },
    { field: 'roleTitle', 
      headerName: 'Role', 
      sortable: false, 
      width: biggerColumnWidth,
      renderCell: (params) => {
        return (
          <div>
            {params.row.roleTitle}
          </div>
        )
      }
    },
    { field: 'roleDescription', 
      headerName: 'Role description', 
      sortable: false, 
      width: biggerColumnWidth,
      renderCell: (params) => {
        return (
          <Tooltip title={params.row.roleDescription}>
            <div style={{width:`${biggerColumnWidth}px`, overflow: 'auto'}}>
              {params.row.roleDescription}
            </div>
          </Tooltip>
        )
      }
    },
    { field: 'influenceLvl',     
      headerName: 'Influence',  
      width: defaultColumnWidth,
      renderCell: (params) => {
        return (
          <EditableNumber
            value={params.row.influenceLvl}
            itemId={params.row.projId}
            minValue={1}
            maxValue={10}
            updateData={updatedProjectNumberValue}
            valueKey='influenceLvl'
          />
        )
      }
    },
    { field: 'interestLvl',
      headerName: 'Interest',  
      width: defaultColumnWidth,
      renderCell: (params) => {
        return (
          <EditableNumber
            value={params.row.interestLvl}
            itemId={params.row.projId}
            minValue={1}
            maxValue={10}
            updateData={updatedProjectNumberValue}
            valueKey='interestLvl'
          />
        )
      }
    },
    { field: 'supportLvl', 
      headerName: 'Support', 
      width: defaultColumnWidth,
      renderCell: (params) => {
        return (
          <EditableNumber
            value={params.row.supportLvl}
            itemId={params.row.projId}
            minValue={-5}
            maxValue={5}
            updateData={updatedProjectNumberValue}
            valueKey='supportLvl'
          />
        )
      }
    },
    { field: 'isDisrupter', 
      headerName: 'Disrupter', 
      width: defaultColumnWidth,
      renderCell: (params) => {
        return (
          <div>
            {params.row.isDisrupter === null ? 'Unknown' : params.row.isDisrupter ? 'yes' : 'no'}
          </div>
        )
      }
    },
    { field: 'workloadPct', 
      headerName: 'FTE', 
      width: defaultColumnWidth,
      renderCell: (params) => {
        return (
          <div>
            {params.row.workloadPct === null 
              ? 'Unknown' 
              : params.row.workloadPct === 'n/a' 
                ? 'n/a' 
                : params.row.workloadPct
            }
          </div>
        )
      }
    },
    { field: 'isProjectTeamMember', 
      headerName: 'Project Team Member', 
      width: defaultColumnWidth,
      renderCell: (params) => {
        return (
          <div>
            {params.row.isProjectTeamMember ? 'yes' : 'no'}
          </div>
        )
      }
    },
  ]
}

export default getColumns
