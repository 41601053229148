import  React from 'react'
import classes from './OrganizationsList.module.css'
import {pageDefaultHeight} from './../common/sizes'
import { PrimaryButton } from '../common/buttons/buttons'
import Search from './../common/Search/Search'
import UserInfoArea from '../common/UserInfoArea/UserInfoArea'
import OrganizationsTable from './OrganizationsTable.js/OrganizationsTable'
import OrganizationModalContainer from './OrganizationModal/OrganizationModalContainer'
import ErrorAlert from '../common/ErrorAlert/ErrorAlert'
import Select from '../common/Select/Select'

const OrganizationsList = (props) => {
  return (
    <>
      <header className={classes.header}>
        <Search onChange={event => props.search(event.target.value)} />
        <UserInfoArea />
      </header>
      <div className={classes.wrapper} style={{height: pageDefaultHeight}}>
        <div className={classes.titleArea} >
          <h1 className={classes.pageTitle}>
            ORGANISATIONS LIST
          </h1>
          <div>
            <Select
              options={props.isActiveOptions}
              onSelect={(value) => props.setSelectedIsActive(value)}
              style={{width: '200px', marginRight: '15px'}}
              value={props.selectedIsActive}
              className='smallSelect'
            />
            <PrimaryButton 
              text='ADD ORGANISATION' 
              onClick={() => props.setOrganizationModalData({isOpen: true})}
            />
          </div>
        </div>
        <OrganizationsTable 
          deleteOrganization={props.deleteOrganization}
          setOrganizationModalData={props.setOrganizationModalData}
          tableRows={props.tableRows}
        />
        {props.organizationModalData.isOpen && 
          <OrganizationModalContainer 
            setSelectedIsActive={props.setSelectedIsActive}
            organizationModalData={props.organizationModalData}
            setOrganizationModalData={props.setOrganizationModalData}
          />
        }
      </div>
      {props.error && 
        <ErrorAlert 
          errorMessage={props.error?.message || 'An unexpected error has occurred'} 
          onClose={() => props.setError(null)}
        />
      }
    </>
  )
}

export default OrganizationsList
