import { Input, Modal } from 'antd'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { PrimaryButton, SecondaryButton } from '../../../../common/buttons/buttons'
import Checkbox from '../../../../common/Checkbox/Checkbox'
import classes from './AnnotationModal.module.css'
import {addAnnotationTC, editAnnotationTC} from '../../../../../store/stakeholderMapReducer'
import { useEffect } from 'react'
import { useStoreState } from 'react-flow-renderer'

const AnnotationModal = (props) => {
  return (
    <Modal
      closable={false}
      footer={null}
      style={{padding: '10px'}}
      visible={props.isAnnotationModalOpen.isOpen} 
      destroyOnClose
    >
      <AnnotationModalForm {...props}/>
    </Modal>
  )
}

const AnnotationModalForm = (props) => {
  const dispatch = useDispatch()
  const selectedProject = useSelector(state => state.projectsReducer.selectedProject)
  const userId = useSelector(state => state.authReducer.userId)
  const annotations = useSelector((state) => state.stakeholderMapReducer.annotations)
  const selectedElements = useStoreState((state) => state.selectedElements)

  const [values, setValues] = useState({comment: '', isProjectViewVisible: false})
  const [isLoading, setIsLoading] = useState(false)
  const [isSaved, setIsSaved] = useState(false)

  const onSubmit = () => {
    const newAnnotation = {
      comment: values.comment,
      isProjectViewVisible: values.isProjectViewVisible,
      projectId: selectedProject,
      userId,
      position: props.isAnnotationModalOpen.position
    }

    setIsLoading(true)
    dispatch(props.isAnnotationModalOpen?.editingData || props.isAnnotationModalOpen?.isEditing
      ? editAnnotationTC(
        props.isAnnotationModalOpen?.editingData ? props.isAnnotationModalOpen?.editingData?.id : selectedElements[0].data?.data?.id,
        values
      ) : addAnnotationTC(newAnnotation)
    )
      .then((resp) => {
        setIsLoading(false)
        !!resp && setIsSaved(true)
      })
  }

  useEffect(() => {
    if (props.isAnnotationModalOpen?.isEditing || props.isAnnotationModalOpen?.editingData) {
      const editingData = props.isAnnotationModalOpen?.editingData || annotations.find(a => a.id === selectedElements[0].data?.data?.id)
      setValues(editingData)
    }
  }, [props.isAnnotationModalOpen, annotations, selectedElements])

  return (
    <div onClick={(e) => e.stopPropagation()}>
      {isSaved ? (
        <div className={classes.successMessageWrap}>
          <div className={classes.successMessage}>
            Annotation has been saved!
          </div>
         <PrimaryButton 
            className={classes.primaryButton}    
            onClick={props.closeModal}
            style={{width:'100px', height: '34px'}}
            text='OK'
          />
        </div>
      ) : (
        <>
          <div className={classes.title}>
            Project Annotation
          </div>
          <div className={classes.form}>
            <Input.TextArea
              value={values?.comment}
              onChange={e => setValues({...values, comment: e.target.value})}
              rows={4}
            />
            <Checkbox
              checked={values.isProjectViewVisible}
              onChange={e => setValues({...values, isProjectViewVisible: e.target.checked})}
              label='Include to the Project View'
              wrapperStyle={{marginTop: '15px'}}
            />
            <div className={classes.buttonsArea}>
              <SecondaryButton
                onClick={props.closeModal}
                style={{width:'100px', height: 'auto', marginRight: '10px'}}
                text='Cancel' 
              />
              <PrimaryButton 
                className={classes.primaryButton}    
                onClick={onSubmit}
                style={{width:'100px', height: '34px'}}
                text='SAVE'
                loading={isLoading} 
              />
            </div>
          </div>
        </>
      )}
    </div>
  )
}

export default AnnotationModal
