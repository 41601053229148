import React, { useState } from 'react'
import { useMediaQuery } from 'react-responsive'
import classes from './Matrix.module.css'
import UserInfoArea from '../common/UserInfoArea/UserInfoArea'
import {pageDefaultHeight} from './../common/sizes'
import MatrixView from './MatrixView'
import {SecondaryButton} from '../common/buttons/buttons'
import TableView from './TableView'
import ErrorAlert from './../common/ErrorAlert/ErrorAlert'
import Spinner from '../common/Spinner/Spinner'
import {ReactComponent as NoDataImg} from './../../img/noDataImg.svg'
import Switch from './../common/Switch/Switch'
import {default as MobileNavBar} from './../NavBar/NavBarContainer'
import MobileHeaderTools from '../common/MobileHeaderTools/MobileHeaderTools'
import ProjectSelect from '../common/ProjectSelect/ProjectSelect'

const Matrix = (props) => {
  const isMobileScreen = useMediaQuery({ query: '(min-width: 360px) and (max-width: 766px)' })

  if (props.isEmpty) {
    return (
      <>
        <header className={`${classes.header} ${classes.emptyPageHeader}`}>
          {/*<MobileNavBar className={classes.mobileHeader} />*/}
          <UserInfoArea />
        </header>
        <div className={`emptyPage ${classes.emptyPage}`}>
          <NoDataImg />
        </div>
      </>
    )
  }

  return (
    <div style={{height: isMobileScreen ? `${window.innerHeight - 30}px` : '100%'}} className={classes.matrixPageWrapper}>
      {props.isLoaded 
        ? <MatrixPageContent {...props} />
        : <Spinner className={classes.spinner}/>
      }
      {props.error && 
        <ErrorAlert 
          errorMessage={props.error?.message || 'An unexpected error has occurred'} 
          onClose={() => props.setError(null)}
        />
      }
    </div>
  )
}

const MatrixPageContent = (props) => {
  const [isToolsVisible, setIsToolsVisible] = useState(false)
  const [showAllNames, setShowAllNames] = useState(false)

  return (
    <>
      <header className={classes.header}>
        <div style={{display:'flex', justifyContent:'space-between', alignItems:'center'}}>
          {/*<MobileNavBar*/}
          {/*  className={classes.mobileHeader}*/}
          {/*  setIsToolsVisible={setIsToolsVisible}*/}
          {/*/>*/}
          <div className={classes.toolsArea}>
            <HeaderTools
              selectProjectOptions={props.selectProjectOptions}
              selectProject={props.selectProject}
              selectedProject={props.selectedProject}
              toggleIsMatrixViewActive={props.toggleIsMatrixViewActive}
              isMatrixViewActive={props.isMatrixViewActive}
              isProjectTeamContactOn={props.isProjectTeamContactOn}
              setIsProjectTeamContactOn={props.setIsProjectTeamContactOn}
              showAllNames={showAllNames}
              setShowAllNames={setShowAllNames}
            />
          </div>
        </div>
        <UserInfoArea onExport={props.exportMatrixAsPdf}/>
      </header>
      <div 
        className={classes.wrapper}
        style={{height: pageDefaultHeight}}
      >
        {props.isMatrixViewActive ? (
          <MatrixView
            matrixData={props.matrixData}
            showAllNames={showAllNames}
          />
        ) : (
          <div style={{display:'flex', alignItems:'center', justifyContent: 'center'}}>
            <TableView matrixData={props.matrixData} organizations={props.organizations}/>
          </div>
        )}
        <MobileHeaderTools
          isToolsVisible={isToolsVisible}
          setIsToolsVisible={setIsToolsVisible}
          content={
            <HeaderTools
              selectProjectOptions={props.selectProjectOptions}
              selectProject={props.selectProject}
              selectedProject={props.selectedProject}
              toggleIsMatrixViewActive={props.toggleIsMatrixViewActive}
              isMatrixViewActive={props.isMatrixViewActive}
              isProjectTeamContactOn={props.isProjectTeamContactOn}
              setIsProjectTeamContactOn={props.setIsProjectTeamContactOn}
              setIsToolsVisible={setIsToolsVisible}
            />
          }
        />
      </div>
    </>
  )
}

const HeaderTools = (props) => {
  const isMobileScreen = useMediaQuery({ query: '(min-width: 360px) and (max-width: 766px)' })
  return (
    <>
      <ProjectSelect
        options={props.selectProjectOptions}
        onSelect={(value) => {
          props.selectProject(value)
          !!isMobileScreen && props.setIsToolsVisible(false)
        }}
        style={isMobileScreen ? {marginBottom:'20px'} : {marginRight:'20px'}}
        value={props.selectedProject}
        className={classes.headerSelect}
        showCompleted
      />
      {isMobileScreen ? (
        <>
          <div className={classes.switchWrapperMobile} style={{marginBottom:'20px'}}>
            <span style={{fontSize: '16px'}}>Project team contacts</span>
            <Switch 
              checked={props.isProjectTeamContactOn} 
              onChange={() => {
                props.setIsProjectTeamContactOn(!props.isProjectTeamContactOn)
                props.setIsToolsVisible(false)
              }}
            />
          </div>
          <div
            className={classes.switchWrapperMobile}
            style={{marginBottom:'20px'}}
            onClick={() => {
              props.toggleIsMatrixViewActive(!props.isMatrixViewActive)
              props.setIsToolsVisible(false)
            }}
          >
            {props.isMatrixViewActive ? 'Open table view' : 'Open matrix view'}
          </div>
          {props.isMatrixViewActive &&
            <div className={classes.switchWrapperMobile}>
              <span style={{fontSize: '16px'}}>Show All Names</span>
              <Switch
                onChange={() => props.setShowAllNames(!props.showAllNames)}
                checked={props.showAllNames}
              />
            </div>
          }
        </>
      ) : (
        <>
          <div style={{display:'flex', alignItems:'center'}}>
            <SecondaryButton 
              onClick={() => props.toggleIsMatrixViewActive(!props.isMatrixViewActive)}
              style={{width: '130px', height:'36px'}}
              text={props.isMatrixViewActive ? 'TABLE VIEW' : 'MATRIX VIEW'}  
            />
          </div>
          <div className={classes.switchWrapper}>
            <span style={{fontSize: '14px'}}>PROJECT TEAM CONTACTS</span>
            <Switch
              checked={props.isProjectTeamContactOn} 
              onChange={() => props.setIsProjectTeamContactOn(!props.isProjectTeamContactOn)} 
              leftLabel='OFF'
              rightLabel='ON'
            />
          </div>
          <div className={classes.switchWrapper}>
            <span style={{fontSize: '14px'}}>SHOW ALL NAMES</span>
            <Switch
              onChange={() => props.setShowAllNames(!props.showAllNames)}
              checked={props.showAllNames}
              leftLabel='OFF'
              rightLabel='ON'
            />
          </div>
        </>
      )}
    </>
  )
}

export default Matrix
