import { useEffect, useState } from "react"
import { DataGridPro } from '@mui/x-data-grid-pro'
import { Dialog, DialogContent } from '@material-ui/core'
import { PrimaryButton } from '../../common/buttons/buttons'
import {ReactComponent as InfoIcon} from './../../../img/icons/infoIcon.svg'

const TableProjectsColumn = (props) => {
  const [isAdditionInfoOpen, setIsAdditionInfoOpen] = useState(false)
  const [page, setPage] = useState(0)

  const tableWidth = 1000
  const bigColumnWidth = tableWidth * 0.199
  const smallColumnWidth = tableWidth * 0.12

  useEffect(() => {
    setPage(0)
  }, [props?.tableRows])

  const columns = [
    { field: 'name',
      headerName: 'Name',
      width: bigColumnWidth,
      renderCell: (params) => {
        return <div style={{overflow:'auto'}}>{params.row.name}</div>
      },
    },
    { field: 'team',
      headerName: 'Workstream',
      width: bigColumnWidth,
      renderCell: (params) => {
        return <div style={{overflow:'auto'}}>{params.row.team}</div>
      },
    },
    { field: 'roleTitle',
      headerName: 'Role',
      width: bigColumnWidth,
      renderCell: (params) => {
        return <div style={{overflow:'auto'}}>{params.row.roleTitle}</div>
      },
    },
    { field: 'roleDescription',
      headerName: 'Role description',
      width: bigColumnWidth,
      renderCell: (params) => {
        return <div style={{overflow:'auto'}}>{params.row.roleDescription}</div>
      },
    },
    { field: 'influenceLvl', headerName: 'Influence', width: smallColumnWidth},
    { field: 'interestLvl', headerName: 'Interest', width: smallColumnWidth},
    { field: 'supportLvl', headerName: 'Support', width: smallColumnWidth},
    { field: 'isDisrupter',
      headerName: 'Disrupter',
      width: smallColumnWidth,
      renderCell: (params) => {
        return (
          <div>
            {params.row.isDisrupter === null ? 'Unknown' : params.row.isDisrupter ? 'yes' : 'no'}
          </div>
        )
      }
    },
    { field: 'workloadPct',
      headerName: 'FTE',
      width: smallColumnWidth,
      renderCell: (params) => {
        return (
          <div>
            {params.row.workloadPct === null 
              ? 'Unknown' 
              : params.row.workloadPct === 'n/a' 
                ? 'n/a' 
                : params.row.workloadPct
            }
          </div>
        )
      }
    },
    { field: 'isProjectTeamMember', 
      headerName: 'Project Team Member', 
      width: smallColumnWidth,
    },
  ]
  return (
    <>
      <div style={{display:'flex', alignItems:'center'}}>
        <span style={{marginRight:'5px'}}>{props.projects?.length}</span>
        {props.projects?.length === 1 ? 'project' : 'projects'}
        <InfoIcon style={{cursor:'pointer', marginLeft:'10px'}} onClick={() => setIsAdditionInfoOpen(true)}/>
      </div>
      {isAdditionInfoOpen && 
        <Dialog
          open={isAdditionInfoOpen}
          aria-labelledby='alert-dialog-title'
          aria-describedby='alert-dialog-description'
          maxWidth='1200px'
          width={`${window.innerWidth - 50}px`}
          onClose={() => setIsAdditionInfoOpen(false)}
        >
          <DialogContent>
            <div style={{height: '400px', width: `${window.innerWidth - 150}px`}}>
              <DataGridPro
                getRowId={e => e.projId || null}
                rows={props.projects || []} 
                columns={columns}  
                rowCount={props.projects?.length || 0}
                pagination
                rowsPerPageOptions={[10]}
                setPage={page}
                onPageChange={(e) => setPage(e.pageCount - 1)}
                pageSize={10}
                style={{borderRadius:'15px', border:'none'}}
              />
            </div>
            <div style={{display:'flex', justifyContent:'flex-end'}}>
              <PrimaryButton 
                text='Close'
                onClick={() => setIsAdditionInfoOpen(false)}
                style={{width:"100px", marginTop:'20px'}}
              />
            </div>
          </DialogContent>
        </Dialog>
      }
    </>
  )
}

export default TableProjectsColumn
