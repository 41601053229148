import React, {useEffect, useState} from 'react'
import Projects from './Projects'
import { deleteProject, getProjectsByPeriod } from '../../store/projectsReducer'
import { useDispatch, useSelector } from 'react-redux'

const ProjectsContainer = () => {
  const dispatch = useDispatch()
  const projectsList = useSelector(store => store.projectsReducer.projectList)
  const userId = useSelector(store => store.authReducer.userId)
  const isAuth = useSelector(store => store.authReducer.isSignedIn)

  const [projectModalDataDetails, setProjectModalDataDetails] = useState({isOpen: false})
  const [tableRows, setTableRows] = useState([])
  const [selectedProjectPeriod, setSelectedProjectPeriod] = useState('active')
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    if(isAuth) {
      setIsLoading(true)
      !!userId && dispatch(getProjectsByPeriod(selectedProjectPeriod, false, userId))
        .then(() => setIsLoading(false))
    }
  },[selectedProjectPeriod, dispatch, userId, isAuth])

  useEffect(() => {
    const tableData = projectsList?.map(row => ({...row, id: row.projId}))
    setTableRows(tableData)
  },[projectsList])
  
  const projectPeriodOptions = [
    {id: 1, name: 'Active', value: 'active'},
    {id: 2, name: 'Completed', value: 'completed'},
    {id: 3, name: 'Not started yet', value: 'notStartedYet'},
  ]

  const selectProjectPeriod = (value) => {
    setSelectedProjectPeriod(value)
  }

  const search = (value) => {
    const searchResults = projectsList.filter(project => project.name.toLowerCase().includes(value.toLowerCase()))
    if (!value) {
      setTableRows(projectsList)
    }
    setTableRows(searchResults)
  }

  const deleteProjectData = (id) => {
    dispatch(deleteProject(id))
  }

  const closeModal = () => {
    setProjectModalDataDetails({isOpen:false})
  }

  const exportHeaders = [
    {label: "Project Name", key: 'name'},
    {label: "Active From", key: 'activeFrom'},
    {label: "Active Till", key: 'activeTill'},
    {label: "Description", key: 'description'},
    {label: "Project Documentation Marking", key: 'projectMarking'}
  ]
  const getExportData = () => {
    return tableRows?.map(proj => {
      let {name, activeFrom, activeTill, description} = proj
      return {name, activeFrom, activeTill, description}
    }) || []
  }

  return (
    <Projects 
      closeModal={closeModal}
      deleteProject={deleteProjectData}
      projectModalDataDetails={projectModalDataDetails}
      projectPeriodOptions={projectPeriodOptions}
      setProjectModalDataDetails={setProjectModalDataDetails}
      search={search}
      selectProjectPeriod={selectProjectPeriod}
      selectedProjectPeriod={selectedProjectPeriod}
      tableRows={tableRows}
      exportHeaders={exportHeaders}
      exportData={getExportData()}
      isLoading={isLoading}
    />
  )
}

export default ProjectsContainer
