import React, {useEffect, useState} from 'react'
import {Formik, Form, useFormikContext} from 'formik'
import * as Yup from 'yup'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import classes from './StakeholderAttributesForm.module.css'
import { PrimaryButton, SecondaryButton } from '../../common/buttons/buttons'
import { connect } from 'react-redux'
import { tableWidth } from '../../common/sizes'
import MandatoryDetails from './tabs/MandatoryDetails/MandatoryDetails'
import ProjectsContainer from './tabs/Projects/ProjectsContainer'
import ContactInformation from './tabs/ContactInformation/ContactInformation'
import { getCurrentUserRoles } from '../../../helpers/localStorage'
import {useNavigate} from "react-router";

const StakeholderAttributesForm = (props) => {
  const [values, setValues] = useState({})
  const [hasProjectChanges, setHasProjectChanges] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [triedToSendForm, setTriedToSendForm] = useState(false)
  const navigate = useNavigate()
  const currentUserRoles = getCurrentUserRoles()
  const isUser = currentUserRoles.length === 1 && currentUserRoles?.some(role => role.code.toLowerCase() === 'user')

  useEffect(() => {
    if(!!props.isDataSent) setIsLoading(false)
  }, [props.isDataSent])

  const onSubmit = (formData, {setSubmitting}) => {
    setIsLoading(true)
    props.sendStakeholderData(formData)
    setHasProjectChanges(false)
    setSubmitting(false)
  }


  const validationSchema = Yup.object({
    firstNameKnownAs: Yup.string()
      .required('Known As is required'),
    lastName: Yup.string()
      .required('Surname is required'),
    firstName: Yup.string()
      .required('First name is required'),
    orgId: Yup.string()
      .required('Organisation is required')
      .notOneOf(['none'], 'Organisation is required'),
  })

  return (
      <Formik
          enableReinitialize
          initialValues={props.fieldValuesWithId || props.initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
          validateOnChange={triedToSendForm}
          validateOnBlur={triedToSendForm}
      >
        {propsFormik => {
          const { dirty, values } = propsFormik;  // Access the dirty state here

          return (
              <Form className={classes.form}>
                <div className={classes.submitArea}>
                  <div className={classes.buttonsArea} style={{width: tableWidth}}>
                    <div>
                      {hasProjectChanges && 'To save the changes you should click the Save button' }
                    </div>
                    <div className={classes.buttons}>
                      <SecondaryButton
                          onClick={() => navigate(-1)}
                          style={{height:'36px', width: '132px', marginRight: '15px'}}
                          text='Cancel'
                      />
                      <PrimaryButton
                          htmlType='submit'
                          style={{height:'36px', width: '132px'}}
                          text='SAVE'
                          loading={isLoading}
                          disabled={!dirty}
                          onClick={() => setTriedToSendForm(true)}
                      />
                    </div>
                  </div>
                </div>
                <div className={classes.tabsWrapper}>
                  <MandatoryDetails
                      values={values}
                      setFieldValue={propsFormik.setFieldValue}
                      organizations={props.organizations}
                      errors={propsFormik.errors}
                      propsFormik={propsFormik}
                      id={props.id}
                  />
                  {!isUser &&
                      <ProjectsContainer
                          values={values}
                          setFieldValue={propsFormik.setFieldValue}
                          setHasProjectChanges={setHasProjectChanges}
                          dirty={dirty}
                      />
                  }
                  <ContactInformation
                      values={values}
                      setFieldValue={propsFormik.setFieldValue}
                  />
                  <Dialog
                      open={props.isDataSent}
                      onClose={() => props.setIsDataSent(false)}
                      aria-labelledby='alert-dialog-title'
                      aria-describedby='alert-dialog-description'
                  >
                    <DialogContent className={classes.successMessageModal}>
                      <div className={classes.successMessage}>
                        All changes have been saved
                      </div>
                      <div className={classes.successMessageButtons}>
                        <PrimaryButton
                            text='OK'
                            onClick={() => {
                              props.setIsDataSent(false)
                              navigate(-1)
                            }}
                            style={{width: '130px', height: '40px', marginLeft: '25px'}}
                            disabled={!dirty}
                        />
                      </div>
                    </DialogContent>
                  </Dialog>
                </div>
                <FormikContext setValues={setValues} values={props.values}/>
              </Form>
          );
        }}
      </Formik>
  );
}

const FormikContext = (props) => {
  const {values} = useFormikContext()
  props.setValues(values)
  return null
}

const mapStateToProps = (state) => ({
  initialValues: state.stakeholderFormReducer.initialValues
})

export default connect(mapStateToProps, {})(StakeholderAttributesForm)
