import React, { useCallback, useEffect, useState } from 'react'
import classes from './MandatoryDetails.module.css'
import {Input, StyledInput} from '../../../../common/Input/Input'
import AvatarFileUpload from './AvatarFileUpload'
import { Field} from 'formik'
import { AddOrganisationSelect } from '../../../../common/Select/Select'
import Checkbox from './../../../../common/Checkbox/Checkbox'
import CustomDivider from './../../../../common/CustomDivider/CustomDivider'
import { getCurrentUserRoles } from '../../../../../helpers/localStorage'
import FloatLabel from '../../../../common/FloatLabel/FloatLabel'
import OrganizationModalContainer from '../../../../OrganizationsList/OrganizationModal/OrganizationModalContainer'
import {useDispatch, useSelector} from "react-redux";
import {setOrgId} from "../../../../../store/stakeholderFormReducer";

const MandatoryDetails = (props) => {
  const [avatarData, setAvatarData] = useState(null)
  const [orgModalData, setOrgModalData] = useState({isOpen: false})
  const [orgOptions, setOrgOptions] = useState([])
  const [isOrgSelectOpen, setIsOrgSelectOpen] = useState(false)
  const dispatch = useDispatch()
  const currentUserRoles = getCurrentUserRoles()
  const organizations = useSelector(state => state.organizationsReducer.organizations)
  const stakeholders = useSelector(state => state.stakeholdersReducer.stakeholders)
  const isAdmin = currentUserRoles?.some(role => role.code.toLowerCase() === "system admin" 
    || role.code.toLowerCase() === "customer admin"
    || role.code.toLowerCase() === "project admin"
  )
  const setFieldValue = props.setFieldValue
  
  const setFieldValueCallback = useCallback((avatarData) => {
    setFieldValue('avatar', avatarData)
  }, [setFieldValue])

  useEffect(() => {
    setFieldValueCallback(avatarData)
  }, [avatarData, setFieldValueCallback])

  useEffect(() => {
    setOrgOptions(getSelectOrganizationOptions(props.organizations))
    // eslint-disable-next-line
  }, [props.organizations])

  const generateFirstName = (value) => { 
    props.setFieldValue('firstNameKnownAs', value)
    props.setFieldValue('firstName', value)
  }

  const getSelectOrganizationOptions = (organizations) => {
    const organizationOptions = organizations
      .filter(organization => organization.isActive)
      .map(organization => ({id: organization.orgId, value: organization.orgId, name: organization.nickname !==  null ? organization.nickname : organization.name}))
    const placeholderOption = {id: 0, value: 'none', name: 'Organisation', disabled: true}
    const isSelectedOrgActive = organizationOptions.some(option => option.value === props.values.orgId) || props.values.orgId === 'none'
    const selectedValue = isSelectedOrgActive 
      ? []
      : [{id: props.values.orgId, value: props.values.orgId, name: organizations.find(org => org.orgId === props.values.orgId)?.nickname || '', disabled: true}]
      return [placeholderOption, ...selectedValue, ...organizationOptions]
  }

  const orgHasWarning = !!props.values.orgId && props.values.orgId !== 'none' && !props.values.organizationConfirmed


  const onOrganizationChange = (name, val) => {
    props.setFieldValue(name, val)
    const orgLocation = organizations.find((o) => o.orgId === val).location
    props.setFieldValue('officeLocation', orgLocation)
  }

  return (
    <>
      {orgModalData.isOpen &&
        <OrganizationModalContainer
          setSelectedIsActive={() => {}}
          organizationModalData={orgModalData}
          setOrganizationModalData={setOrgModalData}
        />
      }




      <CustomDivider text='Mandatory Details' textStyle={{width:'170px'}} style={{margin: '0px 0px 26px 0px'}}/>
      <div className={classes.wrapper}>
        <div className={classes.inputsArea}>
          <div className={classes.doubleInputsRow}>
            <Field
              InputLabelProps={ { shrink: true } }
              component={StyledInput}    
              onChange={(event) => generateFirstName(event.target.value)}
              InputProps={{classes:{root:'autoWidthInput'}}}
              label='First name (Known as)*'
              name='firstNameKnownAs'
              placeholder='First Name (known as)'
              variant='outlined'
              autoFocus={true}
            />
            <Input   
              className='autoWidthInput'      
              label='Surname*'
              name='lastName'
              placeholder='Surname'
            />
          </div>
          <div className={classes.doubleInputsRow}>
            <Input     
              className='autoWidthInput'   
              label='First name*'
              name='firstName'
              placeholder='First name'
              
            />
            <FloatLabel 
              required={true}
              value={props.values.orgId}
              placeholder='Organisation'
              label='Organisation'
              isFocused={isOrgSelectOpen}
              hasError={!!props.errors?.orgId}
              hasWarning={orgHasWarning}
              input={
                <AddOrganisationSelect
                  options={orgOptions}
                  value={props.values.orgId}
                  onChange={(val) => onOrganizationChange('orgId', val)}
                  // onChange={(val) => onOrganizationValueChange('orgId', val)}
                  placeholder='Organisation'
                  onBtnClick={() => setOrgModalData({isOpen: true})}
                  className={props.values?.orgId === 'none' ? 'placeholderIsShown' : ''}
                  error={props.errors?.orgId}
                  hasWarning={orgHasWarning}
                  onDropdownVisibleChange={e => setIsOrgSelectOpen(e)}
                  style={{width:'98%'}}
                />
              }

            />
          </div>
          {(orgHasWarning || !!isAdmin) &&
            <div className={classes.doubleInputsRow}>
              {!isAdmin ? (
                <div></div>
              ) : (
                <Checkbox
                  checked={props.values.isSensitiveContact}
                  label='Is sensitive contact'
                  onChange={() => props.setFieldValue('isSensitiveContact', !props.values.isSensitiveContact)}
                  wrapperStyle={{marginBottom: '15px', marginRight: '20px'}}
                />
              )}
              {orgHasWarning &&
                <Checkbox
                  checked={props.values.organizationConfirmed}
                  label='Confirm organisation'
                  onChange={() => props.setFieldValue('organizationConfirmed', !props.values.organizationConfirmed)}
                  wrapperStyle={{marginBottom: '15px'}}
                />
              }
            </div>
          }
        </div>
        <AvatarFileUpload setAvatarData={setAvatarData} onChange={props.setFieldValue} photo={props.values.photo}/>
      </div>
    </>
  )
}

export default MandatoryDetails

