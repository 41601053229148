import React, { useState } from 'react'
import { Modal, Input } from 'antd'
import { PrimaryButton, SecondaryButton } from '../common/buttons/buttons'

const FeedbackModal = (props) => {
  const { TextArea } = Input
  const [feedbackMessage, setFeedbackMessage] = useState('')

  return (
    <Modal
      centered
      closable={false}   
      footer={null}
      onCancel={props.closeFeedbackModal}
      visible={props.visible} 
    >
      {!props.isFeedbackSent && 
        <div style={{fontSize:'20px', fontWeight:600, marginBottom:'30px'}}>
          Feedback
        </div>
      }
      {props.isFeedbackSent ? (
        <div style={{display:'flex', flexDirection:'column', alignItems:'center', justifyContent:'center'}}>
          <div style={{fontWeight: 600, marginBottom: '20px', fontSize:'24px'}}>
            Thank you for your feedback
          </div>
          <div style={{fontWeight: 400, marginBottom: '20px', fontSize:'20px', textAlign: 'center'}}>
            We will pass this to our development team for future releases.
          </div>
          <SecondaryButton text='Close' onClick={props.closeFeedbackModal}/>
        </div>
      ) : (
        <>
          <TextArea 
            rows={4}
            value={feedbackMessage}
            onChange={(e) => setFeedbackMessage(e.target.value)}
          />
          <div 
            style={{
              alignItems:'center',
              display:'flex',
              justifyContent:'flex-end',
              marginTop: '15px'
            }}
          >
            <SecondaryButton text='Cancel' onClick={props.closeFeedbackModal}/>
            <PrimaryButton 
              text='Send' 
              style={{marginLeft:'15px', width:'100px'}}
              onClick={() => props.sendFeedbackMessage(feedbackMessage)}
            />
          </div>
        </>
      )}
    </Modal>
  )
}

export default FeedbackModal
