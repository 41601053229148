import React from 'react'
import {useParams} from 'react-router-dom'
import classes from './StakeholderAttributesPage.module.css'
import {pageDefaultHeight} from '../common/sizes'
import UserInfoArea from '../common/UserInfoArea/UserInfoArea'
import StakeholderAttributesForm from './StakeholderAttributesForm/StakeholderAttributesForm'
import ErrorAlert from '../common/ErrorAlert/ErrorAlert'
import {ReactComponent as GoBackIcon} from './../../img/icons/goBackIcon.svg'
import {useNavigate} from "react-router";

const StakeholderAttributesPage = (props) => {
  const navigate = useNavigate()
  const {projId} = useParams()
  return (
    <>
      <header className={classes.header}>
        <div className={classes.pageTitle}>
          <GoBackIcon 
            onClick={() => navigate(
              props.parentPage === 'Projects/edit' 
                ? `/Contacts`
                : `/Contacts`
            )}
            style={{cursor: 'pointer', marginRight: '10px'}}
          /> 
          {props.fieldValuesWithId 
            ? props.fieldValuesWithId?.fullName?.toUpperCase()
            : 'NEW CONTACT' 
          }
        </div>
        <UserInfoArea />
      </header>
      <div className={classes.formWrapper} style={{height:pageDefaultHeight}}>
        <StakeholderAttributesForm 
          sendStakeholderData={props.sendStakeholderData}
          parentPage={props.parentPage}
          isDataSent={props.isDataSent}
          setIsDataSent={props.setIsDataSent}
          fieldValuesWithId={props.fieldValuesWithId}
          organizations={props.organizations}
          id={props.id}
        />
      </div>
      {props.error &&
        <ErrorAlert 
          errorMessage={props.error?.message || 'An unexpected error has occurred'} 
          onClose={() => props.setError(null)}
        />
      }
    </>
  )
}

export default StakeholderAttributesPage
