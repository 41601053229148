import React from 'react'
import {Input} from 'antd'
import {CloseOutlined} from "@material-ui/icons";
import {ReactComponent as SearchIcon} from './../../../img/icons/searchIcon.svg'

const Search = (props) => {
    const handleClear = () => {
        if (props.onChange) {
            props.onChange({ target: { value: '' } });
        }
    }

    return (
        props.searchedValue
            ?
            <Input
                onChange={props.onChange}
                placeholder={props.placeholder}
                prefix={<SearchIcon />}
                value={props.searchedValue}
                suffix={
                    props.searchedValue ? (
                        <CloseOutlined onClick={handleClear} style={{ cursor: 'pointer', fontSize: 14 }} />
                    ) : null
                }
                style={{ width: 290, height: '56px', borderRadius: '15px', ...props.style }}
            />
            :
            <Input
                onChange={props.onChange}
                placeholder={props.placeholder}
                prefix={<SearchIcon />}
                value={props.searchedValue}
                suffix={
                    props.searchedValue ? (
                        <CloseOutlined onClick={handleClear} style={{ cursor: 'pointer', fontSize: 14 }} />
                    ) : null
                }
                style={{ width: 290, height: '56px', borderRadius: '15px', ...props.style }}
            />
    )
}

export default Search
