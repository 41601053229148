import  React, { useEffect, useState } from 'react'
import { DataGridPro, GridToolbarColumnsButton, GridToolbarContainer, useGridApiRef} from '@mui/x-data-grid-pro'
import {useSelector, useDispatch} from 'react-redux'
import classes from './../Projects.module.css'
import getColumns from './getColumns'
import {setTableColumns} from './../../../store/tablesReducer'

const ProjectsTable = (props) => {
  const [page, setPage] = useState(0)
  const deleteProject = props.deleteProject
  const tableColumns = getColumns(deleteProject)
  const tableAppearance = useSelector(state => state.tablesReducer?.projectsTable)
  const apiRef = useGridApiRef()
  const dispatch = useDispatch()

  useEffect(() => {
    setPage(0)
  }, [props?.tableRows])

  useEffect(() => {
    apiRef.current.subscribeEvent('columnHeaderDragEnd', () => saveTableColumns())
    // eslint-disable-next-line
  }, [apiRef])

  const saveTableColumns = () => {
    const stateColumns = apiRef.current.getAllColumns()
    const colsData = Object.keys(stateColumns).map(key => stateColumns[key])
    dispatch(setTableColumns('projectsTable', colsData))
  }

  return (
    <div className={classes.tableWrapper}>
      <DataGridPro
        getRowId={e => e.projId || null}
        rows={props.tableRows || []} 
        apiRef={apiRef}
        onColumnWidthChange={(e) => saveTableColumns()}
        columns={tableAppearance.length ? tableAppearance : tableColumns}  
        style={{borderRadius:'15px', border:'none', height: '80% !important'}}
        components={{Toolbar: CustomToolbar}}
        rowCount={props.tableRows?.length || 0}
        pagination
        setPage={page}
        onPageChange={(e) => setPage(e.pageCount - 1)}
        pageSize={10}
        rowsPerPageOptions={[10]}
      />
    </div>
  )
}

const CustomToolbar = () => {
  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
    </GridToolbarContainer>
  )
}

export default ProjectsTable
