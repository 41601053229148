import React, {useEffect, useState} from 'react'
import classes from './CustomerDetails.module.css'
import {PrimaryButton} from '../../common/buttons/buttons'
import Search from '../../common/Search/Search'
import {DataGridPro, GridToolbarColumnsButton, GridToolbarContainer, useGridApiRef} from '@mui/x-data-grid-pro'
import {useDispatch, useSelector} from 'react-redux'
import getColumns from '../../common/UsersTable/getColumns'
import UserModal from '../../common/UsersTable/UserModal'
import {
    setTenantsUsersSearchValue, tenantsModalEditMode,
    validateCustomerUser
} from '../../../store/customersReducer'
import {setTableColumns} from '../../../store/tablesReducer'
import {setIsUserAdding} from "../../../store/usersReducer";
import {message} from "antd";

const UsersTable = (props) => {
    const [page, setPage] = useState(0)
    const [rows, setRows] = useState([])
    const dispatch = useDispatch()
    const searchValue = useSelector(state => state.customersReducer.tenantsUsersSearchValue)
    useEffect(() => {
        setPage(0)
    }, [props?.tableRows])

    useEffect(() => {
        !!props.usersTableRows && setRows(props.usersTableRows)
    }, [props.usersTableRows])


    const addUser = (userData, userId) => {
        if(userData.roles.length){
            dispatch(validateCustomerUser(userData, userId))
                .then(res => !!res && props.saveCustomerUser(userData, userId))
                .then(res => !!res && props.setUsersModalData({isOpen: false}))
        }
        else{
            message.warn('At least one role must be set', 5)
        }

    }

    const tableAppearance = useSelector(state => state.tablesReducer?.editCustomerUsersTable)
    const apiRef = useGridApiRef()

    useEffect(() => {
        apiRef.current.subscribeEvent('columnHeaderDragEnd', () => saveTableColumns())
        // eslint-disable-next-line
    }, [apiRef])

    const saveTableColumns = () => {
        const stateColumns = apiRef?.current?.getAllColumns()
        const colsData = Object.keys(stateColumns).map(key => stateColumns[key])
        dispatch(setTableColumns('editCustomerUsersTable', colsData))
    }


    const onSearch = (event) => {
        props.onUserSearch(event.replace(/^\s+|\s+$/g, ''))
        dispatch(setTenantsUsersSearchValue(event.replace(/^\s+|\s+$/g, '')))
    }


    const onAddUser = () => {
        props.setUsersModalData({isOpen: true})
        dispatch(setIsUserAdding(true))
        dispatch(tenantsModalEditMode(false))
    }


    return (
        <>
            <div style={{height: '500px'}}>
                <div className={classes.titleArea}>
                    <Search onChange={event => onSearch(event.target.value)} style={{height: '43px'}} searchedValue={searchValue}/>
                    <PrimaryButton
                        text='ADD USER'
                        onClick={onAddUser}
                        style={{height: '43px', marginLeft: '20px'}}
                    />
                </div>
                <div className={classes.tableWrapper}>
                    <DataGridPro
                        getRowId={e => e.userId}
                        rows={rows}
                        apiRef={apiRef}
                        onColumnWidthChange={(e) => saveTableColumns()}
                        columns={tableAppearance.length ? tableAppearance : getColumns(props.setUsersModalData, props.removeCustomerUser, rows, true, false).filter((c) => c !== false)}
                        rowCount={props.usersTableRows?.length || 0}
                        pagination
                        setPage={page}
                        onPageChange={(e) => setPage(e.pageCount - 1)}
                        pageSize={10}
                        rowsPerPageOptions={[10]}

                        style={{borderRadius: '15px', border: 'none'}}
                        components={{
                            Toolbar: CustomToolbar,
                        }}
                    />
                </div>
                {props.usersModalData.isOpen &&
                    <UserModal
                        modalData={props.usersModalData}
                        setUsersModalData={props.setUsersModalData}
                        roleOptions={props.roleOptions}
                        addUser={addUser}
                        editUser={props.updateCustomerUserData}
                        isTenantsTable={true}
                        users={props.customerUsers}
                        isUsersTable={false}
                    />
                }
            </div>
        </>
    )
}

const CustomToolbar = () => {
    return (
        <GridToolbarContainer>
            <GridToolbarColumnsButton/>
        </GridToolbarContainer>
    )
}

export default UsersTable
