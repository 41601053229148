import {Popconfirm} from 'antd'
import classes from './UsersTable.module.css'
import {tableWidth} from '../sizes'
import {ReactComponent as EditIcon} from './../../../img/icons/editIcon.svg'
import {ReactComponent as DeleteIcon} from './../../../img/icons/deleteIcon.svg'
import {ReactComponent as ExclamationCircle} from './../../../img/icons/exclamationCircle.svg'
import {useState} from 'react'


const partOfTableWidthForBigColumn = 0.28
const partOfTableWidthForSmallColumn = 0.22
const partOfTableWidthForAdditionalColumn = 0.13
const nameColumnWidth = tableWidth * partOfTableWidthForBigColumn
const columnWidth = tableWidth * partOfTableWidthForSmallColumn
const actionColumnWidth = tableWidth * partOfTableWidthForAdditionalColumn

const getColumns = (setModalData, removeUser, users, isTenants, isAdmins, userId, editCustomer) => [

    {
        field: 'name',
        headerName: 'Name',
        width: nameColumnWidth - 50,
        sortComparator: (v1, v2, user1, user2) => {
            const user1Data = users?.find(user => user.userId === user1.id)
            const user2Data = users?.find(user => user.userId === user2.id)
            return `${user1Data?.firstName} ${user1Data?.lastName}`?.localeCompare(`${user2Data?.firstName} ${user2Data?.lastName}`)
        },
        renderCell: (params) => {
            return (
                <div
                    onClick={
                        () => {
                            setModalData({isOpen: true, userData: params.row});
                        }
                    }
                    style={{
                        cursor: 'pointer'
                    }}>
                    {params.row.firstName} {params.row.lastName}
                </div>
            )
        }
    },
    {
        field: 'actions',
        headerName: 'Actions',
        sortable: false,
        width: actionColumnWidth,
        renderCell: (params) => {
            return (
                <>
                    <div style={{display: 'flex', alignItems: 'center', height: '100%'}}>
                        <EditIcon
                            className={classes.tableIcon}
                            onClick={
                                () => {
                                    setModalData({isOpen: true, userData: params.row});
                                }
                            }
                            style={{marginRight: '15px'}}
                        />
                    </div>
                    {
                        isAdmins
                        &&
                        params.row.isActive && params.row.userId !== userId &&
                        <ConfirmDelete
                            removeUser={removeUser}
                            userId={params.row.userId}
                            isAdmin={isAdmins}
                        />

                    }
                    {
                        isTenants
                        &&
                        params.row.isActive &&
                        <ConfirmDelete
                            removeUser={removeUser}
                            isAdmin={isAdmins}
                            userId={params.row.userId}
                        />

                    }
                </>
            )
        }
    },
    {field: 'email', headerName: 'Email', width: columnWidth},
    {field: 'phoneNumber', headerName: 'Phone', width: columnWidth},
    isTenants
    &&
    {
        field: 'roles',
        headerName: 'Role',
        width: 200,
        renderCell: (params) => {
            return (
                <div style={{
                    display: 'flex'
                }}>
                    {
                        params.row.roles.map((r, index) => {
                            if (!params.row.roles[index - 1] && params.row.roles.length !== 1) {
                                return (
                                    <div style={{
                                        marginRight: '6px',
                                        borderRadius: '16px',
                                        background: '#F0F9FF',
                                        color: '#0E9AFC',
                                        textAlign: 'center',
                                        fontFamily: 'Montserrat',
                                        fontSize: '12px',
                                        fontStyle: 'normal',
                                        fontWeight: 500,
                                        lineHeight: '18px',
                                        padding: '2px 8px'
                                    }}>{r.code.charAt(0).toUpperCase() + r.code.slice(1)}</div>
                                )
                            } else {
                                return (
                                    <div style={{
                                        borderRadius: '16px',
                                        background: '#F0F9FF',
                                        color: '#0E9AFC',
                                        textAlign: 'center',
                                        fontFamily: 'Montserrat',
                                        fontSize: '12px',
                                        fontStyle: 'normal',
                                        fontWeight: 500,
                                        lineHeight: '18px',
                                        padding: '2px 8px'
                                    }}>{r.code.charAt(0).toUpperCase() + r.code.slice(1)}</div>
                                )
                            }

                        })
                    }
                </div>

            )
        }
    },
    !isAdmins
    &&
    !isTenants
    &&
    {
        field: 'tenant_name',
        headerName: 'Tenant(s)',
        width: columnWidth,
        sortable: false,
        renderCell: (params) => {
            return (
                params?.row?.tenants?.map((tenant, index) => {
                    if (index === 0) {
                        return (
                            <div>{tenant.cust_name}</div>
                        )
                    } else {
                        return (
                            <div>, {tenant.cust_name}</div>
                        )
                    }

                })
            )
        }
    },
    isAdmins
    &&
    {
        field: 'isActive',
        headerName: 'Status',
        width: actionColumnWidth,
        renderCell: (params) => {
            const color = params.row.isActive ? 'rgba(194, 232, 18, 0.6)' : 'rgba(246, 88, 88, 0.6)'
            return (
                <div className={classes.tableIsActiveColumn} style={{backgroundColor: color}}>
                    {params.row.isActive ? 'Active' : 'Inactive'}
                </div>
            )
        }
    },
    isTenants
    &&
    {
        field: 'isActive',
        headerName: 'Status',
        width: actionColumnWidth,
        renderCell: (params) => {
            const color = params.row.isActive ? 'rgba(194, 232, 18, 0.6)' : 'rgba(246, 88, 88, 0.6)'
            return (
                <div className={classes.tableIsActiveColumn} style={{backgroundColor: color}}>
                    {params.row.isActive ? 'Active' : 'Inactive'}
                </div>
            )
        }
    },
]

const ConfirmDelete = ({removeUser, userId, isAdmin}) => {

    const [confirmMessageNumber, setConfirmMessageNumber] = useState(0)
    return (
        <>
            {confirmMessageNumber === 0 &&
                <DeleteIcon className={classes.tableIcon} onClick={() => setConfirmMessageNumber(1)}/>
            }
            {confirmMessageNumber === 1 &&
                <Popconfirm
                    title={isAdmin ? "Are you sure to delete this System Admin?" : "Are you sure to delete this user?"}
                    onConfirm={isAdmin ? () => {
                        removeUser(userId, true);
                        setConfirmMessageNumber(0)
                    } : () => setConfirmMessageNumber(2)}
                    onCancel={isAdmin ? () => {
                        removeUser(userId, false);
                        setConfirmMessageNumber(0)
                    } : () => setConfirmMessageNumber(0)}
                    okText="OK"
                    cancelText="Cancel"
                    icon={<ExclamationCircle/>}
                    cancelButtonProps={{type: 'primary', style: {background: '#1890FF'}}}
                    okButtonProps={{type: 'default'}}
                    visible={confirmMessageNumber === 1}
                >
                    <DeleteIcon className={classes.tableIcon}/>
                </Popconfirm>
            }
            {!isAdmin && confirmMessageNumber === 2 &&
                <Popconfirm
                    title="Should the assigned contact be deleted?"
                    onConfirm={() => {
                        removeUser(userId, true);
                        setConfirmMessageNumber(0)
                    }}
                    onCancel={() => {
                        removeUser(userId, false);
                        setConfirmMessageNumber(0)
                    }}
                    okText="OK"
                    cancelText="Cancel"
                    icon={<ExclamationCircle/>}
                    cancelButtonProps={{type: 'primary', style: {background: '#1890FF'}}}
                    okButtonProps={{type: 'default'}}
                    visible={confirmMessageNumber === 2}
                >
                    <DeleteIcon className={classes.tableIcon}/>
                </Popconfirm>
            }
        </>
    )
}

export default getColumns
