import React from 'react'
import { Tooltip } from 'antd'
import { useMediaQuery } from 'react-responsive'
import classes from './ControlPanel.module.css'
import {ReactComponent as DeleteElementIcon} from './../../../../img/stakeholderMap/controlPanel/deleteElementIcon.svg'
import addConnectionIcon from './../../../../img/stakeholderMap/controlPanel/addConnectionIcon.svg'
import addPersonIcon from './../../../../img/stakeholderMap/controlPanel/addPersonIcon.svg'
import zoomInIcon from './../../../../img/stakeholderMap/controlPanel/zoomInIcon.svg'
import zoomOutIcon from './../../../../img/stakeholderMap/controlPanel/zoomOutIcon.svg'
import fitViewIcon from './../../../../img/stakeholderMap/controlPanel/fitViewIcon.svg'
import lockOpen from './../../../../img/stakeholderMap/controlPanel/lockOpen.svg'
import lockClosed from './../../../../img/stakeholderMap/controlPanel/lockClosed.svg'
import annotationIcon from './../../../../img/stakeholderMap/controlPanel/annotationIcon.svg'
import { getCurrentUserRoles } from '../../../../helpers/localStorage'

const ControlPanel = (props) => {
  const isTabletScreen = useMediaQuery({ query: '(min-width: 767px) and (max-width: 1366px)' })
  const isMobileScreen = useMediaQuery({ query: '(min-width: 360px) and (max-width: 766px)' })

  const currentUserRoles = getCurrentUserRoles()
  const isUser = currentUserRoles.length === 1 && currentUserRoles?.some(role => role.code.toLowerCase() === 'user')

  if (isTabletScreen || isMobileScreen) {
    return (
      <div className={classes.wrapperFirstPart}>
        <Tooltip placement="leftTop" title='Fit view'>
          <div 
            className={classes.button}
            onClick={() => props.reactFlowInstance.fitView()}
            style={{borderRadius: '5px'}}
          >
            <img src={fitViewIcon} style={{width:'90%'}} alt=''/>
          </div>
        </Tooltip>
      </div>
    )
  }

  return (
    <>
      <div className={classes.wrapperFirstPart}>
        <Tooltip placement="leftTop" title='Zoom in'>
          <div
            className={classes.button} 
            onClick={() => props.reactFlowInstance.zoomIn()}
            style={{borderTopLeftRadius: '10px', borderTopRightRadius: '10px'}}
          >
            <img src={zoomInIcon} style={{width:'90%'}} alt=''/>
          </div>
        </Tooltip>
        <Tooltip placement="leftTop" title='Zoom out'>
          <div 
            className={classes.button}
            onClick={() => props.reactFlowInstance.zoomOut()}
          >
            <img src={zoomOutIcon} style={{width:'90%'}} alt=''/>
          </div>
        </Tooltip>
        <Tooltip placement="leftTop" title='Fit view'>
          <div 
            className={classes.button}
            onClick={() => props.reactFlowInstance.fitView()}
          >
            <img src={fitViewIcon} style={{width:'90%'}} alt=''/>
          </div>
        </Tooltip>
        <Tooltip placement="leftTop" title={props.isPageBlocked ? 'Allow dragging' : 'Restrict dragging'}>
          <div 
            className={classes.button}
            onClick={() => props.toggleIsPageBlocked(!props.isPageBlocked)}
            style={{borderBottomLeftRadius: '10px', borderBottomRightRadius: '10px'}}
          >
            {props.isPageBlocked
              ? <img src={lockClosed} style={{width:'90%'}} alt=''/>
              : <img src={lockOpen} style={{width:'90%'}} alt=''/> 
            }
          </div>
        </Tooltip>
      </div>

      <div className={classes.wrapperSecondPart}>
        <Tooltip placement="leftTop" title={isUser ? "You can't add users to project" : 'Add contact'}>
          <div
            className={classes.button} 
            onClick={() => !isUser ? props.setIsWorkerModalOpen(true) : {}}
            style={{
              cursor: isUser ? 'default' : 'pointer',
              borderTopLeftRadius: '10px',
              borderTopRightRadius: '10px',

            }}
          >
            <img src={addPersonIcon} style={{width: '90%'}} alt=''/>
          </div>
        </Tooltip>
        <Tooltip placement="leftTop" title='Delete contact or connection'>
          <div 
            className={classes.button}
            onClick={() => props.removeElement()}
          >
            <DeleteElementIcon/>
          </div>
        </Tooltip>
        <Tooltip placement="leftTop" title='Add connection'>
          <div 
            className={classes.button}
            onClick={() => props.setIsConnectingActive(true)}
          >
            <img src={addConnectionIcon} style={{width: '90%'}} alt=''/>
          </div>
        </Tooltip>
        <Tooltip placement="leftTop" title='Add annotation'>
          <div 
            className={classes.button}
            onClick={(e) => {
              e.stopPropagation()
              props.seIsAnnotationModeActive(!props.isAnnotationModeActive)
            }}
            style={{borderBottomLeftRadius: '10px', borderBottomRightRadius: '10px'}}
          >
            <img src={annotationIcon} style={{width: '90%'}} alt=''/>
          </div>
        </Tooltip>
      </div>
    </>
  )
}

export default ControlPanel
