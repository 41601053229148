import React from 'react'
import {Modal} from 'antd'
import classes from './UsersTable.module.css'
import UserModalForm from './UserModalForm'

const UsersModal = (props) => {
    return (
        <Modal
            centered
            closable={false}
            footer={null}
            onCancel={() => props.setUsersModalData({isOpen: false})}
            style={{padding: '10px', width: '100%'}}
            visible={true}
            width={800}
            maskClosable={false}
        >
            {
                props.isAdminsTable
                    ?
                    <div className={classes.modalTitle}>
                        {props.modalData.userData ? 'EDIT SYSTEM ADMIN' : 'NEW SYSTEM ADMIN'}
                    </div>
                    :
                    <div className={classes.modalTitle}>
                        {props.modalData.userData ? 'EDIT USER' : 'NEW USER'}
                    </div>
            }

            <UserModalForm
                addUser={props.addUser}
                editUser={props.editUser}
                setModalData={props.setModalData}
                setUsersModalData={props.setUsersModalData}
                modalData={props.modalData}
                roleOptions={props.roleOptions}
                isAdminsTable={props.isAdminsTable}
                isTenantsTable={props.isTenantsTable}
                isUsersTable={props.isUsersTable}
                users={props.users}
            />
        </Modal>
    )
}

export default UsersModal
