import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import axios from './../../../helpers/axiosHelper'
import ProjectSupportChart from './ProjectSupportChart'
import { getProjectsByPeriod, selectProjectTC } from '../../../store/projectsReducer'
import { exportAsPdf } from '../../../helpers/exportHelper'
import { chartWidth } from '../../common/sizes'
import { getGroupedProjectOptions } from '../../../helpers/groupedDataHelper'

const ProjectSupportChartContainer = (props) => {
  const dispatch = useDispatch()
  const projects = useSelector(state => state.projectsReducer.projectList)
  const selectedProject = useSelector(state => state.projectsReducer.selectedProject)
  const userId = useSelector(state => state.authReducer.userId)

  const [isConnectionStrengthOn, setIsConnectionStrengthOn] = useState(false)
  const [isLoaded, setIsLoaded] = useState(false)
  const [error, setError] = useState(null)
  const [isEmpty, setIsEmpty] = useState(false)
  const [people, setPeople] = useState([])
  const [connections, setConnections] = useState()
  const [isProjectTeamContactOn, setIsProjectTeamContactOn] = useState(true)
  const [chartData, setChartData] = useState([])

  const selectProject = useCallback((value) => {
    dispatch(selectProjectTC(value, projects))
  },[projects, dispatch])

  useEffect(() => {
    if(!!userId) {
      setIsLoaded(false)
      Promise.allSettled([
        dispatch(getProjectsByPeriod(null, false, userId)),
        !!selectedProject && axios.get(`/projects/${selectedProject}/charts/support`)
          .then(response => {
            setPeople(response.data.supportChart.elements)
            setChartData(response.data.supportChart.elements)
            setConnections(response.data.supportChart.connections.map(connection => ({strength: connection.strength, source: connection.sourceId, target:connection.targetId})))
          })
          .then(() => setIsLoaded(true)),
      ]).then((res) => {
        const projectsResp = res[0]?.value
        if (!!projectsResp?.length) {
          setIsEmpty(false)
          setIsLoaded(true)
        } else if (!projectsResp?.length){
          setIsEmpty(true)
          setIsLoaded(true)
        } else {
          return 
        }
      })
    }
  }, [userId, dispatch, selectedProject])

  useEffect(() => {
    if (people.length) {
      isProjectTeamContactOn
      ? setChartData(people)
      : setChartData(people.filter(person => !person.isProjectTeamMember))
    }
  }, [isProjectTeamContactOn, people])

  const exportChart = () => {
    const selectedProjectData = projects.find(project => project.projId === selectedProject)
    const selectedProjectTitle = selectedProjectData?.name
    const selectedProjectMarking = selectedProjectData?.projectMarking
    exportAsPdf(document.getElementById('projectSupportToPdf'), `${selectedProjectTitle}_SupportChart`, chartWidth, null, null, selectedProjectTitle, 'Support Chart', selectedProjectMarking)
  }
 
  return (
    <ProjectSupportChart
      connections={connections}
      error={error}
      people={chartData}
      isConnectionStrengthOn={isConnectionStrengthOn}
      isLoaded={isLoaded}
      isEmpty={isEmpty}
      setIsConnectionStrengthOn={setIsConnectionStrengthOn}
      selectProjectOptions={getGroupedProjectOptions(projects)}
      selectedProject={selectedProject}
      selectProject={selectProject}
      setError={setError}
      exportChart={exportChart}
      isProjectTeamContactOn={isProjectTeamContactOn}
      setIsProjectTeamContactOn={setIsProjectTeamContactOn}
    />
  )
}

export default ProjectSupportChartContainer 
