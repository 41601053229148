import { pageDefaultHeight, canvasWidth } from '../../common/sizes'
import { AnnotationMapCard } from './Annotations/Annotations'

export const newWorkerPosition = () => {
  return {x: (canvasWidth / 2) - 100 , y: (pageDefaultHeight / 2)}
}

export const getElementConnections = (element, connections) => {
  const elementId = element ? element.id : null
  const elementConnections = connections.filter(connection => {
    return connection.source === elementId || connection.target === elementId
  })
  return elementConnections
}

export const updateCardPosition = (positions, cards) => {
  const updatedCards = cards.map(card => {
    const positionData = positions?.find(pos => pos.id === card.id)
    return {...card, position: {
      x: positionData?.position?.x ? positionData?.position?.x : card.position?.x,
      y: positionData?.position?.y ? positionData?.position?.y : card.position?.y
    }}
  })
  return updatedCards
}

export const removeDeletedItemsPosition = (position, deletedElementId, projectStakeholders) => {
  const removedStkData = projectStakeholders.find(stk => stk.stakeholderId === Number(deletedElementId))
  const hasSameOrganisationStakeholders = projectStakeholders
    ?.filter(stk => stk.stakeholderId !== Number(deletedElementId))
    ?.some(stk => stk.orgId === removedStkData.orgId)

  if (hasSameOrganisationStakeholders) {
    return position.id !== deletedElementId
  } else {
    return position.id !== deletedElementId && position.id !== `title${removedStkData.orgId}`
  }
}

export const getElementAllViewsPosition = (projectData, element) => {
  const userViewPosition = projectData.userView?.positions?.find(el => el.id === element.id)?.position 
  const projectViewPosition = projectData.projectView?.positions?.find(el => el.id === element.id)?.position
  return {id: 'positions', userViewPosition, projectViewPosition}
}

export const getAnnotationCards = (annotations, positions, isPageBlocked) => {
  const defaultPosition = {x: 1845, y: 285}

  return annotations.map((item) => (
    {
      id: 'annotation-' + item.id,
      data: {
        label: <AnnotationMapCard annotation={item}/>,
        data: item
      },
      position: positions?.find(p => p.id === 'annotation-' + item.id)?.position || defaultPosition,
      className: 'annotation',
      draggable: !isPageBlocked,
      connectable: false, 
      type: 'input',
      style: {border: '1px solid transparent', width: '40px', background: 'transparent'}
    }
  )) 
}

export const canvasExportFilter = (node) => {
  return (
    node.className !== 'react-flow__controls' 
    && 
    node.className !== 'controlPanel' 
    && 
    node.className !== 'miniMapNavigation'
    && 
    node.className !== 'circlesViewBtn'
    &&
    node.className !== 'annotations'
  )
}
