import React, { useEffect, useState } from 'react'
import {VictoryChart, VictoryScatter, VictoryAxis, VictoryLabel} from 'victory'
import classes from './Matrix.module.css'  
import lodash from 'lodash'
import { Popover, Tooltip } from 'antd'
import { v4 } from 'uuid'
import { useMediaQuery } from 'react-responsive'
import {ReactComponent as UsersIcon} from './../../img/icons/usersIcon.svg'
import {ReactComponent as OneUserIcon} from './../../img/icons/oneUserIcon.svg'
import {ReactComponent as ArrowIcon} from './../../img/icons/arrow.svg'
import {ReactComponent as ArrowTopIcon} from './../../img/icons/arrowTop.svg'
import {ReactComponent as DownArrow} from './../../img/icons/downArrow.svg'

const DataComponent= (props) => {
  const {x, y, datum} = props
  let iconColor = datum.color || '#d9d9d9'

  const yPosition = datum.y > 0 ? y - 5 : y - 30
  const singlePersonIcon = (
    <svg x={x} y={yPosition} width='49'  height='39' version='1.1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 54.8 54.9'>
      <path  fill={iconColor} d='M26.8,3c-4.4,0-7.9,3.5-7.9,7.8s3.5,7.8,7.9,7.8s7.9-3.5,7.9-7.8S31.2,3,26.8,3z'/>
      <path  fill={iconColor} d='M31.5,22c-3.1-0.5-6.2-0.5-9.3,0l-0.3,0.1c-4.4,0.6-7.6,4.3-7.6,8.7c0,2.3,1.9,4.2,4.3,4.2H35c2.4,0,4.3-1.9,4.3-4.2c0-4.3-3.2-8-7.5-8.7L31.5,22z'/>
    </svg>
  )
  const doublePersonIcon = (
    <svg x={x} y={yPosition} width='49'  height='39' version='1.1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 54.8 54.9' >
      <path fill={iconColor} d="M13.9,4.5C9.5,4.5,6,8,6,12.3c0,4.3,3.5,7.8,7.9,7.8c4.4,0,7.9-3.5,7.9-7.8C21.8,8,18.2,4.5,13.9,4.5z"/>
      <path fill={iconColor}  d="M18.5,23.5c-3.1-0.5-6.2-0.5-9.3,0l-0.3,0.1c-4.3,0.7-7.5,4.4-7.5,8.7c0,2.3,1.9,4.2,4.3,4.2h16.4c2.4,0,4.3-1.9,4.3-4.2c0-4.3-3.2-8-7.5-8.7L18.5,23.5z"/>
      <path fill={iconColor} d="M28.7,2.6c-3.5,0-6.4,3-6.4,6.6c0,3.6,2.9,6.6,6.4,6.6c3.5,0,6.4-3,6.4-6.6C35.1,5.6,32.3,2.6,28.7,2.6z"/>
      <path fill={iconColor}  d="M32.5,18.8c-2.5-0.4-5.1-0.4-7.6,0l-0.3,0c-3.5,0.6-6.1,3.7-6.1,7.4c0,2,1.6,3.6,3.5,3.6h13.4c1.9,0,3.5-1.6,3.5-3.6c0-3.7-2.6-6.8-6.1-7.4L32.5,18.8z"/>
    </svg>
  )
  return props.datum.fullName ? (
    singlePersonIcon
  ) : (
    <Tooltip 
      title={() => datum.data.map(item => (
        <div key={v4()} style={{color:item.color}}>
          {item.fullName}
        </div>
      ))}
      color='white'
    >
      {doublePersonIcon}
    </Tooltip>
  ) 
}

const MatrixView = (props) => {
  const stakeholdersData = props.matrixData
  ?.filter(stk => !!stk.interestLvl && !!stk.influenceLvl)
  ?.map(person => {
    const values = {
      '1': -5,
      '2': -4,
      '3': -3,
      '4': -2,
      '5': -1,
      '6': 1,
      '7': 2,
      '8': 3,
      '9': 4,
      '10': 5
    }
    let x = values[String(person.interestLvl)]
    let y = values[String(person.influenceLvl)]
    return {x, y, fullName: person.fullName, color: person.color}
  })

  const groupedData = lodash.groupBy(stakeholdersData, (data) => `${data.x}/${data.y}`)
  const matrixData = []
  for (let [key, value] of Object.entries(groupedData)) {
    if (value?.length === 1) {
      matrixData.push(value[0])
    } else {
      let x = Number(key.substr(0, key.indexOf('/')))
      let y = Number(key.substr(key.indexOf('/') + 1))
      matrixData.push({x, y, data: value})
    }
  }

  const stkWithEmptyValue = props.matrixData?.filter(stk => !stk.interestLvl || !stk.influenceLvl)
  const isMobileScreen = useMediaQuery({ query: '(min-width: 360px) and (max-width: 766px)' })

  const [chartSize, setChartSize] = useState({
    height: isMobileScreen ? window.innerHeight - 60 : window.innerHeight - 100,
    width: isMobileScreen ? window.innerWidth + 100 : window.innerWidth - 100
  })

  const [isUndecidedListOpen, setIsUndecidedListOpen] = useState(false)

  useEffect(() => {
    const changeChartSize = () => {
      setChartSize({
        height: isMobileScreen ? window.innerHeight - 60 : window.innerHeight - 100,
        width: isMobileScreen ? window.innerWidth + 100 : window.innerWidth - 100
      })
    }
    window.addEventListener('resize', changeChartSize)
    return () => window.removeEventListener('resize', changeChartSize) 
  }, [isMobileScreen])

  return (
    <div
      className={classes.matrixViewWrapper}
      style={{height: isMobileScreen ? `${window.innerHeight - 130}px` : ''}}
      id='matrix_to_pdf'
    >
      {stkWithEmptyValue?.length &&
        <Popover
          trigger='click'
          content={
            <>
              {stkWithEmptyValue?.map(stk => (
                <div key={stk.stakeholderId} style={{display: 'flex', alignItems: 'center', padding: '8px'}}>
                  <OneUserIcon style={{height: 20, width: 20, fill: stk.color, marginRight: '7px'}} />
                  {stk.fullName}
                  {!stk.influenceLvl && !stk.interestLvl 
                    ? ' (Unknown)' 
                    : (` (Influence - ${stk.influenceLvl || 'Unknown'}; Interest - ${stk.interestLvl || 'Unknown'})`)
                  }
                </div>
              ))}
            </>
          }
          placement='bottom'
          onVisibleChange={() => setIsUndecidedListOpen(!isUndecidedListOpen)}
        >
          {isMobileScreen ? (
            <UsersIcon className={classes.noDataUsersIcon}/>
          ) : (
            <div className={classes.undecidedButton}>
              <UsersIcon className={classes.noDataUsersIcon} style={{marginRight: '7px'}}/>
              <span>Undecided</span>
              <DownArrow
                style={{
                  height: '10px',
                  width: '10px',
                  marginLeft: '7px',
                  transform: isUndecidedListOpen ? 'rotate(180deg)' : ''
                }}
              />
            </div>
          )}
        </Popover>
      }
      <ArrowIcon className={classes.arrowRight} />
      <div style={{position:'absolute', bottom:'-5px', left:'45%', fontWeight:600, fontSize:'18px'}}>
        INTEREST
      </div> 
      <ArrowTopIcon className={classes.arrowUp} style={{height: window.innerHeight < 670 ? '450px' : `calc(${window.innerHeight}px - 200px)`}}/>
      <div className={classes.keepSatisfiedTitle}>
        KEEP SATISFIED
      </div>
      <div className={classes.manageCloselyTitle}>
        MANAGE CLOSELY
      </div>
      <div className={classes.keepInformedTitle}>
        KEEP INFORMED
      </div>
      <div className={classes.monitorTitle}>
        MONITOR
      </div>
      <div className={classes.influence}>
        INFLUENCE
      </div> 
      <div className={classes.matrixViewSubWrapper}>
        <VictoryChart height={chartSize.height < 520 ? 520 : chartSize.height} width={chartSize.width} style={{background: { fill: 'white' }}}>
          <VictoryAxis
            tickValues={[]}
            tickFormat={[]}
          />
          <VictoryAxis
            dependentAxis
            tickFormat={[]}
          />
          <VictoryScatter
            data={matrixData}
            dataComponent={<DataComponent/>}
            domain={{x: [-5.5, 5.5], y: [-5, 5]}}
            labels={({ datum }) => datum.fullName ? datum.fullName : (props.showAllNames ? datum.data.map(i => i.fullName) : `${datum.data?.length} people`)}
            labelComponent={
              <VictoryLabel 
                dy={({ datum }) => datum.y < 0 
                  ? -5 
                  : (datum?.data && props.showAllNames ? datum?.data?.length * 20 : 35)
                } 
                dx={20}
              />
            }
          />
        </VictoryChart>
      </div>
    </div>
  )
}

export default MatrixView
