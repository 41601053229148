import './App.less';
import { useEffect } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useLocation, Navigate, Route, Routes } from 'react-router-dom';
import { turnOnConnectionStrength } from './store/stakeholderMapReducer';
import { openNotification } from './components/common/ErrorAlert/ErrorAlert';
import { setError } from './store/errorsReducer';
import { getNewNotifications } from './store/notificationsReducer';
import routes from './routes';
import { useMediaQuery } from 'react-responsive';
import NavBarContainer from './components/NavBar/NavBarContainer';
import Footer from './components/Footer/Footer';
import {Outlet} from "react-router";
import {
  getAdminsListFilter,
  getCurrentUserRoles, getLoginRolePicked,
  getTenantsListFilter,
  setAdminsListFilter, setTenantsListFilter
} from "./helpers/localStorage";
import {setUserRoles} from "./store/authReducer";


const App = (props) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const error = useSelector(state => state.errorsReducer.error);
  const tenantsListFilter = getTenantsListFilter()
  const adminsListFilter = getAdminsListFilter()

  props.turnOnConnectionStrength();

  useEffect(() => {
    dispatch(setUserRoles(getCurrentUserRoles()))
    dispatch(getNewNotifications(false));
  }, [dispatch]);


  useEffect(() => {
    if(!tenantsListFilter && !adminsListFilter){
      setAdminsListFilter('Active')
      setTenantsListFilter('ACTIVE')
    }
  }, [tenantsListFilter, adminsListFilter]);

  useEffect(() => {
    if (error) openNotification(error, () => dispatch(setError(null)));
  }, [error, dispatch]);

  useEffect(() => {
    const pageTitles = [
      { url: '/StakeholderMap', title: 'Stakeholder Map' },
      { url: '/SupportChart', title: 'Support Chart' },
      { url: '/InfluenceMatrix', title: 'Influence Matrix' },
      { url: '/Projects', title: 'Projects' },
      { url: '/Contacts', title: 'Contacts' },
      { url: '/Organisations', title: 'Organisations' },
      { url: '/Tenants', title: 'Tenants' },
      { url: '/Users', title: 'Users' },
      { url: '/Settings', title: 'Settings' },
      { url: '/RecordEngagement', title: 'Record Engagement' },
      { url: '/ViewEngagement', title: 'View Engagement' },
      { url: '/Engagements', title: 'Engagements' },
      { url: '/UpcomingEngagements', title: 'Upcoming Engagements' },
      { url: '/ForgotPassword', title: 'Forgot Password' },
      { url: '/Templates', title: 'Templates' },
    ];
    const currentTitle = pageTitles.find(item => location.pathname.includes(item.url))?.title || 'Stakeholder System';
    document.title = `Brickfield | ${currentTitle}`;
  }, [location.pathname]);

  return (
      <>
        {location.pathname === '/' && <Navigate to="/StakeholderMap" />}

        <Routes>
          {routes.map((route) => (
              route.auth ? (
                  <Route key={route.path} path={route.path} element={<PrivateRoute {...route.props}/>}>
                    <Route path={route.path} element={<route.component {...route.props}/>}/>
                  </Route>
              ) : (
                  <Route key={route.path} element={<route.component {...route.props}/>}
                         path={route.path}/>
              )
          ))}
        </Routes>
      </>
  );
};

const PrivateRoute = ({ element: Component, desktopOnly, ...rest }) => {
  const location = useLocation();
  const loginRolePickerValue = getLoginRolePicked()
  const currentUserRoles = getCurrentUserRoles();
  const isLoggedIn = useSelector(state => state.authReducer.isSignedIn);
  const isMobileScreen = useMediaQuery({ query: '(min-width: 360px) and (max-width: 766px)' });
  const isSystemAdmin = loginRolePickerValue ? loginRolePickerValue === "system admin" : currentUserRoles == null ? false  :  currentUserRoles?.some(role => role.code.toLowerCase() === "system admin")
  const systemAdminOnlyPaths = ['/Tenants', '/Templates', '/Users', '/Admins'];
  const tenantsForLogin = useSelector(state => state.authReducer.tenantsForLogin)



  if (!isLoggedIn && !tenantsForLogin.length) {
    return <Navigate to="/login" state={{ from: location }} />;
  }

  if (!isLoggedIn && tenantsForLogin.length) {
    return <Navigate to="/login/choose-tenant" />;
  }

  if (window.innerWidth < 1367 && desktopOnly) {
    return <Navigate to={isSystemAdmin ? '/Tenants' : '/StakeholderMap'} />;
  }

  if (!!isSystemAdmin && !systemAdminOnlyPaths.some(adminPath => location.pathname.includes(adminPath))) {
    return <Navigate to="/Tenants" />;
  }

  if (!isSystemAdmin && systemAdminOnlyPaths.some(adminPath => location.pathname.includes(adminPath))) {
    return <Navigate to="/StakeholderMap" />;
  }

  return (
      <div className="appWrapper">
        {!isMobileScreen && <NavBarContainer />}
        <main>
          <Outlet/>
        </main>
        <Footer className="footer" />
      </div>
  );
};

const mapStateToProps = (state) => ({
  isSignedIn: state.authReducer.isSignedIn,
});

export default connect(mapStateToProps, { turnOnConnectionStrength })(App);


