import axios from '../helpers/axiosHelper'
import {handleError} from './errorsReducer'

const initialState = {
  newNotifications: [],
}

const notificationsReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_NEW_NOTIFICATIONS':
      return {...state, newNotifications: action.notifications} 
    case 'REMOVE_NOTIFICATION_FROM_LIST':
      return {...state, newNotifications: state.newNotifications.filter(n => n.id !== action.notificationId)} 
    case 'EDIT_NOTIFICATION':
      return {
        ...state,
        newNotifications: state.newNotifications.map(n => {
          return n.id === action.notification.id ? action.notification : n
        })
      }
    default: return state
  } 
}

export const setNewNotifications = (notifications) => ({type: 'SET_NEW_NOTIFICATIONS', notifications})
export const removeNotificationFromList = (notificationId) => ({type: 'REMOVE_NOTIFICATION_FROM_LIST', notificationId})
export const editNotification = (notification) => ({type: 'EDIT_NOTIFICATION', notification})

export const getNewNotifications = (isHidden) => (dispatch) => {
    return axios.get(`/notifications/not-completed?is_hidden=${isHidden}`)
      .then(response => {
        response.status === 200 
          ? dispatch(setNewNotifications(response.data.notificationResponses))
          : dispatch(handleError(response.data))
      })
    .catch(error => {
      dispatch(handleError(error))
    })
}

export const removeNotification = (notification) => (dispatch) => {
  return axios.put(`/notifications/${notification.id}`, {...notification, is_hidden: true})
    .then(response => {
      response.status === 200 
        ? dispatch(removeNotificationFromList(notification.id))
        : dispatch(handleError(response.data))
    })
    .catch(error => {
      dispatch(handleError(error))
    })
}

export const markNotificationAsSeen = (notification) => (dispatch) => {
  return axios.put(`/notifications/${notification.id}`, {...notification, is_seen: true})
    .then(response => {
      if(response.status === 200) {
        dispatch(editNotification({...notification, is_seen: true}))
      } else {
        dispatch(handleError(response.data))
      }
    })
    .catch(error => {
      dispatch(handleError(error))
    })
}

export default notificationsReducer
