import React from "react"

const CustomDivider = (props) => {
  const wrapperStyle = {
    alignItems: "center",
    display: "flex", 
    margin: "16px 0px 16px 0px",
    width: "100%",
    ...props.style
  }

  const textStyle = {
    fontSize: "14px", 
    fontWeight: 600,
    width: "100%",
    color:'#0E9AFC',
    ...props.textStyle
  }

  const dividerLineStyle = {
    borderTop: "2px solid #0E9AFC",
    height: "50%",
    marginLeft: "10px",
    width: "100%",
    ...props.lineStyle
  }
  return (
    <div style={wrapperStyle}>
      <div style={textStyle}>{props.text}</div>
      <div style={dividerLineStyle}></div>
    </div>
  )
}

export default CustomDivider

