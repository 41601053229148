import React, {useState, useEffect} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { deleteOrganization, getOrganizations } from '../../store/organizationsReducer'
import OrganizationsList from './OrganizationsList'

const OrganizationsListContainer = (props) => {
  const [organizationsList, setOrganizationsList] = useState()
  const [organizationModalData, setOrganizationModalData] = useState({isOpen: false})
  const [tableRows, setTableRows] = useState()
  const [error, setError] = useState(null)
  const [selectedIsActive, setSelectedIsActive] = useState(true)

  const dispatch = useDispatch()
  const organizations = useSelector(state => state.organizationsReducer.organizations)

  useEffect(() => {
    dispatch(getOrganizations(selectedIsActive))
  }, [selectedIsActive, dispatch])

  useEffect(() => {
    const data = organizations.map(row => ({...row, id: row.orgId}))
    setOrganizationsList(data)
    setTableRows(data)
  }, [organizations])

  useEffect(() => {
    const hasInactiveItem = selectedIsActive && organizations.some(org => !org.isActive)
    const hasActiveItem = !selectedIsActive && organizations.some(org => org.isActive)
    if (hasInactiveItem || hasActiveItem) dispatch(getOrganizations(selectedIsActive))
  }, [organizations, dispatch, selectedIsActive])

  const search = (value) => {
    const searchResults = organizationsList.filter(organization => organization.name.toLowerCase().startsWith(value.toLowerCase()))
    if (!value) {
      setTableRows(organizationsList)
    }
    setTableRows(searchResults)
  }

  const deleteOrganizationData = (id) => {
    dispatch(deleteOrganization(id))
  }

  const isActiveOptions = [
    {id: 1, value: true, name: 'Active'},
    {id: 2, value: false, name: 'Inactive'},
  ]

  return (
    <OrganizationsList 
      deleteOrganization={deleteOrganizationData}
      error={error}
      setSelectedIsActive={setSelectedIsActive}
      organizationModalData={organizationModalData}
      setError={setError}
      setOrganizationModalData={setOrganizationModalData}
      search={search}
      tableRows={tableRows}
      selectedIsActive={selectedIsActive}
      isActiveOptions={isActiveOptions}
    />
  )
}

export default OrganizationsListContainer
