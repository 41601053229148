import React from 'react'
import { message, Upload } from 'antd'
import classes from './MandatoryDetails.module.css'
import { UploadButton } from '../../../../common/buttons/buttons'

const AvatarFileUpload = (props) => {
  function beforeUpload(file) {
    const isLt2M = file.size / 1024 / 1024 < 2
    if (!isLt2M) message.error('File must be less than 2 mb')
    return isLt2M ? true : Upload.LIST_IGNORE
  }

  return (
    <div className={classes.uploadWrapper}>
      <UploadButton
        beforeUpload={beforeUpload}
        onChange={(event) => props.onChange("photo", event.fileList[0])}
        name={'photo'}
        accept={"image/png, image/jpeg"}
        maxCount={1}
        imgUrl={props.photo ? props.photo : null}
        onRemove={() => props.onChange("photo", null)}
        btnName='Photo'
        size='150px'
        showUploadList={false}
      />
    </div>
  )
}

export default AvatarFileUpload
