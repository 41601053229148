import React, {useState} from 'react'
import { useDispatch } from 'react-redux'
import { createOrganization, updateOrganization } from '../../../store/organizationsReducer'
import OrganizationModal from './OrganizationModal'

const OrganizationModalContainer = (props) => {
  const [error, setError] = useState(null)
  const dispatch = useDispatch()

  const saveOrganization = (newOrganizationData) => {
    dispatch(createOrganization(newOrganizationData))
      .then(() => props.setSelectedIsActive(true))
      .then(() => props.setOrganizationModalData({isOpen: false}))
      .catch(error => handleOrganizationsError(error, newOrganizationData))
  }

  const editOrganization = (updatedOrganizationData) => {
    dispatch(updateOrganization(updatedOrganizationData))
      .then(() => props.setOrganizationModalData({isOpen: false}))
      .catch(error => handleOrganizationsError(error, updatedOrganizationData))
  }

  const handleOrganizationsError = (error, organizationData) => {
    if (error.response?.data.message && error.response?.data.message.includes('nickname')) {
      setError({message: error.response?.data.message, duplicatedNickname: organizationData.nickname})
    } else if(error.response?.data.message && error.response?.data.message.includes('name')) {
      setError({message: error.response?.data.message, duplicatedName: organizationData.name})
    } else if(error.response?.data.message) {
      setError({message: error.response?.data.message})
    } else {
      setError({message: 'An unexpected error has occurred'})
    }
  }

  return (
    <OrganizationModal
      error={error}
      updateOrganization={editOrganization}
      organizationModalData={props.organizationModalData}
      saveOrganization={saveOrganization}
      setOrganizationModalData={props.setOrganizationModalData}
      setError={setError}
    />
  )
}

export default OrganizationModalContainer
