import React, { useEffect } from 'react'
import {Form, Formik} from 'formik'
import { format } from 'date-fns'
import moment from 'moment'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import * as Yup from 'yup'
import classes from './ProjectDetailsForm.module.css'
import { PrimaryButton } from '../../common/buttons/buttons'
import {Input} from '../../common/Input/Input'
import DatePicker from '../../common/DatePicker/DatePicker'
import Select from '../../common/Select/Select'
import { useSelector } from 'react-redux'
import {useNavigate} from "react-router";

const MainInfoForm = ({ setFormData, dataForEditing, error, setError, isDataSent, setIsDataSent, formData, modalView, cancelModalView, setDisabledBtn}) => {
  const users = useSelector(state => state.usersReducer.userList)

  let setFormValues = () => {}
  const today = format(new Date(), 'yyyy-MM-dd 00:00:00')
  const tillDateDefault = moment().add(6,'months').format('YYYY-MM-DDT00:00:00')
  let initialValues = {
    name: '',
    activeFrom: today,
    activeTill: tillDateDefault,
    projectMarking: '',
    description: '',
    projectOwnerId: null
  }

  useEffect(() => {
    const today = format(new Date(), 'yyyy-MM-dd 00:00:00')
    const tillDateDefault  = moment().add(6,'months').format('YYYY-MM-DDT00:00:00')
    const activeTill = dataForEditing?.activeTill !== '2099-12-31T00:00:00' ? dataForEditing?.activeTill : null
    setFormValues('name', dataForEditing?.name || '')
    setFormValues('activeFrom', dataForEditing?.activeFrom || today)
    setFormValues('activeTill', activeTill || tillDateDefault)
    setFormValues('projectMarking', dataForEditing?.projectMarking || '')
    setFormValues('description', dataForEditing?.description || '')
    setFormValues('projectOwnerId', dataForEditing?.projectOwnerId || null)
  }, [dataForEditing])

  const validationSchema = Yup.object({
    name: Yup.string()
      .required('Project name is required'),
    projectMarking: Yup.string()
      .required('Project marking is required'),
  })

  const ownerOptions = users.map(user => ({
    id: user.userId,
    name: `${user.firstName} ${user.lastName}`,
    value: user.userId
  }))
  console.log(setDisabledBtn)
  return (
    <div className={`${classes.mainInfoWrapper} ${modalView ? classes.modalView : ''}`}>
      <Formik initialValues={initialValues} validationSchema={validationSchema}>
        {({setFieldValue, values, dirty}) => {
          setFormValues = setFieldValue
          setFormData(values)
          setDisabledBtn(dirty)
          if (error && error.duplicatedName !== values.name) setError(null)
          return (
            <Form className={classes.form}>
              <div className={classes.inputsArea}>
                <Input 
                  name='name'
                  label='Project Name'
                  placeholder='Project Name'
                  className='autoWidthInput'
                />
                <div className={classes.datePickersArea}>
                  <DatePicker 
                    name='activeFrom'
                    label='Active From'
                    value={values.activeFrom}
                    onChange={(value) => setFieldValue('activeFrom', moment(value).format('YYYY-MM-DDT00:00:00'))}
                    style={{width: modalView ? '392px' : ''}}
                  />
                  <DatePicker 
                    name='activeTill'
                    label='Active Till'
                    value={values.activeTill}
                    style={{width: modalView ? '392px' : ''}}
                    onChange={(value) => setFieldValue('activeTill', moment(value).format('YYYY-MM-DDT00:00:00'))}
                  />
                </div>
                <Input 
                  name='projectMarking'
                  label='Project Documentation Marking'
                  placeholder='Project Documentation Marking'
                  className='autoWidthInput'
                />
                <div className={classes.roleDescriptionArea}>
                  <Input 
                    name='description'
                    label='Project Description'
                    placeholder='Project Description'
                    multiline={true}
                    rows={6}
                    className='autoWidthInput'
                    inputProps={{
                      maxLength: 255,
                    }}
                  />
                </div>
              </div>
              <div className={classes.errorMessage}>
                {error && error?.message}
              </div>
            </Form>
          )
        }}
      </Formik>
      <div className={classes.selectWrapper}>
        <Select
          options={ownerOptions}
          onSelect={(value) => setFormValues('projectOwnerId', value)}
          style={{width: '200px', marginRight: '15px'}}
          value={formData?.projectOwnerId}
          className='smallSelect'
          placeholder='Project Owner'
        />
      </div>
      {isDataSent &&
        <SuccessModal
          isDataSent={isDataSent}
          setIsDataSent={setIsDataSent}
          cancelModalView={cancelModalView}
        />
      }
    </div>
  )
}

const SuccessModal = (props) => {
  const navigate = useNavigate()
  const onOk = () => {
    props.setIsDataSent(false)
    props.cancelModalView ? props.cancelModalView() : navigate('/Projects')
  }
  return (
    <Dialog
      open={props.isDataSent}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
    >
      <DialogContent className={classes.successMessageModal}>
        <div className={classes.successMessage}>
          All changes have been saved
        </div>
        <div className={classes.successMessageButtons}>
          <PrimaryButton 
            text='OK' 
            onClick={onOk}
            style={{width: '130px', height: '40px', marginLeft: '25px'}}
          />
        </div>
      </DialogContent>
    </Dialog>
  )
}

export default MainInfoForm
