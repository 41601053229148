import React from 'react'
import { connect, useSelector } from 'react-redux'
import ConnectionModal from './NewConnectionModal'
import {setIsConnectionModalOpen} from '../../../../../store/canvasReducer'
import {createConnection} from '../../../../../store/stakeholderMapReducer'

const NewConnectionModalContainer = (props) => {
  const lineTypes = [{name: 'Straight', value:'smoothstep', id: 1}, {name: 'Flexible', value: 'default', id: 2}]
  const projectStakeholders = useSelector((state) => state.projectsReducer.projectStakeholders)

  const setConnection = (formData) => {
    const sourceStakeholder = projectStakeholders.find(stk => String(stk.stakeholderId) === props.newConnectionData.source)
    const connectionInfo = {
      ...props.newConnectionData,
      purpose: formData.purpose, 
      strength: formData.strength,
      projId: props.selectedProject,
      orgId: sourceStakeholder.orgId
    }
    props.createConnection(connectionInfo, props.isStrengthViewOn)
    cancelConnecting()
  }

  const cancelConnecting = () => {
    props.setIsConnectionModalOpen(false)
  }

  const sliderMarks = {1: 'WEAK', 10: 'STRONG'}

  return (
    <ConnectionModal 
      cancelConnecting={cancelConnecting}
      isConnectionModalOpen={props.isConnectionModalOpen}
      lineTypes={lineTypes}
      setConnection={setConnection}
      sliderMarks={sliderMarks}
    />
  )
}

const mapStateToProps = (state) => ({
  connections: state.stakeholderMapReducer.connections,
  isConnectionModalOpen: state.canvasReducer.connections.isConnectionModalOpen,
  newConnectionData: state.canvasReducer.connections.newConnectionData,
  isStrengthViewOn: state.stakeholderMapReducer.isStrengthViewOn,
  selectedProject: state.projectsReducer.selectedProject,
  projectList: state.projectsReducer.projectList
})

export default connect(mapStateToProps, 
  { setIsConnectionModalOpen,
    createConnection,
  })(NewConnectionModalContainer) 
