import React, { useState } from 'react'
import { useMediaQuery } from 'react-responsive'
import classes from './StakeholderMap.module.css'
import CanvasContainer from './Canvas/CanvasContainer'
import Select from '../common/Select/Select'
import Switch from '../common/Switch/Switch'
import UserInfoArea from '../common/UserInfoArea/UserInfoArea'
import Spinner from '../common/Spinner/Spinner'
import {ReactComponent as NoDataImg} from './../../img/noDataImg.svg'
import { default as MobileNavBar } from '../NavBar/NavBarContainer'
import MobileHeaderTools from '../common/MobileHeaderTools/MobileHeaderTools'
import ProjectSelect from '../common/ProjectSelect/ProjectSelect'

const StakeholderMap = (props) => {
  const [refData, setRefData] = useState(null)
  const [isToolsVisible, setIsToolsVisible] = useState(false)

  const exportCanvas = (componentRef) => {
    setRefData(componentRef)
  }

  if (props.isEmpty) {
    return (
      <>
        <header className={`${classes.header} ${classes.emptyPageHeader}`}>
          <MobileNavBar className={classes.mobileHeader} />
          <UserInfoArea />
        </header>
        <div className={`emptyPage ${classes.emptyPage}`}>
          <NoDataImg />
        </div>
      </>
    )
  }
  return (
    props.isLoaded ? (
      <>
        <header className={classes.header}>
          <MobileNavBar className={classes.mobileHeader} setIsToolsVisible={setIsToolsVisible}/>
          <div className={classes.toolsArea}>
            <HeaderTools
              selectProjectOptions={props.selectProjectOptions}
              selectProject={props.selectProject}
              selectedProject={props.selectedProject}
              viewTypes={props.viewTypes}
              setSelectedView={props.setSelectedView}
              selectedView={props.selectedView}
              isStrengthViewOn={props.isStrengthViewOn}
              switchStrengthView={props.switchStrengthView}
            />
          </div>
          <UserInfoArea onExport={exportCanvas} />
        </header>

        <div>
          <CanvasContainer
            isStrengthViewOn={props.isStrengthViewOn}
            selectedView={props.selectedView}
            refData={refData}
            setRefData={setRefData}
          />
        </div>
        <MobileHeaderTools
          isToolsVisible={isToolsVisible}
          setIsToolsVisible={setIsToolsVisible}
          content={
            <HeaderTools
              selectProjectOptions={props.selectProjectOptions}
              selectProject={props.selectProject}
              selectedProject={props.selectedProject}
              viewTypes={props.viewTypes}
              selectedView={props.selectedView}
              setSelectedView={props.setSelectedView}
              isStrengthViewOn={props.isStrengthViewOn}
              switchStrengthView={props.switchStrengthView}
              setIsToolsVisible={setIsToolsVisible}
            />
          }
        />
      </>
    ): (
      <Spinner className={classes.spinner}/>
    )
  )
}

const HeaderTools = (props) => {
  const isMobileScreen = useMediaQuery({ query: '(min-width: 360px) and (max-width: 766px)' })
  return (
    <>
      <ProjectSelect
        options={props.selectProjectOptions}
        onSelect={(value) => {
          props.selectProject(value)
          !!isMobileScreen && props.setIsToolsVisible(false)
        }}
        style={isMobileScreen ? {marginBottom:'20px'} : {marginRight:'20px'}}
        value={props.selectedProject}
        className={classes.headerSelect}
        showCompleted
      />
      <Select 
        options={props.viewTypes}
        onSelect={(value) => {
          props.setSelectedView(value)
          !!isMobileScreen && props.setIsToolsVisible(false)
        }}
        style={isMobileScreen ? {marginBottom:'20px'} : {marginRight:'20px'}}
        defaultValue={props.selectedView}
        className={classes.headerSelect}
      />
      {isMobileScreen ? (
        <div className={classes.switchWrapperMobile}>
          <span style={{fontSize: '16px'}}>Connection strength</span>
          <Switch 
            checked={props.isStrengthViewOn} 
            onChange={() => {
              props.switchStrengthView();
              props.setIsToolsVisible(false)
            }} 
          />
        </div>
      ) : (
        <div className={classes.switchWrapper}>
          <span style={{fontSize: '16px'}}>Connection strength</span>
          <Switch 
            checked={props.isStrengthViewOn} 
            onChange={() => props.switchStrengthView()} 
            leftLabel='OFF'
            rightLabel='ON'
          />
        </div>
      )}
    </>
  )
}

export default StakeholderMap
