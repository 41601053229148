import generator from 'generate-password-browser'

const generatePassword = () => {
  const randomPassword = generator.generate({
    length: 10,
    numbers: true
  })
  return randomPassword
}

export default generatePassword
