const setToken = (token) => {
  localStorage.setItem('token', JSON.stringify(token))
}
const setTokenExpiryDate = (expiryDate) => {
  localStorage.setItem('tokenExpiryDate', JSON.stringify(expiryDate))
}
export const setTokenData = (token, expiryDate) => {
  setToken(token)
  setTokenExpiryDate(expiryDate)
}


export const getToken = () => {
  const token = localStorage.getItem('token')
  return !!token && JSON.parse(token)
}
export const getTokenExpiryDate = () => {
  return JSON.parse(localStorage.getItem('tokenExpiryDate'))
}
export const getTokenData = () => {
  const token = getToken()
  const tokenExpiryDate = getTokenExpiryDate()
  return {token, tokenExpiryDate}
}

const removeToken = () => {
  return localStorage.removeItem('token')
}
const removeTokenExpiryDate = () => {
  return localStorage.removeItem('tokenExpiryDate')
}
export const removeTokenData = () => {
  removeToken()
  removeTokenExpiryDate()
}

export const setCurrentUserRoles = (userData) => {
  localStorage.setItem('currentUserRoles', JSON.stringify(userData))
}
export const getCurrentUserRoles = () => {
  return JSON.parse(localStorage.getItem('currentUserRoles'))
}
export const removeCurrentUserRoles = () => {
  return localStorage.removeItem('currentUserRoles')
}

export const setUserEmail = (email) => {
  localStorage.setItem('userEmail', JSON.stringify(email))
}
export const getUserEmail = () => {
  return JSON.parse(localStorage.getItem('userEmail'))
}
export const removeUserEmail = () => {
  return localStorage.removeItem('userEmail')
}

export const setUserName = (name) => {
  localStorage.setItem('userName', JSON.stringify(name))
}
export const getUserName = () => {
  return JSON.parse(localStorage.getItem('userName'))
}
export const removeUserName = () => {
  return localStorage.removeItem('userName')
}

export const setCustomer = (customer) => {
  localStorage.setItem('customer', JSON.stringify(customer))
}
export const getCustomer = () => {
  return JSON.parse(localStorage.getItem('customer'))
}
export const removeCustomer = () => {
  return localStorage.removeItem('customer')
}

export const setTenantLogo = (logo) => {
  localStorage.setItem('logo', JSON.stringify(logo))
}
export const getTenantLogo = () => {
  return JSON.parse(localStorage.getItem('logo'))
}
export const removeTenantLogo = () => {
  return localStorage.removeItem('logo')
}

export const setUserIdData = (userId) => {
  localStorage.setItem('userId', JSON.stringify(userId))
}
export const getUserIdData = () => {
  return JSON.parse(localStorage.getItem('userId'))
}
export const removeUserIdData = () => {
  return localStorage.removeItem('userId')
}

export const setIsSynchronizedWithOffice = (isSync) => {
  localStorage.setItem('syncWithOffice', JSON.stringify(isSync))
}
export const getIsSynchronizedWithOffice = () => {
  return JSON.parse(localStorage.getItem('syncWithOffice'))
}
export const removeIsSynchronizedWithOffice = () => {
  return localStorage.removeItem('syncWithOffice')
}

export const setIsSynchronizedWithGoogle = (isSync) => {
  localStorage.setItem('syncWithGoogle', JSON.stringify(isSync))
}
export const getIsSynchronizedWithGoogle = () => {
  return JSON.parse(localStorage.getItem('syncWithGoogle'))
}
export const removeIsSynchronizedWithGoogle = () => {
  return localStorage.removeItem('syncWithGoogle')
}

export const setUserAvatar = (logo) => {
  localStorage.setItem('avatar', JSON.stringify(logo))
}
export const getUserAvatar = () => {
  const avatar = localStorage.getItem('avatar');
  if (avatar) {
    try {
      return JSON.parse(avatar);
    } catch (e) {
      console.error('Error parsing avatar JSON:', e);
      return null;
    }
  }
  return null;
}
export const removeUserAvatar = () => {
  return localStorage.removeItem('avatar')
}

export const setUsersSearchString = (logo) => {
  localStorage.setItem('usersSearchString', JSON.stringify(logo))
}
export const getUsersSearchString = () => {
  return JSON.parse(localStorage.getItem('usersSearchString'))
}
export const removeUsersSearchString = () => {
  return localStorage.removeItem('usersSearchString')
}

export const setAdminsSearchString = (logo) => {
  localStorage.setItem('adminsSearchString', JSON.stringify(logo))
}
export const getAdminsSearchString = () => {
  return JSON.parse(localStorage.getItem('adminsSearchString'))
}
export const removeAdminsSearchString = () => {
  return localStorage.removeItem('adminsSearchString')
}

export const setTenantsSearchString = (logo) => {
  localStorage.setItem('tenantsSearchString', JSON.stringify(logo))
}
export const getTenantsSearchString = () => {
  return JSON.parse(localStorage.getItem('tenantsSearchString'))
}
export const removeTenantsSearchString = () => {
  return localStorage.removeItem('tenantsSearchString')
}

export const setTemplatesSearchString = (templatesSearchString) => {
  localStorage.setItem('templatesSearchString', JSON.stringify(templatesSearchString))
}
export const getTemplatesSearchString = () => {
  return JSON.parse(localStorage.getItem('templatesSearchString'))
}
export const removeTemplatesSearchString = () => {
  return localStorage.removeItem('templatesSearchString')
}

export const setTenantsListFilter = (tenantsListFilter) => {
  localStorage.setItem('tenantsListFilter', JSON.stringify(tenantsListFilter))
}
export const getTenantsListFilter = () => {
  return JSON.parse(localStorage.getItem('tenantsListFilter'))
}
export const removeTenantsListFilter = () => {
  return localStorage.removeItem('tenantsListFilter')
}

export const setAdminsListFilter = (adminsListFilter) => {
  localStorage.setItem('adminsListFilter', JSON.stringify(adminsListFilter))
}
export const getAdminsListFilter = () => {
  return JSON.parse(localStorage.getItem('adminsListFilter'))
}
export const removeAdminsListFilter = () => {
  return localStorage.removeItem('adminsListFilter')
}

export const setLoginRolePicked = (loginRolePicked) => {
  localStorage.setItem('loginRolePicked', JSON.stringify(loginRolePicked))
}
export const getLoginRolePicked = () => {
  return JSON.parse(localStorage.getItem('loginRolePicked'))
}
export const removeLoginRolePicked = () => {
  return localStorage.removeItem('loginRolePicked')
}