import React, { useState } from 'react'
import classes from './WorkerCard.module.css'
import Tooltip from './../../../common/Tooltip/Tooltip'
import WorkerDefaultIcon from '../../../common/WorkerDefaultIcon'
import { getCurrentUserRoles } from '../../../../helpers/localStorage'
import { ReactComponent as StakeholderCommentIcon} from './../../../../img/stakeholderMap/stakeholderCommentIcon.svg'
import { ReactComponent as StakeholderCommentNewIcon} from './../../../../img/stakeholderMap/stakeholderCommentNewIcon.svg'

const workersCards = (workers, connections, isCirclesView, isPageBlocked, handleDoubleClick, isProjectView) => {
  const workersData = workers.map(worker => {
    return {
      id: `${worker.id}`,
      data: {label: <WorkerCard worker={worker} handleDoubleClick={handleDoubleClick} isProjectView={isProjectView}/>},
      position: worker.position,
      className: classes.WorkerCardWrapper,
      draggable: !isCirclesView && !isPageBlocked
    }
  })
  return [...workersData, ...connections]
}

const WorkerCard = ({worker, handleDoubleClick, isProjectView}) => {
  const [isTooltipVisible, setIsTooltipVisible] = useState(false)
  const [isCardMoving, setIsCardMoving] = useState(false)
  const currentUserRoles = getCurrentUserRoles()
  const isUser = currentUserRoles?.some(role => role.code.toLowerCase() === 'user')

  return (
    <Tooltip
      visible={isTooltipVisible && !isCardMoving}
      title={ 
        <>     
          <div>Full name: {worker.fullName}</div>
          {isUser && worker.isSensitiveContact ? (
            <></>
          ) : (
            <>
              <div>Phone: {worker.phone}</div>
              <div>Email: {worker.email}</div></>
          )}
        </>
      }
    >
      <div 
        onMouseEnter={() => setIsTooltipVisible(true)}
        onMouseLeave={() => setIsTooltipVisible(false)}
        onMouseDown={() => setIsCardMoving(true)} 
        onMouseUp={() => setIsCardMoving(false)}
        className='worker-card-wrapper'
        onDoubleClick={e => {e.stopPropagation(); handleDoubleClick({isOpen: true, worker: worker})}}
        style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}
      >
        <WorkerDefaultIcon className={classes.avatar} color={worker.color}/>
        {(worker.comments?.length && !isProjectView ) || (worker.comments?.length && !!isProjectView && worker.comments?.some(c => !!c.is_project_view_visible)) ? <StakeholderCommentIcon /> : <StakeholderCommentNewIcon />}
        <h1 style={{fontWeight: 600, marginBottom: '0px'}}>
          {worker.fullName}
        </h1>
        <h2>
          {worker.roleTitle}
        </h2>  
      </div>
    </Tooltip>
  )
}

export default workersCards


