import React, {useRef, useState} from 'react'
import {useNavigate, useParams} from 'react-router'
import classes from './CustomerDetails.module.css'
import {pageDefaultHeight} from './../../common/sizes'
import UserInfoArea from './../../common/UserInfoArea/UserInfoArea'
import {ReactComponent as GoBackIcon} from './../../../img/icons/goBackIcon.svg'
import MainDataForm from './MainDataForm'
import UsersTable from './UsersTable'
import CustomDivider from './../../common/CustomDivider/CustomDivider'
import {useSelector} from "react-redux";
import {PrimaryButton, SecondaryButton} from "../../common/buttons/buttons";
import {Link} from "react-router-dom";

const CustomerDetails = (props) => {
    const navigate = useNavigate()
    const {id} = useParams()
    const formRef = useRef()
    const [disabledBtn, setDisabledBtn] = useState()

    return (
        <>
            <header className={classes.header}>
                <div className={classes.pageTitle}>
                    <GoBackIcon
                        onClick={() => navigate(`/Tenants`)}
                        style={{cursor: 'pointer', marginRight: '10px'}}
                    />
                    {props.dataForEditing ? props.dataForEditing.name?.toUpperCase() : 'NEW TENANT'}
                </div>
                <UserInfoArea/>
            </header>
            <div style={{height: pageDefaultHeight}} className={classes.tabsWrapper}>
                <MainDataForm
                    dataForEditing={props.dataForEditing}
                    isDataSent={props.isDataSent}
                    setIsDataSent={props.setIsDataSent}
                    newCustomerId={props.newCustomerId}
                    saveCustomer={props.saveCustomer}
                    updateCustomer={props.updateCustomer}
                    formRef={formRef}
                    isLoading={props.isLoading}
                    setDisabledBtn={setDisabledBtn}
                />
                {!!id &&
                    <>
                        <CustomDivider
                            text='Tenant Users'
                            textStyle={{
                                width: '130px',
                                fontSize: '14px',
                                fontWeight: 600,
                                color: '#0E9AFC'
                            }}
                        />
                        <UsersTable
                            customerUsers={props.customerUsers}
                            setSearchValue={props.setSearchValue}
                            onUserSearch={props.onUserSearch}
                            usersTableRows={props.usersTableRows}
                            usersModalData={props.usersModalData}
                            setUsersModalData={props.setUsersModalData}
                            roleOptions={props.roleOptions}
                            removeCustomerUser={props.removeCustomerUser}
                            saveCustomerUser={props.saveCustomerUser}
                            updateCustomerUserData={props.updateCustomerUserData}
                            custId={props.custId}
                        />
                    </>
                }
                <div className={classes.buttonsArea}>
                  <Link to='/Tenants'>
                    <SecondaryButton
                      style={{width:'100px', height: 'auto'}}
                      text='Cancel'
                    />
                  </Link>
                  <PrimaryButton
                    className={classes.primaryButton}
                    style={{width:'100px', height: 'auto', marginLeft: '15px'}}
                    text='SAVE'
                    onClick={() => {
                      if (formRef.current) formRef.current.handleSubmit()
                    }}
                    loading={props.isLoading}
                    disabled={!disabledBtn}
                    // disabled={(addTenantFields?.name === ''
                    //     && addTenantFields?.logo === null
                    //     && addTenantFields?.activeFrom === ''
                    //     && addTenantFields?.activeTill === '') || (moment(addTenantFields.activeTill).isBefore(addTenantFields.activeFrom))}
                  />
                </div>
            </div>
        </>
    )
}

export default CustomerDetails
