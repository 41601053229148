import React from 'react'
import classes from './companyTitles.module.css'

const getCompanyTitles = (companies, isCirclesView, isPageBlocked) => {
  const titles = companies.map(company => {
    const titleClasses = isCirclesView ? `${classes.titleCirclesView}` : `${classes.title}`
    return {
      id: `title${company.id}`,
      data: {label: <CompanyTitle {...company}/> },
      position: company.position,
      connectable: false, 
      type: 'input',
      className: titleClasses,
      draggable: !isCirclesView && !isPageBlocked,
      style: {color: company?.color, backgroundColor: `${company?.color}1A`}
    }
  })
  return titles
}

const CompanyTitle = (company) => {
  return (
      <div>
        {company.title}
      </div>
  )
}

export default getCompanyTitles


