import moment from 'moment'
import {orderBy} from 'lodash'

const getGroupedStakeholders = (stakeholders) => {
  const grouped = [
    { id:1,
      groupName: 'Manage Closely',
      stakeholders: stakeholders?.filter(person => 
        (person?.influenceLvl !== null && person?.influenceLvl > 5)
        &&
        (person?.interestLvl !== null && person?.interestLvl > 5)
      )
    },
    { id:2,
      groupName: 'Keep Satisfied',
      stakeholders: stakeholders?.filter(person => 
        (person?.influenceLvl !== null && person?.influenceLvl > 5)
        &&
        (person?.interestLvl !== null && person?.interestLvl <= 5)
      )
    },
    { id:3,
      groupName: 'Keep Informed',
      stakeholders: stakeholders?.filter(person =>
        (person?.influenceLvl !== null && person?.influenceLvl <= 5)
        &&
        (person?.interestLvl !== null && person?.interestLvl > 5)
      )
    },
    { id:4,
      groupName: 'Monitor',
      stakeholders: stakeholders?.filter(person =>
        (person?.influenceLvl !== null && person?.influenceLvl <= 5)
        &&
        (person?.interestLvl !== null && person?.interestLvl <= 5)
      )
    },
    ...(stakeholders?.some(person => person?.influenceLvl === null || person?.interestLvl === null)
      ? [{id:5,
          groupName: 'Unknown data',
          stakeholders: stakeholders?.filter(person => person?.influenceLvl === null || person?.interestLvl === null)
        }]
      : []
    )
  ]
  return grouped
}

export const getStakeholdersWithGroupData = (stakeholders) => {
  const grouped = stakeholders?.map((person => {
    if (
      (person?.influenceLvl !== null && person?.influenceLvl > 5)
      &&
      (person?.interestLvl !== null && person?.interestLvl > 5)
    ) {
      return {...person, groupName: 'Manage Closely'}
    } else if (
      (person?.influenceLvl !== null && person?.influenceLvl > 5)
        &&
        (person?.interestLvl !== null && person?.interestLvl <= 5)
    ) {
      return {...person, groupName: 'Keep Satisfied'}
    } else if (
      (person?.influenceLvl !== null && person?.influenceLvl <= 5)
      &&
      (person?.interestLvl !== null && person?.interestLvl > 5)
    ) {
      return {...person, groupName: 'Keep Informed'}
    } else if (
      (person?.influenceLvl !== null && person?.influenceLvl <= 5)
        &&
        (person?.interestLvl !== null && person?.interestLvl <= 5)
    ) {
      return {...person, groupName: 'Monitor'}
    } else {
      return {...person, groupName: 'Unknown data'}
    }
  }))
  return grouped || []
}

export const getGroupedProjectOptions = (projectList) => {
  const activeProjectOptions = projectList
    .filter(project => moment(project.activeTill).isAfter(moment()))
    .map(project => ({id: project.projId, value: project.projId, name: project.name}))
  const completedProjectOptions = projectList
    .filter(project => moment(project.activeTill).isBefore(moment()))
    .map(project => ({id: project.projId, value: project.projId, name: project.name}))
  
  const options = [
    {group: 'Active', projects: orderBy(activeProjectOptions, [project => project.name.toLowerCase()], ['asc'])},
    {group: 'Completed', projects: orderBy(completedProjectOptions, [project => project.name.toLowerCase()], ['asc'])},    
  ]
  return options
}

export default getGroupedStakeholders
