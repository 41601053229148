import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import CircularProgress from '@material-ui/core/CircularProgress'

const useStyles = makeStyles((theme) => ({
  root: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    height: '100%',
  },
}))

const Spinner = (props) => {
  const classes = useStyles()
  return (
    <div className={`${classes.root} ${props.className}`} style={props.style}>
      <CircularProgress color={props.color}/>
    </div>
  )
}

export default Spinner

