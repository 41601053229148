import React from 'react'
import Slider from '../../../../common/Slider/Slider'
import { Form, Formik } from 'formik'
import * as Yup from 'yup'
import classes from './NewWorkerModal.module.css'
import Select from '../../../../common/Select/Select'
import { PrimaryButton, SecondaryButton } from '../../../../common/buttons/buttons'
import { Input } from '../../../../common/Input/Input'
import Switch from '../../../../common/Switch/Switch'

const SelectWorker = (props) => {
  const initialValues = {
    contactId: undefined,
    projectRole: '',
    interest: null,
    influence: null,
    support: null
  }

  const validationSchema = Yup.object({
    contactId: Yup.string()
      .required('Contact is required'),
  })

  const onSubmit = (formData) => {
    props.addNewWorker(formData)
  } 

  return (
    <Formik 
      initialValues={initialValues} 
      validationSchema={validationSchema} 
      onSubmit={onSubmit}
      validateOnBlur={false}
      validateOnChange={false}
    > 
      {({ values, setFieldValue, errors, touched, dirty}) => {
        return (
          <Form>
            <div className={classes.selectWorker}>
              <Select 
                className={`selectDark ${!!errors.contactId && 'hasError'}`}
                allowClear
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                placeholder='Name*'
                onSelect={(data) => {
                  props.setSelectedWorker(data)
                  setFieldValue('contactId', data)
                }}
                options={props.stakeholderOptions}
                style={{width: '323px', marginLeft: '-10px', borderColor:'red'}}
                showSearch
                value={values.contactId}
              />
              <div style={{color:'red', fontSize:'11px', textAlign:'start', width: '68%', marginTop:'3px'}}>
                {errors.contactId}
              </div>
              <Input
                name='projectRole'
                placeholder='Project role title'
                InputProps={{style:{ marginTop:'40px'}}}
              />
              <Switch
                leftLabel={<span style={{fontWeight: 600}}>Interest</span>}
                checked={values.interest !== null}
                onChange={() => {
                  values.interest !== null 
                    ? setFieldValue('interest', null)
                    : setFieldValue('interest', 1)
                }}
                style={{width:'328px', marginTop: '40px'}}
              />
              {values.interest !== null &&
                <div style={{width: '70%'}}>
                  <Slider
                    defaultValue={values.interest}
                    onChange={(_, value) => setFieldValue('interest', value)}
                    value={values.interest} 
                    min={1}
                    max={10}
                  />
                </div>
              }
              <Switch
                leftLabel={<span style={{fontWeight: 600}}>Influence</span>}
                checked={values.influence !== null}
                onChange={() => {
                  values.influence !== null 
                    ? setFieldValue('influence', null)
                    : setFieldValue('influence', 1)
                }}
                style={{width:'328px'}}
              />
              {values.influence !== null &&
                <div style={{width: '70%'}}>
                  <Slider 
                    defaultValue={values.influence}
                    onChange={(_, value) => setFieldValue('influence', value)}
                    value={values.influence} 
                    min={1}
                    max={10}
                  />
                </div>
              }
              <Switch
                leftLabel={<span style={{fontWeight: 600}}>Support</span>}
                checked={values.support !== null}
                onChange={() => {
                  values.support !== null 
                    ? setFieldValue('support', null)
                    : setFieldValue('support', 0)
                }}
                style={{width:'328px'}}
              />
              {values.support !== null &&
                <div style={{width: '70%'}}>
                  <Slider 
                    defaultValue={values.support}
                    onChange={(_, value) => setFieldValue('support', value)}
                    value={values.support} 
                    min={-5}
                    max={5}
                  />
                </div>
              }
              <div className={classes.buttonsArea}>
                <SecondaryButton 
                  onClick={() => props.closeModal()}
                  text='Cancel' 
                  style={{width:'100px', height: 'auto'}}
                />
                <PrimaryButton 
                  className={classes.submitBtn} 
                  htmlType='submit'
                  text='SAVE' 
                  style={{width:'100px', height: 'auto'}}
                  loading={props.isLoading}
                  disabled={!dirty}
                />
              </div>
            </div>
          </Form> 
        )
      }}
    </Formik>
  )
}

export default SelectWorker
