import React from 'react'
import { Modal } from 'antd'
import classes from './AddToProjectModal.module.css'
import Select from '../../../../../common/Select/Select'
import ModalForm from './ModalForm'

const AddToProjectModal = (props) => {
  console.log('md', props.modalData?.editingData)
  return (
    <Modal 
      centered
      closable={false}   
      footer={null}
      onCancel={() => props.setModalData({isOpen: false})}
      style={{padding: '10px', width: '100%'}}
      visible={true} 
      width={800}
      bodyStyle={{height:600, overflow: 'auto'}}
      maskClosable={false}
    >
      <div className={classes.modalTitle}>
        {props.modalData?.editingData ? 'EDIT OF PROJECT' : 'ADD TO PROJECT'}
        <Select 
          options={[
            {id: 1, name: 'Active', value: 'active'},
            {id: 2, name: 'Not started yet', value: 'notStartedYet'},
          ]}
          onSelect={(value) => props.setSelectedProjectPeriod(value)}
          style={{width: '200px', marginRight: '15px', height:'35px'}}
          value={props.selectedProjectPeriod}
          className='smallSelect'
        />
      </div>
      <div style={{display:'flex', justifyContent:'center', marginTop:'7px', fontWeight:600}}>
        {props.errorMessage && 
          <div className={classes.errorMessage} style={{}}>
            {props.errorMessage}
          </div>
        }
      </div>
      <ModalForm
        closeModal={props.closeModal}
        dirty={props.dirty}
        modalData={props.modalData}
        initialValues={props.formInitialValues}
        projects={props.projects}
        projectData={props.projectData}
        updateProjectData={props.updateProjectData}
        updateProjectDetails={props.updateProjectDetails}
        saveProjectDetails={props.saveProjectDetails}
        setHasProjectChanges={props.setHasProjectChanges}
      />
    </Modal>
  )
}

export default AddToProjectModal
