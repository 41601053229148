import axios from '../helpers/axiosHelper'
import {handleError} from './errorsReducer'

const initialState = {
  stakeholders: [],
}

const stakeholdersReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_STAKEHOLDERS':
      return {...state, stakeholders: action.stakeholders} 
    case 'ADD_STAKEHOLDER':
      return {...state, stakeholders: [...state.stakeholders, action.stakeholder]} 
    case 'REMOVE_STAKEHOLDER':
      return {...state, stakeholders: state.stakeholders.filter(stakeholder => stakeholder.stakeholderId !== action.stkId)} 
    case 'UPDATE_STAKEHOLDER':
      return {...state, stakeholders: state.stakeholders.map(stakeholder => {
        if (stakeholder.stakeholderId === action.stakeholder.stkId || stakeholder.stakeholderId === action.stakeholder.stakeholderId) {
          return {...action.stakeholder, stakeholderId: action.stakeholder?.stkId || action.stakeholder?.stakeholderId}
        } else {
          return stakeholder
        }  
      })} 
      default: return state
  } 
}

export const setStakeholders = (stakeholders) => ({type: 'SET_STAKEHOLDERS', stakeholders})
export const addStakeholder = (stakeholder) => ({type: 'ADD_STAKEHOLDER', stakeholder})
export const removeStakeholder = (stkId) => ({type: 'REMOVE_STAKEHOLDER', stkId})
export const updateStakeholder = (stakeholder) => ({type: 'UPDATE_STAKEHOLDER', stakeholder})

export const getStakeholders = () => (dispatch) => {
    return axios.get('/stakeholders')
      .then(response => {
        response.status === 200 
          ? dispatch(setStakeholders(response.data.stakeholderDtoResponses))
          : dispatch(handleError(response.data))
      })
    .catch(error => {
      dispatch(handleError(error))
    })
}

export const deleteStakeholder = (stkId, deactivate_user = false) => (dispatch) => {
  return axios.delete(`/stakeholders/${stkId}?deactivate_user=${deactivate_user}`)
    .then(response => {
      response.status === 200 
        ? dispatch(removeStakeholder(stkId))
        : dispatch(handleError(response.data))
    })
  .catch(error => dispatch(handleError(error)))
}

export const editStakeholder = (stkId, editedData, removedProjects = []) => (dispatch) => {
  return axios.put(`/stakeholders/${stkId}`, editedData)
    .then(response => {
      if (response.status === 200) {
        const removedProjectIds = removedProjects.map(proj => proj.projId)
        if (!!removedProjectIds.length) {
          axios.delete(`/stakeholders/${stkId}/projects`, {data: {id_list: removedProjectIds}})
          .then((resp) => {
            resp.status !== 200 && dispatch(handleError(resp?.data))
          })
        }
        // dispatch(updateStakeholder(editedData))
        return response
      } else {
        dispatch(handleError(response?.data))
      }
    })
  .catch(error => dispatch(handleError(error)))
}

export default stakeholdersReducer
