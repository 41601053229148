const pageHeaderHeight = 100
const footerHeight = 55
export const pageDefaultHeight = window.innerHeight - pageHeaderHeight - footerHeight
export const pageDefaultHeightWithSelect = window.innerHeight - pageHeaderHeight - footerHeight

const navBarMarginTop = 20
const navBarAreaWidth = 275
const navBarAreaTabletWidth = 85
export const navBarHeight = window.innerHeight - navBarMarginTop - footerHeight

// stakeholder map page
const leftRightMargins = 20 + 20
export const canvasWidth = window.innerWidth - navBarAreaWidth - leftRightMargins

// contacts page
const leftRightPaddings = 40 + 40
export const tableWidth = window.innerWidth - leftRightPaddings - navBarAreaWidth - leftRightMargins

// matrix page
export const matrixWidth = window.innerWidth - navBarAreaWidth - leftRightMargins

const arrowPaddings = 65
export const matrixArrowWidth = window.innerWidth - navBarAreaWidth - leftRightMargins - arrowPaddings
export const matrixArrowWidthTablets = window.innerWidth - navBarAreaTabletWidth - arrowPaddings
export const matrixArrowHeight =  pageDefaultHeight - arrowPaddings

// charts
export const chartWidth = window.innerWidth - navBarAreaWidth - leftRightMargins


// login page
const loginHeaderHeight = 55
export const loginPageHeight = window.innerHeight  - footerHeight - loginHeaderHeight

// stakeholderForm
export const tapBarHeight = 45
export const formAreaHeight = pageDefaultHeight - tapBarHeight
