import axios from './../helpers/axiosHelper'
import { handleError } from './errorsReducer'

const initialState = {
  organizationalChart: [],
}

const chartsReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_ORGANIZATIONAL_CHART_DATA':
      return {...state, organizationalChart: action.chartData} 
    default: return state
  } 
}

export const setOrganizationalChartData = (chartData) => ({type: 'SET_ORGANIZATIONAL_CHART_DATA', chartData})

export const getOrganizationalChartData = (orgId) => (dispatch) => {
    return axios.get(`organization/chart/${orgId}`)
      .then(response => {
        response.status === 200 
        ? dispatch(setOrganizationalChartData(response.data.orgChartElementResponseList))
        : handleError(response.data)
      })
    .catch(error => handleError(error))
}

export default chartsReducer
