import lodash from 'lodash'
import axios from './../helpers/axiosHelper'
import { handleError } from './errorsReducer'
import { editProject, getProjectStakeholdersTC } from './projectsReducer'
import { addOrganization as addOrganizationAC} from './organizationsReducer'
import { updateStakeholder } from './stakeholdersReducer'

const initialState = {
  workers: [],
  organisationsFromProject: [],
  connections: JSON.parse(localStorage.getItem('connections')) || [],
  isStrengthViewOn: true,
  annotations: []
}

const stakeholderMapReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_WORKERS':
      return {...state, workers: action.workers}
    case 'SET_ORGANISATIONS_FROM_PROJECT':
      return {...state, organisationsFromProject: action.organisationsFromProject}
    case 'SET_CONNECTIONS':
      return {...state, connections: action.connections}
    case 'ADD_CONNECTION':
      return {...state, connections: [...state.connections, action.connection]}
    case 'REMOVE_CONNECTION':
      return {...state, connections: state.connections.filter(con => con.id !== action.conId)}
    case 'UPDATE_CONNECTION':
      return {...state, connections: state.connections.map(con => {
        if (con.id === action.connection.id) {
          return action.connection
        } else {
          return con
        }
      })}
    case 'SET_IS_STRENGTH_VIEW_ON':
      return {...state, isStrengthViewOn: action.isOn}
    case 'TURN_OFF_CONNECTION_STRENGTH':
      return {...state, connections: state.connections.map(connection => ({...connection, style: {strokeWidth: 2}}))}
    case 'TURN_ON_CONNECTION_STRENGTH':
      return {...state, connections: state.connections.map(connection => ({...connection, style: {strokeWidth: connection.data.strength * 2}}))}
    case 'SET_ANNOTATIONS':
      return {...state, annotations: action.annotations}
    case 'ADD_ANNOTATION':
      return {...state, annotations: [...state.annotations, action.annotation]}
    case 'UPDATE_ANNOTATIONS':
      return {...state, annotations:
        state.annotations.map(ann => ann.id === action.annotation.id ? action.annotation : ann)
      }
    case 'DELETE_ANNOTATION':
      return {...state, annotations:
        state.annotations.filter(ann => ann.id !== action.annotationId)
      }
    default: return state
  } 
}

export const setWorkers = (workers) => ({type: 'SET_WORKERS', workers})
export const setOrganisationsFromProject = (organisationsFromProject) => ({type: 'SET_ORGANISATIONS_FROM_PROJECT', organisationsFromProject})

export const setConnections = (connections) => ({type: 'SET_CONNECTIONS', connections})
export const addConnection = (connection) => ({type: 'ADD_CONNECTION', connection})
export const removeConnection = (conId) => ({type: 'REMOVE_CONNECTION', conId})
export const updateConnection = (connection) => ({type: 'UPDATE_CONNECTION', connection})

export const setIsStrengthViewOn = (isOn) => ({type: 'SET_IS_STRENGTH_VIEW_ON', isOn})

export const turnOffConnectionStrength = () => ({type: 'TURN_OFF_CONNECTION_STRENGTH'})
export const turnOnConnectionStrength = () => ({type: 'TURN_ON_CONNECTION_STRENGTH'})

export const setAnnotations = (annotations) => ({type: 'SET_ANNOTATIONS', annotations})
export const addAnnotation = (annotation) => ({type: 'ADD_ANNOTATION', annotation})
export const updateAnnotations = (annotation) => ({type: 'UPDATE_ANNOTATIONS', annotation})
export const deleteAnnotation = (annotationId) => ({type: 'DELETE_ANNOTATION', annotationId})

export const setWorkersData = (stakeholders, positions) => (dispatch) => {
  const groupedByCompany = lodash.groupBy(stakeholders, (stk) => stk.orgId)
  let yCount = 0
  let xCount = 0 
  const workersWithPosition = []

  const getOrgStakeholdersWithPosition = (stks) => {
    xCount += 550
    yCount = 0
    return stks.map(stk => {
      yCount += 250
      const positionData = positions?.find(pos => pos.id === String(stk.stakeholderId))
      return {
        ...stk,
        position: {x: positionData?.position?.x ? positionData?.position?.x : xCount, y: positionData?.position?.y ? positionData?.position?.y : yCount},
        id: stk.stakeholderId,
        phone: stk?.phones.find(phone => phone.isDefault)?.phone || 'none',
        email: stk?.emails.find(email => email.isDefault)?.email || 'none',
      }
    })
  }

  Object.entries(groupedByCompany).forEach(entry => {
    // entry structure example: [orgId, listOfStakeholders]
    const listOfStakeholders = entry[1]
    const orgWithPositions = getOrgStakeholdersWithPosition(listOfStakeholders)
    workersWithPosition.push(...orgWithPositions || [])
  })
  dispatch(setWorkers(workersWithPosition))
}

export const getOrganisationsFromProject = (stakeholders, organisations, positions) => (dispatch) => {
  const groupedByCompany = lodash.groupBy(stakeholders, (stk) => stk.orgId)
  let yCount = 100
  let xCount = -100
  const data = Object.keys(groupedByCompany).map(orgId => {
    const organisationData = organisations.find(org => org?.orgId === Number(orgId))
    const positionData = positions.find(pos => pos.id === `title${organisationData?.orgId}`)
    xCount += 550
    return {
      id: orgId,
      title: organisationData?.name,
      position: {x: positionData?.position?.x || xCount, y: positionData?.position?.y || yCount},
      color: organisationData?.color
    }
  })
  dispatch(setOrganisationsFromProject(data))
}

export const setConnectionsData = (projId, isStrengthViewOn) => (dispatch) => {
  return axios.get(`/stakeholders/relation/projects/${projId}`)
    .then(response => {
      if (response.status === 200) {
        const connectionsData = response.data.stakeholderLinks.map(connection => {
          const strength = connection.link_property.strength || 1
          const lineWidth = isStrengthViewOn ? strength * 2 : 2
          const connectionData = 
          {
            ...connection,
            // organizationId: connection.organization_id,
            // projectId: connection.project_id,
            id: `line-${connection.id}`,
            source: String(connection.from_stakeholder_id),
            target: String(connection.to_stakeholder_id),
            style: {strokeWidth: lineWidth},
            data: {
              purpose: connection.link_property.purpose,
              strength: connection.link_property.strength
            },
            type: "customLine"
          }
          return connectionData
        })
        dispatch(setConnections(connectionsData))
      } else {
        dispatch(handleError(response.data))
      }
    })
    .catch(error => dispatch(handleError(error)))
}

export const createConnection = (data, isStrengthViewOn) => (dispatch) => {
  const newConnectionData = {
    "from_stakeholder_id": Number(data.source),
    "link_code": "project",
    "link_level": "P",
    "link_property": {
      "strength": data.strength,
      "purpose": data.purpose
    },
    "organization_id": data.orgId,
    "project_id": data.projId,
    "to_stakeholder_id": Number(data.target)
  }
  return axios.post(`/stakeholders/relation`, newConnectionData)
    .then(response => {
      if (response.status === 201) {
        dispatch(setConnectionsData(data.projId, isStrengthViewOn))
      } else {
        dispatch(handleError(response.data))
      }
    })
    .catch(error => dispatch(handleError(error)))
}

export const deleteConnection = (linkId) => (dispatch) => {
  const idFromDatabase = Number(linkId.split('-')[1])
  return axios.delete(`/stakeholders/relation/${idFromDatabase}`)
    .then(response => {
      if (response.status === 200) {
        dispatch(removeConnection(linkId))
        return true
      } else {
        dispatch(handleError(response.data))
      }
    })
    .catch(error => dispatch(handleError(error)))
}

export const editConnection = (updatedData, linkId, isStrengthViewOn) => (dispatch) => {
  const idFromDatabase = Number(linkId.split('-')[1])
  return axios.put(`/stakeholders/relation/${idFromDatabase}`, updatedData)
    .then(response => {
      if (response.status === 200) {
        const strength = response.data.link_property.strength || 1
        const lineWidth = isStrengthViewOn ? strength * 2 : 2
        const connectionData = {
          ...response.data,
          id: `line-${response.data.id}`,
          source: String(response.data.from_stakeholder_id),
          target: String(response.data.to_stakeholder_id),
          style: {strokeWidth: lineWidth},
          data: {
            purpose: response.data.link_property.purpose,
            strength: response.data.link_property.strength
          },
          type: "customLine"
        }
        dispatch(updateConnection(connectionData))
      } else {
        dispatch(handleError(response.data))
      }
    })
    .catch(error => dispatch(handleError(error)))
}

export const removeStakeholderFromProject = (projId, stkId, with_return_stk = false) => (dispatch) => {
  return axios.delete(`/projects/${projId}/stakeholders/${stkId}`,{params: {with_return_stk}})
    .then(response => {
      if (response.status === 200) {
        dispatch(getProjectStakeholdersTC(projId))
        return true
      } else {
        dispatch(handleError(response.data))
        return false
      } 
    })
    .catch(error => dispatch(handleError(error)))
}

export const addStakeholderToProject = (projId, stkId, stakeholderData, isNewCompany, userId) => async(dispatch, getState) => {
  const addOrganization = () => {
    const company = {
      address: '',
      color: '#363636',
      isActive: true,
      location: '',
      name: stakeholderData.newOrganizationName,
      nickname: stakeholderData.newOrganizationName,
      tags: ''
    }
    return axios.post(`/organizations`, company)
      .then((resp) => {
        if (resp.status === 201) {
          dispatch(addOrganizationAC(resp.data.organization))
          return resp.data.organization.orgId
        } else {
          dispatch(handleError(resp.data))
          throw resp.data 
        }
      })
      .catch((error) => {
        dispatch(handleError(error))
        throw error 
      })
  }
  const saveStakeholder = (orgId) => {
    const data = {
      department: "",
      emails: [],
      empType: "",
      firstName: stakeholderData.firstName,
      firstNameKnownAs: stakeholderData.firstNameKnownAs,
      grade: "",
      isActive: true,
      isSensitiveContact: false,
      jobTitle: "",
      lastName: stakeholderData.lastName,
      linkedInProfile: "",
      notes: "",
      orgId: orgId,
      organizationConfirmed: true,
      phones: [],
      projectRelations: [
        {
          influenceLvl: stakeholderData.influence,
          interestLvl: stakeholderData.interest,
          isDisrupter: null,
          projId: projId,
          roleDescription: "",
          roleTitle: stakeholderData.projectRole,
          supportLvl: stakeholderData.support,
          workloadPct: null,
          team: stakeholderData.team || '',
          isProjectTeamMember: true
        }
      ],
      tags: ""
    }
    return axios.post(`/stakeholders`, data)
      .then(response => {
        if (response.status === 201) {
          return axios.post(`/projects/${projId}/stakeholders/${response.data.stkId}`)
            .then(response => {
              response.status !== 200 && dispatch(handleError(response.data))
              return response.data
            })
        } else {
          dispatch(handleError(response.data))
          throw response.data
        }
      })
  }
  try {
    if (!!stkId) {
      return axios.put(`/stakeholders/${stkId}`, stakeholderData)
        .then(response => {
          if (response.status === 200) {
            dispatch(updateStakeholder(response.data))
            return dispatch(getProjectStakeholdersTC(getState().projectsReducer.selectedProject))
              .then(() => {return true})
          } else {
            dispatch(handleError(response.data))
            throw response.data
          }
        })
    } else {
      let orgId = isNewCompany ? await addOrganization() : stakeholderData.organizationId
      if ((isNewCompany && orgId) || !isNewCompany) {
        const updatedProjectData = await saveStakeholder(orgId)
        if (!!updatedProjectData ) {
          dispatch(getProjectStakeholdersTC(getState().projectsReducer.selectedProject))
        }
        return !!updatedProjectData
      } else {
        throw new Error('Something went wrong')
      }
    }
  } catch(error) {
    dispatch(handleError(error))
    throw error
  }
}

export const addAnnotationTC = (annotation) => (dispatch, getState) => {
  return axios.post(`/user-project-comments`, annotation)
    .then(response => {
      if (response.status === 201) {
        const userId = getState().authReducer.userId
        const selectedProjectData = getState().projectsReducer.selectedProjectData
        
        const userViewPositions = [...selectedProjectData?.userView?.positions || [], {id: 'annotation-'+response.data.id, position: annotation.position}]
        const projectViewPositions = annotation.isProjectViewVisible ? [...selectedProjectData?.projectView?.positions || [], {id: 'annotation-'+response.data.id, position: annotation.position}] : selectedProjectData?.projectView?.positions

        dispatch(editProject({
          ...selectedProjectData,
          userView: {positions: userViewPositions},
          projectView: {positions: projectViewPositions},
          userId: userId
        }, true))
        
        dispatch(addAnnotation(response.data))
        return response.data
      } else {
        dispatch(handleError(response.data))
      }
    })
    .catch(error => dispatch(handleError(error)))
}

export const getAnnotationsTC = (projId, isProjectViewVisible) => (dispatch) => {
  return axios.get(`/user-project-comments/projects/${projId}`)
    .then(response => {
      if (response.status === 200) {
        dispatch(setAnnotations(response.data.userProjectCommentList))
      } else {
        dispatch(handleError(response.data))
      }
    })
    .catch(error => dispatch(handleError(error)))
}

export const editAnnotationTC = (annotationId, data) => (dispatch, getState) => {
  return axios.put(`/user-project-comments/${annotationId}`, data)
    .then(response => {
      if (response.status === 200) {
        const selectedProjectData = getState().projectsReducer.selectedProjectData
        const userId = getState().authReducer.userId
        if (!!data.isProjectViewVisible && (!selectedProjectData?.projectView?.position?.some(p => p.id === 'annotation-'+data.id))) {
          dispatch(editProject({
            ...selectedProjectData,
            projectView: {positions: [...selectedProjectData?.projectView?.positions, selectedProjectData?.userView?.positions?.find(p => p.id === 'annotation-'+data.id)]},
            userId: userId
          }, true))
        }
        dispatch(updateAnnotations(response.data))
        return true
      } else {
        dispatch(handleError(response.data))
      }
    })
    .catch(error => dispatch(handleError(error)))
}

export const deleteAnnotationTC = (annotationId) => (dispatch, getState) => {
  return axios.delete(`/user-project-comments/${annotationId}`)
    .then(response => {
      if (response.status === 200) {
        dispatch(deleteAnnotation(annotationId))
        const selectedProjectData = getState().projectsReducer.selectedProjectData
        const userId = getState().authReducer.userId
        const updatedPositionsUserView = selectedProjectData?.userView?.positions?.filter(p => p.id !== 'annotation-'+annotationId) || []
        const updatedPositionsProjectView = selectedProjectData?.projectView?.positions?.filter(p => p.id !== 'annotation-'+annotationId) || []

        dispatch(editProject({
          ...selectedProjectData,
          projectView: {positions: updatedPositionsProjectView},
          userView: {positions: updatedPositionsUserView},
          userId: userId
        }, true))
        return true
      } else {
        dispatch(handleError(response.data))
      }
    })
    .catch(error => dispatch(handleError(error)))
}

export const undoDeleteAnnotationTC = (annotation) => (dispatch) => {
  return axios.post(`/user-project-comments`, annotation)
    .then(response => {
      if (response.status === 201) {
        dispatch(addAnnotation(response.data))
        return true
      } else {
        dispatch(handleError(response.data))
      }
    })
    .catch(error => dispatch(handleError(error)))
}

export const undoDeletingConnectionTC = (deletedItems, isStrengthViewOn, projId, stakeholders) => (dispatch) => {
  const requests = deletedItems.map(item => {
    const sourceStk = stakeholders.find(stk => Number(stk.stakeholderId) === Number(item.source))
    const connectionData = {
      source: item.source,
      strength: item.data.strength,
      purpose: item.data.purpose,
      target: item.target,
      orgId: sourceStk.orgId,
      projId,
    }
    return dispatch(createConnection(connectionData, isStrengthViewOn))
  })
  return Promise.all(requests)
    .catch(error => dispatch(handleError(error)))
}

export const undoDeletingStakeholderTC = (deletedItem, projId, stakeholders, positions) => (dispatch, getState) => {
  const stkData = stakeholders.find(stk => stk.stakeholderId === Number(deletedItem.id))
  const updatedStakeholder = {...stkData, projectRelations: stkData?.projects || stkData?.projectRelations}
  const projectData = getState().projectsReducer.selectedProjectData
  const userId = getState().authReducer.userId
  return Promise.all([
    dispatch(addStakeholderToProject(projId, stkData.stakeholderId, updatedStakeholder)),
    dispatch(editProject({
      ...projectData,
      projectView: {
        positions: [
          ...projectData?.projectView?.positions,
          ...(positions.projectViewPosition ? [{id: deletedItem.id, position: positions.projectViewPosition}] : [])
        ]
      },
      userView: {positions: [
        ...projectData.userView?.positions,
        ...(positions.userViewPosition ? [{id: deletedItem.id, position: positions.userViewPosition}] : [])
      ]},
      userId
    }, true))
  ]).catch(error => dispatch(handleError(error)))
}

export const undoDeletingTC = (deletedItems, isStrengthViewOn, projId, stakeholders) => (dispatch) => {
  if (deletedItems.length === 1 && deletedItems[0].id.includes('line')) {
    return dispatch(undoDeletingConnectionTC(deletedItems, isStrengthViewOn, projId, stakeholders))
  } else if (deletedItems.length === 1 && deletedItems[0].id.includes('annotation')) {
    return dispatch(undoDeleteAnnotationTC(deletedItems[0]?.data?.data))
  } else if (!deletedItems.some(item => item.id.includes('line'))) {
    const stk = deletedItems.find(item => !item.id.includes('line'))
    const positions = deletedItems.find(item => item.id.includes('positions'))
    return dispatch(undoDeletingStakeholderTC(stk, projId, stakeholders, positions))
  } else {
    const stk = deletedItems.find(item => !item.id.includes('line'))
    const connections = deletedItems.filter(item => item.id.includes('line'))
    const positions = deletedItems.find(item => item.id.includes('positions'))
    return Promise.all([
      dispatch(undoDeletingStakeholderTC(stk, projId, stakeholders, positions)),
      dispatch(undoDeletingConnectionTC(connections, isStrengthViewOn, projId, stakeholders))
    ]).catch(error => dispatch(handleError(error)))
  }
}

export default stakeholderMapReducer
