import React, { useState } from 'react'
import { useMediaQuery } from 'react-responsive'
import classes from './ProjectSupportChart.module.css'
import UserInfoArea from '../../common/UserInfoArea/UserInfoArea'
import ProjectSupportChartArea from './ProjectSupportChartArea'
import Spinner from '../../common/Spinner/Spinner'
import ErrorAlert from '../../common/ErrorAlert/ErrorAlert'
import {ReactComponent as NoDataImg} from './../../../img/noDataImg.svg'
import Switch from './../../common/Switch/Switch'
import MobileHeaderTools from '../../common/MobileHeaderTools/MobileHeaderTools'
import ProjectSelect from '../../common/ProjectSelect/ProjectSelect'

const ProjectSupportChart = (props) => {
  const [isToolsVisible, setIsToolsVisible] = useState(false)
  const isMobileScreen = useMediaQuery({ query: '(min-width: 360px) and (max-width: 766px)' })
  const [showAllNames, setShowAllNames] = useState(false)

  if (props.isEmpty) {
    return (
      <>
        <header className={`${classes.header} ${classes.emptyPageHeader}`}>
          {/*<MobileNavBar className={classes.mobileHeader} />*/}
          <UserInfoArea />
        </header>
        <div className={classes.emptyPage}>
          <NoDataImg />
        </div>
      </>
    )
  }
  return (
    <div className={classes.pageWrapper} style={{height: isMobileScreen ? `${window.innerHeight - 30}px` : ''}}>
      {props.isLoaded ? (
        <>
          <header className={classes.header}>
            <div style={{display:'flex', alignItems:'center'}}>
              {/*<MobileNavBar className={classes.mobileHeader} setIsToolsVisible={setIsToolsVisible}/>*/}
              <div className={classes.toolsArea}>
                <HeaderTools
                  selectProjectOptions={props.selectProjectOptions}
                  selectProject={props.selectProject}
                  selectedProject={props.selectedProject}
                  isProjectTeamContactOn={props.isProjectTeamContactOn}
                  setIsProjectTeamContactOn={props.setIsProjectTeamContactOn}
                  showAllNames={showAllNames}
                  setShowAllNames={setShowAllNames}
                />
              </div>
            </div>
            <UserInfoArea onExport={props.exportChart}/>
          </header>
          <ProjectSupportChartArea 
            connections={props.connections}
            people={props.people}
            isConnectionStrengthOn={props.isConnectionStrengthOn}
            showAllNames={showAllNames}
          />
          {props.error && 
            <ErrorAlert 
              errorMessage={props.error?.message || 'An unexpected error has occurred'} 
              onClose={() => props.setError(null)}
            />
          }
          <MobileHeaderTools
            isToolsVisible={isToolsVisible}
            setIsToolsVisible={setIsToolsVisible}
            content={
              <HeaderTools
                selectProjectOptions={props.selectProjectOptions}
                selectProject={props.selectProject}
                selectedProject={props.selectedProject}
                isProjectTeamContactOn={props.isProjectTeamContactOn}
                setIsProjectTeamContactOn={props.setIsProjectTeamContactOn}
                setIsToolsVisible={setIsToolsVisible}
              />
            }
          />
        </>
      ) : (
        <Spinner className={classes.spinner}/>
      )}
    </div>
  )
}

const HeaderTools = (props) => {
  const isMobileScreen = useMediaQuery({ query: '(min-width: 360px) and (max-width: 766px)' })
  return (
    <>
      <ProjectSelect
        options={props.selectProjectOptions}
        onSelect={(value) => {
          props.selectProject(value)
          !!isMobileScreen && props.setIsToolsVisible(false)
        }}
        style={isMobileScreen ? {marginBottom:'20px'} : {marginRight:'20px'}}
        value={props.selectedProject}
        className={classes.headerSelect}
        showCompleted
      />
      {isMobileScreen ? (
        <>
          <div className={classes.switchWrapperMobile}>
            <span style={{fontSize: '12px'}}>Project team contacts</span>
            <Switch 
              checked={props.isProjectTeamContactOn} 
              onChange={() => {
                props.setIsProjectTeamContactOn(!props.isProjectTeamContactOn)
                props.setIsToolsVisible(false)
              }} 
            />
          </div>
          <div className={classes.switchWrapperMobile}>
            <span style={{fontSize: '16px'}}>Show All Names</span>
            <Switch
              onChange={() => props.setShowAllNames(!props.showAllNames)}
              checked={props.showAllNames}
            />
          </div>
        </>
      ) : (
        <>
          <div className={classes.switchWrapper}>
            <span style={{fontSize: '14px'}}>PROJECT TEAM CONTACTS</span>
            <Switch
              checked={props.isProjectTeamContactOn} 
              onChange={() => props.setIsProjectTeamContactOn(!props.isProjectTeamContactOn)} 
              leftLabel='OFF'
              rightLabel='ON'
            />
          </div>
          <div className={classes.switchWrapper} style={{marginLeft:'30px'}}>
            <span style={{fontSize: '14px'}}>SHOW ALL NAMES</span>
            <Switch
              onChange={() => props.setShowAllNames(!props.showAllNames)}
              checked={props.showAllNames}
              leftLabel='OFF'
              rightLabel='ON'
            />
          </div>
        </>
      )}
       
      {/* <div className={classes.switchWrapper}>
        <span className={classes.strengthSwitchText}>
          Relationships Strength
        </span>
        <Switch 
          checked={props.isConnectionStrengthOn} 
          onChange={() => props.setIsConnectionStrengthOn(!props.isConnectionStrengthOn)} 
          leftLabel='OFF'
          rightLabel='ON'
        />
      </div> */}
    </>
  )
}

export default ProjectSupportChart
