import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useState } from 'react'
import { Divider, Popconfirm, Tooltip } from 'antd'
import { useMediaQuery } from 'react-responsive'
import classes from './Annotations.module.css'
import {ReactComponent as DeleteIcon} from './../../../../img/stakeholderMap/annotations/deleteIcon.svg'
import {ReactComponent as EditIcon} from './../../../../img/stakeholderMap/annotations/editIcon.svg'
import {ReactComponent as ExclamationCircle} from './../../../../img/icons/exclamationCircle.svg'
import { getUserList } from '../../../../store/usersReducer'
import { deleteAnnotationTC } from '../../../../store/stakeholderMapReducer'
import {ReactComponent as AnnotationsIcon} from './../../../../img/icons/annotationsIcon.svg'
import {ReactComponent as AnnotationsCloseIcon} from './../../../../img/icons/annotationsCloseIcon.svg'
import { useStoreState } from 'react-flow-renderer'

const Annotations = (props) => {
  const dispatch = useDispatch()
  const isMobileScreen = useMediaQuery({ query: '(min-width: 360px) and (max-width: 766px)' })
  const [isAnnotationsShown, setIsAnnotationsShown] = useState(false)

  useEffect(() => {
    dispatch(getUserList(false))
  }, [dispatch])

  if (!isMobileScreen) {
    return <AnnotationsData {...props} />
  }

  return (
    <>
      {isAnnotationsShown ? (
        <>
          <AnnotationsData {...props} />
          <AnnotationsCloseIcon
            onClick={() => setIsAnnotationsShown(false)}
            className={classes.annotationMobileIcon}
          />
        </>
      ) : (
        <AnnotationsIcon
          onClick={() => setIsAnnotationsShown(true)}
          className={classes.annotationMobileIcon}
        />
      )}
    </>
  )
}

const AnnotationsData = (props) => {
  const dispatch = useDispatch()
  const annotations = useSelector((state) => state.stakeholderMapReducer.annotations)
  const userId = useSelector(state => state.authReducer.userId)
  const users = useSelector(state => state.usersReducer.userList)
  const selectedElements = useStoreState((state) => state.selectedElements)
  const mapNodes = useStoreState((state) => state.nodes)

  const [selectedAnnotationId, setSelectedAnnotationId] = useState()

  useEffect(() => {
    if (selectedElements?.[0]?.id.includes('annotation')) {
      setSelectedAnnotationId(selectedElements?.[0].data.data.id)
    } else {
      setSelectedAnnotationId(null)
    }
  }, [selectedElements])

  const annotationsToShow = props.selectedView === 'userView' 
    ? annotations.filter(item => item.userId === userId)
    : annotations.filter(item => !!item.isProjectViewVisible)

  return (
    <div className={classes.annotationsWrapper}>
    {!!annotationsToShow.length ? (
      <>
        {annotationsToShow.map((item, index) => {
          const authorData = users.find(user => user.userId === item.userId)
          return (
            <div key={item.id}>
              {index !== 0 && <Divider style={{margin: '0px'}}/>}
              <div
                className={`
                  ${classes.annotationItemWrapper}
                  ${item.id === selectedAnnotationId ? classes.selected : ''}
                `}
                onClick={() => {
                  const selectedAnnotationNode = mapNodes.find(n => n.id === 'annotation-'+item.id)
                  props.changeSelectedElement(selectedAnnotationNode)
                  props.reactFlowSelectElement([selectedAnnotationNode])
                }}
              >            
                <div style={{display: 'flex', alignItems: 'center', marginBottom: '6px'}}>
                  <div className={classes.author}>
                    {authorData?.firstName} {authorData?.lastName}
                  </div>
                  {props.selectedView === 'userView' &&
                    <>
                      <Popconfirm
                        title={'Are you sure to delete this annotation?'}
                        onConfirm={() => dispatch(deleteAnnotationTC(item.id))}
                        okText='Yes'
                        cancelText='No'
                        icon={<ExclamationCircle />}
                        cancelButtonProps={{type: 'primary', style:{background: '#1890FF'}}}
                        okButtonProps={{type: 'default'}}
                      >
                        <DeleteIcon
                          style={{marginLeft: '10px'}}
                          className={classes.annotationItemIcon}
                        />
                      </Popconfirm>
                      
                      <EditIcon
                        style={{marginLeft: '6px'}}
                        className={classes.annotationItemIcon}
                        onClick={() => props.setIsAnnotationModalOpen({isOpen: true, editingData: item})}
                      />
                    </>
                  }
                </div>
                <div className={classes.annotationText}>
                  {item.comment}
                </div>
              </div>
            </div>
          )
        })}
      </>
    ) : (
      <div style={{textAlign:'center'}}>
        No annotations here
      </div>
    )}
  </div>
  )
}

export const AnnotationMapCard = ({annotation}) => {
  const users = useSelector(state => state.usersReducer.userList)

  const [isTooltipVisible, setIsTooltipVisible] = useState(false)
  const [isCardMoving, setIsCardMoving] = useState(false)

  const authorData = users.find(user => user.userId === annotation.userId)
  
  return (
    <Tooltip
      color='transparent'
      placement='topLeft'
      className='annotation-icon'
      visible={isTooltipVisible && !isCardMoving}
      overlayInnerStyle={{
        boxShadow: 'none',
        border: 'none',
        minHeight: '0px',
        padding: '0px',
        color: 'black',
        width: 'max-content',
      }}
      title={
        <div className={classes.annotationMapTooltip}>
          <div className={classes.author}>
            {authorData?.firstName} {authorData?.lastName}
          </div>
          <div>
            {annotation.comment} 
          </div>
        </div>
      }
    >
      <svg
        width="23"
        height="20"
        viewBox="0 0 23 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        x={annotation.x || null} 
        y={annotation.y || null} 
        onMouseEnter={() => setIsTooltipVisible(true)}
        onMouseLeave={() => setIsTooltipVisible(false)}
        onMouseDown={() => setIsCardMoving(true)} 
        onMouseUp={() => setIsCardMoving(false)}
        className='annotation-icon'
      >
        <path className='annotation-icon' d="M11.5 0.751953C5.97656 0.751953 1.5 4.10742 1.5 8.25195C1.5 11.1816 3.74609 13.7129 7.01172 14.9473L6.11008 17.3484C5.75288 18.2997 6.8775 19.1156 7.67094 18.4808L11.1016 15.7363C11.2344 15.7402 11.3633 15.752 11.5 15.752C17.0234 15.752 21.5 12.3965 21.5 8.25195C21.5 4.10742 17.0234 0.751953 11.5 0.751953ZM6.5 9.91992C5.58203 9.91992 4.83203 9.17383 4.83203 8.25195C4.83203 7.33008 5.57812 6.58398 6.5 6.58398C7.41797 6.58398 8.16797 7.33008 8.16797 8.25195C8.16797 9.17383 7.41797 9.91992 6.5 9.91992ZM11.5 9.91992C10.582 9.91992 9.83203 9.17383 9.83203 8.25195C9.83203 7.33008 10.5781 6.58398 11.5 6.58398C12.418 6.58398 13.168 7.33008 13.168 8.25195C13.168 9.17383 12.418 9.91992 11.5 9.91992ZM16.5 9.91992C15.582 9.91992 14.832 9.17383 14.832 8.25195C14.832 7.33008 15.5781 6.58398 16.5 6.58398C17.418 6.58398 18.168 7.33008 18.168 8.25195C18.168 9.17383 17.418 9.91992 16.5 9.91992Z" stroke="black" fill="black" strokeWidth="1.5"/>
      </svg>
    </Tooltip>
  )
}

export default Annotations
