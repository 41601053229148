import React, { useEffect, useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
import { DataGridPro, GridToolbarColumnsButton, GridToolbarContainer, useGridApiRef} from '@mui/x-data-grid-pro'
import { Popconfirm, Tooltip } from 'antd'
import { useSelector, useDispatch } from 'react-redux'
import classes from './Contacts.module.css'
import {tableWidth} from './../common/sizes'
import {ReactComponent as EditIcon} from './../../img/icons/editIcon.svg'
import {ReactComponent as DeleteIcon} from './../../img/icons/deleteIcon.svg'
import TableContactsColumn from '../common/TableContactsColumn/TableContactsColumn'
import TableProjectsColumn from '../common/TableProjectsColumn/TableProjectsColumn'
import { getCurrentUserRoles } from '../../helpers/localStorage'
import { setStakeholders } from './../../store/stakeholdersReducer'
import {ReactComponent as ExclamationCircle} from './../../img/icons/exclamationCircle.svg'
import {ReactComponent as WarningIcon} from './../../img/icons/warningIcon.svg'

const nameColumnWidth = tableWidth * 0.28
const columnWidth = tableWidth * 0.18
const smallColumnWidth = tableWidth * 0.12

export const getColumns = (deleteContact, organizations, dispatch) => {
  const currentUserRoles = getCurrentUserRoles()
  const isUser = currentUserRoles.length === 1 && currentUserRoles?.some(role => role.code.toLowerCase() === 'user')
  return (
    [
      { field: 'fullName',
        headerName: 'FullName',
        width: nameColumnWidth,
      },
      { field: 'actions', 
        headerName: 'Actions', 
        sortable: false, 
        width: smallColumnWidth,
        renderCell: (params) => {
          return (
            <div style={{display:'flex', alignItems:'center'}} >
              {isUser && params.row.isSensitiveContact ? (
                <></>
              ) : (
                <>
                  <Link to={`/Contacts/edit/${params.row.stakeholderId}`} style={{lineHeight:1}} >
                    <EditIcon 
                      className={classes.tableIcon} 
                      style={{marginRight: '15px'}}
                      onClick={() => dispatch(setStakeholders([]))}
                    />
                  </Link>
                  <ConfirmDelete
                    deleteContact={deleteContact}
                    stakeholderId={params.row.stakeholderId}
                  />
                </>
              )}
            </div>
          )
        }
      },
      { field: 'orgId', 
        headerName: 'Organisation', 
        width: columnWidth, 
        renderCell: (params) => {
          const organizationData = organizations.find(org => org.orgId === params.row.orgId)
          return (
            <div style={{display:'flex', alignItems:'center'}}>
              <span>{organizationData?.name}</span>
              {!params.row?.organizationConfirmed &&
                <Tooltip
                  title='The organisation needs to be checked'
                  overlayInnerStyle={{textAlign:'center'}}
                >
                  <WarningIcon style={{marginLeft:'5px'}}/>
                </Tooltip>
              }
            </div>
          )
        }
      },
      { field: 'empType',
        headerName: 'Contact type',
        width: columnWidth,
      },
      { field: 'jobTitle', 
        headerName: 'Job title', 
        width: columnWidth, 
      },
      { field: 'department', 
        headerName: 'Department', 
        width: columnWidth, 
        hide: true,
      },
      { field: 'firstName', 
        headerName: 'First name', 
        width: columnWidth, 
        hide: true
      },
      { field: 'firstNameKnownAs', 
        headerName: 'Known as', 
        width: columnWidth,  
        hide: true
      },
      { field: 'lastName', 
        headerName: 'Last name', 
        width: columnWidth,  
        hide: true
      },
      { field: 'projects', 
        headerName: 'Projects', 
        width: columnWidth, 
        sortComparator: (projects1, projects2) => {
          const proj1 = projects1?.length ? projects1 : []
          const proj2 = projects2?.length ? projects2 : []
          return proj1?.length - proj2?.length
        },
        renderCell: (params) => {
          return (
            !!params.row.projects?.length && <TableProjectsColumn projects={params.row.projects} />
          )
        },
        hide: true,
      },
      { field: 'phones', 
        headerName: 'Phones', 
        width: columnWidth, 
        sortComparator: (phones1, phones2) => {
          return phones1?.length - phones2?.length
        },
        renderCell: (params) => {
          return (
            isUser && params.row.isSensitiveContact ? (
              <></>
            ) : (
              <TableContactsColumn data={params.row.phones} infoName='phone'/>
            )
          )
        },
        hide: true,
      },
      { field: 'emails', 
        headerName: 'Emails', 
        width: columnWidth, 
        sortComparator: (emails1, emails2) => {
          return emails1?.length - emails2?.length
        },
        renderCell: (params) => {
          return (
            isUser && params.row.isSensitiveContact ? (
              <></>
            ) : (
              <TableContactsColumn data={params.row.emails} infoName='email'/>
            )
          )
        },
        hide: true
      },
      { field: 'linkedInProfile',
      headerName: 'LinkedIn',
      width: smallColumnWidth,
      hide: true,
      renderCell: (params) => {
        return (
          params.row.linkedInProfile &&
          <>
            {isUser && params.row.isSensitiveContact ? (
              <></>
            ) : (
              <a href={params.row.linkedInProfile} style={{color:'black'}}>
                {params.row.linkedInProfile}
              </a>
            )}
          </>
        )
      }},
      { field: 'grade', headerName: 'Grade', width: columnWidth, hide: true},
      { field: 'notes', headerName: 'Notes', width: columnWidth, hide: true},
      { field: 'isActive', 
        headerName: 'Status', 
        width: smallColumnWidth, 
        hide: true,
        renderCell: (params) => {
          const color = params.row.isActive ? 'rgba(194, 232, 18, 0.6)' : 'rgba(246, 88, 88, 0.6)'
          return (
            <div className={classes.tableIsActiveColumn} style={{backgroundColor: color}}>
              {params.row.isActive ? 'Active' : 'Inactive'}
            </div>
          )
        }
      },
      { field: 'isSensitiveContact',
        headerName: 'Sensitive contact',
        width: columnWidth,
        hide: true,
        renderCell: (params) => (params.row.isSensitiveContact ? 'yes' : 'no'),
      }
    ]
  )
}

const ContactsTable = (props) => {
  const dispatch = useDispatch()
  const tableAppearance = useSelector(state => state.tablesReducer?.contactsTable)
  const columns = useMemo(() => {
    return getColumns(props.deleteContact, props.organizations, dispatch)
    // eslint-disable-next-line
  }, [props.organizations, tableAppearance])
  const apiRef = useGridApiRef()

  useEffect(() => {
    apiRef.current.subscribeEvent('columnHeaderDragEnd', () => saveTableColumns())
    // eslint-disable-next-line
  }, [apiRef])

  const saveTableColumns = () => {
    const stateColumns = apiRef.current.getAllColumns()
    const colsData = Object.keys(stateColumns).map(key => stateColumns[key])
    props.setTableColumns('contactsTable', colsData)
  }

  return (
    <div className={classes.tableWrapper}>
      <DataGridPro
        apiRef={apiRef}
        onColumnWidthChange={(e) => saveTableColumns()}
        getRowId={e => e.stakeholderId || null}
        rows={props.contacts || []}   
        columns={tableAppearance.length ? tableAppearance :columns}  
        rowCount={props.contacts?.length || 0}
        components={{
          Toolbar: CustomToolbar,
        }}
        style={{borderRadius:'15px', border:'none'}}
      />
    </div>
  )
}

const CustomToolbar = () => {
  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
    </GridToolbarContainer>
  )
}

const ConfirmDelete = ({deleteContact, stakeholderId}) => {
  const [confirmMessageNumber, setConfirmMessageNumber] = useState(0)
  return (
    <>
      {confirmMessageNumber === 0 &&
        <DeleteIcon className={classes.tableIcon} onClick={() => setConfirmMessageNumber(1)}/>
      }
      {confirmMessageNumber === 1 &&
        <Popconfirm
          title={"Are you sure to delete this contact?"}
          onConfirm={() => setConfirmMessageNumber(2)}
          onCancel={() => setConfirmMessageNumber(0)}
          okText="Yes"
          cancelText="No"
          icon={<ExclamationCircle />}
          cancelButtonProps={{type: 'primary', style:{background: '#1890FF'}}}
          okButtonProps={{type: 'default'}}
          visible={confirmMessageNumber === 1}
        >
          <DeleteIcon className={classes.tableIcon}/>
        </Popconfirm>
      }
      {confirmMessageNumber === 2 &&
        <Popconfirm
          title="Should the linked user be deleted?"
          onConfirm={() => {deleteContact(stakeholderId, true); setConfirmMessageNumber(0)}}
          onCancel={() => {deleteContact(stakeholderId, false); setConfirmMessageNumber(0)}}
          okText="Yes"
          cancelText="No"
          icon={<ExclamationCircle />}
          cancelButtonProps={{type: 'primary', style:{background: '#1890FF'}}}
          okButtonProps={{type: 'default'}}
          visible={confirmMessageNumber === 2}
        >
          <DeleteIcon className={classes.tableIcon}/>
        </Popconfirm>
      }
    </>
  )
}

export default ContactsTable
