import React, { useEffect, useState } from 'react'
import { DataGridPro, GridToolbarColumnsButton, GridToolbarContainer, useGridApiRef } from '@mui/x-data-grid-pro'
import { useDispatch, useSelector } from 'react-redux'
import { PrimaryButton } from './../../common/buttons/buttons'
import classes from './ProjectDetailsForm.module.css'
import {tableWidth} from '../../common/sizes'
import EditableNumber from '../../common/EditableNumber/EditableNumber'
import { setTableColumns } from '../../../store/tablesReducer'
import AddContactsModal from './AddContactsModal'
import { ReactComponent as EditIcon } from './../../../img/icons/editIcon.svg'
import { Link, useParams } from 'react-router-dom'

const nameColumnWidth = tableWidth * 0.24
const columnWidth = tableWidth * 0.15
const smallColumnWidth = tableWidth * 0.1

const ContactsTable = (props) => {
  const {projId} = useParams()

  const [page, setPage] = useState(0)
  const [isAddingContacts, setIsAddingContacts] = useState(false)

  useEffect(() => {
    setPage(0)
  }, [props?.tableRows])

  const columns = [
    { field: 'fullName', headerName: 'FullName', width: nameColumnWidth},
    { field: 'actions', 
      headerName: 'Actions', 
      width: smallColumnWidth, 
      renderCell: (params) => {
        return (
          <Link to={`/Projects/edit/${projId}/StakeholderForm/${params.id}`} >
            <EditIcon style={{marginTop: '20px'}}/>
          </Link>
        )
      }
    },
    { field: 'orgId', 
      headerName: 'Organisation', 
      width: columnWidth, 
      renderCell: (params) => {
        const organizationData = props.organizations.find(org => org.orgId === params.row.orgId)
        return <span style={{width:'100%'}}>{organizationData?.name}</span>
      }
    },
    { field: 'team',
      headerName: 'Workstream',
      width: columnWidth,
      renderCell: (params) => {
        return (
          <div style={{overflow:'auto'}}>
            {params.row.team}
          </div>
        )
      },
    },
    { field: 'roleTitle',
      headerName: 'Role',
      width: columnWidth,
      renderCell: (params) => {
        return (
          <div style={{width:'100%', overflow: 'auto'}}>
            {params.row.roleTitle}
          </div>
        )
      }
    },
    { field: 'roleDescription',
      headerName: 'Role description',
      width: columnWidth,
      renderCell: (params) => {
        return (
          <div style={{width:'100%', overflow: 'auto'}}>
            {params.row.roleDescription}
          </div>
        )
      }
    },
    { field: 'influenceLvl',
      headerName: 'Influence',
      width: smallColumnWidth,
      renderCell: (params) => {
        return (
          <EditableNumber
            value={params.row.influenceLvl}
            itemId={params.row.stakeholderId}
            minValue={1}
            maxValue={10}
            updateData={props.changeContactData}
            valueKey='influenceLvl'
          />
        )
      }
    },
    { field: 'interestLvl',
      headerName: 'Interest',
      width: smallColumnWidth,
      renderCell: (params) => {
        return (
          <EditableNumber 
            value={params.row.interestLvl}
            itemId={params.row.stakeholderId}
            minValue={1}
            maxValue={10}
            updateData={props.changeContactData}
            valueKey='interestLvl'
          />
        )
      }
    },
    { field: 'supportLvl',
      headerName: 'Support',
      width: smallColumnWidth,
      renderCell: (params) => {
        return (
          <EditableNumber 
            value={params.row.supportLvl}
            itemId={params.row.stakeholderId}
            minValue={-5}
            maxValue={5}
            updateData={props.changeContactData}
            valueKey='supportLvl'
          />
        )
      }
    },
    { field: 'isDisrupter',
      headerName: 'Disrupter',
      width: smallColumnWidth,
      renderCell: (params) => {
        return (
          <div>
            {params.row.isDisrupter === null ? 'Unknown' : params.row.isDisrupter ? 'yes' : 'no'}
          </div>
        )
      }
    },
    { field: 'workloadPct',
      headerName: 'FTE',
      width: smallColumnWidth,
      renderCell: (params) => {
        return (
          <div>
            {params.row.workloadPct === null 
              ? 'Unknown' 
              : params.row.workloadPct === 'n/a' 
                ? 'n/a' 
                : params.row.workloadPct
            }
          </div>
        )
      }
    },
    { field: 'isProjectTeamMember', 
      headerName: 'Project Team Member', 
      width: smallColumnWidth,
      renderCell: (params) => {
        return (
          <div>
            {params.row.isProjectTeamMember ? 'yes' : 'no'}
          </div>
        )
      }
    },
  ]

  const tableAppearance = useSelector(state => state.tablesReducer?.editProjectContactsTable)
  const projectStakeholders = useSelector(state => state.projectsReducer.projectStakeholders)
  const apiRef = useGridApiRef()
  const dispatch = useDispatch()

  useEffect(() => {
    apiRef.current.subscribeEvent('columnHeaderDragEnd', () => saveTableColumns())
    // eslint-disable-next-line
  }, [apiRef])

  const saveTableColumns = () => {
    const stateColumns = apiRef?.current?.getAllColumns()
    const colsData = Object.keys(stateColumns).map(key => stateColumns[key])
    dispatch(setTableColumns('editProjectContactsTable', colsData))
  }

  return (
    <div className={classes.tableWrapper}>
      <PrimaryButton
        className={classes.primaryButton}    
        onClick={() => setIsAddingContacts(true)}
        style={{position: 'absolute', top: '-60px', right: '0px', height: 'auto', padding: '7px 10px'}}
        text='ADD CONTACTS' 
      />
      <DataGridPro
        getRowId={e => e.stakeholderId || null}
        rows={projectStakeholders || []} 
        apiRef={apiRef}
        onColumnWidthChange={(e) => saveTableColumns()}
        columns={tableAppearance.length ? tableAppearance : columns}
        rowCount={projectStakeholders?.length || 0}
        pagination
        rowsPerPageOptions={[10]}
        setPage={page}
        onPageChange={(e) => setPage(e.pageCount - 1)}
        pageSize={10}
        components={{
          Toolbar: () => <CustomToolbar />,
          NoRowsOverlay: () => (
            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%'}}>
              {props.tableMessage}
            </div>
          ),
        }}
        disableSelectionOnClick
        style={{borderRadius:'15px', border:'none'}}
      />
      <AddContactsModal
        isOpen={isAddingContacts}
        handleClose={() => setIsAddingContacts(false)}
        isStakeholdersLoading={props.isStakeholdersLoading}
      />
    </div>
  )
}

const CustomToolbar = () => {
  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
    </GridToolbarContainer>
  )
}

export default ContactsTable
