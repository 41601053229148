import React, { useState } from 'react'
import classes from './FloatLabel.module.css'

const FloatLabel = (props) => {
  const [focus, setFocus] = useState(false)
  let { label, value, placeholder, required, isFocused, hasError, style, hasWarning } = props

  if (!placeholder) placeholder = label

  const isOccupied = focus || (value && value.length !== 0)

  const labelClass = isOccupied 
    ? `${classes.label} ${classes.asLabel} ${isFocused ? classes.focused : ''} ${hasError ? classes.error : ''} ${hasWarning ? classes.warning : ''}`
    : `${classes.label} ${classes.asPlaceholder}`

  const requiredMark = required ? <span className={classes.textDanger}>*</span> : null

  return (
    <div
      className={classes.floatLabel}
      onBlur={() => setFocus(false)}
      onFocus={() => setFocus(true)}
      style={style}
    >
      {props.input}
      <label className={`${classes.label} ${classes.asLabel} ${isFocused ? classes.focused : ''} ${hasError ? classes.error : ''} ${hasWarning ? classes.warning : ''}`}>
        {isOccupied ? label : placeholder}{requiredMark}
      </label>
    </div>
  )
}

export default FloatLabel
