import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { DataGridPro, GridToolbarColumnsButton, GridToolbarContainer } from '@mui/x-data-grid-pro'
import { Select as AntSelect } from 'antd'
import {orderBy} from 'lodash'
import moment from 'moment'
import classes from './SetEngagement.module.css'
import { PrimaryButton } from './../../common/buttons/buttons'
import { getProjectList, getProjectStakeholdersTC, selectProjectTC } from '../../../store/projectsReducer'
import UserInfoArea from './../../common/UserInfoArea/UserInfoArea'
import { pageDefaultHeight, tableWidth } from './../../common/sizes'
import {DatePickerForTable} from './../../common/DatePicker/DatePicker'
import { createEngagementsTC, getProjectEngagementsTC, setProjectEngagements } from '../../../store/engagementReducer'
import SuccessModal from './../../common/SuccessModal/SuccessModal'
import {ReactComponent as NoDataImg} from './../../../img/noDataImg.svg'
import Spinner from '../../common/Spinner/Spinner'
import { getOrganizations } from '../../../store/organizationsReducer'
import ProjectSelect from '../../common/ProjectSelect/ProjectSelect'

const SetEngagement = () => {
  const [editedValues, setEditedValues] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [columns, setColumns] = useState([])
  const [isSaved, setIsSaved] = useState(false)
  const [error, setError] = useState(null)
  const [isPageLoading, setIsPageLoading] = useState(false)
  const [tableData, setTableData] = useState([])

  const dispatch = useDispatch()
  const projectEngagements = useSelector(state => state.engagementReducer.projectEngagements)
  const stakeholders = useSelector(state => state.projectsReducer.projectStakeholders)
  const selectedProject = useSelector(state => state.projectsReducer.selectedProject)
  const projects = useSelector(state => state.projectsReducer.projectList)
  const organizations = useSelector(state => state.organizationsReducer.organizations)

  const getSelectProjectOptions = () => {
    const options = projects.map(project => ({id: project.projId, value: project.projId, name: project.name}))
    return orderBy(options, [user => user.name.toLowerCase()], ['asc'])
  }
  const selectProject = useCallback((value) => {
    dispatch(selectProjectTC(value, projects))
    setIsPageLoading(false)
  },[projects, dispatch])

  useEffect(() => {
    setIsPageLoading(true)
    dispatch(getOrganizations())
    dispatch(getProjectList())
    .then((resp) => {
      if (!resp?.length) {
        setError('noProjects')
      }
    })
  }, [dispatch])

  useEffect(() => {
    if (!!selectedProject) {
      setIsPageLoading(true)
      setError('')
      dispatch(getProjectEngagementsTC(selectedProject, true, false))
      dispatch(getProjectStakeholdersTC(selectedProject))
        .then(resp => {
          const availableStakeholders = resp?.filter(stk => !stk.isProjectTeamMember)
          if (!resp?.length || !availableStakeholders.length) {
            setError('noStakeholders')
          }
          setIsPageLoading(false)
        })
    }
    // eslint-disable-next-line
  }, [selectedProject, dispatch])

  useEffect(() => {
    setColumns(getColumns(editedValues, setEditedValues, projectEngagements, organizations))
  }, [stakeholders, editedValues, projectEngagements, organizations])

  useEffect(() => {
    setTableData(stakeholders?.filter(stk => !stk.isProjectTeamMember && projectEngagements.some(eng => eng.stk_id === stk.stakeholderId)))
  }, [projectEngagements, stakeholders])

  useEffect(() => {
    return () => dispatch(setProjectEngagements([]))
    // eslint-disable-next-line
  }, [])

  const onSave = async() => {
    setIsLoading(true)
    const dataForSaving = projectEngagements.map(eng => {
      const editedEngagement = editedValues.find(val => val.id === eng.id)
      return editedEngagement
        ? {...editedEngagement, engagement_plan_date: null}
        : {...eng, engagement_plan_date: null}
    })
    dispatch(createEngagementsTC(dataForSaving))
      .then((resp) => {
        if(!!resp) {
          setIsSaved(true)
        } else {
          setIsLoading(false)
        }
      })
  }

  if (error === 'noProjects') {
    return (
      <>
        <header className={classes.header} style={{display:'flex', justifyContent:'flex-end'}}>
          <UserInfoArea />
        </header>
        <div className='emptyPage' style={{height: pageDefaultHeight}}>
          <NoDataImg />
        </div>
      </>
    )
  }

  if (error === 'noStakeholders') {
    return (
      <>
        <header className={classes.header}>
          <ProjectSelect
            options={getSelectProjectOptions()}
            onSelect={(value) => selectProject(value)}
            style={{marginRight:'20px', width:'290px'}}
            value={selectedProject}
          />
          <UserInfoArea />
        </header>
        <div className='emptyPage' style={{height: pageDefaultHeight}}>
          <div>No stakeholders in this project</div>
        </div>
      </>
    )
  }

  if(!!isPageLoading) {
    return <Spinner style={{minHeight: pageDefaultHeight - 100 }}/>
  }

  return (
    <>
      <header className={classes.header}>
        <ProjectSelect
          options={getSelectProjectOptions()}
          onSelect={(value) => selectProject(value)}
          style={{marginRight:'20px', width:'290px'}}
          value={selectedProject}
        />
        <UserInfoArea />
      </header>
      <div className={classes.wrapper} style={{height: pageDefaultHeight}}>
        <h1 className={classes.pageTitle}>
          CONTACT ENGAGEMENTS
        </h1>
        <div className={classes.tableWrapper}>
          <DataGridPro
            getRowId={e => e.stakeholderId || null}
            rows={tableData} 
            columns={columns}
            rowCount={tableData?.length || 0}
            disableSelectionOnClick
            style={{borderRadius:'15px', border:'none'}}
            components={{Toolbar: () => <CustomToolbar />}}
          />
          <div className={classes.buttonsArea}>
            <PrimaryButton
              text='SAVE'
              style={{width: '95px'}}
              onClick={() => onSave()}
              loading={isLoading}
            />
          </div>
        </div>
        {isSaved &&
          <SuccessModal
            onOk={() => {
              setIsSaved(false)
              setIsLoading(false)
            }}
            isOpen={isSaved}
          />
        }
      </div>
    </>
  )
}

const CustomToolbar = () => {
  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
    </GridToolbarContainer>
  )
}

const getColumns = (editedValues, setEditedValues, projectEngagements, organizations) => {
  const bigColumnSize = 0.35
  const smallColumnSize = 0.2
  const bigColumnWidth = tableWidth * bigColumnSize
  const smallColumnWidth = tableWidth * smallColumnSize

  const frequencyOptions = [
    {id: 1, value: 'Weekly', name:'Weekly'},
    {id: 2, value: 'Fortnightly', name: 'Fortnightly'},
    {id: 3, value: 'Monthly', name: 'Monthly'},
    {id: 4, value: 'Quarterly', name: 'Quarterly'},
    {id: 5, value: 'AdHoc', name: 'Ad Hoc'}
  ] 

  const changeValue = (engagement, key, value) => {
    const isEdited = editedValues.some(eng => eng?.id === engagement?.id)
    if (isEdited) {
      const updatedValues = editedValues.map(eng => eng.id === engagement.id ? {...eng, [key]: value} : eng)
      setEditedValues(updatedValues)
    } else {
      setEditedValues([...editedValues, {...engagement, [key]: value}])
    }
  }

  return (
    [
      { field: 'fullName',
        headerName: 'Full Name',
        width: bigColumnWidth
      },
      { field: 'frequency',
        headerName: 'Engagement Frequency',
        renderCell: (params) => {
          const engagementData = projectEngagements.find(eng => eng.stk_id === params.row.stakeholderId)
          const data = editedValues?.find(eng => eng.stk_id === params.row.stakeholderId) || engagementData
          return (
              <AntSelect
                value={data?.frequency || 'Monthly'}
                style={{width: `${136}px`}}
                onChange={(val) => {
                  changeValue(engagementData, 'frequency', val)
                }}
                options={frequencyOptions}
                className='smallSelect thinBorder'
              />
          )
        },
        sortComparator: (v1, v2, stk1, stk2) => sortFrequency(stk1, stk2, projectEngagements, editedValues, frequencyOptions),
        width: smallColumnWidth,
      },
      { field: 'start_date',
        headerName: 'Engagement Start Date',
        renderCell: (params) => {
          const engagementData = projectEngagements.find(eng => eng.stk_id === params.row.stakeholderId)
          const data = editedValues?.find(eng => eng.stk_id === params.row.stakeholderId) || engagementData
          return (
            <DatePickerForTable
              value={moment(data?.start_date)}
              onChange={(val) => changeValue(engagementData, 'start_date', val)}
            />
          )
        },
        sortComparator: (v1, v2, stk1, stk2) => sortStartDate(stk1, stk2, projectEngagements, editedValues),
        width: smallColumnWidth
      },
      { field: 'orgId', 
        headerName: 'Organisation', 
        width: smallColumnWidth,
        renderCell: (params) => {
          const orgData = organizations?.find(org => org.orgId === params.row.orgId)
          return (
            <div>{orgData?.nickname}</div>
          )
        },
        sortComparator: (v1, v2, org1, org2) => {
          const org1Name = organizations?.find(org => org.orgId === org1.value)?.name
          const org2Name = organizations?.find(org => org.orgId === org2.value)?.name
          return org1Name?.localeCompare(org2Name)
        },
      },
      { field: 'roleTitle',
        headerName: 'Role Title',
        width: smallColumnWidth
      },
      { field: 'jobTitle',
        headerName: 'Job Title',
        width: smallColumnWidth
      },
      { field: 'department',
        headerName: 'Department',
        width: smallColumnWidth
      },
    ]
  )
}

const sortFrequency = (stk1, stk2, projectEngagements, editedValues, frequencyOptions) => {
  const engagementData1 = projectEngagements.find(eng => eng.stk_id === stk1.id)
  const data1 = editedValues?.find(eng => eng.stk_id === stk1.id) || engagementData1
  const engagementData2 = projectEngagements.find(eng => eng.stk_id === stk2.id)
  const data2 = editedValues?.find(eng => eng.stk_id === stk2.id) || engagementData2
  const frequencyValue1 = frequencyOptions.find(opt => opt.value === data1?.frequency)?.id
  const frequencyValue2 = frequencyOptions.find(opt => opt.value === data2?.frequency)?.id
  if (frequencyValue1 < frequencyValue2) return -1
  if (frequencyValue1 > frequencyValue2) return 1
  return 0
}

const sortStartDate = (stk1, stk2, projectEngagements, editedValues) => {
  const engagementData1 = projectEngagements.find(eng => eng.stk_id === stk1.id)
  const data1 = editedValues?.find(eng => eng.stk_id === stk1.id) || engagementData1
  const engagementData2 = projectEngagements.find(eng => eng.stk_id === stk2.id)
  const data2 = editedValues?.find(eng => eng.stk_id === stk2.id) || engagementData2
  if (moment(data1.start_date).isBefore(moment(data2.start_date))) return -1
  if (moment(data1.start_date).isAfter(moment(data2.start_date))) return 1
  return 0
}

export default SetEngagement
