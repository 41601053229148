import React, { useState } from 'react'
import { Modal} from 'antd'
import {Form, Formik} from 'formik'
import * as Yup from 'yup'
import axios from './../../../helpers/axiosHelper'
import { PrimaryButton, SecondaryButton } from '../../common/buttons/buttons'
import {Input} from '../../common/Input/Input'
import classes from './AccessModal.module.css'
import { handleError } from '../../../store/errorsReducer'
import { useDispatch } from 'react-redux'
import { useMediaQuery } from 'react-responsive'

const AccessModal = (props) => {
  const dispatch = useDispatch()
  const [isRequestSent, setIsRequestSent] = useState(false)
  const [isSending, setIsSending] = useState(false)
  const initialValues = {
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    organisation: '',
  }

  const validationSchema = Yup.object({
    firstName: Yup.string()
      .required('This field is required'),
    lastName: Yup.string()
      .required('This field  is required'),
    email: Yup.string()
      .email('Invalid format')
      .required('This field  is required'),
    phoneNumber: Yup.string()
      .required('This field  is required'),
    organisation: Yup.string()
      .required('This field  is required'),
  })

  const onSubmit = (formData) => {
    setIsSending(true)
    axios.post('/users/request-access', formData)
      .then(response => {
        if (response.status === 200) {
          setIsRequestSent(true)
          setIsSending(false)
        } else {
          dispatch(handleError(response.data))
        }
      })
      .catch(error => dispatch(handleError(error)))
  }
  const isSmallScreen = useMediaQuery({ query: '(min-width: 300px) and (max-width: 427px)' })
  return (
    <Modal 
      centered
      closable={false}   
      footer={null}
      title={<b>Request Access</b>}
      onCancel={() => props.setIsAccessModalOpen(false)}
      style={{padding: '10px', width: '100%'}}
      visible={props.isAccessModalOpen} 
      width={isSmallScreen ? 300 : 600}
    >
      {isRequestSent ? (
        <div className={classes.successMessage}>
          Your request has been sent!
          <PrimaryButton 
            text='OK' 
            onClick={() => {
              setIsRequestSent(false)
              props.setIsAccessModalOpen(false)
            }}
            style={{width:'120px', marginTop:'20px'}}
          />
        </div> 
      ) : (
        <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema}>
          {({values, setFieldValue}) => {  
            return (
              <Form className={classes.form}>
                  <Input 
                    name='firstName'
                    label='First name*'
                    placeholder='Enter first name'
                    className='autoWidthInput'
                    InputProps={{style:{width:isSmallScreen ? '230px' : '300px'}}}
                  />
                <Input 
                  name='lastName'
                  label='Last name*'
                  placeholder='Enter last name'
                  className='autoWidthInput'
                  InputProps={{style:{width:isSmallScreen ? '230px' : '300px'}}}
                />
                <Input 
                  name='email'
                  label='Email*'
                  placeholder='Enter email'
                  className='autoWidthInput'
                  InputProps={{style:{width:isSmallScreen ? '230px' : '300px'}}}
                />
                <Input 
                  name='phoneNumber'
                  label='Phone number*'
                  placeholder='Enter phone number'
                  className='autoWidthInput'
                  InputProps={{style:{width:isSmallScreen ? '230px' : '300px'}}}
                />
                <Input 
                  name='organisation'
                  label='Organisation*'
                  placeholder='Enter organisation'
                  className='autoWidthInput'
                  InputProps={{style:{width:isSmallScreen ? '230px' : '300px'}}}
                />
                <div className={classes.buttonsArea}>
                  <SecondaryButton
                    onClick={() => props.setIsAccessModalOpen(false)}
                    style={{width:'100px', height: 'auto'}}
                    text='Cancel' 
                  />
                  <PrimaryButton 
                    htmlType='submit' 
                    style={{width:'100px', height: 'auto', marginLeft: '15px'}}
                    text='SAVE'
                    loading={isSending} 
                  />
                </div>
              </Form>
            )
          }}
        </Formik>
      )}
    </Modal>
  )
}

export default AccessModal
