import React, { useState } from 'react'
import { connect } from 'react-redux'
import ConnectionInfoModal from './ConnectionInfoModal'
import {setIsConnectionInfoModalOpen} from '../../../../../store/canvasReducer'
import {editConnection} from '../../../../../store/stakeholderMapReducer'

const ConnectionInfoModalContainer = (props) => {
  const [isConnectionDeleted, setIsConnectionDeleted] = useState(false)
  const [isDeleting, setIsDeleting] = useState(false)

  const saveChanges = (formData) => {
    const connectionData = props.connections.find(connection => connection.id === props.selectedElement.id)
    const updatedData = {
      "link_code": connectionData.link_code,
      "link_level": connectionData.link_level,
      'link_property': {
        "strength": formData.strength,
        "purpose": formData.purpose
      },
      "organization_id": connectionData.organization_id,
      "project_id": connectionData.project_id,
      "from_stakeholder_id": Number(connectionData.source),
      "to_stakeholder_id": Number(connectionData.target),

    }
    props.editConnection(updatedData, connectionData.id, props.isStrengthViewOn)
    closeModal()
  }

  const closeModal = () => {
    props.setIsConnectionInfoModalOpen(false)
  }

  const deleteConnection = () => {
    setIsDeleting(true)
    props.removeElement()
      .then((resp) => {
        !!resp && setIsConnectionDeleted(true)
        setIsDeleting(false)
      })
  }

  return (
    <ConnectionInfoModal 
      closeModal={closeModal}
      isConnectionInfoModalOpen={props.isConnectionInfoModalOpen}
      saveChanges={saveChanges}
      selectedElement={props.selectedElement}
      deleteConnection={deleteConnection}
      isConnectionDeleted={isConnectionDeleted}
      isDeleting={isDeleting}
    />
  )
}

const mapStateToProps = (state) => ({
  connections: state.stakeholderMapReducer.connections,
  isConnectionInfoModalOpen: state.canvasReducer.connections.isConnectionInfoModalOpen,
  selectedElement: state.canvasReducer.selectedElement,
  isStrengthViewOn: state.stakeholderMapReducer.isStrengthViewOn,
})

export default connect(mapStateToProps, 
  { setIsConnectionInfoModalOpen,
    editConnection
  })(ConnectionInfoModalContainer) 
