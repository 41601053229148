import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import {default as MaterialSlider} from '@material-ui/core/Slider'

const StyledSlider = withStyles({
  root: {
    color: '#0E9AFC',
    height: 8,
    width: '100%',
  },
  thumb: {
    height: 24,
    width: 24,
    backgroundColor: '#0E9AFC',
    border: '2px solid black',
    marginTop: -8,
    marginLeft: -12,
    '&:focus, &:hover, &$active': {
      boxShadow: 'inherit',
    },
  },
  active: {
    color: '#0E9AFC',
  },
  valueLabel: {
    left: 'calc(-50% + 4px)',
  },
  track: {
    height: 8,
    borderRadius: 4,
  },
  rail: {
    height: 8,
    borderRadius: 4,
  },
  mark: {
    color: 'transparent'
  },
})(MaterialSlider)

const Slider = (props) => {
  const marks = [
    {
      value: props.min,
      label: props.min,
    },
    {
      value: props.max,
      label: props.max,
    },
  ]
  return (
    <StyledSlider 
      defaultValue={props.defaultValue}
      onChange={props.onChange}
      value={props.value} 
      min={props.min}
      max={props.max}
      marks={props.marks || marks}
      style={props.style}
      valueLabelDisplay='auto'
      valueLabelFormat={props.valueLabelFormat}
      width='500px'
    />
  )
}
export default Slider 
