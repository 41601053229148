import React, {useState} from 'react'
import {connect, useDispatch, useSelector} from 'react-redux'
import Login from "../Login";
import {
    logIn,
    setCurrentLoginData,
    setCurrentLoginResponseData,
    setRolesForLogin,
} from "../../../store/authReducer";
import axios from "axios";
import {Navigate, useNavigate} from "react-router";
import {setCurrentLoginUser} from "../../../store/usersReducer";

const MultipleTenantsLogin = (props) => {
    const [loginError, setLoginError] = useState(null)
    const [isAccessModalOpen, setIsAccessModalOpen] = useState(false)
    const currentUserRoles = useSelector(store => store.authReducer.userRoles)
    const isSystemAdmin = currentUserRoles?.some(role => role.code.toLowerCase() === 'system admin')
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [isLoading, setIsLoading] = useState(false)

    if (props.isSignedIn && !isSystemAdmin) {
        return <Navigate to='/StakeholderMap' />
    }
    if (props.isSignedIn && isSystemAdmin){
        return <Navigate to='/Tenants' />
    }


    const signIn = (loginData) => {
        setIsLoading(true)
        axios.post('/users/login', loginData)
            .then(response => {
                if (!response.data?.roles?.length) {
                    setLoginError({message: 'This user has no roles'})
                } else {
                    if(response.data.roles?.length === 1 && response.data.roles.some(role => role.code.toLowerCase() === 'system admin')){
                        props.logIn({...response?.data, email:loginData.email})
                        navigate('/Tenants')
                    }
                    else if(response.data.roles?.length >= 1 && response.data.roles.every(role => role.code.toLowerCase() !== 'system admin')){
                        props.logIn({...response?.data, email:loginData.email})
                        navigate('/StakeholderMap')
                    }
                    else{
                        dispatch(setCurrentLoginData(loginData))
                        dispatch(setRolesForLogin(response?.data?.roles))
                        dispatch(setCurrentLoginUser(`${response.data.firstName} ${response.data.lastName}`))
                        navigate('/login/choose-role')
                        dispatch(setCurrentLoginResponseData(response?.data))
                    }
                }
                setIsLoading(false)
            })
            .catch(error => {
                setLoginError(error?.response?.data)
                setIsLoading(false)
            })
    }

    return (
        <Login
            loginError={loginError}
            signIn={signIn}
            isAccessModalOpen={isAccessModalOpen}
            setIsAccessModalOpen={setIsAccessModalOpen}
            isMultipleTenant={true}
            isRolePicker={false}
            isLoading={isLoading}
        />
    )
}

const mapSateToProps = (state) => ({
    isSignedIn: state.authReducer.isSignedIn,
})

export default connect(mapSateToProps,{logIn})(MultipleTenantsLogin)