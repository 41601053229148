import React, {useState} from 'react'
import {Field} from 'formik'
import {withStyles} from '@material-ui/core/styles'
import {TextField} from 'formik-material-ui'
import {default as MateriaTextField} from '@material-ui/core/TextField';
import {Input as AntdInput} from 'antd'
import {Visibility, VisibilityOff} from "@material-ui/icons";
import {IconButton} from "@mui/material";
import InputAdornment from "@material-ui/core/InputAdornment";

export const StyledInput = withStyles({
  root: {
    '& .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-formControl.MuiOutlinedInput-adornedStart': {
      width: '324px'
    },
    '& .MuiInputBase-input.MuiOutlinedInput-input': {
      width: '305px',
      backgroundColor: 'transparent !important'
    },
    '& .MuiOutlinedInput-notchedOutline': {
      border: '2px solid black',
      width: '335px'
    },
    '& .MuiInputBase-inputAdornedEnd.MuiOutlinedInput-inputAdornedEnd': {
      width: '318px',
      backgroundColor: 'transparent !important',
    },
    '& .MuiOutlinedInput-adornedEnd': {
      paddingRight: '0 !important'
    },
    '& .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline': {
      backgroundColor: 'rgba(54, 56, 83, 0.2)',
      border: '2px solid black',
    },
    '& label': {
      color: 'black',
      fontWeight: 600,
    },
    '& .MuiFormLabel-root.Mui-focused': {
      color: '#0E9AFC',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: 'black',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'black',
      },
      '&:hover fieldset': {
        borderColor: 'black',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#0E9AFC',
      },
    },
    // with class longInput
    '& .MuiInputBase-root.MuiOutlinedInput-root.longInput.MuiInputBase-formControl': {
      width: '520px'
    },
    '& .longInput .MuiOutlinedInput-notchedOutline': {
      width: '520px'
    },
    // with class autoWidthInput
    '& .MuiInputBase-root.MuiOutlinedInput-root.autoWidthInput.MuiInputBase-formControl': {
      width: '98%'
    },
    '& .autoWidthInput .MuiOutlinedInput-notchedOutline': {
      width: '100%'
    },
    '& .autoWidthInput.MuiInputBase-formControl.MuiInputBase-multiline.MuiOutlinedInput-multiline': {
      width: '98%'
    },
  },
})(TextField)

export const Input = (props) => {
  const [showPassword, setShowPassword] = useState(false);

  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const isPasswordType = props.InputProps?.type === 'password';

  return (
      <Field
          autoComplete="off"
          component={StyledInput}
          disabled={props.disabled || false}
          helperText={props.helperText}
          label={props.label}
          multiline={props.multiline || false}
          rows={props.rows}
          name={props.name}
          placeholder={props.placeholder}
          variant="outlined"
          InputLabelProps={{ shrink: true }}
          onKeyUp={props.onKeyUp}
          InputProps={{
            classes: { root: props.className },
            ...props.InputProps,
            type: isPasswordType && showPassword ? 'text' : props.InputProps?.type,
            endAdornment: isPasswordType && props.isLogin ? (
                <InputAdornment position="end">
                  <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleTogglePasswordVisibility}
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
            ) : null,
          }}
      />
  )
}

const StyledControlledInput = withStyles({
  root: {
    '& .MuiFormControl-root.MuiTextField-root': {
      width: '98%'
    },
    '& .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-formControl.MuiOutlinedInput-adornedStart': {
      width: '100%'
    },
    '& .MuiInputBase-input.MuiOutlinedInput-input': {
      width: '100%',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      border: '2px solid black',
      width: '100%'
    },
    '& .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline': {
      backgroundColor: 'rgba(54, 56, 83, 0.2)',
      border: '2px solid black',
    },
    '& label': {
      color: 'black',
      fontWeight: 600,
    },
    '& .MuiFormLabel-root.Mui-focused': {
      color: '#0E9AFC',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: 'black',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'black',
      },
      '&:hover fieldset': {
        borderColor: 'black',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#0E9AFC',
      },
    },
    // with class longInput
    '& .MuiInputBase-root.MuiOutlinedInput-root.longInput.MuiInputBase-formControl': {
      width: '520px'
    },
    '& .longInput .MuiOutlinedInput-notchedOutline': {
      width: '520px'
    },
    // with class autoWidthInput
    '& .MuiInputBase-root.MuiOutlinedInput-root.autoWidthInput.MuiInputBase-formControl': {
      width: '100%'
    },
    '& .autoWidthInput .MuiOutlinedInput-notchedOutline': {
      width: '100%'
    },
    '& .autoWidthInput.MuiInputBase-formControl.MuiInputBase-multiline.MuiOutlinedInput-multiline': {
      width: '702px'
    },
  },
})(MateriaTextField)

export const ControlledInput = (props) => {
  return (
    <StyledControlledInput
      autoComplete='off'
      disabled={props.disabled || false}
      error={props.error}
      helperText={props.helperText}  
      label={props.label}
      multiline={props.multiline || false}
      rows={props.rows}
      placeholder={props.placeholder}
      variant='outlined'
      value={props.value}
      onChange={props.onChange}
      InputLabelProps={ { shrink: true } }
      InputProps={{
        classes:{root: props.className},
        ...props.InputProps
      }}
      inputProps={props.inputProps}
      style={{width: '98%'}}
    /> 
  )
}

export const StyledTextArea = withStyles({
  root: {
    '& .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-formControl.MuiOutlinedInput-adornedStart': {
      width: '100%'
    },
    '& .MuiInputBase-input.MuiOutlinedInput-input': {
      width: '100%',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      border: '2px solid black',
      width: '100%'
    },
    '& .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline': {
      backgroundColor: 'rgba(54, 56, 83, 0.2)',
      border: '2px solid black',
    },
    '& label': {
      color: 'black',
      fontWeight: 600,
    },
    '& .MuiFormLabel-root.Mui-focused': {
      color: '#0E9AFC',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: 'black',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'black',
      },
      '&:hover fieldset': {
        borderColor: 'black',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#0E9AFC',
      },
    },
    // with class autoWidthInput
    '& .MuiInputBase-root.MuiOutlinedInput-root.autoWidthInput.MuiInputBase-formControl': {
      width: '98%'
    },
    '& .autoWidthInput .MuiOutlinedInput-notchedOutline': {
      width: '100%'
    },
    '& .autoWidthInput.MuiInputBase-formControl.MuiInputBase-multiline.MuiOutlinedInput-multiline': {
      width: '98%'
    },
  },
})(TextField)

export const TextAreaField = (props) => {
  return (
    <Field
      autoComplete='off'
      component={StyledTextArea}
      disabled={props.disabled || false} 
      helperText={props.helperText}         
      label={props.label}
      multiline={props.multiline || false}
      rows={props.rows}
      name={props.name}
      placeholder={props.placeholder}
      variant='outlined'
      InputLabelProps={ { shrink: true } }
      InputProps={{
        classes:{root: props.className},
        ...props.InputProps
      }}
      inputProps={{...props.inputProps}}
  />
  )
}

export const InputForTable = (props) => {
  return (
    <AntdInput
      placeholder={props.placeholder}
      className='inputForTable'
      value={props.value}
      onChange={props.onChange}
      disabled={props.disabled}
    />
  )
}

