import moment from 'moment'
import { Popconfirm } from 'antd'
import { Link } from 'react-router-dom'
import classes from './../Projects.module.css'
import {tableWidth} from '../../common/sizes'
import {ReactComponent as EditIcon} from './../../../img/icons/editIcon.svg'
import {ReactComponent as DeleteIcon} from './../../../img/icons/deleteIcon.svg'
import {ReactComponent as ExclamationCircle} from './../../../img/icons/exclamationCircle.svg'

const partOfTableWidthForBigColumn = 0.21
const partOfTableWidthForSmallColumn = 0.12
const bigColumnWidth = tableWidth * partOfTableWidthForBigColumn
const columnWidth = tableWidth * partOfTableWidthForSmallColumn

const getColumns = (deleteProject) => [
  { field: 'name', headerName: 'Project Name', width: bigColumnWidth},
  { field: 'actions', 
    headerName: 'Actions', 
    sortable: false, 
    width: columnWidth,
    renderCell: (params) => {
      return (
        <div style={{display:'flex', alignItems:'center'}} >
          <Link to={`/Projects/edit/${params.row.projId}`} style={{lineHeight:'0px'}}>
            <EditIcon className={classes.tableIcon} style={{marginRight: '15px'}}/>
          </Link>
           <Popconfirm
            title="Are you sure to delete this project?"
            onConfirm={() => deleteProject(params.id)}
            okText="Yes"
            cancelText="No"
            icon={<ExclamationCircle />}
            cancelButtonProps={{type: 'primary', style:{background: '#1890FF'}}}
            okButtonProps={{type: 'default'}}
          >
            <DeleteIcon className={classes.tableIcon}/>
          </Popconfirm>
        </div>
      )
    }
  },
  { field: 'activeFrom', 
    headerName: 'Active From', 
    type: 'date', 
    width: columnWidth, 
    renderCell: (params) => {
      return (
        <span>
          {moment(params.row.activeFrom).format('DD.MM.YYYY')}
        </span>
      )
    }
  },
  { field: 'activeTill', 
    headerName: 'Active Till', 
    type: 'date', 
    width: columnWidth, 
    renderCell: (params) => {
      let activeTillDate = params.row.activeTill !== '2099-12-31T00:00:00' ? ( 
        moment(params.row.activeTill).format('DD.MM.YYYY') 
      ) : (
        null
      )
      return (
        <span>
          {activeTillDate}
        </span>
      )
    }
  },
  { field: 'projectMarking', 
    headerName: 'Project Documentation Marking', 
    sortable: true, 
    width: bigColumnWidth,
  },
  { field: 'description', 
    headerName: 'Description', 
    sortable: false, 
    width: bigColumnWidth,
    renderCell: (params) => {
      return (
        <div style={{overflow:'auto'}}>{params.row.description}</div>
      )
    }
  },
]

export default getColumns
