import React from 'react'
import {Field} from 'formik'
import InputAdornment from '@material-ui/core/InputAdornment'
import MenuItem from '@material-ui/core/MenuItem'
import {withStyles} from '@material-ui/core/styles'
import {Select as SelectMaterial} from 'formik-material-ui'
import {default as RadioMaterial} from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'

export const SelectAdornment = (props) => {
  return (
    <InputAdornment position='start'>
      <Field
        component={SelectMaterial}
        disableUnderline={true}
        disabled={false}
        name={props.name}
        style={{width: '135px'}}
        inputProps={{
          variant:'standard',
        }}
      >
        <MenuItem key='Business' value='business'>
          Business
        </MenuItem>
        <MenuItem key='BusMob' value='busMob'>
          Business Mob
        </MenuItem>
        <MenuItem key='Mobile' value='mobile'>
          Mobile
        </MenuItem>
        <MenuItem key='Home' value='home'>
          Home
        </MenuItem>
      </Field>
    </InputAdornment>
  )
}

const StyledRadio = withStyles({
  root: {
    color: 'black',
    '& .MuiSvgIcon-root': {
      fill: '#0E9AFC',
    },

  }
})(RadioGroup)

export const Radio = (props) => {
  return (
    <StyledRadio 
      onChange={props.onChange} 
      style={{display:'inline-flex', flexDirection:'column'}} 
      value={props.value}
    >
      {props.inputs.map((input, index) => {
        return (
          <FormControlLabel
            value={index}
            control={<RadioMaterial />}
            label='Main'
            style={{marginBottom:'50px'}}
            key={index}
          />
        )
      })}
    </StyledRadio>
  )
}
