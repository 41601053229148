import React, {useEffect} from 'react'
import {DataGridPro, GridToolbarColumnsButton, GridToolbarContainer, useGridApiRef} from '@mui/x-data-grid-pro'
import {useDispatch, useSelector} from 'react-redux'
import classes from './Users.module.css'
import getColumns from '../common/UsersTable/getColumns'
import {setTableColumns} from '../../store/tablesReducer'
import {getUserListWithPagination, setCurrentUsersPage} from "../../store/usersReducer";
import {getCurrentUserRoles} from "../../helpers/localStorage";

const UsersTable = (props) => {
    const removeUser = props.removeUser
    const setModalData = props.setModalData
    const tableColumns = getColumns(setModalData, removeUser, props.tableRows ? props.tableRows : [], false, false)
    const page = useSelector(state => state.usersReducer.currentPage)
    const userRole = getCurrentUserRoles()
    const rowsCount = useSelector(state => state.usersReducer.totalCount)
    // console.log(userRole)
    // useEffect(() => {
    //   dispatch(setCurrentUsersPage(1))
    // }, [props?.tableRows])
    const tableAppearance = useSelector(state => state.tablesReducer?.usersTable)
    const apiRef = useGridApiRef()
    const dispatch = useDispatch()

    useEffect(() => {
        apiRef?.current?.subscribeEvent('columnHeaderDragEnd', () => saveTableColumns())
        // eslint-disable-next-line
    }, [apiRef])

    const saveTableColumns = () => {
        const stateColumns = apiRef?.current?.getAllColumns()
        const colsData = Object.keys(stateColumns).map(key => stateColumns[key])
        dispatch(setTableColumns('usersTable', colsData))
    }

    const onPageChange = (page) => {
        if (userRole[0].code === "system admin") {
            dispatch(getUserListWithPagination({
                pagination_request: {
                    page: page + 1,
                    size: 10,
                    sort: []
                },
                filter_request: {
                    search_value: props.searchedValue
                },
            }, true))
        }
        dispatch(setCurrentUsersPage(page))
    }


    // const onSortModelChange = (model) => {
    //     const { sort, ...rest } = model[0];
    //     const sortModel = {
    //         ...rest,
    //         direction: sort
    //     };
    //     // if (userRole[0].code === "system admin") {
    //     //     dispatch(getUserListWithPagination({
    //     //         pagination_request: {
    //     //             page: page + 1,
    //     //             size: 10,
    //     //             sort: sortModel
    //     //         },
    //     //         filter_request: {
    //     //             search_value: props.searchedValue
    //     //         },
    //     //     }, true))
    //     // }
    // }

    return (
        <div className={classes.tableWrapper}>
            <DataGridPro
                getRowId={e => e.userId}
                rows={props.tableRows || []}
                {...props.tableRows || []}
                apiRef={apiRef}
                onColumnWidthChange={(e) => saveTableColumns()}
                columns={tableAppearance.length ? tableAppearance.filter((c) => c !== false) : tableColumns.filter((c) => c !== false)}
                rowCount={userRole[0].code === 'system admin' ? rowsCount : props.tableRows?.length || 0}
                pagination
                page={page}
                onPageChange={onPageChange}
                pageSize={10}
                rowsPerPageOptions={[10]}
                // onSortModelChange={onSortModelChange}
                paginationMode={'server'}
                style={{borderRadius: '15px', border: 'none', width: '100%'}}
                components={{
                    Toolbar: CustomToolbar,
                }}
            />

        </div>
    )
}

const CustomToolbar = () => {
    return (
        <GridToolbarContainer>
            <GridToolbarColumnsButton/>
        </GridToolbarContainer>
    )
}

export default UsersTable
