import { useEffect, useState } from 'react'
import { DataGridPro, GridToolbarColumnsButton, GridToolbarContainer } from '@mui/x-data-grid-pro'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'
import classes from './RecordEngagement.module.css'
import { PrimaryButton, SecondaryButton } from '../../common/buttons/buttons'
import {tableWidth} from '../../common/sizes'
import {getStakeholdersWithGroupData} from './../../../helpers/groupedDataHelper'
import { DatePickerForTable } from '../../common/DatePicker/DatePicker'
import Checkbox from './../../common/Checkbox/Checkbox'
import { InputForTable } from '../../common/Input/Input'
import SuccessModal from './../../common/SuccessModal/SuccessModal'
import { createEngagementTC } from '../../../store/engagementReducer'
import { getNewNotifications } from '../../../store/notificationsReducer'
import {useNavigate} from "react-router";

const Step2 = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [sortModel, setSortModel] = useState([
    {
      field: 'groupName',
      sort: 'asc',
    },
  ])
  const [editedValues, setEditedValues] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [isSaved, setIsSaved] = useState(false)
  const [isSaveDisabled, setIsSaveDisabled] = useState(false)
  const [validationError, setValidationError] = useState('')

  const projectStakeholders = useSelector(state => state.projectsReducer.projectStakeholders)
  const projectEngagements = useSelector(state => state.engagementReducer.projectEngagements)
  const organizations = useSelector(state => state.organizationsReducer.organizations)
  const groupedStakeholders = getStakeholdersWithGroupData(projectStakeholders)
  const notProjectTeamMember = groupedStakeholders?.filter(stk => !stk.isProjectTeamMember) || []
  const userId = useSelector(state => state.authReducer.userId)

  const saveChanges = () => {
    if (editedValues.some(eng => !!eng.is_completed && !eng.engagement_date)) {
      setValidationError('Date is required for all completed engagements!')
    } else {
      setValidationError('')
      setIsLoading(true)
      Promise.all(editedValues.map(val => dispatch(createEngagementTC(null, null, null, {...val, user_id: userId}))))
        .then((resp) => {
          if(!!resp) {
            dispatch(getNewNotifications(false))
            setIsSaved(true)
            setIsSaveDisabled(true)
          }
          setIsLoading(false)
        })
    }
  }

  useEffect(() => {
    return () => {
      setValidationError('')
      setEditedValues([])
    }
  }, [])

  return (
    <div className={classes.tableWrapper}>
      <DataGridPro
        getRowId={e => e.stakeholderId || null}
        rows={notProjectTeamMember} 
        columns={getColumns(projectEngagements, editedValues, setEditedValues, organizations, validationError)}
        rowCount={notProjectTeamMember?.length || 0}
        components={{Toolbar: () => <CustomToolbar />}}
        disableSelectionOnClick
        style={{borderRadius:'15px', border:'none'}}
        sortModel={sortModel}
        onSortModelChange={(model) => {
          if(JSON.stringify(model) !== JSON.stringify(sortModel)) setSortModel(model)
        }}
      />
      <div className={classes.btnArea}>
        {!!validationError.length &&
          <div style={{color: 'red', paddingTop: '20px', marginRight: '10px'}}>
            {validationError}
          </div>
        }
        <SecondaryButton
          text='BACK'
          style={{width:'100px', marginTop: '15px', marginRight: '10px'}}
          onClick={() => navigate(-1)}
        />
        <PrimaryButton
          text='SAVE'
          style={{width:'100px', marginTop: '15px'}}
          onClick={() => saveChanges()}
          loading={isLoading}
          disabled={!!isSaveDisabled}
        />
      </div>
      {isSaved && <SuccessModal onOk={() => setIsSaved(false)} isOpen={isSaved}/>}
    </div>
  )
}

const getColumns = (projectEngagements, editedValues, setEditedValues, organizations, validationError) => {
  const columnSize = 0.16
  const defaultColumnWidth = tableWidth * columnSize

  const changeValue = (engagement, field, value) => {
    const isEdited = editedValues.some(eng => eng.id === engagement.id) 
    let updatedValues
    if (isEdited && field === 'is_completed' && !value) {
      updatedValues = editedValues.filter(eng => eng.id !== engagement.id)
    } else if (isEdited) {
      updatedValues = editedValues.map(eng => {
        return eng.id === engagement.id ? {...eng, [field]: value} : eng
      })
    } else {
      updatedValues = [...editedValues, {...engagement, is_completed: true, comments: '', engagement_date: null}]
    }
    setEditedValues(updatedValues)
  }

  return [
    { field: 'groupName',
      headerName: 'Stakeholder Group',
      renderCell: (params) => {
        const stkEngagementData = projectEngagements.find(eng => eng.stk_id === params.row.stakeholderId)
        const isDateExceeded = moment(stkEngagementData?.engagement_plan_date).isBefore(moment()) && !stkEngagementData.is_completed
        return (
          <div style={{color: isDateExceeded? 'red' : 'black'}}>
            {params.row.groupName}
          </div>
        )
      },
      sortComparator: (v1, v2, group1, group2) => {
        const order = [
          {group: 'Manage Closely', id: 1},
          {group: 'Keep Satisfied', id: 2},
          {group: 'Keep Informed', id: 3},
          {group: 'Monitor', id: 4},
          {group: 'Unknown data', id: 5},
        ]
        const val1 = order.find(group => group.group === group1.value)?.id
        const val2 = order.find(group => group.group === group2.value)?.id
        if(val1 < val2) return -1
        if(val1 > val2) return 1
        return 0;
      },
      width: defaultColumnWidth
    },
    { field: 'fullName',
      headerName: 'Full Name',
      renderCell: (params) => {
        const stkEngagementData = projectEngagements.find(eng => eng.stk_id === params.row.stakeholderId)
        const isDateExceeded = moment(stkEngagementData?.engagement_plan_date).isBefore(moment()) && !stkEngagementData.is_completed
        return (
          <div style={{color: isDateExceeded ? 'red' : 'black'}}>
            {params.row.fullName}
          </div>
        )
      },
      width: defaultColumnWidth
    },
    { field: 'last_engagement_date',
      headerName: 'Last Engagement',
      renderCell: (params) => {
        const stkEngagementData = projectEngagements.find(eng => eng.stk_id === params.row.stakeholderId)
        const isDateExceeded = moment(stkEngagementData?.engagement_plan_date).isBefore(moment()) && !stkEngagementData.is_completed
        return (
          <div style={{color: isDateExceeded ? 'red' : 'black'}}>
            {!!stkEngagementData?.last_engagement_date
              ? moment(stkEngagementData?.last_engagement_date).format('YYYY-MM-DD')
              : 'No data'
            }
          </div>
        )
      },
      width: defaultColumnWidth
    },
    { field: 'is_completed',
      headerName: 'Record Engagement',
      renderCell: (params) => {
        const stkEngagementData = projectEngagements.find(eng => eng.stk_id === params.row.stakeholderId)
        const data = editedValues.find(eng => eng.stk_id === params.row.stakeholderId)
        return (
          <Checkbox
            wrapperStyle={{margin: '0 auto'}}
            checked={data?.is_completed}
            onChange={(e) => changeValue(stkEngagementData, 'is_completed', e.target.checked)}
          />
        )
      },
      width: defaultColumnWidth
    },
    { field: 'engagement_date',
      headerName: 'Engagement Date',
      renderCell: (params) => {
        const stkEngagementData = projectEngagements.find(eng => eng.stk_id === params.row.stakeholderId)
        const editedEngData = editedValues.find(eng => eng.stk_id === params.row.stakeholderId)
        const value = !editedEngData
          ? null
          : editedEngData?.engagement_date ? moment(editedEngData?.engagement_date) : editedEngData?.engagement_date
        return (
          <DatePickerForTable
            disabled={!editedEngData}
            value={value}
            onChange={(val) => changeValue(stkEngagementData, 'engagement_date', val)}
            hasError={!!validationError.length && !!editedEngData && !value}
            disabledDate={(current) => current && current > moment().endOf('day')}
          />
        )
      },
      width: defaultColumnWidth
    },
    { field: 'comments',
      headerName: 'Comment',
      renderCell: (params) => {
        const stkEngagementData = projectEngagements.find(eng => eng.stk_id === params.row.stakeholderId)
        const editedEngData = editedValues.find(eng => eng.stk_id === params.row.stakeholderId)
        return (
          <InputForTable
            disabled={!editedEngData}
            value={editedEngData?.comments || ''}
            onChange={(e) => changeValue(stkEngagementData, 'comments', e.target.value)}
          />
        )
      },
      width: defaultColumnWidth
    },
    { field: 'orgId', 
      headerName: 'Organisation', 
      width: defaultColumnWidth,
      renderCell: (params) => {
        const orgData = organizations?.find(org => org.orgId === params.row.orgId)
        return (
          <div>{orgData?.nickname}</div>
        )
      },
      sortComparator: (v1, v2, org1, org2) => {
        const org1Name = organizations?.find(org => org.orgId === org1.value)?.nickname
        const org2Name = organizations?.find(org => org.orgId === org2.value)?.nickname
        return org1Name?.localeCompare(org2Name)
      },
    },
    { field: 'roleTitle',
      headerName: 'Role Title',
      width: defaultColumnWidth
    },
    { field: 'jobTitle',
      headerName: 'Job Title',
      width: defaultColumnWidth
    },
    { field: 'department',
      headerName: 'Department',
      width: defaultColumnWidth
    },
  ]
}

const CustomToolbar = (props) => {
  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
    </GridToolbarContainer>
  )
}

export default Step2
