import React, { useEffect, useState } from 'react'
import { DataGridPro, GridOverlay, useGridApiRef } from '@mui/x-data-grid-pro'
import { useDispatch, useSelector } from 'react-redux'
import classes from './../Projects.module.css'
import getColumns from './getColumns'
import { setTableColumns } from '../../../../../../store/tablesReducer'

const ProjectsTable = (props) => {
  const [columns, setColumns] = useState([])
  useEffect(() => {
    setColumns(getColumns(
      props.removeProject,
      props.setModalData,
      props.setHasProjectChanges,
      props.updatedProjectNumberValue,
      props?.tableValues))
  }, [props?.tableValues, props.removeProject, props.setHasProjectChanges, props.setModalData, props.updatedProjectNumberValue])

  const [page, setPage] = useState(0)

  useEffect(() => {
    setPage(0)
  }, [props?.tableValues])

  const tableAppearance = useSelector(state => state.tablesReducer?.editContactProjectsTable)
  const apiRef = useGridApiRef()
  const dispatch = useDispatch()

  useEffect(() => {
    apiRef.current.subscribeEvent('columnHeaderDragEnd', () => saveTableColumns())
    // eslint-disable-next-line
  }, [apiRef])

  const saveTableColumns = () => {
    const stateColumns = apiRef?.current?.getAllColumns()
    const colsData = Object.keys(stateColumns).map(key => stateColumns[key])
    dispatch(setTableColumns('editContactProjectsTable', colsData))
  }

  return (
    <div className={classes.tableWrapper}>
      <DataGridPro
        components={{
          NoRowsOverlay:EmptyTableText
        }}
        rows={props.tableValues}
        apiRef={apiRef}
        onColumnWidthChange={(e) => saveTableColumns()}
        columns={tableAppearance.length ? tableAppearance : columns}
        rowCount={props.tableValues?.length || 0}
        pagination
        rowsPerPageOptions={[10]}
        setPage={page}
        onPageChange={(e) => setPage(e.pageCount - 1)}
        pageSize={10}
        style={{borderRadius:'15px', border:'none'}}  
      />
    </div>
  )
}

const EmptyTableText = () => {
  return (
    <GridOverlay>
      <div>
        No projects here
      </div>
    </GridOverlay>
  )
}

export default ProjectsTable
