import React from 'react'
import { Alert, AlertTitle } from '@material-ui/lab';
import {notification} from 'antd'

notification.config({
  placement: 'bottomLeft',
  duration: 3,
})

export const openNotification = (errorMessage, onClose) => {
  notification.open({
    message: 
      <div 
        style={{
          display:'flex',
          justifyContent:'center',
          alignItems:'center',
          padding:'0px',
          color: 'red',
          fontWeight: 600,
          marginTop:'10px'
        }}
      >
        {errorMessage}
      </div>,
    onClick: onClose,
    onClose: onClose,
  });
};

const ErrorAlert = (props) =>  {
  return (
    <Alert 
      onClose={props.onClose}
      severity="error"
      style={{
        height: '110px', 
        width: '350px', 
        position:'absolute', 
        bottom: '10px', 
        left: '20px',
        zIndex: 1000,
      }}
    >
      <AlertTitle style={{marginTop:'10px'}}>Error</AlertTitle>
      {props.errorMessage}
    </Alert>
  )
}

export default ErrorAlert
