import React from 'react'
import classes from './Footer.module.css'
import {ReactComponent as LinkedinIcon} from './../../img/footer/linkedinIcon.svg'
import {ReactComponent as Logo} from './../../img/footer/logo.svg'
import dayjs from "dayjs";

const Footer = (props) => {

    const currentYear = dayjs().year()

  return (
    <footer className={classes.wrapper} id='footer'>
      <div className={classes.infoArea}>
        <Logo style={{height: '35px'}} className={classes.logo}/>
        <div style={{fontSize: '13px'}}>© Brickfield Consulting {currentYear}</div>
      </div>
      
      <div className={classes.iconsArea}>
        <a 
          target='_blank'
          href='https://www.linkedin.com/company/brickfield-consulting'
          rel="noopener noreferrer"
        >
          <LinkedinIcon className={classes.icon}/>
        </a>
      </div>
    </footer>
  )
}

export default Footer

