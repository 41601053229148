import React, {useCallback, useEffect, useState} from 'react'
import { connect } from 'react-redux'
import Contacts from './Contacts'
import {getOrganizations} from './../../store/organizationsReducer'
import {getStakeholders, deleteStakeholder, setStakeholders} from './../../store/stakeholdersReducer'
import {setTableColumns} from './../../store/tablesReducer'
import { syncContactsFromOfficeTC, syncContactsFromGoogleTC } from '../../store/synchronizationReducer'

const ContactsContainer = (props) => {  
  const [searchResults, setSearchResults] = useState(null)
  const [searchQuery, setSearchQuery] = useState('')
  const [isContactsSync, setIsContactsSync] = useState(false)
  const [isLoadingSyncContacts, setIsLoadingSyncContacts] = useState(false)

  useEffect(() => {
    if (!props.stakeholders?.length) props.getStakeholders()
    if (!props.organizations?.length) props.getOrganizations()
  }, [props])

  useEffect(() => {
    return () => props.setStakeholders([])
    // eslint-disable-next-line
  }, [])
  
  const onSearch = useCallback((query) => {
    if (!query?.length) {
      setSearchResults(null)
    } else {
      const results = props.stakeholders.filter(item => item.fullName?.toLowerCase().includes(query?.toLowerCase()))
      setSearchResults(results)
    }
  }, [props.stakeholders])

  const onSyncOfficeContacts = () => {
    setIsLoadingSyncContacts(true)
    props.syncContactsFromOfficeTC()
      .then(resp => {
        props.getStakeholders()
        setIsContactsSync(!!resp)
        setIsLoadingSyncContacts(false)
      })
  }

  const onSyncGoogleContacts = () => {
    setIsLoadingSyncContacts(true)
    props.syncContactsFromGoogleTC()
      .then(resp => {
        props.getStakeholders()
        setIsContactsSync(!!resp)
        setIsLoadingSyncContacts(false)
      })
  }

  useEffect(() => {
    onSearch(searchQuery)
  }, [searchQuery, props.stakeholders, onSearch])

  const deleteContact = (stkId, deactivateUser) => {
    props.deleteStakeholder(stkId, deactivateUser)
  }

  return (
    <Contacts 
      contacts={searchResults ? searchResults : props.stakeholders}
      deleteContact={deleteContact}
      organizations={props.organizations}
      onSearch={setSearchQuery}
      setTableColumns={props.setTableColumns}
      onSyncOfficeContacts={onSyncOfficeContacts}
      isSyncWithOffice={props.isSyncWithOffice}
      isContactsSync={isContactsSync}
      setIsContactsSync={setIsContactsSync}
      isLoadingSyncContacts={isLoadingSyncContacts}
      isSyncWithGoogle={props.isSyncWithGoogle}
      onSyncGoogleContacts={onSyncGoogleContacts}
    />
  )
}

const mapStateToProps = state => ({
  organizations: state.organizationsReducer.organizations,
  stakeholders: state.stakeholdersReducer.stakeholders,
  isSyncWithOffice: state.synchronizationReducer.isSynchronizedWithOffice,
  isSyncWithGoogle: state.synchronizationReducer.isSynchronizedWithGoogle,
})

export default connect(
  mapStateToProps,
  {
    getOrganizations,
    getStakeholders,
    deleteStakeholder,
    setTableColumns,
    setStakeholders,
    syncContactsFromOfficeTC,
    syncContactsFromGoogleTC
  })(ContactsContainer)
