const initialState = {
  connections: {
    isConnectionModalOpen: false,
    isConnectionInfoModalOpen: false,
    newConnectionData: null,
  },
  workers: {
    isWorkerModalOpen: false,
  },
  selectedElement: null,
  isConnectingActive: false,
}

const canvasReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_IS_CONNECTION_MODAL_OPEN':
      return {...state, connections: {...state.connections, isConnectionModalOpen: action.isOpen}}
    case 'SET_NEW_CONNECTION_DATA':
      return {...state, connections: {...state.connections, newConnectionData: action.connectionData}}
    case 'SET_IS_CONNECTION_INFO_MODAL_OPEN': 
      return {...state, connections: {...state.connections, isConnectionInfoModalOpen: action.isOpen}}

    case 'SET_IS_WORKER_MODAL_OPEN':
      return {...state, workers: {...state.workers, isWorkerModalOpen: action.isOpen}}
    case 'SET_NEW_WORKER_DATA':
      return {...state, workers: {...state.workers, newWorkerData: action.workerData}}  

    case 'SET_SELECTED_ELEMENT':
      return {...state, selectedElement: action.selectedElement}    
    case 'SET_IS_CONNECTING_ACTIVE':
      return {...state, isConnectingActive: action.isActive}    
    default: return state
  } 
}

export const setIsConnectionModalOpen = (isOpen) => ({type: 'SET_IS_CONNECTION_MODAL_OPEN', isOpen})
export const setNewConnectionData = (connectionData) => ({type: 'SET_NEW_CONNECTION_DATA', connectionData})
export const setIsConnectionInfoModalOpen = (isOpen) => ({type: 'SET_IS_CONNECTION_INFO_MODAL_OPEN', isOpen})

export const setIsWorkerModalOpen = (isOpen) => ({type: 'SET_IS_WORKER_MODAL_OPEN', isOpen})

export const setSelectedElement = (selectedElement) => ({type: 'SET_SELECTED_ELEMENT', selectedElement})
export const setIsConnectingActive = (isActive) => ({type: 'SET_IS_CONNECTING_ACTIVE', isActive})

export default canvasReducer
