const initialState = {
  error: null,
}

const errorsReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_ERROR':
      return {...state, error: action.error} 
    default: return state
  } 
}

export const setError = (error) => ({type: 'SET_ERROR', error})

export const handleError = (error) => (dispatch) => {
  if (error === 'no error') return
  let data = error?.response?.data
  if (data && data?.message) {
    dispatch(setError(data?.message))
  } else {
    dispatch(setError(error?.message ? error?.message : 'Some error occurred'))
  }
}

export default errorsReducer
