import React, {forwardRef, useEffect, useState} from 'react'
import ReactFlow, {ReactFlowProvider, MiniMap, useStoreState, useStoreActions} from 'react-flow-renderer'
import { Spin } from 'antd'
import classes from './Canvas.module.css'
import ControlPanel from './ControlPanel/ControlPanel'
import {canvasWidth, pageDefaultHeight} from '../../common/sizes'
import NewConnectionModalContainer from './modals/NewConnectionModal/NewConnectionModalContainer'
import NewWorkerModalContainer from './modals/NewWorkerModal/NewWorkerModalContainer'
import {SecondaryButton} from '../../common/buttons/buttons'
import ConnectionInfoModalContainer from './modals/ConnectionInfoModal/ConnectionInfoModalContainer'
import CustomEdge from './CustomEdge'
import DeleteConfirmationModal from './modals/DeleteConfirmationModal/DeleteConfirmationModal'
import AnnotationModal from './modals/AnnotationModal/AnnotationModal'
import Annotations from './Annotations/Annotations'
import { useSelector } from 'react-redux'
import moment from 'moment'
import {ReactComponent as Logo} from './../../../img/footer/logo.svg'
import { useMediaQuery } from 'react-responsive'
import {ReactComponent as UndoIcon} from './../../../img/icons/undoIcon.svg'
import {ReactComponent as SpinnerIcon} from './../../../img/icons/spinnerIcon.svg'
import WorkerCommentsModal from './modals/WorkerCommentsModal/WorkerCommentsModal'

const Canvas = forwardRef((props, ref) => {
  const [zoomIn, setZoomIn] = useState()
  const [reactFlowInstance, setReactFlowInstance] = useState(null)
  const [reactFlowActions, setReactFlowActions] = useState(null)
  const [isAnnotationModeActive, seIsAnnotationModeActive] = useState(false)

  const logo = useSelector(store => store.authReducer.logo)

  const onLoad = (reactFlowInstance) => {
    console.log('flow loaded:', reactFlowInstance)
    reactFlowInstance.fitView()
    setReactFlowInstance(reactFlowInstance)
    setZoomIn(reactFlowInstance)
    setTimeout(() => {reactFlowInstance.fitView()}, 100)
  }

  useEffect(() => {
    if(reactFlowInstance) setTimeout(() => {reactFlowInstance.fitView()}, 10) 
  }, [props.selectedProject, props.workersAmount, props.isCirclesView, props.selectedView, reactFlowInstance, props.isDataLoading])
  const isMobileScreen = useMediaQuery({ query: '(min-width: 360px) and (max-width: 766px)' })

  return (
    <div>
      <Spin indicator={<SpinnerIcon style={{ fontSize: 24 }} spin />} spinning={props.isDataLoading}>
        <ReactFlowProvider>
          <div 
            className={`reactFlowWrapper ${classes.reactFlowWrapper}`}
            style={{height: isMobileScreen ? `${window.innerHeight - 130}px` : `${pageDefaultHeight}px`, width:`${canvasWidth + 10}px`, minHeight:'500px'}}
            ref={ref}
          >
            <div className={classes.exportHeader}>
              <img src={`data:image/jpeg;base64,${logo}`} style={{width:'120px'}} alt=''/>
              <div style={{justifySelf:'center'}}>{props.selectedProjectData.name}</div>
              <div style={{justifySelf:'end'}}>{props.selectedProjectData.projectMarking}</div>
            </div>
            <div className={classes.exportHeader}>
              <div style={{justifySelf:'center', gridColumnStart: 2}}>Stakeholder Map</div>
            </div>
            <ReactFlow
              elements={props.elements}
              edgeTypes={{customLine: CustomEdge}}
              onElementsRemove={props.removeElement}
              onConnect={props.startCardsConnecting}
              onLoad={onLoad}
              onNodeDragStop={props.onNodeDragStop}
              snapToGrid={true}
              snapGrid={[15, 15]}
              minZoom={0.1}
              style={{
                backgroundColor:'white',
                borderRadius:'15px',
                cursor: isAnnotationModeActive ? 'url(annotationCursor.svg),auto' : 'default'
              }}
              id='canvas_to_pdf'
              className={classes.canvas}
              zoomOnDoubleClick ={false}
              onDoubleClick={(e) => {
                if(e.target?.classList[0]==='react-flow__edge-path') {
                  props.setIsConnectionInfoModalOpen(true)
                } else if (e.target.classList.contains('annotation-icon') && props.selectedView === 'userView') {
                  props.setIsAnnotationModalOpen({isOpen: true, isEditing: true})
                } else {
                  zoomIn.zoomIn()
                }
              }}
              onClick={(e) => {
                if (isAnnotationModeActive) {
                  const reactFlowBounds = ref.current.getBoundingClientRect()
                  const { project } = reactFlowInstance
                  props.setIsAnnotationModalOpen({
                    isOpen: true,
                    position: project({x: e.clientX - reactFlowBounds.left, y: e.clientY - reactFlowBounds.top})
                  })
                } else {
                  return
                }
              }}
            >
              {!!props.lastDeletedItems.length &&
                <div className={`controlPanel ${classes.undoButton}`} onClick={() => props.undoDeleting()}>
                  <UndoIcon style={{marginRight: '7px'}}/> Undo
                </div>
              }
              <NavigationMap/>
              <div className={`controlPanel ${classes.controlPanel}`}>
                <ControlPanel
                  removeElement={props.removeElement}
                  setIsWorkerModalOpen={props.setIsWorkerModalOpen}
                  setIsConnectingActive={props.setIsConnectingActive}
                  selectedProject={props.selectedProject}
                  reactFlowInstance={reactFlowInstance}
                  isPageBlocked={props.isPageBlocked}
                  toggleIsPageBlocked={props.toggleIsPageBlocked}
                  setIsAnnotationModalOpen={props.setIsAnnotationModalOpen}
                  seIsAnnotationModeActive={seIsAnnotationModeActive}
                  isAnnotationModeActive={isAnnotationModeActive}
                />
              </div>
              <div className={`annotations ${classes.annotations}`}>
                <Annotations
                  selectedView={props.selectedView}
                  isAnnotationModalOpen={props.isAnnotationModalOpen}
                  setIsAnnotationModalOpen={props.setIsAnnotationModalOpen}
                  changeSelectedElement={props.changeSelectedElement}
                  reactFlowSelectElement={reactFlowActions?.setSelectedElements}
                />
              </div>
              {props.isConnectingActive && 
                <ConnectingMessage 
                  selectedElement={props.selectedElement}
                  setIsConnectingActive={props.setIsConnectingActive}
                />
              }
              <NodesDebugger
                changeSelectedElement={props.changeSelectedElement}
                reactFlowActions={reactFlowActions}
                setReactFlowActions={setReactFlowActions}
              />
              <NewConnectionModalContainer />
              {props.isWorkerModalOpen && <NewWorkerModalContainer />}
              {props.isConnectionInfoModalOpen && <ConnectionInfoModalContainer removeElement={props.removeElement}/>}
              {props.isWorkerCommentModalOpen?.isOpen &&
                <WorkerCommentsModal
                  isWorkerModalOpen={props.isWorkerCommentModalOpen}
                  setIsWorkerCommentModalOpen={props.setIsWorkerCommentModalOpen}
                  selectedView={props.selectedView}
                />
              }
              {props.isAnnotationModalOpen.isOpen &&
                <AnnotationModal
                  isAnnotationModalOpen={props.isAnnotationModalOpen}
                  closeModal={() => {
                    seIsAnnotationModeActive(false)
                    props.setIsAnnotationModalOpen({isOpen: false})
                  }}
                />
              }
              {props.isDeleteConfirmOpen && 
                <DeleteConfirmationModal 
                  removeElement={props.removeElement} 
                  setIsDeleteConfirmOpen={props.setIsDeleteConfirmOpen}
                  isDeleteConfirmOpen={props.isDeleteConfirmOpen}
                  selectedProject={props.selectedProject}
                  selectedElement={props.selectedElement}
                />
              }
            </ReactFlow>
            <div className={classes.exportFooter}>
              <Logo style={{width: '200px'}}/>
              <div>{moment().format('DD-MM-YYYY')}</div>
            </div>
          </div>
        </ReactFlowProvider>
      </Spin>  
    </div>
  )
})

const NodesDebugger = (props) => {
  const selectedElements = useStoreState((state) => state.selectedElements)
  props.changeSelectedElement(selectedElements ? selectedElements[0] : null)

  const actions = useStoreActions((actions) => actions)
  if(props.reactFlowActions === null) {
    props.setReactFlowActions(actions)
  }
  return null
}

const NavigationMap = (props) => {
  return (
    <div className={`miniMapNavigation ${classes.miniMapNavigation}`} style={props.style}>
    <MiniMap
      nodeStrokeColor={(n) => {
        if (n.id.includes('annotation')) return 'black'
        if (n.style?.background) return n.style.background
        if (n.type === 'input') return '#0041d0'
        if (n.type === 'output') return '#ff0072'
        if (n.type === 'default') return '#1a192b'
        return '#eee'
      }}
      nodeColor={(n) => {
        if (n.style?.background) return n.style.background
        return '#fff'
      }}
      nodeBorderRadius={2}
    />
    </div>
  )
}

const ConnectingMessage = (props) => {
  const isHeaderSelected = props.selectedElement?.id.includes('header')
  return (
    <div className={classes.connectingMessage}>
      {isHeaderSelected && 
        <>
          <div>
            You cannot set a connection with the organisation title
          </div>
          <SecondaryButton 
            className={classes.cancelConnecting}
            onClick={() => props.setIsConnectingActive(false)}
            text='OK'
          />
        </>
      }
      {props.selectedElement && !isHeaderSelected &&
        <div>
          Select a card you want to connect with
          <SecondaryButton 
            className={classes.cancelConnecting}
            onClick={() => props.setIsConnectingActive(false)}
            text='CANCEL'
          />
        </div>
      }
      {!props.selectedElement && !isHeaderSelected &&
        <div>
          Select a first card for connection 
          <SecondaryButton 
            className={classes.cancelConnecting}
            onClick={() => props.setIsConnectingActive(false)}
            text='CANCEL'
          />
        </div>
      }
    </div>
  )

}

export default Canvas
