import React, { Fragment, useState }  from 'react'
import classes from './Matrix.module.css'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import {withStyles} from '@material-ui/core'
import {SecondaryButton} from '../common/buttons/buttons'
import Switch from './../common/Switch/Switch'
import TableContactsColumn from '../common/TableContactsColumn/TableContactsColumn'
import TableProjectsColumn from '../common/TableProjectsColumn/TableProjectsColumn'
import { getCurrentUserRoles } from '../../helpers/localStorage'
import getGroupedStakeholders from '../../helpers/groupedDataHelper'

const TableView = (props) => {
  const tableData = getGroupedStakeholders( props.matrixData)
  
  const [selectedColumns, setSelectedColumns] = useState(['fullName'])
  const [isColumnsMenuShown, setIsColumnsMenuShown] = useState(false)
  const selectColumn = (e, option) => {
    e.target.checked ? (
      setSelectedColumns([...selectedColumns, option.value])
    ) : (
      setSelectedColumns(selectedColumns?.filter(col => col !== option.value))
    )
  }

  const handleColumnsBtnClick = (e) => {
    if ((e.target.id === 'secondaryBtnText' || e.target.id === 'columnsBtn') && !isColumnsMenuShown) {
      setIsColumnsMenuShown(true)
    } else {
      setIsColumnsMenuShown(false)
    }
  }

  const options = [
    {id: 1, value: 'fullName', name: 'Name'},
    {id: 2, value: 'orgId', name: 'Organisation'},
    {id: 3, value: 'empType', name: 'Contact type'},
    {id: 4, value: 'jobTitle', name: 'Job title'},
    {id: 5, value: 'department', name: 'Department'},
    {id: 6, value: 'firstName', name: 'First name'},
    {id: 7, value: 'firstNameKnownAs', name: 'Known as'},
    {id: 8, value: 'lastName', name: 'Last name'},
    {id: 9, value: 'projects', name: 'Projects'},
    {id: 10, value: 'phones', name: 'Phones'},
    {id: 11, value: 'emails', name: 'Emails'},
    {id: 12, value: 'linkedInProfile', name: 'LinkedIn'},
    {id: 13, value: 'grade', name: 'Grade'},
    {id: 14, value: 'notes', name: 'Notes'},
    {id: 15, value: 'isActive', name: 'Status'},
  ]

  return (
    <div className={classes.tableViewWrapper} onClick={(e) => handleColumnsBtnClick(e)}>
      <div className={classes.tableViewTitle}>
        CONTACTS MATRIX TABLE
        <div style={{position:'relative'}}>
          <SecondaryButton text='COLUMNS' style={{width: '144px'}} id='columnsBtn'/>
          {isColumnsMenuShown &&
            <div 
              className={classes.optionsPopUp}
              onClick={(e) => {e.stopPropagation(); e.nativeEvent.stopImmediatePropagation()}} 
            >
              {options.map(opt => (
                <div style={{fontSize:'16px', marginBottom: '5px'}} key={opt.id}>
                  <Switch checked={selectedColumns.includes(opt.value)} size="small" onChange={(e) => selectColumn(e, opt)}/>
                  <span style={{marginLeft:'10px'}}>{opt.name}</span>
                </div>
              ))}
            </div>
          }
        </div>
      </div>
      
      <Paper className={classes.matrixTable}>
        <Table id='table_to_pdf'>
          <TableHead>
            <TableRow style={{position:'relative'}}>
              <TableCell className={classes.tableHeader}>Category</TableCell>
              {options.map(opt => {
                return selectedColumns.includes(opt.value) 
                  ? <TableCell className={classes.tableHeader} key={opt.id}>{opt.name}</TableCell> 
                  : <></>
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRowData 
              tableData={tableData}
              options={options}
              selectedColumns={selectedColumns}
              organizations={props.organizations}
            />
          </TableBody>
        </Table>
      </Paper>
    </div>
  )
}

const TransparentCell = withStyles(() => ({
  root: {borderBottom: '1px solid transparent'}
}))(TableCell)
const CellWithBorder = withStyles(() => ({
  root: {borderBottom: '1px solid rgba(224, 224, 224, 1)'}
}))(TableCell)

const TableRowData = ({tableData, options, selectedColumns, organizations}) => {
  return (
    <>
      {tableData.map(item => (
        <Fragment key={item.id}>
          <TableRow>
            {item.stakeholders?.length > 1 ? (
              <TransparentCell>
                {item.groupName}
              </TransparentCell>
            ) : (
              <TableCell>
                {item.groupName}
              </TableCell>
            )}
            {item.stakeholders?.length ? (
              <DataColumns
                stakeholder={item.stakeholders[0]}
                options={options}
                organizations={organizations}
                selectedColumns={selectedColumns}
              /> 
            ) : (
              <TableCell/>
            )}
          </TableRow>
          {item.stakeholders?.length > 1 && 
            item.stakeholders.map((detailItem, index) => {
              return (
                index !== 0 && 
                <TableRow>
                  {index === item.stakeholders?.length - 1 ? <CellWithBorder /> : <TransparentCell/>}
                  <DataColumns
                    stakeholder={detailItem}
                    options={options}
                    organizations={organizations}
                    selectedColumns={selectedColumns}
                  /> 
                </TableRow>
              )
            })
          }
        </Fragment>
      ))}
    </>
  )
}

const DataColumns = ({stakeholder, options, organizations, selectedColumns}) => {
  const columnNames = Object.keys(stakeholder)
  ?.filter(name => options.some(opt => opt.value === name))
  ?.sort(function(a, b) {
    return options.findIndex(opt => opt.value === a) - options.findIndex(opt => opt.value === b);
  })

  const currentUserRoles = getCurrentUserRoles()
  const isUser = currentUserRoles?.some(role => role.code.toLowerCase() === 'user')

  return (
    columnNames.map((col, index) => {
      const isStatusCol = col === 'isActive'
      const isPhonesCol = col === 'phones'
      const isEmailsCol = col === 'emails'
      const isCompanyCol = col === 'orgId'
      const isProjectsCol = col === 'projects'
      const isRegularColumn = !isStatusCol && !isPhonesCol && !isEmailsCol && !isCompanyCol && !isProjectsCol
      const isHiddenFromUserColumn = isPhonesCol || isEmailsCol || col === 'linkedInProfile'
      return (
        !selectedColumns.includes(col) || (stakeholder.isSensitiveContact && isUser && isHiddenFromUserColumn) ? (
          <></>
        ) : (
          <TableCell key={index}>
            {isRegularColumn && stakeholder?.[col] ? stakeholder?.[col] : ''}
            {isPhonesCol && !!stakeholder?.[col]?.length ? <TableContactsColumn data={stakeholder?.[col]} infoName='phone' isSensitiveContact={stakeholder.isSensitiveContact}/> : <></>}
            {isEmailsCol && !!stakeholder?.[col]?.length ? <TableContactsColumn data={stakeholder?.[col]} infoName='email' isSensitiveContact={stakeholder.isSensitiveContact}/> : <></>}
            {isProjectsCol && !!stakeholder?.[col]?.length ? <TableProjectsColumn projects={stakeholder?.[col]}/> : <></>}
            {isStatusCol && 
              <div 
                className={classes.tableIsActiveColumn}
                style={{backgroundColor: stakeholder?.[col] ? 'rgba(194, 232, 18, 0.6)' : 'rgba(246, 88, 88, 0.6)'}}
              >
                {stakeholder?.[col] ? 'Active' : 'Inactive'}
              </div>
            }
            {isCompanyCol && organizations?.find(org => org.orgId === stakeholder?.[col])?.name}
          </TableCell>
        )
      )
    })
  )
}

export default TableView
