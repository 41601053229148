import { Drawer } from 'antd'
import { useSelector } from 'react-redux'
import classes from './MobileHeaderTools.module.css'
import {ReactComponent as RightArrow} from './../../../img/icons/rightArrow.svg'
import {ReactComponent as DefaultLogo} from './../../../img/defaultLogo.svg'

const MobileHeaderTools = (props) => {
  const logo = useSelector(store => store.authReducer.logo)
  return (
    <Drawer
      closable={false}
      placement='right'
      visible={props.isToolsVisible}
      bodyStyle={{overflow:'visible', backgroundColor:'#eef8ff'}}
      width={'90%'}
    >
      <div className={classes.wrapper}>
        <div>
          <div onClick={() => props.setIsToolsVisible(false)} className={classes.backArrow}>
            Close <RightArrow />
          </div>
          {props.content}
        </div>
        {logo
          ? <img src={`data:image/jpeg;base64,${logo}`} className={classes.logo} alt=''/>
          : <DefaultLogo className={classes.logo}/>
        }
      </div>
    </Drawer>
  )
}

export default MobileHeaderTools
