import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import {updatePhoneArea} from './../../../../../../store/stakeholderFormReducer'
import PhoneArea from './PhoneArea'

const PhoneAreaContainer = (props) => {
  const [defaultPhone, setDefaultPhone] = useState(0)

  useEffect(() => {
    const defaultPhoneIndex = props.values.phones?.findIndex(phone => phone.isDefault === true)
    setDefaultPhone(defaultPhoneIndex)
  }, [props.values])

  const addNewField = () => {
    const index = props.phoneArea.length + 1
    props.setFieldValue(`phones[${props.phoneArea.length}].phone`, '')
    props.setFieldValue(`phones[${props.phoneArea.length}].phoneType`, 'mobile')
    props.updatePhoneArea([...props.phoneArea, index])
  }

  const removeField = () => {
    const lastElementIndex = props.phoneArea.length - 1
    let updatedPhones = props.values.phones.filter((_, index) => index !== props.phoneArea.length - 1)

    if (props.values.phones[lastElementIndex].isDefault === true) {
      updatedPhones[lastElementIndex-1].isDefault = true
      setDefaultPhone(lastElementIndex-1)
    }

    props.setFieldValue(`phones`, updatedPhones)
    const updatedInputs = props.phoneArea.filter(inputNumber => inputNumber !== props.phoneArea[lastElementIndex])
    props.updatePhoneArea(updatedInputs)
  }

  const changeDefaultPhone = (phoneIndex) => {
    setDefaultPhone(phoneIndex)
    props.setFieldValue('phones', props.values.phones.map((phoneData, index) => {
      if (index === phoneIndex) {
        return {...phoneData, isDefault: true}
      } else {
        return {...phoneData, isDefault: null}
      }
    }))
  }

  return (
    <PhoneArea 
      addNewField={addNewField}
      changeDefaultPhone={changeDefaultPhone}
      defaultPhone={defaultPhone}
      phoneArea={props.phoneArea}
      removeField={removeField}
      values={props.values}
    />
  )
}

const mapStateToProps = state => ({
  phoneArea: state.stakeholderFormReducer.phoneArea
})

export default connect(mapStateToProps, {updatePhoneArea})(PhoneAreaContainer)
