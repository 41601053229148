import React, {useEffect, useState} from 'react'
import {DataGridPro, GridToolbarColumnsButton, GridToolbarContainer, useGridApiRef} from '@mui/x-data-grid-pro'
import {useDispatch, useSelector} from 'react-redux'
import classes from './Users.module.css'
import getColumns from '../common/UsersTable/getColumns'
import {setTableColumns} from '../../store/tablesReducer'


const AdminsTable = (props) => {
    const removeUser = props.removeUser
    const setModalData = props.setModalData
    const userId = useSelector(state => state.authReducer.userId)
    const tableColumns = getColumns(setModalData, removeUser, props.tableRows ? props.tableRows : [], false, true, userId)
    const page = useSelector(state => state.usersReducer.currentPage)



    const tableAppearance = useSelector(state => state.tablesReducer?.usersTable)
    const apiRef = useGridApiRef()
    const dispatch = useDispatch()

    useEffect(() => {
        apiRef?.current?.subscribeEvent('columnHeaderDragEnd', () => saveTableColumns())
        // eslint-disable-next-line
    }, [apiRef])

    const saveTableColumns = () => {
        const stateColumns = apiRef?.current?.getAllColumns()
        const colsData = Object.keys(stateColumns).map(key => stateColumns[key])
        dispatch(setTableColumns('usersTable', colsData))
    }

    return (
        <div className={classes.tableWrapper}>
            <DataGridPro
                getRowId={e => e.userId}
                rows={props.tableRows || []}
                {...props.tableRows || []}
                apiRef={apiRef}
                onColumnWidthChange={(e) => saveTableColumns()}
                columns={tableAppearance.length ? tableAppearance.filter((c) => c !== false) : tableColumns.filter((c) => c !== false)}
                rowCount={props.tableRows?.length || 0}
                pagination
                setPage={page}
                // onPageChange={onPageChange}
                pageSize={10}
                rowsPerPageOptions={[10]}
                paginationMode={'client'}
                style={{borderRadius: '15px', border: 'none', width: '100%'}}
                components={{
                    Toolbar: CustomToolbar,
                }}
            />
        </div>
    )
}

const CustomToolbar = () => {
    return (
        <GridToolbarContainer>
            <GridToolbarColumnsButton/>
        </GridToolbarContainer>
    )
}

export default AdminsTable