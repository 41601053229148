import React from 'react'
import classes from './EmailArea.module.css'
import {Input} from './../../../../../common/Input/Input'
import {Radio} from '../../../formComponents'
import {ReactComponent as AddFieldIcon} from './../../../../../../img/form/addFieldIcon.svg'
import {ReactComponent as RemoveFieldIcon} from './../../../../../../img/form/removeFieldIcon.svg'

const EmailArea = (props) => {
  return (
    <div className={classes.emailBlock}>
      <div>
        {props.emailArea.map(input => {
          return (
            <div key={input} className={classes.emailInputRow}>
              <Input 
                label={`Email ${input}`}
                name={`emails[${input - 1}].email`}
                placeholder={`Email ${input}`}
                className='autoWidthInput'
              />
              {/* <Input   
                className='autoWidthInput'
                label={`Email ${input} Comment`}
                name={`emails[${input - 1}].comment`}
                placeholder='Comment'
              /> */}
              {props.emailArea?.length === input ? (
                <div className={classes.addInputBtn}>
                  {props.emailArea?.length > 1 && 
                    <RemoveFieldIcon 
                      className={classes.removeInputIcon}
                      onClick={() => props.removeField()} 
                    />
                  }
                  <AddFieldIcon 
                    className={classes.addInputIcon}
                    onClick={() => props.addNewField()}
                  />
                </div>
              ) : (
                <div></div>
              )}
            </div>
          )
        })}
      </div>

      <div className={classes.radioArea}>
        <Radio 
          value={props.defaultEmail}
          inputName='email'
          inputs={props.emailArea}
          onChange={event => props.changeDefaultEmail(Number(event.target.value))}
        />
      </div>
    </div>
  )
}

export default EmailArea
