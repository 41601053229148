import React from 'react'
import classes from './Login.module.css'
import LoginForm from './LoginForm'
import {ReactComponent as CompanyLogo} from './../../img/companyLogo.svg'
import AccessModal from './AccessModal/AccessModal'
import {Link} from 'react-router-dom'
import {useSelector} from "react-redux";
import {useNavigate} from "react-router";

const Login = (props) => {
    const currentLoginUser = useSelector(state => state.usersReducer.currentLoginUser)
    const navigate = useNavigate()
    const onGoBackFromTheRoles = () => {
        navigate(-1)
    }

    return (
        <div className={classes.wrapper}>
            <div className={classes.formArea}>
                <CompanyLogo className={classes.companyLogo}/>
                <div className={classes.formTitle} style={{
                    marginBottom: props.isMultipleTenant ? '10px' : '25px'
                }}>
                    {props.isRolePicker ? `WELCOME${currentLoginUser ? `, ${currentLoginUser}` : ''}` : props.isMultipleTenant ? `WELCOME${currentLoginUser ? `, ${currentLoginUser}` : ''}` : 'SIGN IN TO ACCOUNT'}
                </div>
                {
                    props.isMultipleTenant
                    &&
                    <p className={classes.formText}>
                        You have successfully logged in. To continue, please select a tenant from the list below.
                    </p>
                }
                {
                    props.isRolePicker
                    &&
                    <p className={classes.formText}>
                        There are several roles available for the selected user/tenant. Select a role to continue.
                    </p>
                }
                <LoginForm
                    loginError={props.loginError}
                    signIn={props.signIn}
                    isMultipleLogin={props.isMultipleTenant}
                    isRolePicker={props.isRolePicker}
                    isLoading={props.isLoading}
                />
                {
                    !props.isMultipleTenant
                    && !props.isRolePicker
                    &&
                    <>
                        <div style={{marginTop: '10px'}}>
            <span>
              Don’t have an account yet?
            </span>
                            <span
                                onClick={() => props.setIsAccessModalOpen(true)}
                                style={{color: '#0E9AFC', marginLeft: '6px', cursor: 'pointer', fontWeight: 600}}
                            >
              Request Access
            </span>
                        </div>
                        <Link to='/ForgotPassword'
                              style={{color: '#0E9AFC', marginTop: '10px', cursor: 'pointer', fontWeight: 600}}>
                            Forgot your password?
                        </Link>
                        {props.isAccessModalOpen &&
                            <AccessModal
                                setIsAccessModalOpen={props.setIsAccessModalOpen}
                                isAccessModalOpen={props.isAccessModalOpen}
                            />
                        }
                    </>
                }
                {
                    props.isRolePicker
                    &&
                    <div onClick={onGoBackFromTheRoles} style={{
                        display: 'flex',
                        alignItems: 'center'
                    }} className={classes.roleBack}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <path fill-rule="evenodd" clip-rule="evenodd"
                                  d="M15.0303 6.46967C15.3232 6.76256 15.3232 7.23744 15.0303 7.53033L10.5607 12L15.0303 16.4697C15.3232 16.7626 15.3232 17.2374 15.0303 17.5303C14.7374 17.8232 14.2626 17.8232 13.9697 17.5303L8.96967 12.5303C8.82902 12.3897 8.75 12.1989 8.75 12C8.75 11.8011 8.82902 11.6103 8.96967 11.4697L13.9697 6.46967C14.2626 6.17678 14.7374 6.17678 15.0303 6.46967Z"
                                  fill="black"/>
                        </svg>
                        <span>Back</span>
                    </div>
                }
                {
                    (props.isMultipleTenant || props.isRolePicker)
                    &&
                    <Link to='/login'
                          style={{color: 'black', cursor: 'pointer', textDecoration: 'underline', marginTop: '20px'}}>
                        Back to Sign in
                    </Link>
                }
            </div>
        </div>
    )
}

export default Login
