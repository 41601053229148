import React from 'react'
import classes from './Users.module.css'
import {pageDefaultHeight} from './../common/sizes'
import Search from './../common/Search/Search'
import UserInfoArea from '../common/UserInfoArea/UserInfoArea'
import UsersTable from './UsersTable'
import UsersModal from './UsersModal'
import {setUsersSearchString} from "../../helpers/localStorage";


const Users = (props) => {

    const onSearchUser = (val) => {
        setUsersSearchString(val.replace(/^\s+|\s+$/g, ''))
        props.search(val.replace(/^\s+|\s+$/g, ''))
    }


    return (
        <>
            <header className={classes.header}>
                <Search onChange={event => onSearchUser(event.target.value)} searchedValue={props.searchedValue}/>
                <UserInfoArea/>
            </header>
            <div className={classes.wrapper} style={{height: pageDefaultHeight}}>
                <div className={classes.titleArea}>
                    <h1 className={classes.pageTitle}>
                        USERS
                    </h1>
                </div>
                <UsersTable
                    removeUser={props.removeUser}
                    setModalData={props.setModalData}
                    tableRows={props.tableRows}
                    searchedValue={props.searchedValue}
                />
                {props.modalData.isOpen &&
                    <UsersModal
                        modalData={props.modalData}
                        setModalData={props.setModalData}
                        searchUser={props.search}
                        searchedValue={props.searchedValue}
                        setTableRows={props.setTableRows}
                        isUsersTable={props.isUsersTable}
                    />
                }
            </div>
        </>
    )
}

export default Users
