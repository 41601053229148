import React, { useEffect, useState } from 'react'
import {VictoryScatter, VictoryChart, VictoryAxis, VictoryLabel} from 'victory'
import lodash from 'lodash'
import { Popover, Tooltip } from 'antd'
import { v4 } from 'uuid'
import { useMediaQuery } from 'react-responsive'
import classes from './ProjectSupportChart.module.css'
import {ReactComponent as UsersIcon} from './../../../img/icons/usersIcon.svg'
import {ReactComponent as OneUserIcon} from './../../../img/icons/oneUserIcon.svg'
import {ReactComponent as DownArrow} from './../../../img/icons/downArrow.svg'

const PersonIcon = (props) => {
  const {x, y, datum} = props
  let iconColor = datum.organizationColor || '#d9d9d9'

  const yPosition = y - 15
  const xPosition = props.datum.fullName ? x - 21 : x - 20

  const singlePersonIcon = (
    <svg x={xPosition} y={yPosition} width='44'  height='34' version='1.1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 54.8 54.9'>
      <path  fill={iconColor} d='M26.8,3c-4.4,0-7.9,3.5-7.9,7.8s3.5,7.8,7.9,7.8s7.9-3.5,7.9-7.8S31.2,3,26.8,3z'/>
      <path  fill={iconColor} d='M31.5,22c-3.1-0.5-6.2-0.5-9.3,0l-0.3,0.1c-4.4,0.6-7.6,4.3-7.6,8.7c0,2.3,1.9,4.2,4.3,4.2H35c2.4,0,4.3-1.9,4.3-4.2c0-4.3-3.2-8-7.5-8.7L31.5,22z'/>
    </svg>
  )
  const doublePersonIcon = (
    <svg x={xPosition} y={yPosition} width='44'  height='34' version='1.1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 54.8 54.9' >
      <path  fill={iconColor} d="M13.9,4.5C9.5,4.5,6,8,6,12.3c0,4.3,3.5,7.8,7.9,7.8c4.4,0,7.9-3.5,7.9-7.8C21.8,8,18.2,4.5,13.9,4.5z"/>
      <path fill={iconColor}  d="M18.5,23.5c-3.1-0.5-6.2-0.5-9.3,0l-0.3,0.1c-4.3,0.7-7.5,4.4-7.5,8.7c0,2.3,1.9,4.2,4.3,4.2h16.4c2.4,0,4.3-1.9,4.3-4.2c0-4.3-3.2-8-7.5-8.7L18.5,23.5z"/>
      <path fill={iconColor} d="M28.7,2.6c-3.5,0-6.4,3-6.4,6.6c0,3.6,2.9,6.6,6.4,6.6c3.5,0,6.4-3,6.4-6.6C35.1,5.6,32.3,2.6,28.7,2.6z"/>
      <path fill={iconColor}  d="M32.5,18.8c-2.5-0.4-5.1-0.4-7.6,0l-0.3,0c-3.5,0.6-6.1,3.7-6.1,7.4c0,2,1.6,3.6,3.5,3.6h13.4c1.9,0,3.5-1.6,3.5-3.6c0-3.7-2.6-6.8-6.1-7.4L32.5,18.8z"/>
    </svg>
  )
  return props.datum.fullName ? (
    <Tooltip 
      title={
        <div style={{color: 'black'}}>
          {props.datum.fullName}
        </div>
      }
      color='white'
    >
      {singlePersonIcon}
    </Tooltip>
  ) : (
    <Tooltip 
      title={() => datum.data.map(item => (
        <div key={v4()} style={{color:item.organizationColor}}>
          {item.fullName}
        </div>
      ))}
      color='white'
    >
      {doublePersonIcon}
    </Tooltip>
  ) 
}

const ProjectSupportChartArea = (props) => {
  const peopleData = props.people
    ?.filter(person => person.supportLvl !== null && !!person.influenceLvl)
    ?.map(person => ({...person, x:person.supportLvl, y: person.influenceLvl}))
  const groupedData = lodash.groupBy(peopleData, (data) => `${data.x}/${data.y}`)
  const chartData = []
  for (let [key, value] of Object.entries(groupedData)) {
    if (value.length === 1) {
      chartData.push(value[0])
    } else {
      let x = Number(key.substr(0, key.indexOf('/')))
      let y = Number(key.substr(key.indexOf('/') + 1))
      chartData.push({x, y, data: value, influenceLvl: value[0]?.influenceLvl})
    }
  }

  const stkWithEmptyValue = props.people?.filter(stk => stk.supportLvl === null || !stk.influenceLvl)
  // const lineCoordinates = getLineCoordinates(props.connections, peopleData, chartData)
  const isMobileScreen = useMediaQuery({ query: '(min-width: 360px) and (max-width: 766px)' })

  const [chartSize, setChartSize] = useState({
    height: isMobileScreen ? window.innerHeight - 190 : window.innerHeight - 200,
    width: isMobileScreen ?  window.innerWidth - 20 : window.innerWidth - 50
  })

  const [isUndecidedListOpen, setIsUndecidedListOpen] = useState(false)

  useEffect(() => {
    const changeChartSize = () => {
      setChartSize({
        height: isMobileScreen ? window.innerHeight - 190 : window.innerHeight - 200,
        width: isMobileScreen ?  window.innerWidth - 20 : window.innerWidth - 50
      })
    }
    window.addEventListener('resize', changeChartSize)
    return () => window.removeEventListener('resize', changeChartSize) 
  }, [isMobileScreen])

  return (
    <div className={classes.chartWrapper} id='projectSupportToPdf'>
      <div className={classes.influenceLabel}>
        INFLUENCE
      </div>
      <div className={classes.yLabel}>
        10
      </div>
      <div className={classes.supportLabel}>
        SUPPORT
      </div>
      <div className={classes.zeroXLabel}>
        0
      </div>
      <div className={classes.leftXLabel}>
        -5
      </div>
      <div className={classes.rightXLabel}>
        +5
      </div>
      <VictoryChart height={chartSize.height < 500 ? 500 : chartSize.height} width={chartSize.width} >
        <VictoryAxis
          tickValues={[]}
          tickFormat={[]}
        />
        <VictoryAxis
          dependentAxis
          tickFormat={[]}
        />
        {/* {props.isConnectionStrengthOn && 
          lineCoordinates.map(line => (
            <VictoryLine
              key={line.lineId}
              style={{data: {stroke: '#14496F', strokeWidth: ({data}) => data[0].strength}}}
              data={line}
            />
          ))
        } */}
        <VictoryScatter 
          domain={{x: [-5, 5], y: [0, 10.5]}}
          data={chartData} 
          dataComponent={
            <PersonIcon 
              connections={props.connections}
              people={peopleData}
              isConnectionStrengthOn={props.isConnectionStrengthOn}
            />
          }
          labels={({ datum }) => datum.fullName ? datum.fullName : (props.showAllNames ? datum.data?.map(i => i.fullName) : `${datum.data?.length} people`)}
          labelComponent={
            <VictoryLabel 
              dy={({ datum }) => {
                return datum?.fullName
                  ? 16
                  : props.showAllNames ? 25 : 16
              }}
              style={[{ fontSize: 10 }]}
            />
          }
        />
      </VictoryChart>
      {stkWithEmptyValue?.length &&
        <Popover
          content={
            <>
              {stkWithEmptyValue?.map(stk => (
                <div key={stk.stakeholderId} style={{display: 'flex', alignItems: 'center', padding: '8px'}}>
                  <OneUserIcon style={{height: 20, width: 20, fill: stk.organizationColor, marginRight: '7px'}} />
                  {stk.fullName}
                  {!stk.influenceLvl && !stk.interestLvl 
                    ? ' (Unknown)' 
                    : (` (Influence - ${stk.influenceLvl || 'Unknown'}; Support - ${stk.supportLvl || 'Unknown'})`)
                  }
                </div>
              ))}
            </>
          }
          onVisibleChange={() => setIsUndecidedListOpen(!isUndecidedListOpen)}
          placement='bottom'
          trigger='click'
        >
          {isMobileScreen ? (
            <UsersIcon className={classes.noDataUsersIcon}/>
          ) : (
            <div className={classes.undecidedButton}>
              <UsersIcon className={classes.noDataUsersIcon}/>
              <span>Undecided</span>
              <DownArrow
                style={{
                  height: '10px',
                  width: '10px',
                  marginLeft: '7px',
                  transform: isUndecidedListOpen ? 'rotate(180deg)' : ''
                }}
              />
            </div>
          )}
        </Popover>
      }
    </div>
  )
}

export default ProjectSupportChartArea
