import axios from 'axios'
import moment from 'moment'
import {getTokenData, setTokenData} from './../helpers/localStorage'

axios.interceptors.request.use(async(request) => {
  const {token, tokenExpiryDate} = getTokenData()

  const requestUrlsWithNoToken = ['/users/login', '/users/request-access', '/password', '/reset-password', '/users/customers/login', '/login/choose-tenant']

  if(!token && !requestUrlsWithNoToken.some(url => request.url.includes(url))) return Promise.reject('no error')
  if (token && !request.url?.includes('/refresh')) {
    request.headers['Authorization'] = token 
    const isTokenExpired = moment().isAfter(tokenExpiryDate)
    if (isTokenExpired) {
      await axios.get('/refresh', { headers: {
        'Authorization': token
      }}).then(({data}) => {
        setTokenData(data.token, data.tokenExpiryDate)
        window.dispatchEvent(new Event('storage'))
        request.headers['Authorization'] = data.token
      })
    }
  }
  return request
})

axios.defaults.baseURL = window.env.API_DOMAIN_ADDR

export default axios
