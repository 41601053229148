import React, { useState, useEffect, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import axios from './../../helpers/axiosHelper'
import Matrix from './Matrix'
import {matrixWidth} from './../common/sizes'
import {exportAsPdf, exportAsPdfTable} from './../../helpers/exportHelper'
import { getProjectsByPeriod, selectProjectTC } from '../../store/projectsReducer'
import { getOrganizations } from '../../store/organizationsReducer'
import { getGroupedProjectOptions } from '../../helpers/groupedDataHelper'

const MatrixContainer = (props) => {
  const dispatch = useDispatch()
  const projects = useSelector(state => state.projectsReducer.projectList)
  const organizations = useSelector(state => state.organizationsReducer.organizations)
  const selectedProject = useSelector(state => state.projectsReducer.selectedProject)
  const userId = useSelector(state => state.authReducer.userId)

  const [isMatrixViewActive, toggleIsMatrixViewActive] = useState(true)
  const [isLoaded, setIsLoaded] = useState(false)
  const [stakeholdersList, setStakeholdersList] = useState([])
  const [isProjectTeamContactOn, setIsProjectTeamContactOn] = useState(true)
  const [matrixData, setMatrixData] = useState([])
  const [error, setError] = useState(null)
  const [isEmpty, setIsEmpty] = useState(false)

  const selectProject = useCallback((value) => {
    dispatch(selectProjectTC(value, projects))
  }, [dispatch, projects])

  useEffect(() => {
    if (stakeholdersList?.length) {
      isProjectTeamContactOn
      ? setMatrixData(stakeholdersList)
      : setMatrixData(stakeholdersList.filter(person => !person.isProjectTeamMember))
    }
  }, [isProjectTeamContactOn, stakeholdersList])

  useEffect(() => {
    if(!!userId) {
      setIsLoaded(false)
      Promise.allSettled([
        dispatch(getOrganizations(true)),
        dispatch(getProjectsByPeriod(null, false, userId)),
        !!selectedProject && axios.get(`/projects/${selectedProject}/matrix`)
          .then(response => {
            setStakeholdersList(response.data.responses[0]?.stakeholders)
            setMatrixData(response.data.responses[0]?.stakeholders)
          })
          .then(() => setIsLoaded(true)),
      ]).then((res) => {
        const projectsResp = res[1]?.value
        if (!!projectsResp?.length) {
          setIsEmpty(false)
          setIsLoaded(true)
        } else if (!projectsResp?.length){
          setIsEmpty(true)
          setIsLoaded(true)
        } else {
          return 
        }
      })
    }
  }, [userId, dispatch, selectedProject])

  const exportMatrixAsPdf = () => {
    const selectedProjectData = projects.find(project => project.projId === selectedProject)
    const selectedProjectTitle = selectedProjectData?.name
    const selectedProjectMarking = selectedProjectData?.projectMarking

    isMatrixViewActive ? (
      exportAsPdf(document.getElementById('matrix_to_pdf'), `${selectedProjectTitle}_InfluenceMatrix`, matrixWidth, null, null, selectedProjectTitle, 'Influence Matrix', selectedProjectMarking)
    ) : (
      exportAsPdfTable('table_to_pdf', `${selectedProjectTitle}_InfluenceMatrixTable`, selectedProjectTitle, 'Influence Matrix', selectedProjectMarking)
    )
  }

  return (
    <Matrix 
      error={error}
      exportMatrixAsPdf={exportMatrixAsPdf}
      isLoaded={isLoaded}
      isMatrixViewActive={isMatrixViewActive}
      isEmpty={isEmpty}
      matrixData={matrixData}
      toggleIsMatrixViewActive={toggleIsMatrixViewActive}
      selectProjectOptions={getGroupedProjectOptions(projects)}
      selectedProject={selectedProject}
      selectProject={selectProject}
      setError={setError}
      organizations={organizations}
      setIsProjectTeamContactOn={setIsProjectTeamContactOn}
      isProjectTeamContactOn={isProjectTeamContactOn}
    />
  )
}

export default MatrixContainer
