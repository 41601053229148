import { Dialog, DialogContent } from '@material-ui/core'
import { PrimaryButton } from './../../common/buttons/buttons'
import classes from './SuccessModal.module.css'

const SuccessModal = (props) => {
  return (
    <Dialog
      open={props.isOpen}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
    >
      <DialogContent className={classes.successMessageModal}>
        <div className={classes.successMessage}>
          {props.text || 'All changes have been saved'}
        </div>
        <div className={classes.successMessageButtons}>
          <PrimaryButton
            text='OK' 
            onClick={props.onOk}
            style={{width: '130px', height: '40px'}}
          />
        </div>
      </DialogContent>
    </Dialog>
  )
}

export default SuccessModal
