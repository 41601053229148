import { useCallback, useEffect, useRef, useState } from 'react'
import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux'
import { DataGridPro } from '@mui/x-data-grid-pro'
import { CSVLink } from 'react-csv'
import { orderBy } from 'lodash'
import { pageDefaultHeight, tableWidth } from '../../common/sizes'
import classes from './Summary.module.css'
import UserInfoArea from '../../common/UserInfoArea/UserInfoArea'
import Select from '../../common/Select/Select'
import Checkbox from '../../common/Checkbox/Checkbox'
import { getEngagementsHistoryTC } from './../../../store/engagementReducer'
import { getProjectList, selectProjectTC } from '../../../store/projectsReducer'
import {ReactComponent as NoDataImg} from './../../../img/noDataImg.svg'
import Spinner from '../../common/Spinner/Spinner'
import ProjectSelect from '../../common/ProjectSelect/ProjectSelect'

const Summary = () => {
  const dispatch = useDispatch()
  const engagementsHistory = useSelector(store => store.engagementReducer.engagementsHistory)
  const projects = useSelector(state => state.projectsReducer.projectList)
  const selectedProject = useSelector(state => state.projectsReducer.selectedProject)
  const csvLink = useRef()
  
  const [isPageLoading, setIsPageLoading] = useState(false)
  const [error, setError] = useState(null)
  const [selectedPeriod, setSelectedPeriod] = useState('nextWeek')
  const [selectedStakeholderGroups, setSelectedStakeholderGroups] = useState([
    'Manage Closely',
    'Keep Satisfied',
    'Keep Informed',
    'Monitor',
    'Unknown data',
  ])
  const [tableData, setTableData] = useState(engagementsHistory)

  const [sortModel, setSortModel] = useState([
    {
      field: 'due_in_days',
      sort: 'asc',
    },
  ])

  const periodOptions = [
    {id: 1, name: 'Next Week', value: 'nextWeek', endDate: moment().add(1, 'weeks')},
    {id: 2, name: 'Next 2 Weeks', value: 'next2Weeks', endDate: moment().add(2, 'weeks')},
    {id: 3, name: 'Next Month', value: 'nextMonth', endDate: moment().add(1, 'months')},
    {id: 4, name: 'Next Quarter', value: 'nextQuarter', endDate: moment().add(3, 'months')},
    {id: 5, name: 'All', value: 'all'},
  ]

  const stakeholderGroupOptions = [
    {id: 1, name: 'Manage Closely', value: 'Manage Closely'},
    {id: 2, name: 'Keep Satisfied', value: 'Keep Satisfied'},
    {id: 3, name: 'Keep Informed', value: 'Keep Informed'},
    {id: 4, name: 'Monitor', value: 'Monitor'},
    {id: 5, name: 'Not Defined', value: 'Unknown data'},
  ]

  useEffect(() => {
    setIsPageLoading(true)
    dispatch(getProjectList())
    .then((resp) => {
      if (!resp?.length) {
        setError('noProjects')
      }
    })
  }, [dispatch])

  useEffect(() => {
    if (!!selectedProject) {
      setIsPageLoading(true)
      setError('')
      const selectedEndDate = periodOptions.find(opt => opt.value === selectedPeriod)?.endDate
      selectedPeriod !== 'all'
        ? dispatch(getEngagementsHistoryTC(false, moment(), selectedEndDate, selectedProject))
          .then(() => setIsPageLoading(false))
        : dispatch(getEngagementsHistoryTC(false, null, null, selectedProject))
          .then(() => setIsPageLoading(false))
    }
    // eslint-disable-next-line
  }, [selectedPeriod, selectedProject, dispatch])

  useEffect(() => {
    setTableData(engagementsHistory.filter(eng => selectedStakeholderGroups.some(group => eng.stakeholder_group === group)))
  }, [selectedStakeholderGroups, engagementsHistory])

  const getSelectProjectOptions = () => {
    const options = projects.map(project => ({id: project.projId, value: project.projId, name: project.name}))
    return orderBy(options, [user => user.name.toLowerCase()], ['asc'])
  }
  const selectProject = useCallback((value) => {
    dispatch(selectProjectTC(value, projects))
    setIsPageLoading(false)
  },[projects, dispatch])

  if (error === 'noProjects') {
    return (
      <>
        <header className={classes.header} style={{display:'flex', justifyContent:'flex-end'}}>
          <UserInfoArea />
        </header>
        <div className='emptyPage' style={{height: pageDefaultHeight}}>
          <NoDataImg />
        </div>
      </>
    )
  }

  if(!!isPageLoading) {
    return <Spinner style={{minHeight: pageDefaultHeight - 100 }}/>
  }

  return (
    <>
      <header className={classes.header}>
        <ProjectSelect
          options={getSelectProjectOptions()}
          onSelect={(value) => selectProject(value)}
          style={{marginRight:'20px', width:'290px'}}
          value={selectedProject}
        />
        <UserInfoArea onExport={() => csvLink.current.link.click()} isCsvExport/>
      </header>
      <div className={classes.wrapper} style={{height: pageDefaultHeight}}>
        <h1 className={classes.pageTitle}>
          UPCOMING ENGAGEMENTS
        </h1>
        <Select 
          options={periodOptions}
          style={{width: '200px', marginRight: '15px'}}
          className='smallSelect'
          value={selectedPeriod}
          onSelect={(val) => setSelectedPeriod(val)}
        />
        <Select 
          options={stakeholderGroupOptions}
          style={{width: '250px', marginRight: '15px'}}
          className='smallSelect'
          placeholder={<span style={{color: 'black'}}>Stakeholder Groups</span>}
          dropdownRender={() => (
            <div>
              {stakeholderGroupOptions.map(opt => (
                <div key={opt.id} className={classes.stakeholderGroupOptionWrap}>
                  <Checkbox
                    checked={selectedStakeholderGroups?.some(group => group === opt.value)}
                    onChange={(e) => {
                      !!e.target.checked
                        ? setSelectedStakeholderGroups([...selectedStakeholderGroups, opt.value])
                        : setSelectedStakeholderGroups(selectedStakeholderGroups.filter(group => group !== opt.value))
                    }}
                  />
                  <span>{opt.name}</span>
                </div>
              ))}
            </div>
          )}
        />
        <div className={classes.tableWrapper}>
          <DataGridPro
            getRowId={e => e.id || null}
            rows={tableData} 
            columns={getColumns()}
            rowCount={tableData?.length || 0}
            disableSelectionOnClick
            style={{borderRadius:'15px', border:'none'}}
            sortModel={sortModel}
            onSortModelChange={(model) => {
              if(JSON.stringify(model) !== JSON.stringify(sortModel)) setSortModel(model)
            }}
            components={{
              NoRowsOverlay: () => (
                <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%'}}>
                  No engagements for selected period
                </div>
              ),
            }}
          />
        </div>
      </div>
      <CSVLink
        data={tableData?.length
          ? tableData
            .map(row => (
              { ...row,
                stakeholder_group: row.stakeholder_group === 'Unknown data' ? 'Not Defined' : row.stakeholder_group,
                last_engagement_date: row?.last_engagement_date ? moment(row?.last_engagement_date).format('YYYY-MM-DD') :'No data',
                engagement_plan_date: row?.engagement_plan_date ? moment(row?.engagement_plan_date).format('YYYY-MM-DD') :'No data',
                due_in_days: row?.due_in_days ? row.due_in_days :'Completed',
              }
            ))
            .sort((a, b) => {
              if(a?.due_in_days < b?.due_in_days) { return -1 }
              if(a?.due_in_days > b?.due_in_days) { return 1 }
              return 0
            })
          : []
        }
        headers={
          getColumns()
            .map(col => ({label: col.headerName, key: col.field}))
        }
        style={{display:'none'}}
        ref={csvLink}
        filename={`Engagements_${selectedPeriod}.csv`}
      >
        Download
      </CSVLink>
    </>
  )
}

const getColumns = () => {
  const columnSize = 0.2
  const columnWidth = tableWidth * columnSize
  return [
    { field: 'full_name',
      headerName: 'Full Name',
      renderCell: (params) => {
        const isExpired = moment(params.row.engagement_plan_date).isBefore(moment()) && !params.row.is_completed
        return (
          <div style={isExpired ? {color:'red'} : {}}>
            {params.row?.full_name}
          </div>
        )
      },
      width: columnWidth
    },
    { field: 'stakeholder_group',
      headerName: 'Stakeholder Group',
      renderCell: (params) => {
        const isExpired = moment(params.row.engagement_plan_date).isBefore(moment()) && !params.row.is_completed
        return (
          <div style={isExpired ? {color:'red'} : {}}>
            {params.row?.stakeholder_group === 'Unknown data'
              ? 'Not Defined'
              : params.row?.stakeholder_group
            }
          </div>
        )
      },
      width: columnWidth
    },
    { field: 'last_engagement_date',
      headerName: 'Last Engagement Date',
      renderCell: (params) => {
        const isExpired = moment(params.row.engagement_plan_date).isBefore(moment()) && !params.row.is_completed
        return (
          <div style={isExpired ? {color:'red'} : {}}>
            {!!params.row?.last_engagement_date
              ? moment(params.row?.last_engagement_date).format('YYYY-MM-DD')
              : 'No data'
            }
          </div>
        )
      },
      width: columnWidth
    },
    { field: 'engagement_plan_date',
      headerName: 'Engagement Due Date',
      renderCell: (params) => {
        const isExpired = moment(params.row.engagement_plan_date).isBefore(moment()) && !params.row.is_completed
        return (
          <div style={isExpired ? {color:'red'} : {}}>
            {!!params.row?.engagement_plan_date
              ? moment(params.row?.engagement_plan_date).format('YYYY-MM-DD')
              : 'No data'
            }
          </div>
        )
      },
      width: columnWidth
    },
    { field: 'due_in_days',
    headerName: 'Due In Days',
    renderCell: (params) => {
      const isExpired = moment(params.row.engagement_plan_date).isBefore(moment()) && !params.row.is_completed
      return (
        <div style={isExpired ? {color:'red'} : {}}>
          {!!params.row?.due_in_days
            ? params.row?.due_in_days
            : 'Completed'
          }
        </div>
      )
    },
    width: columnWidth
  },
  ]
}

export default Summary
