import React from 'react'
import { Modal} from 'antd'
import {Form, Formik} from 'formik'
import classes from './NewConnectionModal.module.css'
import { PrimaryButton, SecondaryButton } from '../../../../common/buttons/buttons'
import Slider from '../../../../common/Slider/Slider'
import {Input} from '../../../../common/Input/Input'
import Switch from '../../../../common/Switch/Switch'

const NewConnectionModal = (props) => {
  return (
    <Modal 
      closable={false}
      footer={null}
      style={{padding: '10px'}}
      visible={props.isConnectionModalOpen} 
    >
      <NewConnectionModalForm {...props}/>
    </Modal>
  )
}

const NewConnectionModalForm = (props) => { 
  let clearForm 
  const initialValues = {
    purpose: '',
    strength: null,
  }

  const onSubmit = (formData) => {
    props.setConnection(formData)
    clearForm()
  }

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit}>
      {({setFieldValue, values, resetForm}) => {
        clearForm = resetForm
        return (
        <Form className={classes.form}>
          <div className={classes.inputLabel}>
            Relationship Purpose
          </div>
          <div style={{height: '180px'}}>
            <Input 
              name='purpose'
              placeholder='Purpose'
              multiline={true}
              rows={6}
            />
          </div>
          <Switch
            leftLabel={<span style={{fontWeight: 600}}>Relationship Strength</span>}
            checked={values.strength !== null}
            onChange={() => values.strength !== null ? setFieldValue('strength', null) : setFieldValue('strength', 1)}
          />
          {values.strength !== null &&
            <>
              <div className={classes.inputLabel}>
                Relationship Strength
              </div>
              <div style={{width: '70%'}}>
                <Slider 
                  defaultValue={values.strength}
                  onChange={(_, value) => setFieldValue('strength', value)}
                  value={values.strength} 
                  min={1}
                  max={10}
                />
              </div>
            </>
          }
          <div className={classes.buttonsArea}>
            <SecondaryButton
              onClick={() => {
                props.cancelConnecting()
                resetForm()
              }}
              style={{width:'100px', height: 'auto'}}
              text='Cancel' 
            />
            <PrimaryButton 
              className={classes.primaryButton}    
              htmlType='submit' 
              style={{width:'100px', height: 'auto'}}
              text='SAVE' 
            />
          </div>
        </Form>
      )}}
    </Formik>
  )
}

export default NewConnectionModal
