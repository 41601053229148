import { Checkbox, Divider, Input, Modal, Popconfirm } from 'antd'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { addCommentToStakeholderTC, deleteCommentToStakeholderTC, editCommentToStakeholderTC } from '../../../../../store/projectsReducer'
import { PrimaryButton, SecondaryButton } from '../../../../common/buttons/buttons'
import classes from './WorkerCommentsModal.module.css'
import {ReactComponent as DeleteIcon} from './../../../../../img/stakeholderMap/annotations/deleteIcon.svg'
import {ReactComponent as EditIcon} from './../../../../../img/stakeholderMap/annotations/editIcon.svg'

const WorkerCommentsModal = ({isWorkerModalOpen, setIsWorkerCommentModalOpen, selectedView}) => {
  const dispatch = useDispatch()
  const userId = useSelector(state => state.authReducer.userId)
  const users = useSelector(state => state.usersReducer.userList)
  const projectStakeholders = useSelector(state => state.projectsReducer.projectStakeholders)

  const [comments, setComments] = useState(null)
  const [editingComment, setEditingComment] = useState(null)

  useEffect(() => {
    if (isWorkerModalOpen?.worker) {
      const stkData = projectStakeholders?.find(stk => stk.stakeholderId === isWorkerModalOpen?.worker?.stakeholderId)
      
      setComments(selectedView === 'projectView' ? stkData?.comments.filter(c => c.is_project_view_visible) : stkData?.comments)
    }
  }, [isWorkerModalOpen, projectStakeholders, selectedView])

  const deleteComment = (comment) => {
    dispatch(deleteCommentToStakeholderTC({id: comment.id, stakeholder_id: comment.stakeholder_id}))
  }

  return (
    <Modal
      closable={false}
      footer={null}
      onCancel={() => setIsWorkerCommentModalOpen({isOpen: false})}
      visible={isWorkerModalOpen.isOpen} 
      className={classes.contentWrapper}
    >
      <h1>
        {isWorkerModalOpen?.worker?.firstNameKnownAs}’s Comments
      </h1>
        <div className={classes.commentsWrapper}>
          {comments?.map((comment, index) => {
            const author = users.find(u => u.userId === comment.user_id)
            return (
              <div key={comment.id} className={classes.comment}>
                {editingComment?.id === comment.id ? (
                  <CommentForm
                    handleCancel={() => setEditingComment(null)}
                    stakeholderId={isWorkerModalOpen.worker.stakeholderId}
                    editingComment={editingComment}
                  />
                ) : (
                  <>
                    <div className={classes.author}>
                      {`${author.firstNameKnownAs} ${author.lastName}`}
                      {comment.user_id === userId &&
                        <div className={classes.modificationIcons}>
                          <Popconfirm
                            title={'Are you sure to delete this comment?'}
                            onConfirm={() => deleteComment(comment)}
                            okText='Yes'
                            cancelText='No'
                            cancelButtonProps={{type: 'primary', style:{background: '#1890FF'}}}
                            okButtonProps={{type: 'default'}}
                          >
                            <DeleteIcon />
                          </Popconfirm>
                          <EditIcon 
                            style={{marginLeft: '7px'}}
                            onClick={() => setEditingComment(comment)}
                          />
                        </div>
                      }
                    </div>
                    <div className={classes.commentText}>
                      {comment.comment}
                    </div>
                    {index < comments?.length - 1 && <Divider style={{margin: '15px 0px 0px 0px'}} />}
                  </>
                )}
              </div>
            )
          })}
        </div>
        <CommentForm
          handleCancel={() => setIsWorkerCommentModalOpen({isOpen: false})}
          stakeholderId={isWorkerModalOpen.worker.stakeholderId}
        />
    </Modal>
  )
}

const CommentForm = ({handleCancel, stakeholderId, editingComment}) => {
  const dispatch = useDispatch()
  const selectedProject = useSelector(state => state.projectsReducer.selectedProject)
  const userId = useSelector(state => state.authReducer.userId)

  const [commentData, setCommentData] = useState({comment: '', is_project_view_visible: true})
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    if (editingComment?.id) {
      setCommentData(editingComment)
    }
  }, [editingComment])

  const addComment = () => {
    if (!commentData.comment.length) return
    setIsLoading(true)
    dispatch(addCommentToStakeholderTC({
      comment: commentData.comment,
      is_project_view_visible: commentData.is_project_view_visible,
      project_id: selectedProject,
      stakeholder_id: stakeholderId,
      user_id: userId
    }))
      .then((r) => {
        setIsLoading(false)
        r.status === 201 && handleCancel()
      })
  }

  const editComment = () => {
    if (!commentData.comment.length) return
    setIsLoading(true)
    dispatch(editCommentToStakeholderTC(commentData))
      .then((r) => {
        setIsLoading(false)
        r.status === 200 && handleCancel()
      })
  }

  return (
    <>
      <Input.TextArea
        value={commentData.comment}
        onChange={(e) => setCommentData({...commentData, comment: e.target.value})}
        placeholder='Add new comment'
      />
      <Checkbox
        checked={commentData.is_project_view_visible}
        onChange={(e) => setCommentData({...commentData, is_project_view_visible: e.target.checked})}
        className={classes.projectViewCheckbox}
      >
        Include to the Project View
      </Checkbox>
      <div className={classes.btnArea}>
        <SecondaryButton
          text='CANCEL'
          onClick={handleCancel}
        />
        <PrimaryButton
          text='SAVE'
          loading={isLoading}
          onClick={editingComment?.id ? editComment : addComment}
        />
      </div>
    </>
  )
}

export default WorkerCommentsModal
