import React from 'react'
import classes from './Users.module.css'
import { PrimaryButton } from '../common/buttons/buttons'
import {pageDefaultHeight} from './../common/sizes'
import Search from './../common/Search/Search'
import UserInfoArea from '../common/UserInfoArea/UserInfoArea'
import Select from '../common/Select/Select'
import AdminsTable from "./AdminsTable";
import AdminsModal from "./AdminsModal";
import {setAdminsSearchString} from "../../helpers/localStorage";

const Admins = (props) => {

    const onSearchAdmin = (val) => {
        setAdminsSearchString(val.replace(/^\s+|\s+$/g, ''))
        props.search(val.replace(/^\s+|\s+$/g, ''))
    }

    return (
        <>
            <header className={classes.header}>
                <Search onChange={event => onSearchAdmin(event.target.value)} searchedValue={props.searchedValue}/>
                <UserInfoArea />
            </header>
            <div className={classes.wrapper} style={{height: pageDefaultHeight}}>
                <div className={classes.titleArea} >
                    <h1 className={classes.pageTitle}>
                        SYSTEM ADMINS
                    </h1>
                    <div>
                        <Select
                            options={props.userTypeOptions}
                            onSelect={(value) => props.selectUserType(value)}
                            style={{width: '200px', marginRight: '15px'}}
                            value={props.selectedUserType}
                            className='smallSelect'
                        />
                        <PrimaryButton
                            text='ADD SYSTEM ADMIN'
                            onClick={() => props.setModalData({isOpen: true})}
                        />
                    </div>
                </div>
                <AdminsTable
                    removeUser={props.removeUser}
                    setModalData={props.setModalData}
                    tableRows={props.tableRows}
                    searchedValue={props.searchedValue}
                    isAdminsTable={true}
                />
                {props.modalData.isOpen &&
                    <AdminsModal
                        modalData={props.modalData}
                        setModalData={props.setModalData}
                        searchUser={props.search}
                        searchedValue={props.searchedValue}
                        setTableRows={props.setTableRows}
                    />
                }
            </div>
        </>
    )
}

export default Admins