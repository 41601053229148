import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import _ from 'lodash'
import classes from './RecordEngagement.module.css'
import UserInfoArea from '../../common/UserInfoArea/UserInfoArea'
import Step1 from './Step1'
import Step2 from './Step2'
import { editStakeholderInProject, getProjectsByPeriod, getProjectStakeholdersTC, selectProjectTC } from '../../../store/projectsReducer'
import { pageDefaultHeight } from '../../common/sizes'
import { getProjectEngagementsTC } from '../../../store/engagementReducer'
import Spinner from '../../common/Spinner/Spinner'
import engagementStep1 from './../../../img/icons/engagementStep1.svg'
import engagementStep2 from './../../../img/icons/engagementStep2.svg'
import engagementStep1Active from './../../../img/icons/engagementStep1Active.svg'
import engagementStep2Active from './../../../img/icons/engagementStep2Active.svg'
import { getOrganizations } from '../../../store/organizationsReducer'
import {ReactComponent as NoDataImg} from './../../../img/noDataImg.svg'
import ProjectSelect from '../../common/ProjectSelect/ProjectSelect'

const RecordEngagement = () => {
  const {step} = useParams()
  const dispatch = useDispatch()
  const selectedProject = useSelector(state => state.projectsReducer.selectedProject)
  const userId = useSelector(state => state.authReducer.userId)
  const projectList = useSelector(state => state.projectsReducer.projectList)

  const [step1Changes, setStep1Changes] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState(null)

  const getProjectOptions = () => {
    const options = projectList.map(project => ({id: project.projId, value: project.projId, name: project.name}))
    return _.orderBy(options, [project => project.name.toLowerCase()], ['asc'])
  }

  useEffect(() => {
    if(!!userId) {
      setIsLoading(true)
      setError(null)
      dispatch(getOrganizations())
      !selectedProject && dispatch(getProjectsByPeriod('active', false, userId))
        .then((resp) => {
          if (!resp?.length) {
            setError('noProjects')
            setIsLoading(false)
          }
        })
      if (!!selectedProject) {
        Promise.all([
          dispatch(getProjectStakeholdersTC(selectedProject)),
          dispatch(getProjectEngagementsTC(selectedProject, false, true))
        ]).then((resp) => {
          const engagements = resp[1]
          !engagements?.length && setError('noEngagements')
          setIsLoading(false)
        })
      }
    }
  }, [userId, dispatch, selectedProject, step])

  const onSaveStep1 = () => {
    return Promise.all(
      step1Changes.map(stk => dispatch(editStakeholderInProject(selectedProject, stk.stakeholderId, stk)))
    ).then(() => dispatch(getProjectStakeholdersTC(selectedProject)))
  }

  if (error === 'noEngagements') {
    return (
      <>
      <header className={classes.header}>
        <ProjectSelect
          options={getProjectOptions()}
          onSelect={(value) => dispatch(selectProjectTC(value, projectList))}
          style={{width:'290px'}}
          value={selectedProject}
        />
        <UserInfoArea />
      </header>
      <div
        className={classes.mainAreaWrapper}
        style={{height: pageDefaultHeight, display: 'flex', justifyContent: 'center', alignItems:'center'}}
      >
        No engagements in this project
      </div>
      </>
    )
  }

  if (error === 'noProjects') {
    return (
      <>
        <header className={classes.header} style={{display:'flex', justifyContent:'flex-end'}}>
          <UserInfoArea />
        </header>
        <div className='emptyPage' style={{height: pageDefaultHeight}}>
          <NoDataImg />
        </div>
      </>
    )
  }

  if(!!isLoading) {
    return <Spinner style={{minHeight: pageDefaultHeight - 100 }}/>
  }

  return (
    <>
      <header className={classes.header}>
        <ProjectSelect
          options={getProjectOptions()}
          onSelect={(value) => dispatch(selectProjectTC(value, projectList))}
          style={{width:'290px'}}
          value={selectedProject}
          disabled={isLoading}
        />
        <UserInfoArea />
      </header>
      <div className={classes.mainAreaWrapper} style={{height: pageDefaultHeight}}>
      {step === '1' ? (
        <div style={{marginBottom: '10px'}}>
          <img src={engagementStep1Active} alt='' style={{marginRight: '10px'}}/>
          <img src={engagementStep2} alt=''/>
        </div>
      ) : (
        <div style={{marginBottom: '10px'}}>
          <img src={engagementStep1} alt='' style={{marginRight: '10px'}}/>
          <img src={engagementStep2Active} alt=''/>
        </div>
      )}
        {step === '1' &&
          <Step1
            step1Changes={step1Changes}
            setStep1Changes={setStep1Changes}
            onSaveStep1={onSaveStep1}
          />
        }
        {step === '2' && <Step2 />}
      </div>
    </>
  )
}

export default RecordEngagement
