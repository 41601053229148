import React, {useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {createUser, editUserData, getAllowedUserRoles, getSystemAdminsList, getUserList} from '../../store/usersReducer'
import UserModal from '../common/UsersTable/UserModal'

const AdminsModal = (props) => {
    const dispatch = useDispatch()
    const userRoles = useSelector(state => state.usersReducer.allowedUserRoles)
    const page = useSelector(state => state.usersReducer.currentPage)

    useEffect(() => {
        dispatch(getAllowedUserRoles())
    }, [dispatch])

    const closeModal = () => {
        props.setModalData({isOpen: false})
    }

    const editUser = (userData, userId) => {
        dispatch(editUserData(userData, userId))
            .then((resp) => !!resp && closeModal())
            .then(() => {
                if(props.searchedValue && props.searchedValue !== ''){
                    dispatch(getSystemAdminsList({
                        pagination_request: {
                            page: page + 1,
                            size: 10,
                            sort: []
                        },
                        filter_request: {
                            search_value: props.searchedValue.trim()
                        }
                    }, true))
                }
                else{
                    dispatch(getSystemAdminsList({
                        pagination_request: {
                            page: page,
                            size: 10,
                            sort: []
                        },
                        filter_request: {
                            search_value: "system_admin"
                        },

                    }))
                }

            })
    }

    const addUser = (userData) => {
        dispatch(createUser(userData))
            .then((resp) => !!resp && closeModal())
            .then(() => {
                if(props.searchedValue && props.searchedValue !== ''){
                    dispatch(getSystemAdminsList({
                        filter_request: {
                            search_value: props.searchedValue.trim()
                        }
                    }, true))
                }
                else{
                    dispatch(getSystemAdminsList({
                        filter_request: {
                            search_value: "system_admin"
                        },

                    }))
                }

            })
    }

    return (
        <UserModal
            addUser={addUser}
            editUser={editUser}
            modalData={props.modalData}
            setUsersModalData={props.setModalData}
            roleOptions={userRoles}
            isAdminsTable={true}
        />
    )
}

export default AdminsModal