import React, {useEffect, useState} from 'react'
import classes from './AddToProjectModal.module.css'
import {ControlledInput} from '../../../../../common/Input/Input'
import {SelectInput} from '../../../../../common/Select/Select'
import {PrimaryButton, SecondaryButton} from '../../../../../common/buttons/buttons'
import Slider from './../../../../../common/Slider/Slider'
import Checkbox from './../../../../../common/Checkbox/Checkbox'
import Switch from './../../../../../common/Switch/Switch'
import isEqual from "lodash/isEqual";

const ModalForm = (props) => {
    const [isDirty, setIsDirty] = useState(false);
    const notesSymbolsLeft = 255 - props.projectData.roleDescription?.length

    useEffect(() => {
        const dirtyCheck = !isEqual(props.initialValues, props.projectData);
        setIsDirty(dirtyCheck);
    }, [props.initialValues, props.projectData]);


    const onSave = () => {
        if (props.modalData.editingData) {
            props.updateProjectDetails()
        } else {
            props.saveProjectDetails()
        }
        props.setHasProjectChanges(true)
    }

    return (
        <div className={classes.formWrapper}>
            <SelectInput
                label='Project Name*'
                value={props.projectData.projId}
                onChange={(event) => {
                    props.updateProjectData({
                        projId: event.target.value,
                        projectName: props.projects.find(proj => proj.id === event.target.value).name
                    })
                }}
                options={props.projects}
                InputProps={{
                    className: props.projectData.projId === 'none' ? 'placeholderIsShown LongInput' : 'LongInput',
                }}
            />
            <div className={classes.combinedInputsArea} style={{width: '101%'}}>
                <div style={{width: '324px'}}>
                    <ControlledInput
                        label='Project Workstream'
                        placeholder='Project Workstream'
                        value={props.projectData.team}
                        onChange={(event) => props.updateProjectData({team: event.target.value})}
                    />
                </div>
                <div style={{width: '324px'}}>
                    <ControlledInput
                        label='Project Role Title'
                        placeholder='Project Role Title'
                        value={props.projectData.roleTitle}
                        onChange={(event) => props.updateProjectData({roleTitle: event.target.value})}
                    />
                </div>
            </div>
            <div style={{height: '180px'}}>
                <ControlledInput
                    label='Project Role Description'
                    placeholder='Project Role Description'
                    multiline={true}
                    rows={6}
                    className='autoWidthInput'
                    value={props.projectData.roleDescription}
                    onChange={(event) => props.updateProjectData({roleDescription: event.target.value})}
                    inputProps={{
                        maxLength: 255,
                    }}
                />
            </div>
            {!!props.projectData.roleDescription?.length &&
                <div style={{marginTop: '-25px', color: '#959595', marginBottom: '10px'}}>
                    {notesSymbolsLeft} {notesSymbolsLeft === 1 ? 'symbol' : 'symbols'} left
                </div>
            }
            <div className={classes.combinedInputsArea}>
                <div style={{width: '328px'}}>
                    <SelectInput
                        label='Disrupter'
                        options={[
                            {id: 1, value: true, title: 'Yes'},
                            {id: 2, value: false, title: 'No'},
                            {id: 3, value: 'unknown', title: 'Unknown'},
                        ]}
                        value={props.projectData.isDisrupter === null ? 'unknown' : props.projectData.isDisrupter}
                        onChange={(event) => props.updateProjectData({isDisrupter: event.target.value === 'unknown' ? null : event.target.value})}
                    />
                </div>
                <div style={{width: '328px'}}>
                    <SelectInput
                        label='FTE'
                        options={[
                            {id: 6, value: 'n/a', title: 'n/a'},
                            {id: 5, value: 'unknown', title: 'Unknown'},
                            {id: 1, value: 0.1, title: '0.1'},
                            {id: 2, value: 0.25, title: '0.25'},
                            {id: 3, value: 0.5, title: '0.5'},
                            {id: 4, value: 0.75, title: '0.75'},
                        ]}
                        value={props.projectData.workloadPct === null ? 'unknown' : props.projectData.workloadPct}
                        onChange={(event) => props.updateProjectData({workloadPct: event.target.value === 'unknown' ? null : event.target.value})}
                    />
                </div>
            </div>
            <div className={classes.combinedInputsArea}>
                <div className={classes.slider} style={{marginLeft: '7px', width: '313px'}}>
                    <Switch
                        leftLabel={<span style={{fontWeight: 600}}>Influence</span>}
                        checked={props.projectData.influenceLvl !== null}
                        onChange={() => {
                            props.projectData.influenceLvl !== null
                                ? props.updateProjectData({influenceLvl: null})
                                : props.updateProjectData({influenceLvl: 1})
                        }}
                    />
                    {props.projectData.influenceLvl !== null &&
                        <Slider
                            defaultValue={props.projectData.influenceLvl}
                            onChange={(_, value) => props.updateProjectData({influenceLvl: value})}
                            value={props.projectData.influenceLvl}
                            min={1}
                            max={10}
                            style={{marginLeft: '12px'}}
                            width='309px'
                        />
                    }
                </div>
                <div className={classes.slider}>
                    <Switch
                        leftLabel={<span style={{fontWeight: 600}}>Interest</span>}
                        checked={props.projectData.interestLvl !== null}
                        onChange={() => {
                            props.projectData.interestLvl !== null
                                ? props.updateProjectData({interestLvl: null})
                                : props.updateProjectData({interestLvl: 1})
                        }}
                    />
                    {props.projectData.interestLvl !== null &&
                        <Slider
                            defaultValue={props.projectData.interestLvl}
                            min={1}
                            max={10}
                            width='309px'
                            value={props.projectData.interestLvl}
                            onChange={(_, value) => props.updateProjectData({interestLvl: value})}
                            style={{marginRight: '20px'}}
                        />
                    }
                </div>
            </div>
            <div className={classes.slider} style={{marginTop: '20px', marginLeft: '7px', paddingRight: '7px'}}>
                <Switch
                    leftLabel={<span style={{fontWeight: 600, marginRight: '9px'}}>Support</span>}
                    checked={props.projectData.supportLvl !== null}
                    onChange={() => {
                        props.projectData.supportLvl !== null
                            ? props.updateProjectData({supportLvl: null})
                            : props.updateProjectData({supportLvl: 0})
                    }}
                />
                {props.projectData.supportLvl !== null &&
                    <Slider
                        defaultValue={props.projectData.supportLvl}
                        min={-5}
                        max={5}
                        style={{marginLeft: '12px'}}
                        width='309px'
                        value={props.projectData.supportLvl}
                        onChange={(_, value) => props.updateProjectData({supportLvl: value})}
                    />
                }
            </div>
            <Checkbox
                checked={props.projectData.isProjectTeamMember}
                onChange={(e) => props.updateProjectData({isProjectTeamMember: e.target.checked})}
                label={<span style={{fontWeight: 600}}>Project Team Member</span>}
                wrapperStyle={{marginTop: '10px', marginLeft: '7px'}}
            />
            <div className={classes.buttonsArea}>
                <SecondaryButton
                    onClick={() => props.closeModal()}
                    style={{height: '36px', width: '132px'}}
                    text='Cancel'
                />
                <PrimaryButton
                    onClick={onSave}
                    style={{height: '36px', width: '132px', marginLeft: '20px'}}
                    text='OK'
                    disabled={!isDirty}
                />
            </div>
        </div>
    )
}

export default ModalForm
