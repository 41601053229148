const initialState = {
  contactsTable: [],
  projectsTable: [],
  organisationsTable: [],
  customersTable: [],
  usersTable: [],
  editProjectContactsTable: [],
  editCustomerUsersTable: [],
  editContactProjectsTable: []
}

const tablesReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_TABLE_COLUMNS': 
      return {...state, [action.table]: action.columns}
    case 'RESET_TABLE_PARAMS':
      return action.table === 'all'
        ? initialState
        : {...state, [action.table]: []}
    default: return state
  } 
}

export const setTableColumns = (table, columns) => ({type: 'SET_TABLE_COLUMNS', table, columns})
export const resetTableParams = (table) => ({type: 'RESET_TABLE_PARAMS', table})

export default tablesReducer
