import React from 'react'
import {default as SwitchMaterial} from '@material-ui/core/Switch'
import { withStyles } from '@material-ui/core/styles'

const Switch = (props) => {
  const switchLabelRightWeight = props.checked ? 600 : 300
  const switchLabelLeftWeight = props.checked ? 300 : 600

  const StyledSwitch = withStyles({
    switchBase: {
      color: '#0E9AFC',
      '&$checked': {
        color: '#0E9AFC',
      },
      '&$checked + $track': {
        backgroundColor: '#0E9AFC',
      },
    },
    checked: {},
    track: {},
  })(SwitchMaterial)

  return (
    <div style={{...props.style, display: 'inline'}}>
      <span style={{fontWeight: switchLabelLeftWeight, ...props.leftLabelStyle}}>{props.leftLabel}</span>
      <StyledSwitch 
        checked={props.checked} 
        onChange={(e) => props.onChange(e)} 
        size={props.size} 
      />
      <span style={{fontWeight: switchLabelRightWeight}}>{props.rightLabel}</span>
    </div>
  )
}

export default Switch
