import React from 'react'
import { connect } from 'react-redux'
import classes from './PhoneArea.module.css'
import {Input} from './../../../../../common/Input/Input'
import {SelectAdornment, Radio} from '../../../formComponents'
import {ReactComponent as AddFieldIcon} from './../../../../../../img/form/addFieldIcon.svg'
import {ReactComponent as RemoveFieldIcon} from './../../../../../../img/form/removeFieldIcon.svg'
import {updatePhoneArea} from './../../../../../../store/stakeholderFormReducer'

const PhoneArea = (props) => {
  return (
    <div className={classes.telephoneBlock}>
      <div>
        {props.phoneArea.map(input => {
          const adornmentName = `phones[${input - 1}].phoneType`
          return (
            <div className={classes.phoneInputRow} key={input}>
              <Input        
                label={`Telephone ${input}`}
                name={`phones[${input - 1}].phone`}
                placeholder={`Telephone ${input}`}
                className='autoWidthInput'
                InputProps={{
                  startAdornment: (<SelectAdornment name={adornmentName} />)
                }}
              />
              {/* <Input   
                className='autoWidthInput'
                label={`Telephone ${input} Comment`}
                name={`phones[${input - 1}].comment`}
                placeholder='Comment'
              /> */}
              {props.phoneArea?.length === input ? (
                <div className={classes.addInputBtn}>
                  {props.phoneArea?.length > 1 && 
                    <RemoveFieldIcon 
                      className={classes.removeInputIcon}
                      onClick={() => props.removeField()} 
                    />
                  }
                  <AddFieldIcon 
                    className={classes.addInputIcon}
                    onClick={() => props.addNewField()}
                  />
                </div>
              ) : (
                <div></div>
              )}
            </div>
          )
        })}
      </div>
      <div className={classes.radioArea}>
        <Radio 
          value={props.defaultPhone}
          inputName='phone'
          inputs={props.phoneArea}
          onChange={event => props.changeDefaultPhone(Number(event.target.value))}
        />
      </div>
    </div>
  )
}

const mapStateToProps = (state => ({
  phoneArea: state.stakeholderFormReducer.phoneArea
}))

export default connect(mapStateToProps, {updatePhoneArea})(PhoneArea)
